<template>
  <div>
    <div @click="deleteEvent">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IEvent } from '@/interfaces';
import { lang } from '@/plugins';
import Swal from 'sweetalert2';

type Props = {
  event: IEvent;
};
const props = defineProps<Props>();
async function deleteEvent() {
  try {
    const res = await Swal.fire({
      cancelButtonText: lang('l.cancel'),
      confirmButtonText: lang('l.yes'),
      icon: 'warning',
      showCancelButton: true,
      text: lang('l.delete.generalQuestion'),
      title: lang('l.delete.default'),
    });

    if (res.isConfirmed) {
      useAxios(route('events.destroy', { event: props.event }), { method: 'DELETE' });
      window.location.replace('/races');
    }
  }
  catch (error) {
    console.error(error);
  }
}
</script>
