<template>
  <td ref="td" :class="overrideClass ? overrideClass : `border-b px-4 py-2 ${additionalClass}`">
    <slot v-if="$slots.default" />
    <template v-else>
      {{ displayValue }}
    </template>
  </td>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';
import 'dayjs/locale/de';

type Props = {
  dateFormat?: string;
  isDate?: boolean;
  overrideClass?: string;
  value?: Date | number | string;
};
const props = withDefaults(defineProps<Props>(), {
  dateFormat: 'DD.MM.YYYY',
  overrideClass: undefined,
  value: undefined,
});

dayjs.locale('de');

function isDate() {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  return regex.test(props.value);
}

const displayValue = computed(() => {
  if (!props.value) return;
  if (props.isDate || (isDate() && (typeof props.value === 'string' || props.value instanceof Date))) {
    return dayjs(props.value).format(props.dateFormat);
  }
  return props.value;
});

const td = ref<HTMLElement>();

onMounted(() => {
  if (td.value) setClassesDependsOnTableRow(td.value);
});

const additionalClass = ref<string>('');

function setClassesDependsOnTableRow(element: HTMLElement) {
  const parent = element.parentNode as HTMLElement;
  if (parent.tagName === 'TR') {
    const hasBgClass = [...parent.classList].some(className => className.startsWith('bg-'));
    if (!hasBgClass) {
      additionalClass.value = 'bg-white';
    }
  }
}
</script>
