import type { SwalConfirmationProps } from '../interfaces/swal.interface';
import Swal, { type SweetAlertOptions } from 'sweetalert2';
import { getTranslatedLabel } from '.';
import { lang } from '../plugins/lang';

export async function SwalConfirmation({ template, title }: SwalConfirmationProps) {
  return await Swal.fire({
    icon: 'warning',
    showCancelButton: true,
    title: getTranslatedLabel(title),
    ...getTemplate(template),
  });
}

function getTemplate(template: 'delete'): SweetAlertOptions {
  switch (template) {
    case 'delete':
      return {
        cancelButtonColor: '#416ab9',
        cancelButtonText: lang('l.cancel'),
        confirmButtonColor: '#ef4444',
        confirmButtonText: lang('l.delete.default'),
        focusCancel: true,
      };
    default:
      return {
        cancelButtonText: lang('l.cancel'),
        confirmButtonText: lang('l.yes'),
      };
  }
}
