export default {
  install: (app: any) => {
    app.config.globalProperties.$route = (
      name: string,
      params?: string | number | Array<any> | object,
      absolute = true,
    ) => {
      return globalThis.route(name, params, absolute);
    };
  },
};
export function route(name: string, params?: string | number | Array<any> | object, absolute = true) {
  return globalThis.route(name, params, absolute);
}
