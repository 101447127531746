<template>
  <div class="mb-4 w-full 2xl:w-1/2 3xl:max-w-xl">
    <VueCard class="mx-2 h-full flex flex-col justify-between">
      <template #title>
        <div class="my-auto text-xl">
          {{ raceRunner.firstname }} {{ raceRunner.lastname }}
        </div>
        <VueButton @click="signoff">
          <i
            aria-hidden="true"
            class="far fa-trash" />
        </VueButton>
      </template>
      <div class="grow p-3">
        <div class="mb-5">
          <div v-if="raceRunner.race">
            {{ $lang('l.race.*') }}: {{ raceRunner.race.name }}
          </div>
          <div>{{ $lang('l.category.default') }}: {{ raceRunner.category_race.category.short_name }}</div>
        </div>
        <div>{{ $lang('l.year_of_birth') }}: {{ raceRunner.year_of_birth }}</div>
        <div>{{ $lang('l.place') }}: {{ raceRunner.plz }} {{ raceRunner.city }}</div>
        <div v-if="raceRunner.club">
          {{ $lang('l.club') }}: {{ raceRunner.club.name }}
        </div>
        <div>{{ $lang('l.category.default') }}: {{ raceRunner.category_race.category.short_name }}</div>
        <div>{{ $lang('l.block.header') }}: {{ $lang(raceRunner.race_region_text) }}</div>
        <EditSiNumber
          class="mt-6"
          :race-runner="raceRunner" />
        <EditStartTime
          v-if="
            raceRunner.category_race.has_starttime_request
              || raceRunner.category_race.has_parental_starttime_request
          "
          :parental-request-notes-confirmed="parentalRequestNotesConfirmed"
          :race-runner="raceRunner"
          :starttime-request-notes-confirmed="starttimeRequestNotesConfirmed"
          @confirm-parental-request-notes="$emit('confirmParentalRequestNotes')"
          @confirm-starttime-request-notes="$emit('confirmStarttimeRequestNotes')" />
        <div
          v-if="raceRunner.has_starts"
          class="relative mt-5 min-h-16">
          <VueSelectStart
            :as-event-admin="asEventAdmin"
            :race="raceRunner.race"
            :race-runner="raceRunner" />
        </div>
        <ExtraList
          v-if="hasExtras"
          :race-runner="raceRunner" />
      </div>
      <template #footer>
        <div class="h-7 w-full text-right text-lg font-bold">
          <p>
            {{ $lang('l.total') }}: CHF
            <span
              v-if="raceRunner.price_info.race.discount"
              class="text-red-500 line-through">
              {{ formatCurrency(raceRunner.price_info.race.price) }}
            </span>
            {{ formatCurrency(raceRunner.price_info.tot) }}
          </p>
        </div>
      </template>
    </VueCard>
  </div>
</template>

<script setup lang="ts">
import type { IRaceRunner } from '@/interfaces';

import axios from 'axios';
import { computed, type PropType, provide } from 'vue';

import { route } from '../../plugins/route';
import { useCartStore } from '../../store/cart';
import VueButton from '../button.vue';
import VueCard from '../card.vue';
import EditSiNumber from '../raceRunner/editSiNumber.vue';
import ExtraList from '../raceRunner/extraList.vue';
import VueSelectStart from '../raceRunner/selectStart.vue';
import EditStartTime from './editStartTime.vue';

const props = defineProps({
  asEventAdmin: { default: false, type: Boolean },
  index: { required: true, type: Number },
  parentalRequestNotesConfirmed: { required: true, type: Boolean },
  raceRunner: { required: true, type: Object as PropType<IRaceRunner> },
  starttimeRequestNotesConfirmed: { required: true, type: Boolean },
});

defineEmits(['confirmStarttimeRequestNotes', 'confirmParentalRequestNotes']);

const cartStore = useCartStore();

provide('raceRunnerIndex', props.index);

const hasExtras = computed(() => {
  if (props.raceRunner.race.extra_def_permissions.length > 0) return true;
  if (props.raceRunner.category_race.extra_def_permissions.length > 0) return true;
  if (props.raceRunner.race.event.extra_def_permissions.length > 0) return true;
  return false;
});

function signoff() {
  try {
    axios.delete(route('webapi.racerunner.delete', props.raceRunner.id));
    const index = cartStore.raceRunners.findIndex(item => item.id === props.raceRunner.id);
    cartStore.raceRunners.splice(index, 1);
  }
  catch (error) {
    console.log(error);
  }
}

const formatCurrency = (amount: number) => amount.toFixed(2);
</script>
