<template>
  <div class="rounded-lg bg-white text-base text-gray-900 shadow-xl">
    <div
      v-if="$slots.title"
      class="flex content-center justify-between rounded-t-lg border-b bg-lightgray-300 p-3">
      <slot name="title" />
    </div>

    <slot />

    <div
      v-if="$slots.footer"
      class="flex content-center justify-between p-3">
      <slot name="footer" />
    </div>
  </div>
</template>
