<template>
  <div v-tooltip.auto="tooltip" class="block">
    <InputLabel v-if="label" :for="id ?? name" :label="label" :required="required" />
    <input
      :id="id ?? name"
      v-model="value"
      class="py-auto h-10 w-full rounded-md border border-gray-200 px-4 outline-none disabled:opacity-60"
      :class="hasError ? 'border-red-600' : 'focus:border-blue-700'"
      :disabled="disabled"
      :max="max"
      :min="min"
      :name="name"
      onkeydown="return type === 'number' ? event.keyCode !== 69 : true"
      :placeholder="placeholder"
      :type="type"
      @blur="onBlur"
      @input="onInput">
    <InputFooter
      :has-error="hasError"
      :message="hasError && errorMessage ? errorMessage : hint ? hint : undefined" />
  </div>
</template>

<script setup lang="ts" generic="T">
import type { IExtendedProps } from './types';

import { useFieldCustom } from './composables';
import InputFooter from './inputFooter.vue';
import InputLabel from './inputLabel.vue';

type Props = {
  disabled?: boolean;
  max?: number;
  min?: number;
  modelValue?: T;
  parser?: (value: T) => any;
  type?: string;
  tooltip?: string;
};

type Emits = {
  'blur': [value: T];
  'change': [];
  'update:modelValue': [value: T];
};

const props = defineProps<Props & IExtendedProps>();
const emit = defineEmits<Emits>();

const { errorMessage, hasError, value } = useFieldCustom<T>(props);

function onInput() {
  const { parser } = props;
  if (!parser)
    return;
  value.value = parser(value.value);
}

function onBlur() {
  const { parser } = props;
  emit('blur', parser ? parser(value.value) : value.value);
}
</script>
