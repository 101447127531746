<template>
  <div>
    <VueCheckbox
      v-model="isChecked"
      :disabled="result.linked"
      :label="label"
      name="runners[]"
      :tooltipp="tooltipp"
      :value-id="result.id" />
  </div>
</template>

<script setup lang="ts">
import type { ISolvRunnerResult } from '@/interfaces';
import { computed, type PropType, ref } from 'vue';
import { lang } from '../../plugins/lang';
import VueCheckbox from '../checkbox.vue';

const props = defineProps({
  result: Object as PropType<ISolvRunnerResult>,
});
const isChecked = ref(props.result.linked);

const label = computed(() => {
  return `${props.result.lastname} ${props.result.firstname}<br>(${props.result.year_of_birth})`;
});

const tooltipp = computed(() => {
  if (props.result.linked) {
    return lang('l.runnerIsAllreadyLinked');
  }
});
</script>
