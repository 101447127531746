<template>
  <div class="absolute bottom-[-2px] right-[-8px] size-[22px] flex flex-col items-center justify-center rounded-full bg-gray-300 text-lg text-white leading-none">
    {{ cartStore.cartItemCount }}
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '../../store/cart';

const props = defineProps({
  cartItemCount: { default: 0, type: Number },
});
const cartStore = useCartStore();
cartStore.$patch({ count: props.cartItemCount });
</script>
