<template>
  <Card v-if="userManagers.length">
    <template #title>
      {{ $lang('l.managedBy') }}
    </template>
    <div class="p-3">
      <div
        v-for="(manager, index) in userManagers"
        :key="index"
        class="flex justify-between gap-2">
        <div>{{ manager.firstname }} {{ manager.name }}</div>
        <div
          class="flex cursor-pointer"
          @click="deleteManager(manager)">
          <i class="fas fa-x m-auto text-red-600" />
        </div>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { IRunner, IUser } from '@/interfaces';

import axios from 'axios';
import Swal from 'sweetalert2';
import { type PropType, ref, type Ref } from 'vue';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import Card from '../card.vue';

const props = defineProps({
  runners: { type: Array as PropType<IRunner[]> },
  usersManageMyRunner: { default: [], type: Array as PropType<IUser[]> },
});

const userManagers: Ref<IUser[]> = ref(props.usersManageMyRunner);

async function deleteManager(manager: IUser) {
  try {
    const result = await Swal.fire({
      cancelButtonColor: '#d33',
      cancelButtonText: lang('l.cancel'),
      confirmButtonColor: '#3085d6',
      confirmButtonText: lang('l.unlink'),
      icon: 'warning',
      showCancelButton: true,
      text: lang('l.doYouWannaUnlinkManagementOf', { name: `${manager.firstname} ${manager.name}` }),
      title: lang('l.attention'),
    });
    if (result.isConfirmed) {
      const runner = props.runners.find(el => el.pivot.me === true || el.pivot.me === 1);
      await axios.get(route('runner.unlink', [runner, manager]));
      const index = userManagers.value.findIndex(el => el.id === manager.id);
      userManagers.value.splice(index, 1);
    }
  }
  catch (error) {
    console.log(error);
  }
}
</script>
