<template>
  <div class="break-inside w-full">
    <Card>
      <template #title>
        {{ $lang('l.missingIof') }}
        <div class="ml-2 flex gap-2">
          <div
            class="cursor-pointer"
            @click="getInvalidIofRunners(false)">
            <div v-if="isLoading">
              <i
                aria-hidden="true"
                class="fas fa-sync fa-spin" />
            </div>
            <div v-else>
              <i
                aria-hidden="true"
                class="fas fa-sync" />
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="!initializing"
        class="p-3">
        <div v-if="invalidIofRunners.length > 0">
          <ul>
            <li
              v-for="(runner, index) in invalidIofRunners"
              :key="index">
              {{ runner.firstname }} {{ runner.lastname }}
              <a :href="`mailto:${runner.email}?subject=${$lang('l.mailSubjectMissingIof')}`">
                <iconify-icon
                  aria-hidden="true"
                  class="ml-1 text-blue-600 hover:text-blue-700"
                  icon="fal:paper-plane" />
              </a>
            </li>
          </ul>
        </div>
        <div v-else>
          {{ $lang('l.noMissingIof') }}
        </div>
      </div>
      <div
        v-else
        class="w-full p-3">
        <div class="mx-auto text-center text-4xl">
          <i class="fas fa-circle-notch fa-spin" />
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { IRunner, ITeam } from '@/interfaces';

import axios from 'axios';
import { onMounted, ref, type Ref } from 'vue';

import Card from '../card.vue';

const props = defineProps({
  raceId: Number,
});

const isLoading = ref(false);
const initializing = ref(true);
const invalidIofRunners: Ref<IRunner[] | ITeam[]> = ref([]);

onMounted(async () => {
  await getInvalidIofRunners(true);
  initializing.value = false;
});

async function getInvalidIofRunners(init: boolean) {
  try {
    if (!init) {
      isLoading.value = true;
    }

    const request = await axios.get<IRunner[] | ITeam[]>(init ? `/webapi/races/${props.raceId}/missingiof` : `/webapi/races/${props.raceId}/missingiof?from_solv=1`);
    invalidIofRunners.value = request.data;

    if (!init) {
      isLoading.value = false;
    }
  }
  catch (error) {
    console.log(error);
  }
}
</script>
