<template>
  <form
    class="flex flex-col gap-5"
    novalidate
    @submit="searchUser">
    <BaseInput
      :label="$lang('l.lastname')"
      name="name"
      required />
    <BaseInput
      :label="$lang('l.firstname')"
      name="firstname" />
    <BaseButton
      :label="$lang('l.user.search')"
      type="submit"
      variant="block" />
  </form>
</template>

<script setup lang="ts">
import type { IEvent, IUser } from '@/interfaces';
import type { PropType } from 'vue';
import axios from 'axios';
import { useForm } from 'vee-validate';
import { Toast } from '../../helpers/toast';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import BaseButton from '../base/baseButton.vue';
import BaseInput from '../base/baseInput.vue';

const props = defineProps({
  event: { required: true, type: Object as PropType<IEvent> },
});
const emits = defineEmits(['userRes']);
const { handleSubmit } = useForm<UserData>();

const searchUser = handleSubmit(async (values) => {
  try {
    const res = await axios.get<IUser>(route('users.getAll'), {
      params: {
        event_id: props.event.id,
        firstname: values.firstname ? values.firstname : undefined,
        name: values.name,
      },
    });
    emits('userRes', res.data);
  }
  catch (error) {
    Toast.fire({ icon: 'error', text: error.response.data.message, title: lang('l.errorTitle') });
  }
});

interface UserData {
  firstname: string;
  name: string;
}
</script>
