<template>
  <div>
    <div
      class="flex flex-col gap-2 divide-y rounded-lg bg-white text-base text-gray-900 shadow-xl transition duration-300 ease-in-out hover:shadow-button">
      <form
        novalidate
        @submit="submit">
        <div class="flex content-center justify-between rounded-t-lg border-b bg-lightgray-300 p-3">
          <div>
            <BaseInput
              :disabled="!editMode"
              name="name"
              no-label
              required />
          </div>
          <div>
            <button
              v-if="!editMode && !startlist.has_starts"
              class="mx-2 cursor-pointer"
              @click="editMode = true">
              <i
                aria-hidden="true"
                class="far fa-edit" />
            </button>
            <button
              v-if="showDeleteStartlistButton"
              class="mx-2 cursor-pointer disabled:cursor-not-allowed disabled:opacity-25"
              :disabled="loading.delete"
              @click="deleteStartlist">
              <i
                aria-hidden="true"
                class="far fa-trash-alt" />
            </button>
            <button
              v-if="editMode"
              class="mx-2 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
              :disabled="loading.update || !meta.dirty"
              type="submit">
              <i
                aria-hidden="true"
                class="far fa-save" />
            </button>
            <button
              v-if="editMode"
              class="mx-2 cursor-pointer"
              @click="cancel">
              <i
                aria-hidden="true"
                class="far fa-times" />
            </button>
          </div>
        </div>
        <div class="p-3 text-sm md:pl-6 md:text-base">
          <div class="flex flex-col gap-2">
            <div class="flex flex-row justify-between">
              <div>
                <div>{{ $lang('l.firstStartTime') }}</div>
                <BaseInput
                  :disabled="!editMode"
                  name="begin"
                  no-label
                  required
                  rules="required"
                  type="time" />
              </div>
              <div class="pl-5">
                <div>{{ $lang('l.lastStartTime') }}</div>
                <div class="relative h-[2.9rem]">
                  <div
                    class="input box-border block w-20 cursor-not-allowed rounded-lg border border-solid border-gray-200 px-4 py-2 text-center text-gray-900 shadow-md"
                    :class="!editMode ? 'opacity-50' : 'opacity-80'"
                    type="time">
                    {{ lastStartingTime ? lastStartingTime : '--:--' }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>{{ $lang('l.interval') }}</div>
              <BaseInput
                :disabled="!editMode"
                name="interval"
                no-label
                required
                rules="required"
                type="number" />
            </div>
            <div>
              <div>
                {{ $lang('l.numberOfStartingPlaces') }}
              </div>
              <BaseInput
                :disabled="!editMode"
                name="amount"
                no-label
                required
                rules="required"
                type="number" />
            </div>
          </div>
        </div>
      </form>
      <div>
        <div class="p-3 text-sm md:pl-6 md:text-base">
          <div>{{ $lang('l.categories.default') }}</div>
          <div class="my-3 flex flex-wrap gap-2">
            <StartlistElementCategoryRace
              v-for="(categoryRace, index) in categoryRacesOnStartlist"
              :key="index"
              :category-race="categoryRace"
              @detached="detachedCategoryRace" />
          </div>
          <VueSelect
            v-model="selectedCategoryRace"
            :disabled="loading.attach"
            :options="availableCategoryRaces"
            :search-keys="['category.short_name']"
            searchable
            :show-option="(categoryRace) => categoryRace.category.short_name"
            @update:model-value="attacheCategoryRace" />
        </div>
      </div>
      <div
        v-if="startlist"
        class="flex content-center justify-between p-3">
        <BaseButton
          :class="{ invisible: !createStartsIsVisible }"
          :disabled="!createStartsIsEnabled"
          :label="$lang('l.createStarts')"
          @click="createStarts" />
        <BaseButton
          :class="{ invisible: !deleteStartsIsVisible }"
          :disabled="!deleteStartsIsEnabled"
          :label="$lang('l.deleteStarts')"
          @click="deleteStarts" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, IRace, IStartlist } from '@/interfaces';
import { errorHandler } from '@/helpers';
import axios from 'axios';
import { sortBy } from 'lodash';
import { useForm } from 'vee-validate';
import { computed, type PropType, type Ref, ref } from 'vue';
import { isoToInput, timeToDayjs, timeToIso } from '../../helpers/dayjs';
import { route } from '../../plugins/route';
import BaseButton from '../base/baseButton.vue';
import BaseInput from '../base/baseInput.vue';
import VueSelect from '../select.vue';
import StartlistElementCategoryRace from './startlistElementCategoryRace.vue';

const props = defineProps({
  categoryRaces: { default: () => [], type: Array as PropType<ICategoryRace[]> },
  race: { required: true, type: Object as PropType<IRace> },
  startlist: { required: true, type: Object as PropType<IStartlist> },
});

const emits = defineEmits(['updated', 'deleted', 'updatedCategoryRace']);

const { handleSubmit, meta, resetForm, values } = useForm<IStartlist>({
  initialValues: {
    ...props.startlist,
    begin: isoToInput(props.startlist.begin, 'time'),
  },
});

const categoryRacesList: Ref<ICategoryRace[]> = ref(props.categoryRaces);
const loading = ref({
  attach: false,
  delete: false,
  startRequest: false,
  update: false,
});
const editMode = ref(false);
const selectedCategoryRace: Ref<ICategoryRace | undefined> = ref();

const submit = handleSubmit((values) => {
  updateStartlist(values);
});

const categoryRacesOnStartlist = computed(() => {
  return categoryRacesList.value.filter(categoryRace => categoryRace.startlist_id === props.startlist.id);
});

const availableCategoryRaces = computed(() => {
  return sortBy(
    categoryRacesList.value.filter(categoryRace => !categoryRace.startlist_id),
    'category.short_name',
  );
});

const lastStartingTime = computed(() => {
  if (!values.amount || !values.interval) return;
  return timeToDayjs(values.begin)
    .add(values.interval * values.amount - values.interval, 'm')
    .format('HH:mm');
});

async function attacheCategoryRace(categoryRace: ICategoryRace) {
  try {
    loading.value.attach = true;
    const res = await axios.patch<ICategoryRace>(route('webapi.categoryRace.patch', categoryRace), {
      startlist_id: props.startlist.id,
    });
    const index = categoryRacesList.value.findIndex(el => el.id === res.data.id);
    categoryRacesList.value[index] = res.data;
    selectedCategoryRace.value = null;
    loading.value.attach = false;
  }
  catch (error) {
    errorHandler(error);
    loading.value.attach = false;
  }
}

async function updateStartlist(startlist: IStartlist) {
  try {
    loading.value.update = true;
    const res = await axios.patch(route('webapi.startlist.patch', startlist), {
      ...startlist,
      begin: timeToIso(startlist.begin, props.race.race_dates[0]?.start_date),
    });
    emits('updated', res.data);
    resetStartlist(res.data);
    editMode.value = false;
    loading.value.update = false;
  }
  catch (error) {
    errorHandler(error);
    loading.value.update = false;
  }
}
async function deleteStartlist() {
  try {
    loading.value.delete = true;
    await axios.delete(route('webapi.startlist.delete', props.startlist));
    emits('deleted', props.startlist);
    loading.value.delete = false;
  }
  catch (error) {
    errorHandler(error);
    loading.value.delete = false;
  }
}

async function cancel() {
  resetStartlist(props.startlist);
  editMode.value = false;
}

async function createStarts() {
  try {
    loading.value.startRequest = true;
    const res = await axios.post(route('webapi.startlist.createstarts', props.startlist));
    emits('updated', res.data);
    loading.value.startRequest = false;
  }
  catch (error) {
    errorHandler(error);
    loading.value.startRequest = false;
  }
}

async function deleteStarts() {
  try {
    loading.value.startRequest = true;
    const res = await axios.delete<IStartlist>(route('webapi.startlist.deleteStarts', props.startlist));
    emits('updated', res.data);
    loading.value.startRequest = false;
  }
  catch (error) {
    errorHandler(error);
    loading.value.startRequest = false;
  }
}

const createStartsIsVisible = computed(() => {
  if (editMode.value) return false;
  if (props.startlist.has_starts) return false;
  return true;
});
const createStartsIsEnabled = computed(() => {
  if (Object.values(loading.value).includes(true)) return false;
  if (!values.amount || !values.interval) return false;
  if (!meta.value.valid) return false;
  return true;
});
const deleteStartsIsVisible = computed(() => {
  if (!props.startlist.has_starts) return false;
  return true;
});
const deleteStartsIsEnabled = computed(() => {
  if (Object.values(loading.value).includes(true)) return false;
  if (!props.startlist.starts_are_deletable) return false;
  return true;
});

const showDeleteStartlistButton = computed(() => {
  if (editMode.value) return false;

  if (props.startlist.has_starts) return false;
  return !categoryRacesOnStartlist.value.length;
});

function resetStartlist(startlist: IStartlist) {
  resetForm({
    values: {
      ...startlist,
      begin: isoToInput(startlist.begin, 'time'),
    },
  });
}

function detachedCategoryRace(categoryRace: ICategoryRace) {
  const index = categoryRacesList.value.findIndex(el => el.id === categoryRace.id);
  categoryRacesList.value[index] = categoryRace;
}
</script>

<style>
.input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
}
</style>
