<template>
  <SupCard title="l.finances">
    <div class="grid gap-5 p-3 md:grid-cols-2">
      <div>
        <div class="border-b px-3">
          <b>{{ $lang('l.general') }}</b>
        </div>
        <ul>
          <ListItem label="l.collectedMoney" :value="race.organizer_revenue" />
          <template v-if="isServerAdmin">
            <ListItem label="l.paymentFee" :value="race.fee" />
            <ListItem label="l.proceeds" :value="race.organizer_charges - race.fee" />
            <ListItem label="l.settled" :value="race.settled" />
            <ListItem label="l.runner_cost" :value="race.runner_cost" />
            <ListItem v-if="race.charge_rate" :custom-format="formatPercent" label="l.charge_rate" :value="race.charge_percent" />
            <ListItem v-if="race.refund_charge_rate" :custom-format="formatPercent" label="l.refund_charge_rate" :value="race.refund_charge_percent" />
          </template>
        </ul>
      </div>
      <div>
        <div class="border-b px-3">
          <b>{{ $lang('l.fees') }}</b>
        </div>
        <ul>
          <ListItem label="l.race.fixedAmount" :value="race.flat_rate_cost" />
          <ListItem label="l.runnerFixPrice" :value="race.organizer_participant_charge" />
          <ListItem label="l.runnerFee" :value="race.organizer_participant_revenue_charge" />
          <ListItem label="l.extra" :value="race.organizer_extra_revenue_charge" />
          <ListItem label="l.refund.title" :value="race.organizer_refund_revenue_charge" />
          <ListItem label="l.priceTotal" :value="race.organizer_charges" />
        </ul>
      </div>

      <ul />
    </div>
  </SupCard>
</template>

<script setup lang="ts">
import type { IRace } from '@/interfaces';

import SupCard from '@/components/ui/supCard.vue';

import ListItem from './listItem.vue';

interface ExtendedRace extends IRace {
  charge_percent: number;
  charge_rate: number;
  fee: number;
  flat_rate_cost: number;
  organizer_charges: number;
  organizer_extra_revenue_charge: number;
  organizer_participant_charge: number;
  organizer_participant_revenue_charge: number;
  organizer_refund_revenue_charge: number;
  organizer_revenue: number;
  refund_charge_percent: number;
  refund_charge_rate: number;
  runner_cost: number;
  settled: boolean;
}

type Props = {
  race: ExtendedRace;
};
defineProps<Props>();

const formatPercent = (value: boolean | number) => `${value}%`;

const isServerAdmin = computed(() => window.User.is_admin);
</script>
