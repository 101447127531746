<template>
  <form novalidate>
    <fieldset
      class="flex gap-5"
      :disabled="disabled || isLoading">
      <div class="my-auto">
        {{ maxYearDisplay }} -
      </div>
      <SupInput
        class="grow focus:placeholder:text-transparent active:placeholder:text-transparent"
        :disabled="!lastItem"
        label="l.year"
        :max="maxYearDisplay ? maxYearDisplay - 1 : undefined"
        :min="1900"
        name="min_year"
        :parser="yearParser"
        :placeholder="$lang('l.older')"
        :required="!lastItem ? true : undefined"
        type="number"
        @blur="submit()" />
      <SupInput
        class="min-w-[6rem] max-w-[6rem]"
        label="l.amount"
        :min="0"
        name="amount"
        :required="!lastItem ? true : undefined"
        type="number"
        @blur="submit()" />
      <SupButton
        v-if="firstItemDeletable && firstItem || !firstItem && lastItem"
        class="my-auto text-xl"
        color="negative"
        icon="fas:trash"
        @click="remove" />
      <div
        v-else
        class="w-[49.59px]" />
    </fieldset>
  </form>
</template>

<script setup lang="ts">
import type { IRacePrice } from '@/interfaces';

import SupButton from '@/components/ui/supButton.vue';
import SupInput from '@/components/ui/supInput.vue';
import { errorHandler } from '@/helpers';
import { useAxios } from '@vueuse/integrations/useAxios';
import dayjs from 'dayjs';

type Props = {
  disabled?: boolean;
  firstItem?: boolean;
  firstItemDeletable?: boolean;
  lastItem?: boolean;
  price?: IRacePrice;
  raceId: number;
};
type Emits = {
  removed: [];
  updated: [racePrice: IRacePrice];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const maxYearDisplay = computed(() => {
  if (props.price && props.price.max_year) {
    return props.price.max_year;
  }
  return dayjs().year();
});

const { handleSubmit, meta, setValues, values } = useForm({ initialValues: props.price });

const { execute, isLoading } = useAxios<IRacePrice>();

watch(() => props.price, (value) => {
  if (value) {
    setValues(value);
  }
});

const submit = handleSubmit(async (values) => {
  if (meta.value.dirty) await update(values);
});

function yearParser(year: number) {
  if (String(year).length < 4) return year;
  if (year >= maxYearDisplay.value) {
    return maxYearDisplay.value - 1;
  }
  else if (year < 1900) {
    return 1900;
  }
  return year;
}

async function update(price: IRacePrice): Promise<void> {
  try {
    const { data } = await execute(route('webapi.price.update', { price, race: { id: props.raceId } }), {
      data: values,
      method: 'PATCH',
    });
    if (data.value) {
      emit('updated', data.value);
    }
  }
  catch (error) {
    errorHandler(error);
  }
}

async function remove() {
  try {
    await execute(route('webapi.price.delete', { price: props.price, race: { id: props.raceId } }), { method: 'DELETE' });
    emit('removed');
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
