<template>
  <VueCard class="w-full">
    <template #title>
      {{ $lang('l.runner.*') }}
    </template>
    <div class="p-4">
      <VueTeamSlot
        v-for="(slot, index) in team.slots"
        :key="index"
        :slot="slot"
        :dropzones="dropzones"
        :index="index"
        :moving-member="movingMember"
        :moving-runner="movingRunner"
        :optional="isOptional(index)"
        :team="team"
        @add-slot="addSlot"
        @dropzone="pushDropzones"
        @move-member="moveMember"
        @remove-slot="removeSlot"
        @set-moving-member="movingMember = $event"
        @update-member="updateMember" />
    </div>
  </VueCard>
</template>

<script setup lang="ts">
import type { IRunner, ISlot, ITeam, ITeamMember } from '@/interfaces';
import { type PropType, type Ref, ref } from 'vue';
import VueCard from '../card.vue';
import VueTeamSlot from './teamSlot.vue';

const props = defineProps({
  movingRunner: Object as PropType<IRunner>,
  team: Object as PropType<ITeam>,
});
const emits = defineEmits(['addSlot', 'removeSlot', 'moveMember', 'dropzones', 'updateMember']);

const movingMember: Ref<ITeamMember> = ref();

function addSlot(slot: ISlot, index: number) {
  emits('addSlot', slot, index);
}

const dropzones = ref([]);

function pushDropzones(dropzone: any, index: number) {
  dropzones.value.splice(index, 1, dropzone);
  emits('dropzones', dropzones.value);
}

function moveMember(targetIndex: number, currentIndex: number) {
  emits('moveMember', targetIndex, currentIndex);
}

function updateMember(member: ITeamMember, index: number) {
  emits('updateMember', member, index);
}

function dragMember(member: ITeamMember) {
  movingMember.value = member;
}

function removeSlot(index: number) {
  emits('removeSlot', index);
}

function isOptional(index: number) {
  const count = index + 1;
  if (!props.team.rules.hasOwnProperty('min_team_member_count')) {
    return false;
  }

  if (count <= props.team.rules.min_team_member_count) {
    return false;
  }

  return true;
}
</script>
