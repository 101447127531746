<template>
  <div class="border-t px-2">
    <div class="flex h-9 w-full grow gap-5">
      <button
        class="flex grow gap-3"
        type="button"
        @click="open = !open">
        <div
          v-if="!open"
          class="my-auto flex">
          <i class="fas fa-plus my-auto" />
        </div>
        <div
          v-else
          class="my-auto flex">
          <i class="fas fa-minus my-auto" />
        </div>
        <div class="my-auto grow text-start">
          {{ category.short_name }}
        </div>
        <div
          v-if="!open"
          class="my-auto grid grid-cols-5 gap-2">
          <ExtraIcon
            v-for="extra, index in extras"
            :key="index"
            :active="categoryRace[extra.name]"
            :icon="extra.icon" />
        </div>
      </button>
      <button
        class="flex h-9 w-9 text-red-500 hover:enabled:text-red-400 disabled:cursor-not-allowed disabled:opacity-50"
        :disabled="!categoryRace.is_detachable || isLoading"
        type="button"
        @click="deleteCategoryRace">
        <i class="fas fa-trash m-auto" />
      </button>
    </div>
    <div
      v-if="open"
      class="pb-3">
      <CategoryUpdateForm
        :category="category"
        :disabled="category.event_id ? false : true"
        @updated="$emit('categoryUpdated', $event)" />
      <ExtrasForm
        :category-race="categoryRace"
        :extras="extras"
        @updated="$emit('categoryRaceUpdated', $event)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategory, ICategoryRace } from '@/interfaces';

import { errorHandler, RaceKey, SwalConfirmation } from '@/helpers';
import { route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { ref } from 'vue';

import CategoryUpdateForm from './categoryUpdateForm.vue';
import ExtraIcon from './extraIcon.vue';
import ExtrasForm from './extrasForm.vue';

type Props = {
  category: ICategory;
  categoryRace: ICategoryRace;
};

type Emits = {
  categoryRaceCreated: [categoryRace: ICategoryRace];
  categoryRaceDeleted: [categoryRace: ICategoryRace];
  categoryRaceUpdated: [categoryRace: ICategoryRace];
  categoryUpdated: [category: ICategory];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const open = ref(false);

const { execute, isLoading } = useAxios();

async function deleteCategoryRace() {
  const swal = await SwalConfirmation({ template: 'delete', title: 'l.category.delete_assignment' });
  if (swal.isConfirmed) {
    try {
      await execute(route('webapi.categoryRace.delete', props.categoryRace), {
        method: 'DELETE',
      });
      emit('categoryRaceDeleted', props.categoryRace);
    }
    catch (error) {
      errorHandler(error);
    }
  }
}
const race = inject(RaceKey);

const extras = [
  { disabled: race?.classification_id === 1, icon: 'fas fa-alarm-clock', label: 'l.earlyOrLate', name: 'has_starttime_request' },
  { disabled: race?.classification_id === 1, icon: 'fas fa-user-friends', label: 'l.parents', name: 'has_parental_starttime_request' },
  { icon: 'fas fa-baby', label: 'l.nursery', name: 'has_nursery_extra' },
  { icon: 'fas fa-running', label: 'l.extraDef.extraRunner', name: 'has_extra_runners' },
  { icon: 'fas fa-running', label: 'l.extraDef.extraRunner.withMap', name: 'has_extra_runners_with_map' },
];
</script>
