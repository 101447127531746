<template>
  <div class="grid gap-5 rounded-none lg:rounded-lg">
    <Card class="lg:rounded-lg">
      <div class="flex justify-between gap-3 p-3 lg:rounded-lg">
        <b> {{ lang('l.invoice.default') }} {{ transaction.receipt_number }} </b>
        <div>{{ dayjs(transaction.created_at).format('DD.MM.YYYY HH:mm:ss') }}</div>
      </div>
      <div class="bg-lightgray-100">
        <div class="flex justify-end px-6 py-2 text-sm">
          <div>
            <b>{{ lang('l.vendor') }}</b>
            <div>{{ transaction.accounts[0].account_owner }}</div>
            <div>{{ transaction.accounts[0].street }}</div>
            <div>{{ transaction.accounts[0].plz }} {{ transaction.accounts[0].city }}</div>
          </div>
        </div>
        <div class="flex flex-col gap-5 px-6 py-3">
          <div>
            <b>{{ race.name }}</b>
            <ul class="px-3">
              <li
                v-for="(teamMember, index) in teamMembers"
                :key="index">
                {{ teamMember.team_position }}. {{ teamMember.firstname }} {{ teamMember.lastname }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <div class="px-3 py-2">
          <div class="text-right font-bold">
            {{ lang('l.amount') }}
          </div>
          <div>
            <span
              v-if="transaction.costs[0].discount"
              class="mr-10 text-gray-500">{{
                `${$lang('l.discount')}: CHF ${transaction.costs[0].discount}.-`
              }}</span>CHF {{ transaction.amount }}
          </div>
        </div>
      </div>
    </Card>
    <div class="mb-5 flex justify-end gap-5 px-3">
      <BaseButton
        color="secondary"
        :label="lang('l.back')"
        @click="$emit('back')" />
      <BaseButton
        v-if="transaction.transaction_state_id === 3"
        color="primary"
        :label="lang('l.finalize')"
        :link="route('transaction.guest.confirm', transaction)" />
      <a
        v-else
        class="button-primary block w-auto rounded-md px-4 py-2 text-center transition duration-300 ease-in-out hover:shadow-button my-auto"
        :href="transaction.payment_link">
        {{ $lang('l.pay') }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRace, ITeam, ITransaction } from '@/interfaces';
import dayjs from 'dayjs';

import { computed } from 'vue';
import { lang } from '../../../plugins/lang';
import { route } from '../../../plugins/route';
import BaseButton from '../../base/baseButton.vue';
import Card from '../../card.vue';

type Props = {
  race: IRace;
  team: ITeam;
  transaction: ITransaction;
};
type Emits = {
  (e: 'back'): void;
};

const props = defineProps<Props>();
defineEmits<Emits>();

const teamMembers = computed(() => {
  return props.team.slots.filter(slot => !Array.isArray(slot.teamMember)).map(slot => slot.teamMember);
});
</script>
