<template>
  <div class="relative mt-2 min-h-16 text-left text-gray-700">
    <div :class="{ 'grid gap-5 lg:grid-cols-[1fr_200px]': placeholders.length }">
      <Editor
        :id="`${id}_editor`"
        :api-key="env.VITE_TINY_API_KEY"
        class="min-h-20 w-full"
        :disabled="disabled"
        :init="editorSettings"
        :model-value="internalValue"
        :name="name"
        @update:model-value="input" />
      <div v-if="placeholders.length > 0">
        <div class="float-left mb-3 mr-5 flex lg:mb-0 lg:mr-0 lg:text-lg">
          <div class="my-auto mr-2 font-bold lg:mx-2">
            {{ $lang('l.placeholder') }}
          </div>
          <InformationButton :text="$lang('l.placeholderInformation')" />
        </div>
        <div
          v-for="(placeholder, index) in placeholders"
          :key="index"
          class="float-left mx-2 flex lg:m-2"
          @click="addPlaceholder(placeholder)">
          <div class="cursor-pointer rounded-md bg-lightgray-200 px-1 font-mono">
            {{ placeholder }}
          </div>
        </div>
      </div>
    </div>
    <span
      ref="labelRef"
      class="input__label">
      {{ label }}{{ required ? ' *' : '' }}
      <InformationButton
        v-if="tooltipp"
        :text="tooltipp" />
    </span>

    <span
      v-if="showError"
      class="input__error text-xs"
      role="alert">
      {{ initialError || fieldControl.errors[0] }}
    </span>
  </div>
</template>

<script setup lang="ts">
import Editor from '@tinymce/tinymce-vue';
import { onBeforeUpdate, type PropType, ref } from 'vue';

import { useFieldControl } from '../composables/form/fieldControl';
import { editorSettings } from '../configs/baseEditor';
import InformationButton from './informationButton.vue';

const props = defineProps({
  id: { default: undefined, type: String },
  blade: { default: false, type: Boolean },
  disabled: { default: false, type: Boolean },
  initError: { default: undefined, type: String },
  initialError: { default: undefined, type: String },
  initValue: { default: undefined, type: String },
  label: { default: undefined, type: String },
  modelValue: { default: undefined, type: String },
  name: { default: undefined, type: String },
  placeholders: { default: () => [], type: Array as PropType<string[]> },
  required: { default: false, type: Boolean },
  tooltipp: { default: undefined, type: String },
  validationRules: { default: undefined, type: String },
});

const emit = defineEmits(['update:modelValue', 'blur']);

const env = import.meta.env;

const isDirty = ref(false);
const isTouched = ref(false);
const internalValue = ref('');
const labelRef = ref(null);

internalValue.value = props.blade ? props.initValue : props.modelValue;

const initialError = ref(props.initialError);

const fieldControl = useFieldControl(props.name, isTouched, props.required, props.validationRules);
fieldControl.validate(internalValue.value);

function showError() {
  if (initialError.value) {
    return true;
  }

  return !fieldControl.isValid.value && isTouched.value;
}

function input(event_: string) {
  console.log(event_);
  internalValue.value = event_;
  initialError.value = null;
  validate();
  updateModelValue(event_);
}

function addPlaceholder(placeholder: string) {
  // @ts-expect-error: Dont work if it get Imported

  tinymce.activeEditor.execCommand('mceInsertContent', false, `${placeholder}`);
}

function blur(event_: any) {
  isTouched.value = true;
  validate();
  emit('blur', event_);
}

function updateModelValue(event_: any) {
  emit('update:modelValue', event_);
}

function validate() {
  fieldControl.validate(internalValue.value);
  isDirty.value = true;
}

onBeforeUpdate(() => {
  if (props.blade) {
    return;
  }
  internalValue.value = props.modelValue;
  validate();
});
</script>
