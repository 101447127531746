<template>
  <div class="relative">
    <fieldset
      class="box-border block w-full rounded-lg border border-gray-200 text-gray-900 shadow-md disabled:cursor-not-allowed disabled:opacity-50"
      :class="required ? 'border-solid' : 'border-dashed'">
      <legend
        v-if="label"
        class="absolute -top-4 left-2 z-10 scale-90 bg-white px-1 text-gray-400">
        {{ label }}
      </legend>
      <div class="grid grid-cols-[1fr_80px_30px] gap-2 px-2 pb-2 pt-4">
        <div class="flex flex-col">
          <label class="text-sm">{{ $lang('l.quarter') }}</label>
          <div class="flex h-[30px] justify-between border border-gray-200 px-2">
            <fieldset
              v-for="(quarter, index) in quarters"
              :key="index"
              class="flex cursor-pointer gap-2 disabled:cursor-not-allowed disabled:opacity-50"
              :disabled="disableQuarter(quarter)">
              <label :for="String(index)">{{ dayjs(quarter).quarter() }}</label>
              <input
                :id="String(index)"
                :checked="checkedIndex === index"
                class="my-auto cursor-pointer disabled:cursor-not-allowed"
                :name="name"
                :required="required"
                type="radio"
                :value="quarter"
                @change="setValue(index)">
            </fieldset>
          </div>
        </div>
        <div class="flex flex-col">
          <label class="text-sm">{{ $lang('l.year') }}</label>
          <input
            class="border border-gray-200 py-0.5 pr-0.5"
            :min="minYear"
            type="number"
            :value="year"
            @input="selectYear(($event.target as HTMLInputElement).value)">
        </div>
        <BaseButton
          class="mt-5 h-[30px] w-[30px] py-4"
          color="negative"
          icon="fas fa-x"
          variant="plain"
          @click="reset" />
      </div>
    </fieldset>
    <span
      class="block w-full whitespace-nowrap pr-2 text-right font-light text-red-600"
      role="alert">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script setup lang="ts">
import dayjs, { type Dayjs } from 'dayjs';
import { useField } from 'vee-validate';
import { computed, type Ref, ref } from 'vue';
import { dayjsToLaravel } from '../../helpers/date';
import BaseButton from '../base/baseButton.vue';

const props = defineProps({
  label: { default: '', type: String },
  name: { required: true, type: String },
  required: { default: false, type: Boolean },
});

const { errorMessage, resetField, value } = useField<string>(props.name, props.required ? 'required' : '', {
  type: 'radio',
});

const year: Ref<number> = ref();
const currQuarter = dayjs().quarter();

if (value.value) {
  year.value = Number(dayjs(value.value).format('YYYY'));
}

const quarters = computed(() => {
  return Array.from({ length: 4 }, (_, i) => i + 1).map((number) => {
    let value: Dayjs;
    if (!year.value || year.value === dayjs().get('y')) {
      value = number < currQuarter ? dayjs().add(1, 'y').quarter(number) : dayjs().quarter(number);
    }
    else {
      value = dayjs().set('y', year.value).quarter(number);
    }
    return dayjsToLaravel(value.set('h', 12).set('m', 0).set('s', 0));
  });
});
function disableQuarter(quarter: string) {
  const yr = year.value ? Number(year.value) : 2022;
  if (dayjs(quarter).get('y') > yr) return true;
  return false;
}

function selectYear(val: string) {
  const yr = Number(val);
  year.value = yr;
  value.value = quarters.value[checkedIndex.value];
  if (Number(yr) === dayjs().get('y')) {
    value.value = quarters.value[dayjs().quarter() - 1];
  }
}
const minYear = ref(Number(dayjs().format('YYYY')));
function reset() {
  resetField();
  year.value = undefined;
}
function setValue(index: number) {
  value.value = quarters.value[index];
  if (!year.value) {
    year.value = Number(dayjs(value.value).format('YYYY'));
  }
}
const checkedIndex = computed(() => {
  if (!value.value) return;
  return quarters.value.findIndex(el => dayjs(el).quarter() === dayjs(value.value).quarter());
});
</script>
