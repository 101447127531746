<template>
  <div>
    <slot v-if="$slots.default" />
    <template v-else>
      <b>{{ getTranslatedLabel(label) }}: </b>{{ displayValue }}
    </template>
  </div>
</template>

<script setup lang="ts">
import { getTranslatedLabel } from '@/helpers';
import dayjs from 'dayjs';

type Props = {
  dateFormat?: string;
  label?: string;
  value?: Date | number | string;
};
const props = withDefaults(defineProps<Props>(), {
  dateFormat: 'DD.MM.YYYY',
  value: undefined,
});

function isDate() {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/;
  return regex.test(props.value);
}

const displayValue = computed(() => {
  if (!props.value) return;
  if (isDate() && (typeof props.value === 'string' || props.value instanceof Date)) {
    return dayjs(props.value).format(props.dateFormat);
  }
  return props.value;
});
</script>
