<template>
  <td>
    <input
      v-if="type === 'number'"
      v-model="value"
      class="w-full border px-2 py-1 text-center outline-none ring-0"
      :min="0"
      onkeydown="return type === 'number' ? event.keyCode !== 69 : true"
      type="number"
      @blur="$emit('blur')">
    <div
      v-else-if="type === 'select'"
      class="relative">
      <select
        v-model="value"
        class="w-32 border px-2 py-1 outline-none ring-0 disabled:text-black"
        @change="$emit('blur')">
        <option
          v-for="option, index in options"
          :key="index"
          :value="option.id">
          {{ option.name }}
        </option>
      </select>
      <button
        v-if="value"
        class="absolute right-8 top-1/4 flex h-4 w-3 disabled:opacity-0"
        type="button"
        @click="reset()">
        <i class="fal fa-xmark m-auto" />
      </button>
    </div>

    <button
      v-else-if="type === 'boolean'"
      class="w-full border px-2 py-0.5 text-center text-lg outline-none ring-0"
      type="button"
      @click="toggle()">
      <span v-if="value"><i class="fas fa-check text-green-600" /></span>
      <span v-else><i class="fas fa-x text-red-600" /></span>
    </button>
  </td>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';

type Props = {
  name: string;
  type: string;
  options?: { id: number; name: string }[];
};
type Emits = {
  blur: [];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleChange, value } = useField<string | number | boolean>(() => props.name, undefined, {
  checkedValue: props.type === 'boolean' ? true : undefined,
  keepValueOnUnmount: false,
  syncVModel: true,
  type: props.type === 'boolean' ? 'checkbox' : 'default',
  uncheckedValue: props.type === 'boolean' ? false : undefined,
});
function toggle() {
  handleChange(!value);
  emit('blur');
}

function reset() {
  value.value = null;
  emit('blur');
}
</script>
