<template>
  <div class="input relative mt-2 min-h-[3.75rem] text-left text-gray-700">
    <label>
      <span class="input__label">
        {{ label }}{{ required ? ' *' : '' }}
        <InformationButton
          v-if="tooltipp"
          :text="tooltipp" />
      </span>
    </label>
    <input
      :id="id"
      :autocomplete="`current-${name}`"
      :autofocus="autofocus"
      class="input__field w-full"
      :class="{
        'is-invalid': showError(),
      }"
      :disabled="disabled"
      :max="max"
      :min="min"
      :name="name"
      :placeholder="placeholder"
      :type="type"
      :value="internalValue"
      @blur="blur"
      @input.stop="input">
    <span
      v-if="showError()"
      class="input__error text-xs sm:text-base"
      role="alert">
      {{ initialError || fieldControl.errors.value[0] }}
    </span>
  </div>
</template>

<script lang="ts">
export default {
  components: { InformationButton },
  name: 'VueInput',
};
</script>

<script setup lang="ts">
// eslint-disable-next-line import/first
import { onBeforeUpdate, type Ref, ref } from 'vue';
// eslint-disable-next-line import/first
import { useFieldControl } from '../composables/form/fieldControl';
// eslint-disable-next-line import/first
import InformationButton from './informationButton.vue';

const props = defineProps({
  id: String,
  autofocus: Boolean,
  blade: Boolean,
  disabled: Boolean,
  initError: String,
  initialError: String,
  initValue: String,
  label: { default: '', type: String },
  max: Number,
  min: Number,
  modelValue: [String, Number],
  name: { required: true, type: String },
  placeholder: { default: '', type: String },
  required: Boolean,
  tooltipp: { default: undefined, type: String },
  type: { default: 'text', type: String },
  validationRules: String,
});

const emit = defineEmits(['update:modelValue', 'blur']);

const isDirty = ref(false);
const isTouched = ref(false);
const internalValue: Ref<string | number> = ref('');

if (props.blade) {
  internalValue.value = props.initValue;
}
else {
  internalValue.value = props.modelValue;
}

const initialError = ref(props.initError);

const fieldControl = useFieldControl(props.name, isTouched, props.required, props.validationRules);
fieldControl.validate(internalValue.value);

function showError() {
  if (initialError.value) {
    return true;
  }

  return !fieldControl.isValid.value && isTouched.value;
}

function input(ev: any) {
  internalValue.value = ev.target.value;
  initialError.value = null;
  validate();
  updateModelValue(ev.target.value);
}

function blur(ev: any) {
  isTouched.value = true;
  validate();
  emit('blur', ev);
}

function updateModelValue(ev: any) {
  emit('update:modelValue', ev);
}

function validate() {
  fieldControl.validate(internalValue.value);
  isDirty.value = true;
}

onBeforeUpdate(() => {
  if (props.blade) {
    return;
  }
  internalValue.value = props.modelValue;
  validate();
});
</script>
