<template>
  <div class="grid w-full font-mono xl:overflow-hidden xl:rounded-lg xl:shadow-lg">
    <button
      class="flex w-full cursor-pointer gap-3 md:gap-5 bg-white px-2 py-0.5 md:p-2 hover:bg-lightgray-200"
      :class="[result.combined_name ? 'h-24' : 'h-12 md:h-16']"
      type="button"

      @click="back()">
      <i class="fas fa-chevron-left my-auto text-xl text-orange-500" />
      <div class="my-auto w-[22px] text-center text-xl text-orange-500">
        {{ result.rank && result.rank > 0 ? result.rank : '-' }}
      </div>
      <div class="flex w-full justify-between">
        <div class="flex flex-col text-left text-sm md:text-base">
          <div>
            <div>
              {{ result.bib_number }}
              <span>{{ result.team_name }}</span>
              <span v-if="!result.combined_name">{{ result.lastname }}, {{ result.firstname }}</span>
            </div>
            <div
              v-if="result.combined_name"
              class="text-xs">
              {{ result.combined_name }}
            </div>
          </div>
          <div v-if="result.finish_time" class="flex gap-5">
            <span>{{ formatSecondsAsTime(result.finish_time) }}</span>
          </div>
        </div>
      </div>
    </button>
    <div
      v-if="data"
      class="my-auto w-full gap-3">
      <div class="bg-white">
        <div class="grid grid-cols-3 bg-lightgray-400 px-2 py-0.5 md:p-2">
          <div>{{ lang('l.control') }}</div>
          <div class="flex flex-col text-end xl:flex-row xl:justify-end">
            <div class="xl:w-32 text-sm md:text-base">
              {{ lang('l.split_time') }}
            </div>
            <div
              v-if="!result.team_result_id"
              class="text-xs md:text-sm xl:w-24 xl:text-base">
              {{ lang('l.behind') }}
            </div>
          </div>
          <div class="flex flex-col text-end xl:flex-row xl:justify-end">
            <div class="xl:w-32 text-sm md:text-base">
              {{ lang('l.total_time') }}
            </div>
            <div
              v-if="!result.team_result_id"
              class="text-xs md:text-sm xl:w-24 xl:text-base">
              {{ lang('l.behind') }}
            </div>
          </div>
        </div>
        <div>
          <div
            v-for="(cs, index) in controlStationResults"
            :key="index"
            class="grid grid-cols-3 border-b px-2 py-0.5 md:p-2"
            :class="{ 'last:border-none': splitTimesVisible }">
            <div v-if="cs.name === 'Z'" class="text-sm md:text-base">
              {{ cs.name }}
            </div>
            <div v-else class="text-sm md:text-base">
              {{ cs.position }}
            </div>
            <div class="flex justify-end gap-2">
              <div class="flex flex-col text-end xl:flex-row xl:justify-end">
                <div class="xl:w-32 text-sm md:text-base">
                  <div v-if="cs.status === 1">
                    {{ formatSecondsAsTime(cs.split_time) }}
                  </div>
                  <div v-else>
                    --:--
                  </div>
                  <span
                    v-if="!result.team_result_id"
                    class="hidden xl:inline-block">({{ cs.split_rank }})</span>
                </div>
                <div
                  v-if="!result.team_result_id"
                  class="text-xs text-gray-500 xl:w-24 xl:text-base">
                  + {{ formatSecondsAsTime(cs.split_time_diff) }}
                </div>
              </div>
              <div
                v-if="!result.team_result_id"
                class="xl:hidden text-sm md:text-base">
                ({{ cs.split_rank }})
              </div>
            </div>
            <div class="flex justify-end gap-2">
              <div class="flex flex-col text-end xl:flex-row xl:justify-end">
                <div class="xl:w-32 text-sm md:text-base">
                  <div v-if="cs.status === 1">
                    {{ formatSecondsAsTime(cs.total_time) }}
                  </div>
                  <div v-else>
                    --:--
                  </div>
                  <span
                    v-if="!result.team_result_id"
                    class="hidden xl:inline-block">({{ cs.total_rank }})</span>
                </div>
                <div
                  v-if="!result.team_result_id"
                  class="text-xs text-gray-500 xl:w-24 xl:text-base">
                  + {{ formatSecondsAsTime(cs.total_time_diff) }}
                </div>
              </div>
              <div
                v-if="!result.team_result_id"
                class="xl:hidden text-sm md:text-base">
                ({{ cs.total_rank }})
              </div>
            </div>
          </div>
          <div v-if="!splitTimesVisible" class="flex w-full justify-center gap-3 bg-white p-2">
            <iconify-icon class="my-auto text-2xl text-yellow-500" icon="fal:triangle-exclamation" />
            <div class="my-auto font-sans ">
              {{ showSplitTimesDate
                ? $lang('l.noSplitTimesWarning', { date: `${dayjs(showSplitTimesDate).format('DD.MM')}`, time: `${dayjs(showSplitTimesDate).format('HH:mm')}` })
                : $lang('l.noSplitTimesWarningLater')
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="isLoading"
      class="border-t bg-white p-5">
      <div class="flex justify-center">
        <i class="fas fa-spinner-third fa-spin text-7xl" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IControlStation, IControlStationSplitTime, IResult } from '@/interfaces';

import { formatSecondsAsTime } from '@/helpers/time';
import { lang, route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import dayjs from 'dayjs';
import { watch } from 'vue';

type Props = {
  baseUrl: string;
  radioControlStations: IControlStation[];
  result: IResult;
  showSplitTimesDate?: string;
  splitTimesVisible?: boolean;
};
type Emits = {
  resetResultDetail: [categoryRaceId: number];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { cancel, data, execute, isLoading } = useAxios<IControlStationSplitTime[]>(
  route('webapi.results.getControlStations', { result: props.result }),
);

const controlStationResults = computed(() => {
  if (!data.value) return [];
  const constrolStations = data.value.map((cs, index) => {
    return {
      ...cs,
      position: index + 1,
    };
  });
  if (props.splitTimesVisible) {
    return constrolStations;
  }
  return constrolStations.filter(cd => props.radioControlStations.find(cs => cs.name === cd.name));
});

watch(
  () => props.result,
  () => {
    execute(route('webapi.results.getControlStations', { result: props.result }));
  },
);

function back() {
  emit('resetResultDetail', props.result.category_race_id),
  cancel();
}
</script>
