<template>
  <b>{{ $lang('l.extra') }}:</b>
  <TeamExtra
    v-for="(extraDef, index) in team.extra_defs"
    :key="index"
    class="mb-2"
    :extra="getExtra(extraDef.id)"
    :extra-def="extraDef"
    :max="extraDef.name === 'foodChoiceBaselTeamOl' ? currentMemberCount : undefined"
    :readonly="readonly"
    :team-id="team.id"
    @create-extra="createExtra(extraDef.id)"
    @extra-deleted="extras.splice(index, 1)" />
</template>

<script setup lang="ts">
import type { IExtra, ITeam } from '@/interfaces';
import { errorHandler } from '@/helpers';
import { route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { computed, ref } from 'vue';
import TeamExtra from './teamExtra.vue';

type Props = {
  readonly?: boolean;
  team: ITeam;
};

const props = defineProps<Props>();
const extras = ref(props.team ? props.team.extras : null);

function getExtra(extraDefId: number) {
  if (!extras.value) return;
  return extras.value.find(extra => extra.extra_def_id === extraDefId);
}

const currentMemberCount = computed(() => {
  if (!props.team.slots || !props.team.slots.length) return;
  return props.team.slots.filter(slot => !Array.isArray(slot.teamMember)).length;
});

async function createExtra(extraDefId: number) {
  try {
    const { data } = await useAxios<IExtra>(route('webapi.extra.post'), {
      data: {
        extra_def_id: extraDefId,
        team_id: props.team.id,
      },
      method: 'POST',
    });
    extras.value.push(data.value);
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
