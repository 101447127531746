<template>
  <div
    v-if="extraFieldDef"
    class="mt-4">
    <template v-if="extraFieldDef.input_type.name === 'integer'">
      <BaseInput
        v-if="!readonly"
        :custom-format="getFormatFunction()"
        :disabled="disabledField"
        :label="`l.extraFieldDef.${extraFieldDef.name}.name`"
        :min="min"
        :model-value="fieldValue"
        :name="`extraFieldInput: ${extraFieldDef.name}`"
        :required="extraFieldDef.required"
        type="number"
        @blur="$emit('update', extraFieldDef.id, $event)" />
      <div v-else>
        {{ $lang(`l.extraFieldDef.${extraFieldDef.name}.name`) }}: {{ fieldValue }}
      </div>
    </template>
    <template
      v-else-if="extraFieldDef.input_type.name === 'text'">
      <BaseInput
        v-if="!readonly"
        :custom-format="getFormatFunction()"
        :disabled="disabledField"
        :label="`l.extraFieldDef.${extraFieldDef.name}.name`"
        :model-value="fieldValue"
        :name="`extraFieldInput: ${extraFieldDef.name}`"
        :required="extraFieldDef.required"
        @blur="$emit('update', extraFieldDef.id, $event)" />
      <div v-else>
        {{ $lang(`l.extraFieldDef.${extraFieldDef.name}.name`) }}: {{ fieldValue }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { IExtraField, IExtraFieldDef } from '@/interfaces';
import { computed } from 'vue';
import BaseInput from '../base/baseInput.vue';

type Props = {
  disabled: boolean;
  extraDefName: string;
  extraFieldDef: IExtraFieldDef;
  extraField?: IExtraField;
  min?: number;
  max?: number;
  readonly?: boolean;
};
type Emits = {
  update: [extraFieldDefId: number, value: number];
};
const props = defineProps<Props>();

defineEmits<Emits>();

const disabledField = computed(() => {
  if (props.disabled) return props.disabled;
  if (props.extraFieldDef.input_type.name === 'integer') {
    if (props.max !== undefined || props.max !== null) return false;
    if (props.max === 0) return true;
  }
  return null;
});

const fieldValue = computed(() => {
  if (props.extraField) {
    return props.extraField.value;
  }
  return null;
});

function getFormatFunction() {
  if (props.extraFieldDef.name === 'si_card') return formatSiCard;
  if (props.extraDefName === 'foodChoiceBaselTeamOl') return formatValueNumber;
  return null;
}

function formatValueNumber(value: number) {
  if (props.min !== undefined && value < props.min) return 0;
  if (props.max !== undefined && value >= props.max) return props.max;
  return value;
}

function formatSiCard(value: string | number) {
  let number = typeof value === 'string' ? Number.parseInt(value) : value;
  if (!value) {
    return value;
  }
  number = checkNaN(number);
  return checkMaxNumbers(number, 9);
}
/**
 * Check the Number if its a valid Number.
 * If the Input should be a String with "NaN", the function returns an Empty String
 * If the Input is NaN it removes the last Char which should return a Number.

 * @param number
 * @returns
 */
function checkNaN(number: number) {
  if (isNaN(number)) {
    const string = number.toString();
    if (string === 'NaN') {
      return;
    }
    return Number.parseInt(string.slice(0, -1));
  }
  return number;
}

/**
 * The Function removes the last Digit if the Number is higher than max Digits.
 *
 * @param number
 * @param maxDigits Number of the Digits the Number should max be.
 * @returns a Number between 0 and a Number of Nines with max Digits
 */
function checkMaxNumbers(number: number, maxDigits: number) {
  maxDigits = Number.parseInt('9'.repeat(maxDigits));
  if (number > maxDigits) {
    return Math.floor(number / 10);
  }
  return number;
}
</script>
