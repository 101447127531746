<template>
  <BaseButton
    hide-label-on-mobile
    icon="fas fa-arrow-alt-left "
    :label="$lang('l.back')"
    :link="backUrl" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseButton from './base/baseButton.vue';

const backUrl = computed(() => window.backUrl);
</script>
