<template>
  <div class="w-full">
    <SupCard title="l.discounts.assigned">
      <div class="p-3">
        <fieldset v-if="discounts.length > 0" :disabled="isLoading">
          <Discount
            v-for="discount, index in discounts"
            :key="index"
            :discount="discount"
            :discount-assignments="filterDiscountAssignments(discount)"
            @remove-discount="removeDiscount($event)"
            @remove-discount-assignment="removeDiscountAssignment($event)" />
        </fieldset>
        <div v-else>
          {{ $lang('l.discounts.noneCreated') }}
        </div>
      </div>
    </SupCard>
  </div>
</template>

<script setup lang="ts">
import type { IRaceDiscount, IRaceDiscountAssignment } from '@/interfaces';

import SupCard from '@/components/ui/supCard.vue';
import { errorHandler } from '@/helpers';

import Discount from './discount.vue';

type Props = {
  discountAssignments: IRaceDiscountAssignment[];
  discounts: IRaceDiscount[];
  raceId: number;
};
type Emits = {
  removeDiscount: [discount: IRaceDiscount];
  removeDiscountAssignment: [discountAssignment: IRaceDiscountAssignment];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const filterDiscountAssignments = (discount: IRaceDiscount) => props.discountAssignments.filter(discountAssignment => discountAssignment.discount_id === discount.id);

const { execute, isLoading } = useAxios<IRaceDiscountAssignment>();

async function removeDiscount(discount: IRaceDiscount) {
  try {
    await execute(route('webapi.discount.delete', { discount, race: { id: props.raceId } }), { method: 'DELETE' });
    emit('removeDiscount', discount);
  }
  catch (error) {
    errorHandler(error);
  }
}

async function removeDiscountAssignment(discountAssignment: IRaceDiscountAssignment) {
  try {
    await execute(route('webapi.discountAssignment.delete', { discountAssignment, race: { id: props.raceId } }), { method: 'DELETE' });
    emit('removeDiscountAssignment', discountAssignment);
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
