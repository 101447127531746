<template>
  <div class="mb-4 flex justify-between gap-4 pb-2">
    <div class="flex grow">
      <div class="my-auto w-4 lg:w-6">
        {{ index + 1 }}
      </div>
      <div
        ref="dropzone"
        class="flex h-16 grow flex-col border border-gray-100"
        :class="{
          'pointer-events-none cursor-not-allowed bg-lightgray-100':
            disableSlot && (props.movingRunner || disableSlotsWithoutMovedMember),
          'bg-green-300': !disableSlot && (props.movingMember || props.movingRunner),
          'border-solid': !optional,
          'border-dashed': optional,
        }"
        @dragover.prevent
        @drop="dropRunner($event)">
        <div
          v-if="!Array.isArray(slot.teamMember)"
          class="flex h-full justify-between border border-gray-50 bg-lightgray-100 p-2"
          :class="[!onEdit ? 'cursor-grab active:hover:cursor-grabbing' : 'cursor-not-allowed']"
          :draggable="!onEdit"
          @dragend="stopMove($event)"
          @dragstart="startMove($event, slot.teamMember)"
          @touchend="stopMove($event)"
          @touchmove="handleTouchMove($event)"
          @touchstart="startMove($event, slot.teamMember)">
          <div class="pointer-events-none my-auto flex gap-2">
            <i class="fas fa-person-running my-auto" />
            <div>
              {{ slot.teamMember.firstname }}
              {{ slot.teamMember.lastname }}
            </div>
            <div>{{ slot.teamMember.year_of_birth }}</div>
          </div>
          <SiCardInput
            v-if="team.form_id === 2"
            inline
            :model-value="slot.teamMember.si_card"
            @blur="emits('updateMember', slot.teamMember, index)"
            @update:model-value="updateSiCard" />
        </div>
      </div>
    </div>

    <div
      v-if="!Array.isArray(slot.teamMember)"
      class="my-auto"
      @click="emits('removeSlot', index)">
      <i
        aria-hidden="true"
        class="fas fa-trash h-6 w-4 cursor-pointer text-red-500 lg:h-6 lg:w-6" />
    </div>
    <div
      v-else
      class="h-6 w-4 lg:h-6 lg:w-6" />
  </div>
</template>

<script setup lang="ts">
import type { IRunner, ISlot, ITeam, ITeamMember } from '@/interfaces';
import { computed, type PropType, ref, watch } from 'vue';
import { parseRunnerToMember, parseToValidationMember } from '../../composables/team/helper';
import { validateSlot } from '../../composables/team/validateSlot';
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../../composables/touch';
import SiCardInput from './siCardInput.vue';

const props = defineProps({
  dropzones: Array,
  index: Number,
  movingMember: Object as PropType<ITeamMember>,
  movingRunner: Object as PropType<IRunner>,
  optional: Boolean,
  slot: Object as PropType<ISlot>,
  team: Object as PropType<ITeam>,
});
const emits = defineEmits(['addSlot', 'removeSlot', 'setMovingMember', 'moveMember', 'dropzone', 'updateMember']);
const dropzone = ref(null);

watch(
  () => props.movingRunner,
  (runner) => {
    if (runner) {
      if (disableSlot.value) {
        emits('dropzone', null, props.index);
      }
      else {
        emits('dropzone', dropzone.value, props.index);
      }
    }
  },
);
watch(
  () => props.movingMember,
  (runner) => {
    if (runner) {
      if (disableSlot.value) {
        emits('dropzone', null, props.index);
      }
      else {
        emits('dropzone', dropzone.value, props.index);
      }
    }
  },
);

const onEdit = ref(false);

function updateSiCard(value: string) {
  props.slot.teamMember.si_card = value;
}

function startMove(ev: any, member: ITeamMember) {
  if (ev.type === 'dragstart') {
    member.year_of_birth = String(member.year_of_birth);
    ev.dataTransfer.setData('currentIndex', String(props.index));
    emits('setMovingMember', member);
  }
  if (ev.type === 'touchstart') {
    handleTouchStart(ev);
    emits('setMovingMember', member);
  }
}

async function dropRunner(event: DragEvent) {
  if (props.movingRunner) {
    props.slot.teamMember = parseRunnerToMember(props.movingRunner);
    emits('addSlot', props.slot, props.index);
  }
  else if (props.movingMember) {
    const currentIndex = Number.parseInt(event.dataTransfer.getData('currentIndex'));
    props.slot.teamMember = props.movingMember;
    emits('moveMember', props.index, currentIndex);
  }
}

function stopMove(ev: any) {
  if (ev.type === 'dragend') {
    emits('setMovingMember', null);
  }
  if (ev.type === 'touchend') {
    const touchEvent: TouchEvent = ev;
    if (handleTouchEnd(touchEvent, props.dropzones)) {
      const index = handleTouchEnd(touchEvent, props.dropzones);
      emits('moveMember', index, props.index);
    }
    emits('setMovingMember', null);
    ev.target.style = null;
  }
}

const disableSlotsWithoutMovedMember = computed(() => {
  if (!props.movingMember) {
    return false;
  }
  if (props.team.slots[props.index].teamMember.id !== props.movingMember.id) {
    return true;
  }
  return false;
});

const disableSlot = computed(() => {
  const validationMember = ref();
  if (props.movingMember) {
    validationMember.value = parseToValidationMember(props.movingMember);
  }
  else if (props.movingRunner) {
    validationMember.value = parseToValidationMember(props.movingRunner);
  }
  else {
    return;
  }

  if (!Array.isArray(props.slot.teamMember)) {
    return true;
  }
  const isSlotValid = validateSlot(props.slot.rules, props.team.rules, validationMember.value, props.team.slots);
  if (!isSlotValid) {
    if (props.movingRunner || props.movingMember) {
      return true;
    }
  }
  return false;
});
</script>
