<template>
  <div class="break-inside w-full">
    <Card>
      <template #title>
        {{ $lang('l.missingDopState') }}
        <div class="ml-2 flex gap-2">
          <div
            class="cursor-pointer"
            @click="getInvalidDopingRunners(false)">
            <div v-if="isLoading">
              <i
                aria-hidden="true"
                class="fas fa-sync fa-spin" />
            </div>
            <div v-else>
              <i
                aria-hidden="true"
                class="fas fa-sync" />
            </div>
          </div>
          <a
            :href="$route('races.doping.pdf', raceId)"
            rel="noopener noreferrer"
            target="_blank">
            <i
              aria-hidden="true"
              class="fal fa-file-pdf" />
          </a>
          <a
            :href="$route('race.export.missingDoping', raceId)"
            rel="noopener noreferrer"
            target="_blank">
            <i
              aria-hidden="true"
              class="fal fa-file-csv" />
          </a>
        </div>
      </template>
      <div
        v-if="!initializing"
        class="p-3">
        <div v-if="invalidDopingRunners.length > 0">
          <ul v-if="team">
            <li
              v-for="(team, index) in invalidDopingRunners"
              :key="index"
              class="mb-3">
              {{ team.club_name }} {{ team.name }}
              <ul
                v-for="(runner, index) in team.team_members"
                :key="index"
                class="list-disc list-inside">
                <li class="ml-5">
                  {{ runner.firstname }}
                  {{ runner.lastname }} -
                  {{ runner.year_of_birth }}
                  <a :href="`mailto:${runner.email}?subject=${$lang('l.mailSubjectMissingDoping')}`">
                    <i
                      aria-hidden="true"
                      class="fal fa-paper-plane ml-1 text-blue-600 hover:text-blue-700" />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul v-else>
            <li
              v-for="(runner, index) in invalidDopingRunners"
              :key="index">
              {{ runner.firstname }}
              {{ runner.lastname }} -
              {{ runner.year_of_birth }}
              <a :href="$route('race.raceRunners.edit', [runner.id])">
                <i
                  aria-hidden="true"
                  class="fas fa-pencil ml-2 text-blue-600 hover:text-blue-700" />
              </a>
              <a :href="`mailto:${runner.email}?subject=${$lang('l.mailSubjectMissingDoping')}`">
                <i
                  aria-hidden="true"
                  class="fal fa-paper-plane ml-1 text-blue-600 hover:text-blue-700" />
              </a>
            </li>
          </ul>
        </div>
        <div v-else>
          {{ $lang('l.noMissingDop') }}
        </div>
      </div>
      <div
        v-else
        class="w-full p-3">
        <div class="mx-auto text-center text-4xl">
          <i class="fas fa-circle-notch fa-spin" />
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { IRunner, ITeam } from '@/interfaces';

import axios from 'axios';
import { onMounted, ref, type Ref } from 'vue';

import Card from '../card.vue';

const props = defineProps({
  raceId: Number,
  team: { default: false, type: Boolean },
});
const isLoading = ref(false);
const initializing = ref(true);
const invalidDopingRunners: Ref<IRunner[] | ITeam[]> = ref([]);

onMounted(async () => {
  await getInvalidDopingRunners(true);
  initializing.value = false;
});

async function getInvalidDopingRunners(init: boolean) {
  try {
    if (!init) {
      isLoading.value = true;
    }

    const request = await axios.get<IRunner[] | ITeam[]>(init ? `/webapi/races/${props.raceId}/missingdop` : `/webapi/races/${props.raceId}/missingdop?from_solv=1`);
    invalidDopingRunners.value = request.data;

    if (!init) {
      isLoading.value = false;
    }
  }
  catch (error) {
    console.log(error);
  }
}
</script>
