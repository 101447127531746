<template>
  <div class="xxl:w-1/2 mb-4 w-full 3xl:max-w-xl">
    <Select
      v-model="parentalPartner"
      :edit-mode="true"
      :label="$lang('l.parentalPartner')"
      clearable
      name="parental_starttime_race_runner_id"
      :options="selectableRaceRunners"
      required
      :search-keys="['firstname', 'lastname']"
      searchable
      :show-option="(raceRunner: IRaceRunner) => `${raceRunner.firstname} ${raceRunner.lastname}`"
      :sort-options="(a: IRaceRunner, b: IRaceRunner) => a.lastname.localeCompare(b.lastname)"
      @update:model-value="updateRaceRunner" />
  </div>
</template>

<script setup lang="ts">
import type { IRaceRunner } from '@/interfaces';
import { onMounted, type PropType, ref, type Ref, watch } from 'vue';
import { useCartStore } from '../../store/cart';
import Select from '../select.vue';

const props = defineProps({
  raceRunner: { required: true, type: Object as PropType<IRaceRunner> },
});
defineEmits(['updateRaceRunner', 'getParent']);
const cartStore = useCartStore();

const parentalPartner: Ref<IRaceRunner | undefined> = ref();

onMounted(() => {
  parentalPartner.value = selectableRaceRunners.find(
    value => value.id === props.raceRunner.parental_starttime_race_runner_id,
  );
});
function updateRaceRunner() {
  cartStore.raceRunnerUpdate(props.raceRunner.id, {
    parental_starttime_race_runner_id: parentalPartner.value ? parentalPartner.value.id : null,
    starttime_request_id: null,
  });
}

watch(
  () => props.raceRunner.parental_starttime_race_runner_id,
  (parentalId) => {
    if (parentalId) {
      loadParentalPartner(props.raceRunner);
    }
    else {
      parentalPartner.value = undefined;
    }
  },
);

function loadParentalPartner(parental: IRaceRunner) {
  parentalPartner.value = selectableRaceRunners.find(
    raceRunner => raceRunner.id === parental.parental_starttime_race_runner_id,
  );
}

const selectableRaceRunners = cartStore.raceRunners.filter((raceRunner) => {
  if (raceRunner.race_id !== props.raceRunner.race_id) return false;
  if (!raceRunner.category_race) return false;
  if (!raceRunner.category_race.has_parental_starttime_request) return false;
  if (raceRunner.id === props.raceRunner.id) return false;
  if (
    raceRunner.parental_starttime_race_runner_id
    && raceRunner.parental_starttime_race_runner_id !== props.raceRunner.id
  ) {
    return false;
  }
  return true;
});
</script>
