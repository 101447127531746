<template>
  <Card>
    <template #title>
      <div class="w-full flex justify-between">
        <div>{{ raceRunner.firstname }} {{ raceRunner.lastname }}</div>
      </div>
      <div class="flex gap-4">
        <a
          class="text-blue-600 hover:text-blue-700"
          :href="route('race.raceRunners.edit', [raceRunner])">
          <i
            aria-hidden="true"
            class="fa fa-pencil" />
        </a>
        <button
          type="button"
          @click="refreshRaceRunner">
          <i
            aria-hidden="true"
            class="fa-regular fa-rotate" />
        </button>
        <div v-if="raceRunner.is_cancelable && !race.is_end_of_mutation">
          <button
            class="text-red-500"
            type="button"
            @click="cancelRaceRunner">
            <i
              aria-hidden="true"
              class="fa fa-trash" />
          </button>
        </div>
      </div>
    </template>
    <div class="p-3">
      <div class="mb-1 border-b border-b-solid pb-2">
        <div>
          <span class="text-gray-300">{{ lang('l.year_of_birth') }}:</span> {{ raceRunner.year_of_birth }}
        </div>
        <div>
          <span class="text-gray-300">{{ lang('l.place') }}:</span> {{ raceRunner.plz }} {{ raceRunner.city }}
        </div>
        <div>
          <span class="text-gray-300">{{ lang('l.club') }}:</span> {{ raceRunner.club.name }}
        </div>
        <div>
          <span class="text-gray-300">{{ lang('l.si_card') }}:</span> {{ raceRunner.si_card }}
        </div>
      </div>

      <div>
        <div>
          <span class="text-gray-300">{{ lang('l.etappen') }}:</span> {{ race.name }}
        </div>
        <div>
          <span class="text-gray-300">{{ lang('l.categories_short') }}:</span>
          {{ raceRunner.category?.short_name }}
        </div>
        <div>
          <span class="text-gray-300">{{ lang('l.signupGroup.default') }}:</span>
          {{
            isDefaultSignupGroup
              ? `${$lang('l.defaultGroupOf')
              } ${
                raceRunner.signup_user.firstname
              } ${
                raceRunner.signup_user.lastname}`
              : `${raceRunner.signup_group.name} ${$lang('l.of')} ${raceRunner.signup_user.firstname} ${
                raceRunner.signup_user.lastname
              }`
          }}
        </div>
        <div v-if="raceRunner.hasRegionAssignments">
          <span class="text-gray-300">{{ lang('l.block.header') }}:</span>
          {{ lang(raceRunner.race_region_text) }}
        </div>
        <div v-if="raceRunner.start && raceRunner.start.time">
          <span class="text-gray-300">{{ lang('l.starttime.*') }}:</span>
          {{ dayjs(raceRunner.start.time).format('HH:mm') }}
        </div>
        <div v-else-if="race.startlist_url">
          <span class="text-gray-300">{{ lang('l.startlist_url') }}:</span>
          <a :href="race.startlist_url">
            <i
              aria-hidden="true"
              class="far fa-external-link-alt ml-2" />
          </a>
        </div>
        <div v-if="raceRunner.starttimeRequest">
          <span class="text-gray-300">{{ lang('l.startimeRequest.name') }}:</span>
          {{ lang(raceRunner.starttimeRequest.name) }}
          <span v-if="raceRunner.parental_starttime_race_runner_id">
            ({{ lang('l.parentalStartTime') }})
          </span>
        </div>
      </div>
      <div
        v-if="extraRunners && flatExtraRunners(extraRunners).length"
        class="mt-1 border-t border-t-solid pt-2">
        <div>
          <span class="text-gray-300">{{ lang('l.additionalRunners') }}</span>
        </div>
        <div>
          <div
            v-for="(extraRunner, index) in flatExtraRunners(extraRunners)"
            :key="index"
            class="ml-2">
            {{ extraRunner.firstname }} {{ extraRunner.lastname }}
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { IRace, IRaceRunner } from '@/interfaces';
import { useAxios } from '@vueuse/integrations/useAxios';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { computed } from 'vue';
import { errorHandler } from '../../helpers/api';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import Card from '../card.vue';

type Props = {
  race: IRace;
  raceRunner: IRaceRunner;
  extraRunners: Record<string, { extraMap: boolean; firstname: string; lastname: string }>;
};

const props = defineProps<Props>();
const { execute, isLoading } = useAxios();

async function cancelRaceRunner() {
  const swal = await Swal.fire({
    cancelButtonText: lang('l.cancel'),
    confirmButtonText: lang('l.yes'),
    icon: 'warning',
    showCancelButton: true,
    title: lang('l.raceRunnerCancel'),
  });
  if (swal.isConfirmed) {
    try {
      await execute(route('webapi.raceRunner.cancel', props.raceRunner), {
        method: 'POST',
      });
      location.reload();
    }
    catch (error) {
      errorHandler(error);
    }
  }
}

async function refreshRaceRunner() {
  if (isLoading.value) return;
  const swal = await Swal.fire({
    cancelButtonText: lang('l.cancel'),
    confirmButtonText: lang('l.yes'),
    icon: 'warning',
    showCancelButton: true,
    text: props.raceRunner.has_solv_number ? lang('l.reloadData_solv_text') : lang('l.reloadData_text'),
    title: props.raceRunner.has_solv_number ? lang('l.reloadData_solv_title') : lang('l.reloadData_title'),
  });
  if (swal.isConfirmed) {
    await execute(route('webapi.raceRunner.refresh', props.raceRunner), {
      method: 'POST',
    });
    if (swal.isConfirmed) {
      try {
        await execute(route('webapi.raceRunner.refresh', props.raceRunner), {
          method: 'POST',
        });
        location.reload();
      }
      catch (error) {
        errorHandler(error);
      }
    }
  }
}
function flatExtraRunners(extraRunners: Record<string, { extraMap: boolean; firstname: string; lastname: string }>) {
  const arr = [];
  Object.values(extraRunners).forEach((extraRunner) => {
    arr.push(extraRunner);
  });
  return arr;
}

const isDefaultSignupGroup = computed(() => {
  if (!props.raceRunner.signup_group) return true;
  if (!props.raceRunner.signup_group.id) return true;
  return false;
});
</script>
