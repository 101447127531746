<template>
  <SupCard>
    <div class="flex w-full flex-col gap-5 p-3 md:flex-row">
      <SupSelect
        class="w-full"
        :clear-on-search="true"
        :delay="0"
        :disabled="isLoading"
        :infinite="true"
        label="l.user"
        :limit="30"
        :min-chars="3"
        name="runner_id"
        no-clear
        :options="async (query: string) => {
          return await fetchUsers(query)
        }"
        :placeholder="$lang('l.lastnameMinChars', { min: '3' })"
        :resolve-on-load="false"
        @update:model-value="addAdmin($event)" />
      <div class="w-full">
        <div class="border-b px-3">
          <b>{{ $lang('l.admins') }}</b>
        </div>
        <Admin
          v-for="admin, index in _admins"
          :key="index"
          :admin="admin"
          class="odd:bg-lightgray-100"
          :disabled="isLoading"
          @remove="removeAdmin(admin)" />
      </div>
    </div>
  </SupCard>
</template>

<script setup lang="ts">
import type { IUser } from '@/interfaces';

import SupCard from '@/components/ui/supCard.vue';
import SupSelect from '@/components/ui/supSelect.vue';
import { errorHandler } from '@/helpers';
import { pull } from 'lodash';

import Admin from './admin.vue';

type Props = {
  admins: IUser[];
  eventId: number;
};
const props = defineProps<Props>();

const _admins = ref(props.admins);

const abortController = ref<AbortController>();

async function fetchUsers(query?: string) {
  if (!query) return [];
  try {
    if (abortController.value) {
      abortController.value.abort();
    }
    abortController.value = new AbortController();
    const signal = abortController.value.signal;
    const { data } = await useAxios<IUser[]>(route('users.getAll', { _query: { event_id: props.eventId, name: query } }), { signal });
    console.log(data.value);
    if (data.value) return filterMapUsers(data.value);
    return [];
  }
  catch {
    return [];
  }
}

function filterMapUsers(users: IUser[]) {
  const filteredUsers = users.filter(user => !_admins.value.some(admin => admin.id === user.id));
  console.log(filteredUsers);
  return filteredUsers.map((user) => {
    return {
      ...user,
      label: `${user.lastname} ${user.firstname}`,
    };
  });
}

const { execute, isLoading } = useAxios<{ user: IUser }>();

async function addAdmin(adminId: number) {
  try {
    const { data } = await execute(route('webapi.admin.events.addAdmin', { event: { id: props.eventId } }), {
      data: { user_id: adminId },
      method: 'POST',
    });
    if (data.value) {
      _admins.value.push(data.value.user);
    }
  }
  catch (error) {
    errorHandler(error);
  }
}
async function removeAdmin(admin: IUser) {
  try {
    await execute(route('webapi.admin.events.removeAdmin', { event: { id: props.eventId } }), {
      data: { user_id: admin.id },
      method: 'POST',
    });
    pull(_admins.value, admin);
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
