import { localize, setLocale } from '@vee-validate/i18n';
import de from '@vee-validate/i18n/dist/locale/de.json';
import en from '@vee-validate/i18n/dist/locale/en.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import it from '@vee-validate/i18n/dist/locale/it.json';
import { email, length, max, max_value, min_value, required, url } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';

function setLanguage() {
  const language = document.querySelector('meta[name="language"]')?.getAttribute('content') || 'en';
  setLocale(language.slice(0, 2));
}

defineRule('email', email);
defineRule('length', length);
defineRule('max', max);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('required', required);
defineRule('url', url);
configure({
  generateMessage: localize({
    de,
    en,
    fr,
    it,
  }),
});

setLanguage();
