<template>
  <div>
    <Card
      v-if="account && !show"
      class="p-3">
      <div class="grid grid-cols-1 gap-5 md:grid-cols-[1fr_1fr_60px]">
        <div class="rounded-lg border px-2 py-1">
          <div class="mb-1">
            <i class="fal fa-user" />
          </div>
          <div>
            {{ getAddress() }}
          </div>
          <div v-if="gender">
            {{ gender }}
          </div>
          <div>{{ account.email }}</div>
          <div v-if="club">
            {{ club.name }}
          </div>
        </div>
        <div>
          <div class="flex flex-col rounded-lg border px-2 py-1">
            <div class="mb-1">
              <i class="fal fa-credit-card" />
            </div>
            <div>{{ account.account_owner }}</div>
            <div>{{ account.account }}</div>
            <div>{{ lang('l.supsign_account') }}: {{ account.supsign_account }}</div>
          </div>
        </div>
        <div class="flex justify-center md:flex-col">
          <div class="flex gap-5">
            <BaseButton
              icon="fat fa-pencil"
              variant="plain"
              @click="show = true" />
            <BaseButton
              color="negative"
              :disabled="account.has_races"
              icon="fat fa-trash"
              variant="plain"
              @click="remove" />
          </div>
        </div>
      </div>
    </Card>
    <Card
      v-else-if="!show"
      class="cursor-pointer p-3"
      @click="show = true">
      <div
        class="flex justify-center gap-5 rounded-lg border-2 border-dashed border-green-600 px-2 py-1 text-4xl">
        <i class="fa-light fa-user-plus my-auto py-5" />
        <div class="my-auto">
          {{ lang('l.addAccount') }}
        </div>
      </div>
    </Card>
    <Card v-else>
      <AccountForm
        :account="account"
        :clubs="clubs"
        :countries="countries"
        :genders="genders"
        @close="show = false"
        @created="create"
        @updated="update" />
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { IAccount, IClub, ICountry, IGender } from '@/interfaces';
import { useAxios } from '@vueuse/integrations/useAxios';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import { errorHandler } from '../../../helpers/api';
import { lang } from '../../../plugins/lang';
import { route } from '../../../plugins/route';
import BaseButton from '../../base/baseButton.vue';
import Card from '../../card.vue';
import AccountForm from './accountForm.vue';

type Props = {
  account?: IAccount;
  clubs: IClub[];
  countries: ICountry[];
  genders: IGender[];
};

type Emits = {
  (e: 'updated', account: IAccount): void;
  (e: 'created', account: IAccount): void;
  (e: 'removed'): void;
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const show = ref(false);

function getAddress() {
  if (!props.account) return;
  let str = '';
  if (props.account.firstname && props.account.lastname)
    str += `${props.account.firstname} ${props.account.lastname}`;
  if (props.account.street) str += `, ${props.account.street}`;
  if (props.account.street2) str += `, ${props.account.street2}`;
  if (props.account.plz && props.account.city) str += `, ${props.account.plz} ${props.account.city}`;
  if (country.value) str += `, ${country.value.name}`;
  return str;
}

function create(account: IAccount) {
  show.value = false;
  emit('created', account);
}

function update(account: IAccount) {
  show.value = false;
  emit('updated', account);
}
const club = computed(() => props.clubs.find(el => el.id === props.account?.club_id));
const gender = computed(() => {
  const gender = props.genders.find(el => el.id === props.account.gender_id);
  if (gender) return gender.name_translated.charAt(0).toUpperCase() + gender.name_translated.slice(1);
  return '';
});
const country = computed(() => props.countries.find(el => el.id === props.account.country_id));

async function remove() {
  if (await removeAccountConfirmation()) {
    try {
      await useAxios<IAccount>(route('webapi.account.patch', { id: props.account.id }), {
        method: 'DELETE',
      });
      emit('removed');
    }
    catch (error) {
      errorHandler(error);
    }
  }
}

async function removeAccountConfirmation() {
  const swal = await Swal.fire({
    cancelButtonText: lang('l.no'),
    confirmButtonText: lang('l.yes'),
    icon: 'warning',
    showCancelButton: true,
    text: lang('l.removeAccountText'),
    title: lang('l.removeAccount'),
  });
  if (swal.isConfirmed) return true;
  return false;
}
</script>
