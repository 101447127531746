<template>
  <div class="scrollbar flex h-full flex-col gap-5 overflow-x-auto md:flex-row">
    <RoadmapColumn
      v-for="(value, key, index) in featureRequestStatuses"
      :key="index"
      :feature-edit="featureEdit"
      :feature-request-statuses="featureRequestStatuses"
      :feature-requests="featureRequests"
      :features="getFeaturesByStatus(value)"
      :status="value"
      @updated="updated" />
  </div>
</template>

<script setup lang="ts">
import type { IFeature, IFeatureRequest } from '@/interfaces';
import { type PropType, type Ref, ref } from 'vue';
import RoadmapColumn from './roadmapColumn.vue';

const props = defineProps({
  featureRequests: { default: () => [], type: Array as PropType<IFeatureRequest[]> },
  featureRequestStatuses: { default: undefined, type: Object as PropType<{ [key: string]: number }> },
  features: { default: () => [], type: Array as PropType<IFeature[]> },
});

const featureEdit: Ref<IFeature> = ref();
const featureList = ref(props.features);

function getFeaturesByStatus(status: number) {
  return featureList.value.filter(el => el.status === status);
}

function updated(feature: IFeature) {
  const index = featureList.value.findIndex(el => el.id === feature.id);
  featureList.value[index] = feature;
}
</script>
