<template>
  <div
    v-if="!disabled"
    @click="deleteTeam">
    <i
      aria-hidden="true"
      class="far fa-trash cursor-pointer text-red-500" />
  </div>
</template>

<script setup lang="ts">
import type { IRace, ITeam } from '@/interfaces';
import type { PropType } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';

const props = defineProps({
  disabled: Boolean,
  race: Object as PropType<IRace>,
  team: Object as PropType<ITeam>,
});

async function deleteTeam() {
  try {
    const swal = await Swal.fire({
      cancelButtonText: lang('l.cancel'),
      confirmButtonText: lang('l.delete.default'),
      icon: 'warning',
      showCancelButton: true,
      text: lang('l.reallyDeleteTeam'),
      title: lang('l.warning'),
    });
    if (swal.isConfirmed) {
      const result = await axios.delete(route('races.signup.teams.delete', [props.race.id, props.team.id]));
      window.location.href = result.data;
    }
  }
  catch (error) {
    console.log(error);
    await Swal.fire({
      html: error.response.data.message,
      icon: 'error',
    });
  }
}
</script>
