<template>
  <div class="w-full">
    <div
      v-if="!sameDateRace"
      class="mb-4 ml-2">
      {{ race.name }}
    </div>
    <div
      v-if="raceRunnerSignedByMe"
      class="space-y-6">
      <BaseMultiselect
        clearable
        :disable-message="disableMessage"
        :disabled="isLoading || (!props.race.registration_is_open && !isEventAdmin) || missingAttributes || (props.raceRunner && props.raceRunner.state_id !== 1)"
        label="l.category.default"
        :model-value="selectedCategoryRaceId"
        :options="selectableCategoryRaces"
        @update:model-value="categoryRaceInput" />
      <SelectStart
        v-if="raceRunnerSignup && raceRunnerSignup.has_starts && !categorySelectionIsDisabled"
        ref="selectStart"
        :race="race"
        :race-runner="raceRunnerSignup" />
    </div>
    <div v-else>
      {{ $lang('l.signedUpByOther') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, IRace, IRaceRunner, IRunner, IUser } from '@/interfaces';
import { errorHandler, Toast } from '@/helpers';
import { lang, route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { sortBy } from 'lodash';
import { useCartStore } from '../../store/cart';
import BaseMultiselect from '../base/baseMultiselect.vue';
import SelectStart from '../raceRunner/selectStart.vue';

type Props = {
  isEventAdmin?: boolean;
  race: IRace;
  raceRunner?: IRaceRunner;
  runner: IRunner;
  sameDateRace?: boolean;
  user: IUser;
};

const props = defineProps<Props>();
const cartStore = useCartStore();

const {
  data: categoryRaces,
  execute,
  isLoading,
} = useAxios<ICategoryRace[] | undefined>(route('webapi.signup.categoryRaces', props.race), {
  data: { runner: props.runner },
  method: 'POST',
}, { immediate: !!props.race });

const raceRunnerSignup = ref<IRaceRunner>(props.raceRunner);

const selectedCategoryRaceId = computed(() => {
  if (!raceRunnerSignup.value) return null;
  const categoryRace = selectableCategoryRaces.value.find(el => el.id === raceRunnerSignup.value.category_race_id);
  return categoryRace ? categoryRace.id : null;
});
const categorySelectionIsDisabled = ref(false);

const selectStart = ref<InstanceType<typeof SelectStart>>(null);

function solvRequirementsAccomplished(categoryRace: ICategoryRace) {
  if (!solvIsMissing.value) return true;
  if (!categoryRace || !categoryRace.category) return true;
  return !categoryRace.category.solv_required;
}

const selectableCategoryRaces = computed(() => {
  if (!categoryRaces.value) return [];
  const categories = categoryRaces.value.map((categoryRace) => {
    const solvReq = solvRequirementsAccomplished(categoryRace);
    return {
      ...categoryRace,
      disabled: !solvReq,
      label: solvReq
        ? categoryRace.category?.short_name
        : `${categoryRace.category?.short_name} (${lang('l.solvNumberRequired')})`,
    };
  });
  return sortBy(categories, 'sort_order');
});

watch(
  () => props.race,
  async () => await execute(),
);

const raceRunnerSignedByMe = computed(() => {
  if (!raceRunnerSignup.value) return true;
  return props.user.id === raceRunnerSignup.value.user_id;
});

const solvIsMissing = computed(() => {
  if (props.runner.has_solv || props.runner.nation_id !== 1) return false;
  return props.race.has_td;
});
const missingAttributes = computed(() => {
  if (!props.runner.club_id) return true;
  if (!props.runner.nation_id) return true;
  return false;
});

const disableMessage = computed(() => {
  const transactions = props.runner.race_runners.find(rr => props.raceRunner?.id === rr.id)?.related_transactions;
  if (!props.race.registration_is_open) {
    return lang('l.signupWindowClosed');
  }
  else if (missingAttributes.value) {
    return `<p class="flex flex-wrap">${lang('l.registerDisabledBecauseMissingInformations')}
        <a class="text-blue-600 hover:text-blue-700 hover:underline" href=${route('runners.edit', props.runner.id)}>
        ${lang('l.complete')}</a></p>`;
  }
  else if (transactions?.length) {
    return `<p class="flex flex-wrap gap-1">${lang('l.registerDisabledBecauseOpenTransactions')}
        <a class="text-blue-600 hover:text-blue-700 hover:underline" href=${route('cart.summary', transactions[0]?.id)}> 
        ${lang('l.invoice.default')} #${transactions[0]?.id}
        </a></p>`;
  }
  return '';
});

function categoryRaceInput(categoryRaceId: number | null) {
  if (categoryRaceId) {
    if (raceRunnerSignup.value) return patchRaceRunner(categoryRaceId);
    return storeRaceRunner(categoryRaceId);
  }
  return deleteRaceRunner();
}

async function storeRaceRunner(categoryRaceId: number) {
  categorySelectionIsDisabled.value = true;
  try {
    const { data } = await useAxios<IRaceRunner>(route('webapi.signup.store', props.race), {
      data: {
        category_race_id: categoryRaceId,
        runner_id: props.runner.id,
        user_id: props.user.id,
      },
      method: 'POST',
    });
    raceRunnerSignup.value = data.value;
    Toast.fire({
      icon: 'success',
      title: lang('l.signedUp'),
    });
    cartStore.$patch({ count: cartStore.count + 1 });
  }
  catch (error) {
    errorHandler(error);
  }
  categorySelectionIsDisabled.value = false;
}

async function deleteRaceRunner() {
  categorySelectionIsDisabled.value = true;
  try {
    await useAxios<IRaceRunner>(route('webapi.racerunner.delete', raceRunnerSignup.value), {
      method: 'DELETE',
    });
    raceRunnerSignup.value = null;
    Toast.fire({
      icon: 'success',
      title: lang('l.signedOff'),
    });
    cartStore.$patch({ count: cartStore.count - 1 });
  }
  catch (error) {
    errorHandler(error);
  }
  categorySelectionIsDisabled.value = false;
}

async function patchRaceRunner(categoryRaceId: number) {
  categorySelectionIsDisabled.value = true;
  try {
    const { data } = await useAxios(route('webapi.racerunner.update.edit', raceRunnerSignup.value), {
      data: { category_race_id: categoryRaceId },
      method: 'PATCH',
    });
    raceRunnerSignup.value = data.value;
    selectStart.value?.loadStarts();
    Toast.fire(lang('l.categoryUpdated'), '', 'success');
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
