<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="mb-4 space-y-5">
    <div class="md:flex md:flex-row md:space-x-4">
      <div class="md:w-1/2">
        <DateTimePicker
          v-model="raceDate.start_date"
          :disabled="disabled"
          name="start_date"
          type="date" />
      </div>
      <div class="md:w-1/2">
        <DateTimePicker
          v-model="raceDate.end_date"
          :disabled="disabled"
          :min="raceDate.start_date"
          name="end_date"
          type="date" />
      </div>
    </div>

    <div class="flex justify-between">
      <BaseButton
        :disabled="!raceDate.start_date || !raceDate.end_date || disabled"
        :label="$lang('l.save')"
        :loading="loading.update"
        @click="update" />
      <BaseButton
        :disabled="!deletable"
        :label="$lang('l.delete.default')"
        :loading="loading.delete"
        @click="remove" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRaceDate } from '@/interfaces';

import axios from 'axios';
import Swal from 'sweetalert2';
import { type PropType, ref } from 'vue';

import { Toast } from '../helpers/toast';
import { lang } from '../plugins/lang';
import { route } from '../plugins/route';
import BaseButton from './base/baseButton.vue';
import DateTimePicker from './dateTimePicker.vue';

const props = defineProps({
  deletable: { default: true, type: Boolean },
  disabled: { type: Boolean },
  raceDate: { required: true, type: Object as PropType<IRaceDate> },
});

const emits = defineEmits(['remove']);

const loading = ref({
  delete: false,
  update: false,
});

async function update() {
  try {
    loading.value.update = true;
    await axios.patch<IRaceDate>(
      route('race.racedate.update', [{ id: props.raceDate.race_id }, { id: props.raceDate.id }]),
      props.raceDate,
    );
    loading.value.update = false;
    await Toast.fire({ icon: 'success', text: lang('l.saved') });
  }
  catch (error) {
    Swal.fire({
      icon: 'error',
      text: error.response.data.message,
    });
    loading.value.update = false;
  }
}

async function remove() {
  try {
    loading.value.delete = true;
    await axios.delete(route('race.racedate.delete', [{ id: props.raceDate.race_id }, { id: props.raceDate.id }]));
    emits('remove', props.raceDate);
    loading.value.delete = false;
    return props.raceDate;
  }
  catch (error) {
    Swal.fire({
      icon: 'error',
      text: error.response.data.message,
    });
    loading.value.delete = false;
  }
}
</script>
