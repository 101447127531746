<template>
  <div class="flex flex-col gap-5">
    <div class="grid gap-5">
      <div>
        <TeamInformations
          :category-races="race.category_races"
          :race-id="race.id"
          :readonly="currentProgressIndex !== 0"
          @created="teamCreated($event)" />
      </div>
      <template v-if="currentProgressIndex > 0">
        <TeamMembers
          :errors="errorMessages"
          :genders="genders"
          :readonly="currentProgressIndex !== 1"
          :team="team"
          @open-payment="openPayment"
          @update-team="team = $event" />
        <Card
          v-if="team.extra_defs && team.extra_defs.length > 0"
          class="grid w-full gap-5 rounded-none p-3 lg:rounded-lg">
          <TeamExtras
            :readonly="currentProgressIndex !== 1"
            :team="team" />
        </Card>
        <template v-if="currentProgressIndex === 1">
          <TeamDiscountCode
            v-if="team.race.has_discount_codes"
            :team="team"
            @update-has-discount="team.has_discount = $event" />
          <div class="flex justify-end gap-5 px-3">
            <BaseButton
              color="secondary"
              :label="$lang('l.back')"
              @click="currentProgressIndex = 0" />
            <BaseButton
              :label="$lang('l.check_team_and_confirm')"
              @click="checkTeamMembers()" />
          </div>
        </template>
      </template>
      <TeamInvoice
        v-if="currentProgressIndex > 1 && transaction"
        :race="race"
        :team="team"
        :transaction="transaction"
        @back="currentProgressIndex = 1" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IGender, IRace, ITeam, ITeamMember, ITransaction } from '@/interfaces';

import { useAxios } from '@vueuse/integrations/useAxios';
import { ref } from 'vue';

import { useCheckTeam } from '../../../composables/team/checkTeam';
import { errorHandler } from '../../../helpers/api';
import { route } from '../../../plugins/route';
import BaseButton from '../../base/baseButton.vue';
import Card from '../../card.vue';
import TeamExtras from '../../team/teamExtras.vue';
import TeamDiscountCode from './teamDiscountCode.vue';
import TeamInformations from './teamInformations.vue';
import TeamInvoice from './teamInvoice.vue';
import TeamMembers from './teamMembers.vue';

type Props = {
  genders: IGender[];
  race: IRace;
};

defineProps<Props>();

const team = ref<ITeam | null>(null);
const transaction = ref<ITransaction | null>(null);
const currentProgressIndex = ref(0);
const errorMessages = ref(null);

function openPayment() {
  // team.value = updatedTeam;
  createTransaction();
  currentProgressIndex.value = 2;
}

function teamCreated(item: ITeam) {
  team.value = item;
  currentProgressIndex.value = 1;
}

function checkTeamMembers() {
  const { checkTeam, errors } = useCheckTeam(team.value);
  const teamMembers = team.value.slots.map(slot => slot.teamMember);
  checkTeam(removeIncompleteUsers(teamMembers));
  if (errors.value.length === 0) {
    openPayment();
  }
  else {
    errorMessages.value = errors.value;
  }
}

function removeIncompleteUsers(teamMembers: ITeamMember[]) {
  return teamMembers.filter((member) => {
    if (!member.firstname) return false;
    if (!member.lastname) return false;
    if (!member.year_of_birth) return false;
    if (!member.gender_id) return false;
    return true;
  });
}
async function createTransaction() {
  try {
    const { data } = await useAxios<ITransaction>(route('guest.checkout', team.value.user_id), { method: 'POST' });
    transaction.value = data.value;
    team.value.has_discount = true;
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
