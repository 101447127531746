<template>
  <div class="input relative mt-2 min-h-[3.75rem] text-left text-gray-700">
    <label>
      <span
        ref="labelRef"
        class="input__label"> {{ label }}{{ required ? ' *' : '' }} </span>
    </label>
    <input
      v-model="value"
      class="input__field w-full"
      :disabled="disabled"
      :max="getMax"
      :min="getMin"
      :type="type"
      v-bind="$attrs"
      @blur="correctDate"
      @input="$emit('update:modelValue', ($event.target as HTMLTextAreaElement).value)">
    <span
      v-if="initError"
      class="input__error text-xs sm:text-base"
      role="alert">
      {{ initError }}
    </span>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, ref } from 'vue';

const props = defineProps({
  disabled: Boolean,
  initError: String,
  initValue: String,
  label: String,
  max: [String, Date],
  min: [String, Date],
  modelValue: [String, Date],
  required: Boolean,
  type: String,
});
const emits = defineEmits(['update:modelValue']);

const value = ref('');

const format = getFormat(props.type);

init();

function init() {
  if (props.initValue) {
    value.value = props.type === 'time' ? props.initValue : dayjs(props.initValue).format(format);
  }
  else if (props.modelValue) {
    value.value = props.type === 'time' ? String(props.modelValue) : dayjs(props.modelValue).format(format);
  }
  emits('update:modelValue', value.value);
}

function correctDate() {
  if (props.type === 'time') {
    return;
  }
  if (dayjs(value.value).isBefore(dayjs('1970-01-01T00:00'))) {
    value.value = dayjs(value.value).set('y', dayjs().get('y')).format(format);
  }
  if (dayjs(value.value).isAfter(dayjs('2037-12-31T23:59'))) {
    value.value = dayjs(value.value).set('y', dayjs().get('y')).format(format);
  }
  emits('update:modelValue', value.value);
}

function getFormat(type: string) {
  switch (type) {
    case 'date': {
      return 'YYYY-MM-DD';
    }
    case 'datetime-local': {
      return 'YYYY-MM-DDTHH:mm';
    }
    case 'time': {
      return 'HH:mm';
    }
  // No default
  }
}

const getMin = computed(() => {
  if (props.type === 'date') {
    return props.min ? String(props.min) : '1970-01-01';
  }
  else if (props.type === 'datetime-local') {
    return '1970-01-01T00:00';
  }
});

const getMax = computed(() => {
  if (props.type === 'date') {
    return props.max ? String(props.max) : '2037-12-31';
  }
  else if (props.type === 'datetime-local') {
    return '2037-12-31T23:59';
  }
});
</script>
