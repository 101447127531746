export function getFormId(instance: any) {
  const parent = instance?.parent;

  if (!parent) {
    return;
  }

  if (parent.exposed?.formId) {
    return parent.exposed.formId;
  }

  return getFormId(parent);
}
