import type { ITeam, ITeamMember } from '@/interfaces';
import { ref } from 'vue';
import { lang } from '../../plugins/lang';

export function useCheckTeam(team: ITeam) {
  const rules = ref((team && team.rules) ?? []);
  const slots = ref((team && team.slots) ?? []);
  const checked = ref(false);
  const errors = ref([]);

  async function checkTeam(teamMembers: ITeamMember[]) {
    if (team) {
      rules.value = team.rules;
      slots.value = team.slots;
    }

    const newErrors = [];
    checkAllFieldsSet();
    Object.entries(rules.value).forEach(([key, value]) => {
      if (key === 'min_team_member_count') checkMinTeamMemberCount(value as number);
      if (key === 'min_year_of_birth_count') checkMinYearOfBirthCount(value as Record<string, number>);
      if (key === 'min_year_of_birth') checkMinYearOfBirth(Number.parseInt(value as any));
      if (key === 'max_year_of_birth') checkMaxYearOfBirth(Number.parseInt(value as any));
      if (key === 'gender') checkGender(value as number);
    });

    function checkAllFieldsSet() {
      let error = false;
      const validationFields = ['firstname', 'lastname', 'gender_id', 'year_of_birth'];
      teamMembers.forEach((member) => {
        Object.keys(member).forEach((key) => {
          if (validationFields.includes(key) && !member[key]) error = true;
        });
      });
      if (error) newErrors.push('Alle Felder müssen ausgefüllt sein.');
    }
    function checkMinTeamMemberCount(value: number) {
      if (teamMembers.length < value) {
        newErrors.push(lang('l.team_rules.min_team_member_count', { count: String(value) }));
      }
    }

    function checkMinYearOfBirthCount(value: Record<string, number>) {
      const year = Object.keys(value as Record<string, number>)[0];
      const count = value[year];
      const correctEntries = teamMembers.filter(member => member.year_of_birth >= Number.parseInt(year));
      if (correctEntries.length < count) {
        newErrors.push(
          lang('l.team_rules.min_year_of_birth_count', { count: String(count), year: String(year) }),
        );
      }
    }

    function checkMinYearOfBirth(value: number) {
      const year = value as number;
      const correctEntries = teamMembers.filter(member => member.year_of_birth >= year);
      if (correctEntries.length < teamMembers.length) {
        newErrors.push(lang('l.team_rules.min_year_of_birth', { year: String(year) }));
      }
    }

    function checkMaxYearOfBirth(value: number) {
      const year = value as number;
      const correctEntries = teamMembers.filter(member => member.year_of_birth <= year);
      if (correctEntries.length < teamMembers.length) {
        newErrors.push(lang('l.team_rules.max_year_of_birth', { year: String(year) }));
      }
    }

    function checkGender(value: number) {
      const correctEntries = teamMembers.filter(member => member.gender_id === value);
      if (correctEntries.length < teamMembers.length) {
        newErrors.push(lang('l.team_rules.gender'));
      }
    }
    checked.value = true;
    errors.value = newErrors;
  }

  return { checkTeam, errors, slots };
}
