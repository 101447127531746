<template>
  <tr class="cursor-pointer bg-white hover:bg-lightgray-100" @click="openTransaction">
    <BaseTableField :value="transaction.receipt_number" />
    <BaseTableField :value="transaction.created_at" />
    <BaseTableField :value="races" />
    <BaseTableField>
      <span class="whitespace-nowrap rounded-md px-2 py-1" :class="stateColor">{{ getTranslatedLabel(`l.transaction_state.${transaction.transaction_state.name}`) }}</span>
    </BaseTableField>
    <BaseTableField class="w-32" :value="amount" />
  </tr>
</template>

<script setup lang="ts">
import type { IRace, ITransaction } from '@/interfaces';

import { getTranslatedLabel } from '@/helpers';

import BaseTableField from '../base/table/baseTableField.vue';

type Props = {
  transaction: ITransaction & { races: IRace[] };
};
const props = defineProps<Props>();

const races = computed(() => {
  if (!props.transaction.races || props.transaction.races.length === 0) return;
  const races = props.transaction.races.map(race => race.name);
  return races.join(' | ');
});
0;
const amount = computed(() => props.transaction.amount === null ? '-' : `CHF ${props.transaction.amount.toFixed(2)}`);

function openTransaction() {
  window.open(route('cart.summary', props.transaction), '_blank');
}

const stateColor = computed(() => {
  if (!props.transaction.transaction_state) return;
  const state = props.transaction.transaction_state.name;
  if (state === 'confirmed' || state === 'external-payment') return 'bg-lightgray-300';
  if (state === 'created' || state === 'waiting' || state === 'waiting-invoice') return 'bg-red-500 text-white';
});
</script>
