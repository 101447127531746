<template>
  <div>
    <div class="relative">
      <div
        class="box-border block w-full rounded-lg border border-gray-200 text-gray-900 shadow-md disabled:cursor-not-allowed disabled:opacity-50"
        :class="required ? 'border-solid' : 'border-dashed'">
        <legend
          v-if="label"
          class="absolute -top-4 left-2 z-10 scale-90 bg-white px-1 text-gray-400">
          {{ label }}
        </legend>
        <div class="mt-2">
          <div
            v-for="(field, index) in fields"
            :key="index"
            class="m-2 flex justify-between gap-2 rounded-md bg-lightgray-200 px-2 py-1">
            <span>{{ field.value.name }}</span>
            <BaseButton
              class="my-auto"
              color="negative"
              icon="fas fa-x"
              variant="plain"
              @click="remove(index)" />
          </div>
        </div>
        <SupSelect
          v-model="select"
          class="m-2"
          name="select"
          option-key="name"
          :options
          @update:model-value="addToFields" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IFeatureRequest } from '@/interfaces';
import { useFieldArray } from 'vee-validate';
import { computed, nextTick, type PropType, ref } from 'vue';
import BaseButton from '../base/baseButton.vue';
import SupSelect from '../ui/supSelect.vue';

const props = defineProps({
  featureRequests: { default: () => [], type: Array as PropType<IFeatureRequest[]> },
  label: { default: '', type: String },
  name: { required: true, type: String },
  required: { default: false, type: Boolean },
});

const { fields, push, remove } = useFieldArray<IFeatureRequest>(props.name);

const options = computed(() => {
  return props.featureRequests.filter(req => !fields.value.find(field => field.value.id === req.id));
});

function addToFields(id: number) {
  const option = options.value.find(opt => opt.id === id);
  if (option) {
    push(option);
    changed();
  }
}

const select = ref();
function changed() {
  nextTick(() => {
    select.value = undefined;
  });
}
</script>
