<template>
  <div>
    <div class="space-y-3 md:space-y-6 xl:grid xl:grid-cols-2 xl:gap-4 xl:space-y-0 2xl:grid-cols-3">
      <CourseItem
        v-for="(course, index) in courses"
        :key="index"
        :course="course"
        :race="race"
        @remove="remove"
        @updated="updated" />
    </div>
    <div class="mt-5">
      <Button
        class="button-primary w-full md:w-auto"
        @click="createEmptyCourse">
        {{ $lang('l.courseCreate') }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICourse, IRace } from '@/interfaces';
import axios from 'axios';
import { type PropType, type Ref, ref } from 'vue';
import { Toast } from '../../helpers/toast';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import Button from '../button.vue';
import CourseItem from './courseItem.vue';

const props = defineProps({
  initCourses: { default: () => [], type: Array as PropType<ICourse[]> },
  race: { required: true, type: Object as PropType<IRace> },
});

const courses: Ref<ICourse[]> = ref(props.initCourses);

async function createEmptyCourse() {
  try {
    const nextInternalId = courses.value.length + 1;
    const emptyCourse: ICourse = {
      id: null,
      name: lang('l.course') + nextInternalId,
      race_id: props.race.id,
    };
    const res = await axios.post<ICourse>(route('webapi.course.store', { race: props.race.id }), {
      name: emptyCourse.name,
    });
    courses.value.push(res.data);
    Toast.fire({ icon: 'success', title: lang('l.saved') });
  }
  catch (error) {
    console.log(error);
    Toast.fire({ icon: 'error', text: error.response.data.message, title: lang('l.errorTitle') });
    console.log(error.response.data.message);
  }
}
function updated(course: ICourse) {
  const index = courses.value.findIndex(el => el.id === course.id);
  courses.value[index] = course;
}
function remove(course: ICourse) {
  courses.value.splice(courses.value.indexOf(course), 1);
}
</script>
