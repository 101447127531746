<template>
  <div class="flex gap-2">
    <a
      :class="!lastYear ? 'cursor-not-allowed opacity-50' : ''"
      :href="lastYear"><i class="fas fa-caret-left" /></a>
    <div>{{ year }}</div>
    <a
      :class="!nextYear ? 'cursor-not-allowed opacity-50' : ''"
      :href="nextYear"><i class="fas fa-caret-right" /></a>
  </div>
</template>

<script setup lang="ts">
type Props = {
  max?: number;
  routeName: string;
  year: number;
};
const props = defineProps<Props>();
const nextYear = computed(() => {
  const nextYearPath = route(props.routeName, { year: Number(props.year) + 1 });
  if (!props.max || props.year < props.max) {
    return nextYearPath;
  }
  return undefined;
});
const lastYear = computed(() => {
  if (props.year > 1900) {
    return route(props.routeName, { year: Number(props.year) - 1 });
  }
  return undefined;
});
</script>
