<template>
  <VueCard>
    <template #title>
      {{ $lang('l.create.runner') }}
    </template>
    <div class="bg-white p-4">
      <BaseInput
        v-model="runner.lastname"
        :errors="errors.lastname"
        :label="$lang('l.lastname')"
        required />
      <BaseInput
        v-model="runner.firstname"
        :errors="errors.firstname"
        :label="$lang('l.firstname')"
        required />
      <BaseYearInput
        v-model="runner.year_of_birth"
        :errors="errors.year_of_birth"
        :label="$lang('l.year_of_birth')"
        required />
      <div class="mb-5">
        <VueSelect
          v-model="runner.gender"
          :errors="errors.gender"
          :label="$lang('l.gender')"
          option-key="name_translated"
          :options="genders" />
      </div>
      <BaseInput
        v-model="runner.plz"
        :errors="errors.plz"
        :label="$lang('l.plz')"
        required />
      <BaseInput
        v-model="runner.city"
        :errors="errors.city"
        :label="$lang('l.city')"
        required />
      <div class="mt-5 flex justify-between gap-5">
        <Button
          class="button-primary"
          :disabled="fieldIsEmpty()"
          @click="createRunner">
          {{ $lang('l.create.default') }}
        </Button>
        <Button
          class="button-primary"
          @click="back()">
          {{ $lang('l.back') }}
        </Button>
      </div>
    </div>
  </VueCard>
</template>

<script setup lang="ts">
import type { IGender, IQuickRunner, IRunner } from '@/interfaces';

import axios from 'axios';
import { inject, type PropType, type Ref, ref, watch } from 'vue';
import { route } from '../../plugins/route';
import BaseInput from '../base/input.vue';
import BaseYearInput from '../base/yearInput.vue';
import Button from '../button.vue';
import VueCard from '../card.vue';
import VueSelect from '../select.vue';

const props = defineProps({
  runners: Array as PropType<IRunner[]>,
  searchingRunner: Object as PropType<{ firstname: string; lastname: string }>,
});

const emits = defineEmits(['back']);
const genders = inject<IGender[]>('genders');

const runner: Ref<IQuickRunner> = ref({
  city: '',
  firstname: '',
  gender: null,
  lastname: '',
  plz: '',
  year_of_birth: null,
});

const errors = ref({
  city: [],
  firstname: [],
  gender: [],
  lastname: [],
  plz: [],
  year_of_birth: [],
});

watch(
  () => props.searchingRunner.lastname,
  (value) => {
    runner.value.lastname = value;
  },
);

watch(
  () => props.searchingRunner.firstname,
  (value) => {
    runner.value.firstname = value;
  },
);

async function createRunner() {
  try {
    runner.value.gender_id = runner.value.gender.id;
    delete runner.value.gender;
    const result = await axios.put<IRunner>(route('webapi.runners.store'), runner.value);
    props.runners.push(result.data);
    emits('back', result.data);
    runner.value = { city: '', firstname: '', gender: null, lastname: '', plz: '', year_of_birth: null };
    resetErrors();
  }
  catch (error) {
    resetErrors();
    errors.value = error.response.data.errors;
  }
}

function back() {
  resetErrors();
  emits('back', null);
}
function fieldIsEmpty() {
  if (
    !runner.value.lastname
    || !runner.value.firstname
    || !runner.value.year_of_birth
    || !runner.value.gender
    || !runner.value.plz
    || !runner.value.city
  ) {
    return true;
  }
  return false;
}
function resetErrors() {
  errors.value = {
    city: [],
    firstname: [],
    gender: [],
    lastname: [],
    plz: [],
    year_of_birth: [],
  };
}
</script>
