<template>
  <SupForm class="p-10" reset-label="Reset" :submit="submit" submit-label="Senden">
    <div class="mb-5 text-2xl">
      Active
    </div>
    <SupInput label="Test" name="test" :parser="parser" required />
    <SupSelect label="Test" name="select" option-key="name" :options="options" required />
    <SupCheckbox label="Label" name="checkbox" required />
  </SupForm>
  <SupForm class="p-10" disabled :submit="submit" submit-label="Senden">
    <div class="mb-5 text-2xl">
      Disabled
    </div>
    <SupInput label="Test" name="test" :parser="parser" required />
    <SupSelect label="Test" name="select" option-key="name" :options="options" required />
    <SupCheckbox label="Label" name="checkbox" required />
  </SupForm>
  <div class="flex gap-5 p-10">
    <SupButton icon="fas:bullseye-arrow" label="Test" />
    <SupButton icon="fas:bullseye-arrow" />
    <SupButton label="Test" />
    <SupButton icon="fas:bullseye-arrow" label="Test" loading />
    <SupButton icon="fas:bullseye-arrow" loading />
    <SupButton label="Test" loading />
    <SupButton icon="fas:bullseye-arrow" label="Test" no-mobile-label />
  </div>
</template>

<script setup lang="ts">
import SupButton from './supButton.vue';
import SupCheckbox from './supCheckbox.vue';
import SupForm from './supForm.vue';
import SupInput from './supInput.vue';
import SupSelect from './supSelect.vue';

async function submit(values: Record<string, any>) {
  await new Promise(resolve => setTimeout(resolve, 3000));
  console.log(values);
}

const parser = (value: string) => value.toUpperCase();

const options = [
  { id: 1, name: 'test1' },
  { id: 2, name: 'test2' },
  { id: 3, name: 'test3' },
];
</script>
