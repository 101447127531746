<template>
  <label class="flex gap-2" :for="id">
    <input
      :id="id"
      v-model="modelValue"
      class="my-auto"
      :name="name"
      type="radio"
      :value="value">
    <span class="my-auto leading-4">{{ label }}</span>
  </label>
</template>

<script setup lang="ts">
import type { IBaseProps } from './types';
import { useFieldCustom } from '@/components/ui/composables';

type Props = {
  value: string;
};
const props = defineProps<Props & IBaseProps>();

const { value: modelValue } = useFieldCustom<number>(props, {
  type: 'radio',
});
</script>
