<template>
  <div class="mt-3">
    <b class="my-auto">{{ $lang('l.extra') }}: </b>
    <form class="flex flex-wrap gap-3">
      <ButtonCheckbox
        v-for="extra, index in extras"
        :key="index"
        :disabled="extra.disabled"
        :icon="extra.icon"
        :label="extra.label"
        :name="extra.name"
        @change="submit" />
    </form>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace } from '@/interfaces';

import { errorHandler } from '@/helpers';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';

import ButtonCheckbox from './buttonCheckbox.vue';

type Extra = { disabled: boolean; icon: string; label: string; name: string };

type Props = {
  categoryRace: ICategoryRace;
  extras: Extra[];
};
type Emits = {
  updated: [categoryRace: ICategoryRace];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleSubmit } = useForm<Record<string, boolean>>({ initialValues: props.categoryRace });

const { abort, execute, isLoading } = useAxios<ICategoryRace>();

const submit = handleSubmit.withControlled(async (values) => {
  try {
    if (isLoading.value) {
      abort();
    }
    const { data } = await execute(route('webapi.categoryRace.patch', { categoryRace: props.categoryRace }), {
      data: removeDisabledExtras(values),
      method: 'PATCH',
    });
    emit('updated', data.value);
  }
  catch (error) {
    errorHandler(error);
  }
});

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function removeDisabledExtras(extras: Record<string, boolean>) {
  return Object.keys(extras).reduce((result, key) => {
    if (props.extras.find(extra => extra.name === key)?.disabled !== true) {
      result[key] = extras[key];
    }
    return result;
  }, {} as Record<string, any>);
}
</script>
