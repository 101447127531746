import type { IGender, IRace } from '@/interfaces';
import type { ICategoryType } from '@/interfaces/categoryType.interface';
import { inject, type InjectionKey } from 'vue';

export const CategoryTypesKey: InjectionKey<ICategoryType[]> = Symbol('categoryTypes');
export const GendersKey: InjectionKey<IGender[]> = Symbol('genders');
export const RaceKey: InjectionKey<IRace> = Symbol('race');
export const IsServerAdminKey: InjectionKey<boolean> = Symbol('isServerAdmin');

export function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Could not resolve ${key.description}`);
  }
  return resolved;
}
