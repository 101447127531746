<template>
  <div class="flex flex-col gap-4">
    <Refund
      v-for="(cost, index) in costs"
      :key="index"
      :cost="cost"
      :costs-to-refund="costsToRefund"
      :states="states" />
    <VueRefundSummary
      :costs-to-refund="costsToRefund"
      :transaction="transaction" />
  </div>
</template>

<script setup lang="ts">
import type { ICost, ICostToRefund, IRaceRunnerState, ITransaction } from '@/interfaces';
import { type PropType, ref, type Ref } from 'vue';
import Refund from './refund.vue';
import VueRefundSummary from './refundSummary.vue';

const props = defineProps({
  costs: Array as PropType<ICost[]>,
  states: Array as PropType<IRaceRunnerState[]>,
  transaction: Object as PropType<ITransaction>,
});

const costsToRefund: Ref<ICostToRefund[]> = ref([]);
</script>
