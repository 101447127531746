<template>
  <div>
    <div class="px-2 pb-1 pt-3 font-semibold">
      {{ label }}
    </div>
    <template v-if="isTeam">
      <TeamCategoryRaceEdit
        v-for="categoryRace, index in sortedCategoryRaces"
        :key="index"
        :category="getCategory(categoryRace.category_id)"
        :category-race="categoryRace"
        @category-race-deleted="categoryRaceDeleted"
        @category-race-updated="categoryRaceUpdated"
        @category-updated="categoryUpdated" />
    </template>
    <template v-else>
      <SingleCategoryRaceEdit
        v-for="categoryRace in sortedCategoryRaces"
        :key="categoryRace.id"
        :category="getCategory(categoryRace.category_id)"
        :category-race="categoryRace"
        @category-race-deleted="categoryRaceDeleted"
        @category-race-updated="categoryRaceUpdated"
        @category-updated="categoryUpdated" />
    </template>
    <div
      class="border-t py-5">
      <div
        class="p-3 xl:rounded-md xl:border"
        :class="showCreate ? 'grid md:grid-cols-2 gap-5 divide-y md:divide-y-0 md:divide-x' : ''">
        <CategorySelect
          :categories="selectableCategories"
          @category-race-created="sortedCategoryRaces.push($event)" />
        <CategoryCreate
          v-if="showCreate"
          class="pt-5 md:pl-5 md:pt-0"
          :is-team="isTeam"
          @created="categoryCreated" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategory, ICategoryRace } from '@/interfaces';

import { sortBy } from 'lodash';
import { computed, ref } from 'vue';

import CategoryCreate from './categoryCreate.vue';
import CategorySelect from './categorySelect.vue';
import SingleCategoryRaceEdit from './singleCategoryRaceEdit.vue';
import TeamCategoryRaceEdit from './teamCategoryRaceEdit.vue';

type Props = {
  categories: ICategory[];
  categoryRaces: ICategoryRace[];
  isTeam?: boolean;
  label: string;
  showCreate?: boolean;
};

const props = defineProps<Props>();

const _categories = ref<ICategory[]>(props.categories);
const sortedCategoryRaces = ref<ICategoryRace[]>(sortBy(props.categoryRaces, 'category.sort_order'));

const getCategory = (categoryId: number) => _categories.value.find(c => c.id === categoryId);

const selectableCategories = computed(() => _categories.value.filter(c => !sortedCategoryRaces.value.some(cr => cr.category_id === c.id)));

function categoryCreated(category: ICategory & { category_races: ICategoryRace[] }) {
  _categories.value.push(category);
  for (const cr of category.category_races) sortedCategoryRaces.value.push(cr);
}
function categoryUpdated(category: ICategory) {
  const crIndex = sortedCategoryRaces.value.findIndex(cr => cr.category_id === category.id);
  const cIndex = _categories.value.findIndex(c => c.id === category.id);
  _categories.value[cIndex] = category;
  sortedCategoryRaces.value[crIndex].category = category;
}

function categoryRaceUpdated(categoryRace: ICategoryRace) {
  const index = sortedCategoryRaces.value.findIndex(cr => cr.id === categoryRace.id);
  sortedCategoryRaces.value[index] = categoryRace;
}

function categoryRaceDeleted(categoryRace: ICategoryRace) {
  const index = sortedCategoryRaces.value.findIndex(cr => cr.id === categoryRace.id);
  sortedCategoryRaces.value.splice(index, 1);
}
</script>
