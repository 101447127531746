<template>
  <GoogleMap
    v-slot="{ map }"
    class="relative h-80 w-full sm:h-90 md:h-100 lg:h-120"
    :options="mapOptions"
    @click="openMarkerIndex = undefined">
    <template v-if="map">
      <GoogleMapMarker
        v-for="(item, index) in markers"
        :key="index"
        v-slot="{ marker }"
        :icon="item.icon"
        :map="map"
        :options="item.marker"
        @click="openMarkerIndex = index">
        <GoogleMapInfoWindow
          v-if="marker"
          :map="map"
          :marker="marker"
          :opened="openMarkerIndex === index"
          @closeclick="openMarkerIndex = undefined">
          <div class="space-y-1 p-1">
            <div class="text-lg">
              {{ item.info.title }}
            </div>
            <div
              v-for="date, dIndex in item.info.dates"
              :key="dIndex">
              <i class="fal fa-calendar w-7" />{{ date }}
            </div>
            <div v-if="item.info.chart">
              <i class="fal fa-map w-7" />{{ item.info.chart }}
            </div>
            <div class="mt-3">
              <a
                class="text-blue-600 hover:text-blue-900"
                :href="item.info.link"
                target="_blank">
                <i class="fal fa-external-link-alt w-6" />
                {{ $lang('l.informations.default') }}
              </a>
            </div>
          </div>
        </GoogleMapInfoWindow>
      </GoogleMapMarker>
    </template>
  </GoogleMap>
</template>

<script setup lang="ts">
import type { IRace, IRaceDate } from '@/interfaces';
import { GoogleMap, GoogleMapInfoWindow, GoogleMapMarker } from '@/components/google/maps';
import { useMediaQuery } from '@vueuse/core';
import dayjs from 'dayjs';
import { onMounted, ref } from 'vue';

type Props = {
  races: (IRace & { gpsLat?: number; gpsLng?: number })[];
  type?: 'fix-finder' | 'ol-events';
};

const props = withDefaults(defineProps<Props>(), { type: 'ol-events' });

const openMarkerIndex = ref<number>();

const isLg = useMediaQuery('(min-width: 1024px)');
const isMd = useMediaQuery('(min-width: 768px)');

const zoom = computed(() => {
  if (isLg.value)
    return 7.75;
  if (isMd.value)
    return 7.25;
  return 7;
});

const mapOptions = {
  center: { lat: 46.998_544_2, lng: 8.189_931_9 },
  mapId: '65903117d0dc0097',
  rotateControl: false,
  streetViewControl: false,
  zoom: zoom.value,
};

onMounted(async () => {
  await getMarkers();
});

const markers = ref<IMarker[]>([]);

function getMarkers() {
  const filteredRaces = props.races.filter(race =>
    race[props.type === 'ol-events' ? 'latitude' : 'gpsLat']
    && race[props.type === 'ol-events' ? 'longitude' : 'gpsLng'],
  );
  for (const race of filteredRaces) {
    const marker: google.maps.marker.AdvancedMarkerElementOptions = {

      position: {
        lat: race[props.type === 'ol-events' ? 'latitude' : 'gpsLat'] as number,
        lng: race[props.type === 'ol-events' ? 'longitude' : 'gpsLng'] as number,
      },
      title: race.name,
    };
    const info = getInfo(race);
    const icon = race.is_current === false && race.registration_is_open === false ? '/images/icons/flag_gray.png' : '/images/icons/flag.png';
    markers.value.push({ icon, info, marker });
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getInfo(race: IRace) {
  return {
    chart: race.chart,
    dates: race.race_dates ? getRaceDate(race.race_dates) : undefined,
    link: props.type === 'ol-events' ? `/races/${race.id}` : `https://www.fixfinder.ch/projects/${race.id}`,
    title: race.name,
  };
}

function getRaceDate(raceDates: IRaceDate[]) {
  return raceDates.map((raceDate) => {
    const start = dayjs(raceDate.start_date);
    const end = dayjs(raceDate.end_date);
    if (start.isSame(end, 'day')) {
      return start.format('DD.MM.YY');
    }
    return `${start.format('DD.MM.YY')} - ${end.format('DD.MM.YY')}`;
  });
}

type IMarker = {
  icon: string;
  info: {
    chart?: string;
    dates?: string[];
    link: string;
    title: string;
  };
  marker: google.maps.marker.AdvancedMarkerElementOptions;
};
</script>
