function isFinished(rating: number) {
  switch (rating) {
    case 1:
    case 3:
    case 4:
    case 5:
    case 6:
    case 11:
      return true;
    default:
      return false;
  }
}

function isFailed(rating: number) {
  switch (rating) {
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return true;
    default:
      return false;
  }
}

function isRunning(rating: number) {
  switch (rating) {
    case 7:
    case 8:
    case 13:
      return true;
    default:
      return false;
  }
}

export { isFailed, isFinished, isRunning };
