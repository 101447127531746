<template>
  <div>
    <div class="flex gap-2">
      <div
        class="size-6 relative flex rounded-md border border-gray-200"
        :class="hasError
          ? ' border-red-600'
          : disabled || parentDisabled
            ? 'opacity-60 bg-[#efefef4d]'
            : 'cursor-pointer focus:border-blue-700'">
        <iconify-icon v-if="value" class="m-auto text-xl text-green-600" icon="fat:check" />
        <input :id="id ?? name" v-model="value" class="size-full absolute left-0 top-0 cursor-pointer text-2xl opacity-0 disabled:cursor-default" type="checkbox" @update:model-value="$emit('changed')">
      </div>
      <InputLabel v-if="label" class="my-auto" :for="id ?? name" :label="label" :required="required" />
    </div>
    <InputFooter
      :has-error="hasError"
      :message="hasError && errorMessage ? errorMessage : hint ? hint : undefined"
      :meta="meta" />
  </div>
</template>

<script setup lang="ts">
import type { IBaseProps } from './types';

import { useFieldCustom } from './composables';
import InputFooter from './inputFooter.vue';
import InputLabel from './inputLabel.vue';

type Props = {
  hint?: string;
  modelValue?: boolean;
  notSyncVModel?: boolean;
};

type Emits = {
  'changed': [];
  'update:modelValue': [value: boolean];
};
const props = defineProps<Props & IBaseProps>();

defineEmits<Emits>();

const { errorMessage, hasError, meta, value } = useFieldCustom<boolean>(props, {
  checkedValue: true,
  syncVModel: !props.notSyncVModel,
  type: 'checkbox',
  uncheckedValue: false,
});

const parentDisabled = inject<boolean>('disabled', false);
</script>
