<template>
  <div>
    <Card>
      <template #title>
        {{ $lang('l.exportSettings') }}
      </template>
      <div class="p-3">
        <form>
          <SupSelect
            :hint="$lang('l.exportORwareHint')"
            label="l.exportORWare"
            name="export_id_field"
            no-clear
            no-search
            :options
            value-prop="value"
            @update:model-value="submit" />
        </form>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { IUser } from '@/interfaces';
import { Toast } from '@/helpers';
import { lang } from '@/plugins';
import Card from '../card.vue';
import SupSelect from '../ui/supSelect.vue';

type Props = {
  eventId: number;
  user: IUser;
};
const props = defineProps<Props>();

const options = [
  { label: lang('l.runnerId.default'), value: 'runner_id' },
  { label: lang('l.runnerId.perRace'), value: 'racerunner_id' },
  { label: lang('l.runnerId.perEvent'), value: 'bib' },
];

const { handleSubmit } = useForm<{ id: string }>({
  initialValues: {
    export_id_field: props.user.settings?.races?.export?.id_field ?? 'id',
  },
});

const submit = handleSubmit(async (values) => {
  try {
    await useAxios(route('webapi.user.exportSettings'), { data: { ...values, event_id: props.eventId }, method: 'POST' });
    Toast.fire({ icon: 'success', title: lang('l.exportSettingChanged') });
  }
  catch (error) {
    console.error(error);
  }
});
</script>
