<template>
  <div>
    <VueForm
      id="approveRunnerMail-solv"
      :action="$route('user.addSolvRunner')"
      method="POST">
      <VueInput
        v-model="solvNumber"
        :label="$lang('l.solvNumber')"
        name="solv_number"
        type="text" />
      <BaseButton
        class="w-full"
        :label="$lang('l.add.default')"
        type="submit" />
    </VueForm>
  </div>
</template>

<script setup lang="ts">
import { type Ref, ref } from 'vue';
import BaseButton from '../base/baseButton.vue';
import VueForm from '../form.vue';
import VueInput from '../input.vue';

const solvNumber: Ref<string> = ref(null);
</script>
