<template>
  <Card>
    <template #title>
      {{ $lang('l.AttendeesList') }}
      <AttendeeCount
        :all="attendeesCount"
        :current="filteredAttendees ? filteredAttendees.length : 0" />
    </template>
    <div
      class="p-3">
      <div v-if="race.hasOwnProperty('startlist_url') && race.startlist_url && race.startlist_public">
        <a
          :href="race.startlist_url"
          class="flex"
          target="_blank">
          <iconify-icon icon="fas:arrow-up-right-from-square" class="my-auto mr-1" />
          {{ $lang('l.startlist.title') }}
        </a>
      </div>

      <div
        class="xl:flex xl:flex-row xl:space-x-8">
        <div
          class="md:grid-cols-0 space-y-2 sm:mt-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 md:block md:gap-0 md:space-y-2 xl:mb-4 xl:w-60">
          <div class="mt-4 sm:mt-0">
            <SupSelect              
              v-model="selectedClubId"
              clearable
              label="l.club"
              name="selectClub"
              option-key="name"
              :options="race.confirmed_race_runner_clubs"
              searchable
              @update:model-value="selectedCategoryRaceId = undefined"  />
          </div>
          <div class="">
            <SupSelect
              v-model="selectedCategoryRaceId"
              clearable
              label="l.category"
              name="selectCategoryRace"
              :options="categoryRaces"
              :search-keys="['category.short_name']"
              searchable
              :show-option="(categoryRace) => categoryRace.category.short_name"
              @update:model-value="selectedClubId = undefined" />
          </div>
        </div>
        <div

          class="monospace mx-2 grow pt-4">
          <template v-if="filteredAttendees === null">
            <RaceAttendeeCategory
              v-for="categoryRace, index in filteredCategoryRaces"
              :key="index"
              :attendees="sortAttendees(categoryRace.race_runners)"
              :category-name="getCategoryName(categoryRace.id)"
              :show-start="showStarts" />
          </template>
          <template v-else-if="filteredAttendees.length">
            <RaceAttendee
              v-for="attendee, index in filteredAttendees"
              :key="index"
              :attendee="attendee"
              :show-category-race="selectedClubId !== undefined"
              :show-start="showStarts" />
          </template>
          <template v-else>
            {{ $lang('l.noRunnerInCategory') }}
          </template>
        </div>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { ICategoryRace, IClub, IRace, IRaceRunner } from '@/interfaces';
import { sortBy } from 'lodash';
import { computed, ref } from 'vue';
import Card from '../../components/card.vue';
import AttendeeCount from './attendeeCount.vue';
import RaceAttendee from './raceAttendee.vue';
import RaceAttendeeCategory from './raceAttendeeCategory.vue';
import SupSelect from '../ui/supSelect.vue';

type Props = {
  race: IRace & { confirmed_race_runner_clubs: IClub[] };
};

const props = defineProps<Props>();

const selectedCategoryRaceId = ref<number>();
const selectedClubId = ref<number>();


const categoryRaces = computed(() => {
  return props.race.category_races.map((categoryRace) => {
    return {
      ...categoryRace,
      label: categoryRace.category?.short_name ?? '',
    };
  });
})

const filteredCategoryRaces = computed(() => {
  if (!props.race.category_races) return [];
  const categoryRaces = props.race.category_races.filter(categoryRace => categoryRace.race_runners && categoryRace.race_runners.length);
  return categoryRaces.map((categoryRace) => {
    return {
      ...categoryRace,
      race_runners: categoryRace.race_runners.map((raceRunner) => {
        const club = props.race.confirmed_race_runner_clubs.find(el => el.id === raceRunner.club_id);
        return {
          ...raceRunner,
          club,
        };
      }),
    };
  });
});

const filteredAttendees = computed(() => {
  if (selectedCategoryRaceId.value) {
    const raceRunners = filteredCategoryRaces.value.find(el => el.id === selectedCategoryRaceId.value)?.race_runners;
    if (!raceRunners) return [];
    return raceRunners;
  }
  else if (selectedClubId.value) {
    const attendees: IRaceRunner[] = [];
    filteredCategoryRaces.value.forEach((categoryRace) => {
      categoryRace.race_runners.filter(el => el.club_id === selectedClubId.value).forEach(el => attendees.push(el));
    });
    return attendees.map((attendee) => {
      return {
        ...attendee,
        category_race: props.race.category_races.find(el => el.id === attendee.category_race_id),
      };
    });
  }
  return null;
});

const attendeesCount = computed(() => {
  return filteredCategoryRaces.value.reduce((count, categoryRace) => count + categoryRace.race_runners.length, 0);
});

const showStarts = computed(() => {
  return filteredCategoryRaces.value.every(categoryRace => categoryRace.race_runners.every(raceRunner => raceRunner.start));
});

function getCategoryName(categoryRaceId: number) {
  const categoryRace = props.race.category_races.find(el => el.id === categoryRaceId);
  return categoryRace?.category.short_name ?? '';
}

function sortAttendees(attendees: IRaceRunner[]) {
  return showStarts.value ? sortBy(attendees, 'start.time') : sortBy(attendees, 'lastname');
}
</script>
