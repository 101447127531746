import dayjs from 'dayjs';

export function isoToInput(date: string, type: 'date' | 'datetime-local' | 'time'): string {
  if (type === 'time') return dayjs(date).format('HH:mm');
}

export function timeToDayjs(time: string) {
  const splitTime = time.split(':');
  return dayjs().set('hour', Number.parseInt(splitTime[0])).set('minute', Number.parseInt(splitTime[1]));
}

export function timeToIso(time: string, date?: string) {
  const splitTime = time.split(':');
  const test = dayjs(date).set('hour', Number.parseInt(splitTime[0])).set('minute', Number.parseInt(splitTime[1])).toISOString();
  console.log(test);
  return test;
}
