import dayjs from 'dayjs';

export function secondsToTime(seconds: number) {
  if (seconds < 3600) {
    return new Date(1000 * seconds).toISOString().substr(11, 8).substr(3, 6);
  }
  const time = new Date(1000 * seconds).toISOString().substr(11, 8);
  if (time.charAt(0) === '0' && time.charAt(1) === '0') {
    return time.substring(3);
  }
  else if (time.charAt(0) === '0') {
    return time.substring(1);
  }
  return time;
}

export function formatSecondsAsTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Nur Stunden anzeigen, wenn sie größer als 0 sind
  const hoursDisplay = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';

  return `${hoursDisplay}${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export function timerFromStart(startTime: string) {
  if (!startTime) {
    return;
  }

  const seconds = dayjs().diff(dayjs(startTime), 's');
  if (seconds < 0) {
    return;
  }
  return secondsToTime(seconds);
}
