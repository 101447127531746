<template>
  <div>
    <div>{{ $lang('l.starttimeRequestTitle') }}</div>
    <VueCheckbox
      v-if="raceRunner.category_race.has_parental_starttime_request"
      :label="$lang('l.parentalRequest')"
      :model-value="isParental"
      @update:model-value="parentalRequestInput" />
    <VueCheckbox
      v-if="raceRunner.category_race.has_starttime_request"
      :label="$lang('l.starttimeRequest')"
      :model-value="isStartTime"
      @update:model-value="startTimeRequestInput" />
    <EditParentalPartner
      v-if="isParental"
      class="mt-6"
      :race-runner="raceRunner" />
    <EditStartTimeRequest
      v-if="showStartTimeEdit"
      class="mt-6"
      :race-runner="raceRunner" />
  </div>
</template>

<script setup lang="ts">
import type { IRaceRunner } from '@/interfaces';
import Swal from 'sweetalert2';
import { computed, type PropType, ref, watch } from 'vue';
import { lang } from '../../plugins/lang';
import { useCartStore } from '../../store/cart';
import VueCheckbox from '../checkbox.vue';
import EditParentalPartner from './editParentalPartner.vue';
import EditStartTimeRequest from './editStartTimeRequest.vue';

const props = defineProps({
  parentalRequestNotesConfirmed: { required: true, type: Boolean },
  raceRunner: { required: true, type: Object as PropType<IRaceRunner> },
  starttimeRequestNotesConfirmed: { required: true, type: Boolean },
});

const emit = defineEmits([
  'updateRaceRunnerLocal',
  'getParent',
  'confirmStarttimeRequestNotes',
  'confirmParentalRequestNotes',
]);

watch(
  () => props.raceRunner.parental_starttime_race_runner_id,
  () => evaluateIsParentalStartTimeByRaceRunner(),
);

const cartStore = useCartStore();

const isParental = ref(false);
const isStartTime = ref(false);

async function parentalRequestInput(value: boolean) {
  if (await partentalRequestSwal()) {
    emit('confirmParentalRequestNotes');
    resetStartTime();
    if (value) return setToParental();
    isParental.value = false;
  }
}

async function resetStartTime() {
  const oldParentId = props.raceRunner.parental_starttime_race_runner_id;
  if (oldParentId) {
    await cartStore.raceRunnerUpdate(oldParentId, {
      parental_starttime_race_runner_id: null,
      starttime_request_id: null,
    });
  }
}

function evaluateIsParentalStartTimeByRaceRunner() {
  if (props.raceRunner.parental_starttime_race_runner_id) return setToParental();
  if (props.raceRunner.starttime_request_id) return setToStartTime();
}

evaluateIsParentalStartTimeByRaceRunner();

function setToParental() {
  isParental.value = true;
  isStartTime.value = false;
}

function setToStartTime() {
  isParental.value = false;
  isStartTime.value = true;
}

const showStartTimeEdit = computed(() => {
  if (isStartTime.value) return props.raceRunner.category_race.has_starttime_request;
  return false;
});

async function startTimeRequestInput(value: boolean) {
  if (isStartTime.value || (await startTimeRequestSwal())) {
    emit('confirmStarttimeRequestNotes');
    resetStartTime();
    if (value) return setToStartTime();
    isStartTime.value = false;
  }
  isStartTime.value = false;
}
async function partentalRequestSwal(): Promise<boolean> {
  if (props.parentalRequestNotesConfirmed) return true;
  const swal = await Swal.fire({
    cancelButtonText: lang('l.cancel'),
    confirmButtonText: lang('l.confirm'),
    icon: 'warning',
    showCancelButton: true,
    text: props.raceRunner.race.parental_request_info_text || lang('l.parental_request.info_text'),
  });
  if (swal.isConfirmed) {
    return true;
  }
  return false;
}

async function startTimeRequestSwal(): Promise<boolean> {
  if (props.starttimeRequestNotesConfirmed) return true;
  const swal = await Swal.fire({
    cancelButtonText: lang('l.cancel'),
    confirmButtonText: lang('l.confirm'),
    icon: 'warning',
    showCancelButton: true,
    text: props.raceRunner.race.starttime_request_info_text || lang('l.starttime_request.info_text'),
  });
  if (swal.isConfirmed) {
    return true;
  }
  return false;
}
</script>
