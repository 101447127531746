<template>
  <div class="input relative mt-2 min-h-[3.75rem] text-left text-gray-700">
    <label
      v-if="label"
      class="input__label"> {{ label }}{{ required ? ' *' : '' }} </label>
    <input
      v-bind="$attrs"
      class="input__field w-full"
      :class="{
        'is-invalid': errorMessages.length,
      }"
      :type="type"
      :value="value"
      @blur="onBlur"
      @input="onInput">
    <span
      v-if="errorMessages.length"
      class="input__error text-xs sm:text-base"
      role="alert">
      <template
        v-for="(message, index) in errorMessages"
        :key="index">{{ message
        }}<template v-if="errorMessages.length > 1 && index !== errorMessages.length - 1">
          |
        </template></template>
    </span>
  </div>
</template>

<script lang="ts">
export default {
  name: 'BaseYearInput',
};
</script>

<script setup lang="ts">
// eslint-disable-next-line import/first
import { whenever } from '@vueuse/shared';
// eslint-disable-next-line import/first
import dayjs from 'dayjs';
// eslint-disable-next-line import/first
import { computed, ref } from 'vue';

const props = defineProps({
  errors: { default: [], type: Array },
  label: String,
  modelValue: Number,
  required: Boolean,
  type: String,
});

const emits = defineEmits(['update:modelValue']);

whenever(
  () => props.errors,
  () => {
    touched.value = true;
  },
);
const touched = ref(false);

const value = computed(() => {
  if (isNaN(props.modelValue)) {
    return emits('update:modelValue', null);
  }
  return props.modelValue;
});
const errorMessages = computed(() => {
  if (touched.value) {
    return props.errors;
  }
  return [];
});

function onInput(ev: any) {
  emits('update:modelValue', Number(ev.target.value));
}

function onBlur(ev: FocusEvent) {
  touched.value = true;
  if (props.modelValue) {
    emits('update:modelValue', fixYear(props.modelValue));
  }
}

function fixYear(value: number) {
  if (value > dayjs().get('y')) {
    return dayjs().get('y');
  }
  else if (value < 1900) {
    return 1900;
  }
  return Number(value);
}
</script>
