<template>
  <div
    v-if="inline"
    class="flex border-b">
    <i class="fa-thin fa-id-badge aria-hidden mt-auto h-6 pb-2" />
    <input
      class="min-h-8 w-28 focus:ring-0"
      type="text"
      :value="modelValue"
      @blur="emits('blur', $event.target.value)"
      @input="updateModelValue($event.target.value)"
      @keypress="checkInput($event)">
  </div>
  <div
    v-else
    class="input relative mt-2 min-h-[3.75rem] text-left text-gray-700">
    <label>
      <span
        ref="labelRef"
        class="input__label">
        {{ label }}
      </span>
    </label>
    <input
      class="input__field"
      type="text"
      :value="modelValue"
      @blur="emits('blur', $event.target.value)"
      @input="updateModelValue($event.target.value)"
      @keypress="checkInput($event)">
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  inline: Boolean,
  label: String,
  modelValue: [String, Number],
});
const emits = defineEmits(['update:modelValue', 'blur']);

function checkInput(ev: any) {
  const charCode = ev.which ? ev.which : ev.keyCode;
  if (charCode < 48 || charCode > 57) {
    ev.preventDefault();
    return;
  }
  if (charCode === 48 && !ev.target.value.length) {
    ev.preventDefault();
    return;
  }

  if (ev.target.value.length > 9) {
    ev.preventDefault();
    return;
  }
  return true;
}

function updateModelValue(value: string) {
  emits('update:modelValue', Number(value));
}
</script>
