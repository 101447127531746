<template>
  <VueCard class="mx-2">
    <template #title>
      <div class="text-xl">
        {{ $lang('l.summary') }}
      </div>
      <a
        class="hidden cursor-pointer sm:block"
        :href="$route('races.index')">
        <div class="button-primary">
          {{ $lang('l.races.registerMore') }}
        </div>
      </a>
    </template>
    <div class="space-y-4 p-3">
      <CartSummaryItem
        v-for="(item, index) in cartStore.fullCart"
        :key="index"
        :item="item" />
      <div class="mt-8 border-t pt-4 text-right font-bold">
        CHF {{ totalPrice }}
      </div>
    </div>
    <div class="mt-3 p-3">
      <VueCheckbox
        v-model="agbValue"
        :label="$lang('l.cart.acceptAGB')"
        required />
    </div>
    <div
      v-if="hasExternalPayments || hasMixedPayments"
      class="px-4 py-2 font-bold text-red-500">
      {{ hasExternalPayments ? $lang('l.externalPaymentInfoCart') : $lang('l.hasMixedPayments') }}
    </div>
    <template
      v-if="!hasMixedPayments"
      #footer>
      <VueSpinner v-if="checkout === true" />
      <button
        v-else
        class="button-primary px-4 py-2"
        type="submit">
        {{ $lang('l.buy') }}
      </button>
    </template>
  </VueCard>
</template>

<script setup lang="ts">
import lodash from 'lodash';
import { computed, ref } from 'vue';

import { useCartStore } from '../../store/cart';
import VueCard from '../card.vue';
import VueCheckbox from '../checkbox.vue';
import VueSpinner from '../spinner.vue';
import CartSummaryItem from './cartSummaryItem.vue';

defineProps({
  checkout: { required: true, type: Boolean },
});

const cartStore = useCartStore();

const totalPrice = computed(() => lodash.sumBy(cartStore.fullCart, o => o.price_info.tot));

const hasExternalPayments = computed(() => cartStore.races.some(race => race.has_external_payment));

const hasMixedPayments = computed(() => {
  const extension = cartStore.races.some(race => race.has_external_payment);
  const int = cartStore.races.some(race => !race.has_external_payment);
  return extension && int;
});

const agbValue = ref(false);
</script>
