<template>
  <form class=" event:bg-lightgray-100 flex w-full flex-col gap-3 space-y-4 lg:flex-row" :class="{ 'opacity-50': loading }">
    <div class=" my-auto mr-3 flex" :class="{ 'handle cursor-grab': !loading }">
      <i
        aria-hidden="true"
        class="far fa-bars my-auto pt-4 lg:mr-0" />
    </div>
    <BaseMultiselect
      class="mr-3 mt-1 w-full lg:w-56"
      label="l.fileType"
      name="collection_name"
      :options="collectionOptions"
      required
      value-prop="label"
      @update:model-value="submit()" />

    <BaseInput
      class="w-full lg:w-96"
      label="l.name"
      name="name"
      required
      @blur="submit()" />
    <div
      class="my-auto flex lg:mt-4"
      @click="deleteMedia">
      <i
        aria-hidden="true"
        class="far fa-trash-alt my-auto ml-3 cursor-pointer text-red-500 lg:ml-0" />
    </div>
  </form>
</template>

<script setup lang="ts">
import type { IFile } from '@/interfaces';

import BaseInput from '@/components/base/baseInput.vue';
import BaseMultiselect from '@/components/base/baseMultiselect.vue';
import { errorHandler } from '@/helpers';
import { route } from '@/plugins';
import { onMounted } from 'vue';

type Props = {
  file: IFile;
  index: number;
  loading?: boolean;
};

type Emits = {
  deleted: [];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

type CollectionName = 'additional' | 'default' | 'purchased';

const collectionOptions: { label: CollectionName }[] = [
  { label: 'additional' },
  { label: 'purchased' },
  { label: 'default' },
];

const { handleSubmit } = useForm({ initialValues: props.file });

const submit = handleSubmit(values => updateMedia(values));

async function updateMedia(file: IFile, orderColumn: number = props.index + 1) {
  try {
    await useAxios(route('webapi.media.patch', props.file), {
      data: {
        collection_name: file.collection_name,
        name: file.name,
        order_column: orderColumn,
      },
      method: 'PATCH',
    });
  }
  catch (error) {
    errorHandler(error);
  }
}

onMounted(async () => {
  if (!props.file.order_column || props.file.order_column !== props.index + 1) {
    await updateMedia(props.file, props.index + 1);
  }
});

async function deleteMedia() {
  try {
    await useAxios(route('webapi.media.delete', props.file), { method: 'DELETE' });
    emit('deleted');
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
