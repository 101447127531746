<template>
  <form
    :id="`form_${index}`" />
  <tr :form="`form_${index}`">
    <TeamRuleTableField
      v-for="attr, attrIdx in allowedAttrs"
      :key="attrIdx"
      :name="attr.name"
      :options="attr.options"
      :type="attr.type" />
    <td>
      <button
        class="px-2 text-green-600 disabled:cursor-not-allowed disabled:opacity-50"
        :disabled="isLoading"
        type="button"
        @click="submit()">
        <i class="fas fa-save" />
      </button>
    </td>
    <td>
      <button
        class="px-2 text-red-600 disabled:cursor-not-allowed disabled:opacity-50"
        :disabled="isLoading"
        type="button"
        @click="deleteRule">
        <i class="fas fa-trash" />
      </button>
    </td>
  </tr>
</template>

<script setup lang="ts">
import type { IRule } from '@/interfaces';
import { errorHandler, Toast } from '@/helpers';
import { lang, route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { watch } from 'vue';
import TeamRuleTableField from './teamRuleTableField.vue';

type Props = {
  allowedAttrs: { name: string; type: string; options?: { id: number; name: string }[] }[];
  index: number;
  rule: IRule;
};
type Emits = {
  deleted: [];
  updated: [value: IRule];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const { abort, execute, isLoading } = useAxios();

const { handleSubmit, setValues } = useForm({ initialValues: props.rule, keepValuesOnUnmount: false });

watch(() => props.rule, (value) => {
  setValues(value);
});

const submit = handleSubmit(async (values) => {
  try {
    if (isLoading.value) {
      abort();
    }
    const { data } = await execute(route('webapi.teamRule.patch', props.rule), {
      data: values,
      method: 'PATCH',
    });
    emit('updated', data.value);
    await Toast.fire(lang('l.rulesUpdated'), '', 'success');
  }
  catch (error) {
    errorHandler(error);
  }
});

async function deleteRule() {
  try {
    await useAxios(route('webapi.teamRule.delete', props.rule), {
      method: 'DELETE',
    });
    emit('deleted');
  }
  catch (error) {
    console.log(error);
    errorHandler(error);
  }
}
</script>
