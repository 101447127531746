<template>
  <div class="flex flex-col 2xl:pb-4 3xl:flex-row">
    <fieldset class="flex flex-col gap-5 md:flex-row" :disabled="loading">
      <BaseInput
        v-model="_team.club_name"
        class="sm:w-80"
        l="l.club_name"
        labe
        name="club_name"
        @blur="updateTeam" />
      <BaseInput v-model="_team.name" class="sm:w-80" label="l.name" name="name" @blur="updateTeam" />
    </fieldset>
    <div class="flex flex-col gap-2 px-3 py-2 text-sm md:flex-row md:gap-5">
      <div v-for="member, index in team.teammembers" :key="index">
        {{ getMember(member, index) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ITeam, ITeamMember } from '@/interfaces';

import BaseInput from '@/components/base/baseInput.vue';
import { errorHandler } from '@/helpers';

type Props = {
  team: ITeam;
};

type Emits = {
  updated: [value: ITeam];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const _team = ref(props.team);

const loading = ref(false);
const getMember = (member: ITeamMember, index: number) => `${index + 1}. ${member.firstname} ${member.lastname} / ${member.club?.name ?? '-'} / ${member.city}`;

async function updateTeam() {
  try {
    loading.value = true;
    const { data } = await useAxios<ITeam>(route('webapi.team.post', { team: props.team }), {
      data: {
        club_name: _team.value.club_name,
        name: _team.value.name,
      },
      method: 'POST',
    });

    if (data.value) {
      loading.value = false;
      emit('updated', data.value);
    }
  }
  catch (error) {
    loading.value = false;
    errorHandler(error);
  }
}
</script>
