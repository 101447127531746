<template>
  <button
    class="flex gap-2 rounded-md border p-1 px-2 text-left hover:enabled:bg-orange hover:enabled:text-white disabled:opacity-60"
    :class="checked ? 'bg-orange text-white  border-orange ' : 'text-gray-400 border-gray-400'"
    :disabled="disabled"
    type="button"
    @click="change">
    <span
      v-if="icon"
      class="my-auto flex text-xl">
      <i
        class="m-auto"
        :class="icon" />
    </span>
    <span>{{ getTranslatedLabel(label) }}</span>
  </button>
</template>

<script setup lang="ts">
import { getTranslatedLabel } from '@/helpers/trans';
import { useField } from 'vee-validate';

type Props = {
  disabled?: boolean;
  icon?: string;
  label: string;
  name: string;
};
type Emits = {
  change: [];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { checked, handleChange } = useField(() => props.name, undefined, {
  checkedValue: true,
  keepValueOnUnmount: true,
  type: 'checkbox',
  uncheckedValue: false,
});

function change() {
  handleChange(!checked.value);
  emit('change');
}
</script>
