<template>
  <div class="relative w-full">
    <table class="w-full table-auto overflow-hidden rounded-md">
      <thead>
        <TableHeader
          v-for="header, index in tableHeaders"
          :key="index"
          :header="header"
          :sort="sort"
          @sort="$emit('sort', $event)" />
      </thead>
      <tbody ref="tbody">
        <slot />
      </tbody>
    </table>
    <div
      v-if="isLoading"
      class="absolute inset-0 h-full flex rounded-md bg-lightgray-100/50" />
  </div>
</template>

<script setup lang="ts">
import type { ComponentProps } from '@/helpers';

import { concat } from 'lodash';
import { computed, type HTMLAttributes, onMounted, ref } from 'vue';

import TableHeader from './tableHeader.vue';

type Props = {
  headers: ComponentProps<typeof TableHeader>['header'][];
  isLoading?: boolean;
  sort?: Sort;
};

type Emits = {
  sort: [value?: Sort];
};

const props = defineProps<Props>();
defineEmits<Emits>();

const missingHeaders = ref<string[]>([]);

type Sort = ComponentProps<typeof TableHeader>['sort'];

const tableHeaders = computed(() => {
  return concat(props.headers, missingHeaders.value);
});

const tbody = ref<HTMLAttributes>();

onMounted(() => {
  addMissingHeaders();
});

/**
 * Push empty strings to the table headers if the table has more cells in the first column than headers.
 */
function addMissingHeaders() {
  const tableRowElement = (tbody.value as Element).firstElementChild;
  if (tableRowElement) {
    const length = tableRowElement.childElementCount - props.headers.length;
    if (length > 0) {
      missingHeaders.value.push(...Array.from<string>({ length }).fill(''));
      console.warn(`The table is missing ${length} header(s). They have been automatically added.`);
    }
  }
}
</script>
