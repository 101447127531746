<template>
  <VueCard class="w-full 3xl:w-1/2">
    <template #title>
      <div>{{ team.club_name }} {{ team.name }} - {{ team.category.name }}</div>
      <div class="flex gap-4">
        <VueButton
          v-if="user.id === team.user_id"
          id="singleElement"
          class="button-primary text-xs"
          :href="$route('races.signup.teams', team.race)">
          <i
            aria-hidden="true"
            class="fa-solid fa-arrow-left-long mr-2" />
          <span>
            {{ $lang('l.teamsManagement') }}
          </span>
        </VueButton>
        <VueButton
          v-else
          class="button-primary text-xs"
          :href="$route('team.index')">
          <i
            aria-hidden="true"
            class="fa-solid fa-arrow-left-long mr-2" />
          <span>
            {{ $lang('l.teamsManagement') }}
          </span>
        </VueButton>
      </div>
    </template>

    <div class="p-4">
      <div class="mb-3">
        <div class="flex flex-col justify-between">
          <VueInput
            v-model="club_name"
            class="w-full"
            :label="team.form_id !== 1 ? $lang('l.club_name') : $lang('l.teamOrClubName')"
            name="club_name"
            type="text"
            @blur="patchTeam" />
          <VueInput
            v-if="team.form_id !== 1"
            v-model="name"
            class="w-full"
            :label="$lang('l.teamName')"
            name="name"
            type="text"
            @blur="patchTeam" />
          <SiCardInput
            v-if="team.form_id === 1"
            v-model="si_card"
            :label="$lang('l.si_card')"
            @blur="patchTeam" />
          <VueSelect
            v-if="team.public_transportation_options.length > 0"
            v-model="publicTransportationCount"
            :label="$lang('l.amount_people_using_public_transportation')"
            name="publicTransportationCount"
            :options="team.public_transportation_options"
            :placeholder="$lang('l.choose_amount')"
            @update:model-value="patchTeam" />
          <div
            v-if="team.extra_defs && team.extra_defs.length"
            class="mt-4 border-t-2 pt-4">
            <TeamExtras :team="team" />
          </div>
          <div v-if="rules">
            <div class="mb-2 mt-5 border-b text-lg">
              {{ $lang('l.rules') }}
            </div>
            <div>{{ rules }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end px-3 pb-3">
      <TeamCartStatusAction class="shadow-md" :init-team="team" only-cart-button />
    </div>
  </VueCard>
</template>

<script setup lang="ts">
import type { ITeam, IUser } from '@/interfaces';
import { ref } from 'vue';
import VueButton from '../button.vue';
import VueCard from '../card.vue';
import VueInput from '../input.vue';
import VueSelect from '../select.vue';
import SiCardInput from './siCardInput.vue';
import TeamCartStatusAction from './teamCartStatusAction.vue';
import TeamExtras from './teamExtras.vue';

type Props = {
  team: ITeam;
  user: IUser;
  rules: string;
};

type Emits = {
  updateTeam: [clubName: string, name: string, publicTransportationCount: number, siCard: number];
};
const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const club_name = ref(props.team.club_name);
const name = ref(props.team.name);
const publicTransportationCount = ref(props.team.public_transportation_count);
const si_card = ref(props.team.si_card);

function patchTeam() {
  emits('updateTeam', club_name.value, name.value, publicTransportationCount.value, si_card.value);
}
</script>
