<template>
  <a
    class="my-1 flex px-4 py-2 transition duration-300 ease-in-out hover:bg-lightgray-600"
    :class="item.active ? 'bg-lightgray-700 text-white' : 'text-gray-700'"
    :href="`/${item.url}`">
    <iconify-icon class="my-auto w-8" :icon="item.icon" />
    <span class="grow">{{ getTranslatedLabel(item.label) }}</span>
    <CartBadge v-if="item.label === 'l.cart'" />
    <span v-if="tagValue !== undefined" class="float-right flex h-7 w-7 items-center justify-center rounded-full bg-blue-800 text-sm text-white">
      {{ tagValue }}
    </span>
  </a>
</template>

<script setup lang="ts">
import { getTranslatedLabel } from '@/helpers';
import CartBadge from '../cart/cartBadge.vue';

type Item = {
  active?: boolean;
  hasTag?: boolean;
  icon: string;
  label: string;
  url: string;
};
type Props = {
  item: Item;
  tagValue?: string | number;
};
defineProps<Props>();

const showSlot = ref(false);

onMounted(() => showSlot.value = true);
</script>
