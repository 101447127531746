<template>
  <div class="flex flex-wrap gap-10">
    <Card class="w-full">
      <template #title>
        {{ $lang('l.sponsors_list') }}
      </template>
      <Sponsor
        v-for="(sponsor, index) in sponsorList"
        :key="index"
        :index="index"
        :sponsor="sponsor"
        @after-delete="afterDelete"
        @after-update="afterUpdate" />
      <Sponsor @after-create="afterCreate" />
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { ISponsor } from '@/interfaces';
import { type PropType, ref } from 'vue';
import Card from '../card.vue';
import Sponsor from './sponsor.vue';

const props = defineProps({
  sponsors: Object as PropType<ISponsor[]>,
});

const sponsorList = ref(props.sponsors);

function afterCreate(sponsor: ISponsor) {
  sponsorList.value.push(sponsor);
}
function afterUpdate(sponsor: ISponsor, index: number) {
  sponsorList.value.splice(index, 1, sponsor);
}
function afterDelete(index: number) {
  sponsorList.value.splice(index, 1);
}
</script>
