<template>
  <form class="w-full grow">
    <b>{{ $lang('l.category.select_existing') }}</b>
    <BaseMultiselect
      class="mt-4 grow"
      :disabled="isLoading"
      name="category_id"
      option-key="short_name"
      :options="categories"
      searchable
      @update:model-value="submit()" />
  </form>
</template>

<script setup lang="ts">
import type { ICategory, ICategoryRace } from '@/interfaces';
import BaseMultiselect from '@/components/base/baseMultiselect.vue';
import { errorHandler, RaceKey } from '@/helpers';
import { route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { inject } from 'vue';

type Props = {
  categories: ICategory[];
};

type Emits = {
  categoryRaceCreated: [categoryRace: ICategoryRace];
};
defineProps<Props>();
const emit = defineEmits<Emits>();

const race = inject(RaceKey);

const { execute, isLoading } = useAxios<ICategoryRace>();
const { handleSubmit, resetForm } = useForm<{ category_id: number }>();

const submit = handleSubmit(async (values) => {
  if (!values.category_id) return;
  try {
    const { data } = await execute(route('webapi.categoryRace.post'), {
      data: {
        ...values,
        race_id: race.id,
      },
      method: 'POST',
    });
    emit('categoryRaceCreated', data.value);
    resetForm();
  }
  catch (error) {
    errorHandler(error);
  }
});
</script>
