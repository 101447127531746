<template>
  <VueForm :action="checkout">
    <div class="grow xl:flex xl:space-x-4">
      <div class="flex grow flex-row flex-wrap items-stretch">
        <RaceRunnerCartItem
          v-for="(raceRunner, index) in cartStore.raceRunners"
          :key="index"
          :as-event-admin="asEventAdmin"
          :index="index"
          :parental-request-notes-confirmed="parentalRequestNotesConfirmed"
          :race-runner="raceRunner"
          :starttime-request-notes-confirmed="starttimeRequestNotesConfirmed"
          @confirm-parental-request-notes="parentalRequestNotesConfirmed = true"
          @confirm-starttime-request-notes="starttimeRequestNotesConfirmed = true" />
        <TeamCartItem
          v-for="(team, index) in cartStore.teams"
          :key="index"
          :team="team" />
      </div>
      <div class="flex w-full flex-col gap-5 xl:max-w-xl">
        <CartSummary :checkout="isCheckingOut" />
        <ClubAdminInformation
          v-if="isClubAdmin"
          class="mx-2" />
      </div>
    </div>
  </VueForm>
</template>

<script setup lang="ts">
import type { IClub, IEvent, IRace, IRaceRunner, IStarttimeRequest, ITeam, IUser } from '@/interfaces';
import axios from 'axios';
import Swal from 'sweetalert2';
import { type PropType, ref } from 'vue';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import { useCartStore } from '../../store/cart';
import VueForm from '../form.vue';
import CartSummary from './cartSummary.vue';
import ClubAdminInformation from './clubAdminInformation.vue';
import RaceRunnerCartItem from './raceRunnerCartItem.vue';
import TeamCartItem from './teamCartItem.vue';

const props = defineProps({
  asEventAdmin: { default: false, type: Boolean },
  asUser: { default: () => undefined, required: false, type: Object as PropType<IUser> },
  clubs: { required: true, type: Array as PropType<IClub[]> },
  event: { default: () => undefined, required: false, type: Object as PropType<IEvent> },
  isClubAdmin: { required: false, type: Boolean },
  raceRunners: { required: true, type: Array as PropType<IRaceRunner[]> },
  races: { required: true, type: Array as PropType<IRace[]> },
  startTimeRequests: { required: true, type: Array as PropType<IStarttimeRequest[]> },
  teams: { required: true, type: Array as PropType<ITeam[]> },
});
const cartStore = useCartStore();

cartStore.$patch({
  clubs: props.clubs,
  races: props.races,
  startTimeRequests: props.startTimeRequests,
});

cartStore.$patch({
  raceRunners: props.raceRunners.map((raceRunner) => {
    return cartStore.mapCartItem(raceRunner);
  }),
  teams: props.teams.map((team) => {
    return cartStore.mapCartItem(team);
  }),
});
const starttimeRequestNotesConfirmed = ref(false);
const parentalRequestNotesConfirmed = ref(false);

const isCheckingOut = ref(false);

async function checkout() {
  isCheckingOut.value = true;
  try {
    const transcactionId = props.asEventAdmin
      ? await checkoutAsEventAdmin(props.event, props.asUser)
      : await checkoutAsUser();
    if (!transcactionId) {
      return window.location.replace(route('home'));
    }
    window.location.replace(route('cart.summary', { transaction: transcactionId }));
  }
  catch (error) {
    Swal.fire({
      confirmButtonText: lang('l.close'),
      icon: 'error',
      text: error.response.data?.message || lang('l.errorText'),
      title: lang('l.errorTitle'),
    });
  }
  isCheckingOut.value = false;
}

async function checkoutAsUser() {
  try {
    const res = await axios.post<number>(route('webapi.user.checkout'));
    return res.data;
  }
  catch (error) {
    console.log(error);
  }
}

async function checkoutAsEventAdmin(event: IEvent, user: IUser) {
  try {
    const res = await axios.post<number>(route('webapi.impersonate.checkout', [event.id, user.id]));
    return res.data;
  }
  catch (error) {
    console.log(error);
  }
}
</script>
