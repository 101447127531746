<template>
  <button
    class="my-auto flex rounded-full p-2 text-lg text-orange-500 hover:bg-lightgray-300"
    type="button"
    @click.prevent="updateFavorite">
    <iconify-icon
      v-if="active"
      class="m-auto h-5  w-5 text-yellow-500"
      icon="fas:star" />
    <iconify-icon
      v-else
      class="m-auto  h-5 w-5"
      icon="fal:star" />
  </button>
</template>

<script setup lang="ts">
import type { IRace } from '@/interfaces';

import { errorHandler } from '@/helpers';
import { route } from '@/plugins';

type Props = {
  race: IRace;
};

type Emits = {
  toggle: [value: boolean];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const active = ref(props.race.is_favourite);

watch(() => props.race.is_favourite, (value) => {
  active.value = value;
});

async function updateFavorite() {
  await (active.value ? removeFavourite() : addFavourite());
}

async function addFavourite() {
  try {
    active.value = true;
    emit('toggle', active.value);
    await useAxios(route('webapi.races.addFavourite', props.race));
  }
  catch (error) {
    active.value = false;
    emit('toggle', active.value);
    errorHandler(error);
  }
}

async function removeFavourite() {
  try {
    active.value = false;
    emit('toggle', active.value);
    await useAxios(route('webapi.races.removeFavourite', props.race));
  }
  catch (error) {
    active.value = true;
    emit('toggle', active.value);
    errorHandler(error);
  }
}
</script>
