<template>
  <transition name="fade">
    <div
      v-if="windowTop >= 300"
      class="fixed bottom-20 right-5 z-30 cursor-pointer">
      <a
        class="block rounded-md bg-orange-600 bg-opacity-50 text-white"
        @click="scrollToTop">
        <span class="hidden">Back to Top</span>
        <svg
          class="h-10 w-10"
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
            fill="currentColor" />
        </svg>
      </a>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

const windowTop = ref(0);
function onScroll(e: any) {
  if (!e || !e.target || !e.target.documentElement) {
    return;
  }
  windowTop.value = e.target.documentElement.scrollTop;
}
function scrollToTop() {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: 0,
  });
}
onMounted(() => {
  window.addEventListener('scroll', onScroll, true);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll, true);
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
