<template>
  <form
    class="mb-3 mt-5 p-3"
    novalidate
    @submit="updateUser">
    <div class="flex flex-col gap-1">
      <SupInput
        label="l.firstname"
        name="firstname"
        required />
      <SupInput
        label="l.name"
        name="lastname"
        required />
      <SupInput
        disabled
        label="l.email_short"
        name="email"
        required />
      <SupSelect
        label="l.gender"
        name="gender_id"
        no-clear
        option-key="name_translated"
        :options="genders"
        required />
      <SupSelect
        label="l.language"
        name="language_id"
        no-clear
        option-key="name"
        :options="languages"
        required />
    </div>
    <div class="mt-4">
      <h6 class="font-bold">
        {{ $lang('l.privacy') }}
      </h6>
      <div class="mt-3 flex flex-col gap-2">
        <BaseCheckbox
          :label="$lang('l.smartlook_api_cookie')"
          name="smartlook_api_form_consent" />
        <BaseCheckbox
          :label="$lang('l.smartlook_ip_cookie')"
          name="smartlook_ip_consent" />
        <div class="text-xs text-gray-400">
          {{ $lang('l.cookiesDeclarationEdit') }}
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        class="button-primary mt-5 w-auto"
        type="submit">
        {{ $lang('l.save') }}
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import type { IGender, ILanguage, IRegion, IUser, IUserForm } from '@/interfaces';
import { errorHandler, Toast } from '@/helpers';
import { lang, route } from '@/plugins';
import { useForm } from 'vee-validate';
import BaseCheckbox from '../base/baseCheckbox.vue';
import SupInput from '../ui/supInput.vue';
import SupSelect from '../ui/supSelect.vue';

type Props = {
  genders: IGender[];
  languages: ILanguage[];
  regions: IRegion[];
  user: IUser;
};

const props = defineProps<Props>();

const { handleSubmit, resetForm, setValues } = useForm<IUserForm>({ initialValues: props.user });
const prevLangId = ref(props.user.language_id);

const updateUser = handleSubmit.withControlled(async (values) => {
  try {
    const { data } = await useAxios<IUser>(route('webapi.user.patch', props.user), { data: values, method: 'PATCH' });
    resetForm();
    setValues({ ...data.value });
    await Toast.fire({
      icon: 'success',
      title: lang('l.saved'),
    });
    if (prevLangId.value !== data.value?.language_id) {
      window.location.reload();
    }
  }
  catch (error) {
    errorHandler(error);
  }
});
</script>
