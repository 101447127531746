<template>
  <div class="bg-transparent" style="perspective: 1000px; -webkit-perspective: 1000px;">
    <div
      class="relative h-full w-full"
      :class="{ 'flip-card': flip }"
      style="
        transition: transform 800ms;
        transform-style: preserve-3d;
        -webkit-transition: transform 800ms;
        -webkit-transform-style: preserve-3d;
      ">
      <!-- Search Runner -->
      <div
        class="backface-hidden absolute h-full w-full"
        :class="{ invisible: flip && !flipping }">
        <VueCard class="h-full w-full">
          <template #title>
            {{ $lang('l.searchRunner') }}
          </template>

          <div class="rounded-b-lg bg-white p-4">
            <VueInput
              v-model="runner.lastname"
              :label="$lang('l.lastname')"
              name="lastname"
              @update:model-value="search" />
            <VueInput
              v-model="runner.firstname"
              :label="$lang('l.firstname')"
              name="firstname"
              @update:model-value="search" />
            <div
              v-if="loading && !flip"
              class="relative mx-5 h-full w-full flex flex-col items-center justify-center text-4xl">
              <i aria-hidden="true" class="fas fa-circle-notch animate-spin" />
            </div>
            <div
              v-if="runners.length > 0 && !flip"
              class="m-2 border p-2"
              :class="isPending ? 'text-red-500' : ''">
              <div class="flex justify-start gap-3">
                <i aria-hidden="true" class="fas fa-lightbulb-on my-auto text-yellow-400" />
                <div class="text-left text-sm">
                  {{ $lang('l.drag_and_drop_runner_hint') }}
                </div>
              </div>
            </div>

            <div
              v-for="(runner, index) in runners"
              :key="index"
              class="runner mx-2 my-5 flex justify-between border border-gray-50 bg-lightgray-100 p-2"
              :class="{
                'cursor-not-allowed opacity-50': isRunnerRegistered(runner),
                'cursor-grab active:hover:cursor-grabbing': !isRunnerRegistered(runner),
              }"
              :draggable="!isRunnerRegistered(runner)"
              @click="start()"
              @dragend="stopMove($event)"
              @dragstart="startMove($event, runner)"
              @touchend="!isRunnerRegistered(runner) ? stopMove($event) : ''"
              @touchmove="!isRunnerRegistered(runner) ? handleTouchMove($event) : ''"
              @touchstart="!isRunnerRegistered(runner) ? startMove($event, runner) : ''">
              <span class="pointer-events-none"><i class="fas fa-person-running mr-2" />{{ runner.displayname }}</span>{{ runner.year_of_birth }}
            </div>
            <div>
              <div
                v-if="noRunnersFound"
                class="m-2 border p-2">
                <div class="flex justify-center gap-3">
                  <i
                    aria-hidden="true"
                    class="fas fa-triangle-exclamation my-auto text-red-700" />
                  <div class="text-left text-sm">
                    {{ $lang('l.NothingFoundYet') }}
                  </div>
                </div>
              </div>
              <div
                v-if="!noRunnersFound && team.race.allow_quick_add_runner"
                class="m-2 border p-2">
                <div class="flex justify-center gap-3">
                  <i
                    aria-hidden="true"
                    class="fas fa-square-info my-auto text-blue-700" />
                  <div class="text-left text-sm">
                    {{ $lang('l.notFoundRightRunner?') }}
                  </div>
                </div>
              </div>
              <div
                v-if="team.race.allow_quick_add_runner"
                class="mt-5">
                <Button
                  class="button-primary my-auto text-sm"
                  @click="flipCard()">
                  {{ $lang('l.create.runner') }}
                </Button>
              </div>
            </div>
          </div>
        </VueCard>
      </div>

      <!-- Make Runner -->
      <QuickAddRunner
        v-if="team.race.allow_quick_add_runner"
        class="backface-hidden absolute h-full w-full"
        :class="{ invisible: !flip && !flipping }"
        :runners="runners"
        :searching-runner="runner"
        style="transform: rotateY(180deg);"
        @back="flipToRunnerSearch" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IQuickRunner, IRunner, ITeam, ITeamMember } from '@/interfaces';
import { useTimeout } from '@vueuse/core';
import { whenever } from '@vueuse/shared';
import axios from 'axios';
import { computed, type PropType, type Ref, ref, watch } from 'vue';
import { parseRunnerToMember } from '../../composables/team/helper';
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../../composables/touch';
import { route } from '../../plugins/route';
import Button from '../button.vue';
import VueCard from '../card.vue';
import VueInput from '../input.vue';
import QuickAddRunner from './quickAddRunner.vue';

const props = defineProps({
  dropzones: Array,
  searchTrigger: Boolean,
  team: Object as PropType<ITeam>,
});

const emits = defineEmits([
  'movingRunner',
  'endSearchTrigger',
  'handleTouchStart',
  'addSlot',
  'dropTouch',
  'scrollToSlots',
]);
const { isPending, ready, start } = useTimeout(5000, { controls: true });
isPending.value = false;

const runner = ref({
  firstname: '',
  lastname: '',
});

const cardRef = ref(null);
const offsetHeight = ref(0);

watch(cardRef, () => {
  if (cardRef.value) {
    offsetHeight.value = cardRef.value.offsetHeight;
  }
});

const runners: Ref<IRunner[]> = ref([]);
const registeredRunners: Ref<ITeamMember[]> = ref([]);
const firstStart = ref(true);
const loading = ref(false);
const abortController = ref();
const flip = ref(false);
const flipping = ref(false);
const flipDuration = 800;

function flipCard() {
  flip.value = !flip.value;
  if (flip.value) {
    if (abortController.value) {
      abortController.value.abort();
    }
    loading.value = false;
    runners.value = [];
  }
  flipping.value = true;
  setTimeout(() => {
    flipping.value = false;
  }, flipDuration);
}

whenever(
  () => props.searchTrigger,
  () => search(),
);

function search(): void {
  if (flip.value) return;
  if (runner.value.lastname.length < 3) {
    runners.value = [];
    return;
  }
  if (abortController.value) {
    abortController.value.abort();
  }
  queryRunners();
}

const pickedRunner = ref();

function startMove(ev: any, runner: IRunner) {
  if (ev.type === 'dragstart') {
    emits('movingRunner', runner);
  }
  if (ev.type === 'touchstart') {
    pickedRunner.value = runner;
    emits('scrollToSlots');
    emits('movingRunner', runner);
    const touchEvent: TouchEvent = ev;
    handleTouchStart(ev);
  }
}

function stopMove(ev: any) {
  if (ev.type === 'touchend') {
    const touchEvent: TouchEvent = ev;
    if (handleTouchEnd(touchEvent, props.dropzones)) {
      const i = handleTouchEnd(touchEvent, props.dropzones);
      const member = parseRunnerToMember(pickedRunner.value);
      emits('dropTouch', member, i);
      ev.target.style = null;
      emits('movingRunner', null);
    }
  }
  emits('movingRunner', null);
}

const noRunnersFound = computed(() => {
  if (runners.value.length) {
    return false;
  }
  if (runner.value.lastname.length < 3) {
    return false;
  }
  if (loading.value) {
    return false;
  }
  return true;
});

function queryRunners() {
  if (!runner.value.lastname || flip.value) {
    runners.value = [];
    return;
  }
  emits('endSearchTrigger');
  abortController.value = new AbortController();
  const signal = abortController.value.signal;
  loading.value = true;
  runners.value = [];
  axios
    .post(route('webapi.team.searchMember', props.team), runner.value, { signal })
    .then((res) => {
      if (!flip.value) {
        runners.value = res.data.registrableRunners || [];
        registeredRunners.value = res.data.alreadyRegistredRunners;
      }
      loading.value = false;
    })
    .catch((err) => {
      if (err.message !== 'canceled') {
        console.log(err);
      }
    });
}

function isRunnerRegistered(runner: IRunner) {
  if (registeredRunners.value.findIndex(regRunner => regRunner.id === runner.id) > -1) {
    return true;
  }
  return false;
}

function flipToRunnerSearch(quickRunner: IQuickRunner) {
  flip.value = false;
  if (quickRunner) {
    runner.value.lastname = quickRunner.lastname;
    runner.value.firstname = quickRunner.firstname;
  }
  search();
  flipping.value = true;
  setTimeout(() => {
    flipping.value = false;
  }, flipDuration);
}
</script>

<style scoped>
.flip-card {
  transform: rotateY(180deg);
}
.backface-hidden {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.invisible {
  visibility: hidden;
}
</style>
