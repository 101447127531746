<template>
  <div class="2xl:col-span-2">
    <div class="flex gap-2">
      <span class="whitespace-nowrap">{{ $lang('l.exportORwareCsv') }}</span>
      <div v-if="isLoading || percent">
        <i class="fas fa-spinner-third fa-spin" />
      </div>
      <button
        v-else
        type="button"
        @click="generateExport">
        <i class="fa-solid fa-plus" />
      </button>
    </div>

    <ul class="ml-3">
      <li
        v-for="item, index in loadedExports"
        :key="index">
        <a
          class="text-blue-600 hover:text-blue-800"
          :href="route('webapi.race.export.orware.get', {
            race,
            filename: item,
          })">
          <iconify-icon class="mr-2" icon="fas:file-csv" />{{ parseDate(item) }}
        </a>
      </li>
      <li
        v-if="percent !== undefined"
        class="relative">
        <div class="relative h-4 w-[155px] overflow-hidden rounded-full bg-gray-50">
          <span
            class="absolute left-0 top-0 h-4 rounded-full bg-green-500"
            :style="`width: ${percent}%`" />
          <span class="absolute left-1/2 -translate-x-1/2 text-xs">{{ percent }}%</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { IRace } from '@/interfaces';
import { useIntervalFn } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';

type Props = {
  race: IRace;
};
const props = defineProps<Props>();

const { execute, isLoading } = useAxios();

const exports = ref<string[]>([]);

const percent = ref<number>();
const startDownload = ref(false);
const numberOfExports = ref<number>();

async function generateExport() {
  try {
    startDownload.value = true;
    numberOfExports.value = exports.value.length;
    percent.value = 0;
    await execute(route('webapi.race.export.orware.create', { race: props.race }), { method: 'POST' });
    console.log('HIT');
    await getAllExports();
  }
  catch {
    //
  }
}

async function getAllExports() {
  try {
    const { data } = await useAxios<string[]>(route('webapi.race.export.orware.get', { race: props.race }));
    if (data.value) {
      const lastEntry = data.value[data.value.length - 1];
      if (percent.value !== undefined && percent.value === 0 && data.value.length === numberOfExports.value) {
        percent.value = lastEntry && lastEntry?.includes('%') ? Number(lastEntry.slice(0, -1)) : 0;
      }
      else {
        percent.value = lastEntry && lastEntry?.includes('%') ? Number(lastEntry.slice(0, -1)) : undefined;
      }

      exports.value = data.value;
    }
  }
  catch {
    // errorHandler(error);
  }
}

const { resume } = useIntervalFn(async () => {
  await getAllExports();
}, 2000);

onMounted(async () => {
  await getAllExports();
  resume();
});
const loadedExports = computed(() => {
  if (!exports.value || exports.value.length === 0)
    return;

  if (exports.value[exports.value.length - 1].includes('%')) {
    return exports.value.slice(0, -2);
  }
  return exports.value;
});

function parseDate(filename: string) {
  const dateString = filename.slice(0, 16);
  const dateSplit = dateString.split('_');
  if (dateSplit[2].length === 4)
    return `${dateSplit[0]}.${dateSplit[1]}.${dateSplit[2].slice(2, 4)}`;
  return `${dateSplit[2]}.${dateSplit[1]}.${dateSplit[0].slice(2, 4)} ${dateSplit[3]}:${dateSplit[4]} Uhr`;
}
</script>
