<template>
  <span
    v-if="isResendable"
    class="cursor-pointer text-blue-600 hover:text-blue-800"
    @click="resendeVerification">
    {{ $lang('l.approval_open') }}
  </span>
  <span v-else>{{ $lang('l.approval_requested') }}</span>
</template>

<script setup lang="ts">
import { Toast } from '@/helpers/toast';
import { lang } from '@/plugins';
import _dayjs from 'dayjs';

const props = defineProps<Props>();

const dayjs = _dayjs;

type Props = {
  runnerId: number;
  lastResendAt: string;
};

const lastResendAtIntern = ref<Date | null>(null);
const now = ref<Date | null>(null);
const timeout = ref<NodeJS.Timeout>();

onMounted(() => {
  lastResendAtIntern.value = new Date(props.lastResendAt);
  timeout.value = setInterval(setNow, 500);
});

onBeforeUnmount(() => {
  clearInterval(timeout.value);
});
const isResendable = computed(() => {
  if (!lastResendAtIntern.value) {
    return true;
  }
  return dayjs(now.value).diff(lastResendAtIntern.value, 'minute') > 5;
});

function setNow() {
  now.value = new Date();
}

async function resendeVerification() {
  try {
    await useAxios(`/user/verifyrunners/${props.runnerId}`, {
      method: 'post',
    });
    await Toast.fire({
      icon: 'success',
      title: lang('l.unapprovedRunnerEmailSend'),
    });
    lastResendAtIntern.value = new Date();
  }
  catch (error) {
    console.log(error);
  }
}
</script>
