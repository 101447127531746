<template>
  <button
    v-if="!href"
    class="rounded-md px-4 py-2 transition duration-300 ease-in-out hover:shadow-button"
    :class="{ 'cursor-not-allowed bg-gray-300 hover:shadow-none': disabled }"
    :disabled="disabled"
    :type="type"
    @click="$emit('click', $event)"
    @hover="$emit('hover', $event)">
    <slot />
  </button>
  <a
    v-else
    class="inline-block rounded-md px-4 py-2 transition duration-300 ease-in-out hover:shadow-button"
    :href="href"><slot /></a>
</template>

<script lang="ts">
export default {
  name: 'VueButton',
};
</script>

<script setup lang="ts">
// eslint-disable-next-line import/first
import type { PropType } from 'vue';

defineProps({
  disabled: { default: false, type: Boolean },
  href: { required: false, type: String },
  type: { default: 'button', type: String as PropType<'button' | 'submit' | 'reset'> },
});

defineEmits(['click', 'hover']);
</script>
