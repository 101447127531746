<template>
  <VueCard>
    <template #title>
      {{ $lang('l.fileManagement') }}: {{ name }}
    </template>
    <div class="p-3">
      <div ref="filesRef">
        <RaceFile
          v-for="file, index in files"
          :key="file.id"
          :file="file"
          :index="index"
          :loading="isMoving"
          @deleted="deletedFile(file.id)" />
      </div>

      <VueForm
        :action="saveMedia"
        class="mt-5">
        <MediaLibraryAttachment
          v-if="!isSaving"
          :initial-value="initUploadFiles"
          :multiple="true"
          name="files"
          :translations="medialibarytranslations"
          @change="pickedMedia = $event"
          @is-ready-to-submit-change="isReadyToSubmit = $event" />
        <BaseButton
          class="mt-2 w-full"
          :disabled="disableSave"
          :label="$lang('l.save')"
          :loading="isSaving"
          type="submit" />
      </VueForm>
    </div>
  </VueCard>
</template>

<script setup lang="ts">
import type { IFile, IRace } from '@/interfaces';

import { errorHandler } from '@/helpers';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useSortable } from '@vueuse/integrations/useSortable';
import { MediaLibraryAttachment } from 'spatie-media-lib-pro/media-library-pro-vue3-attachment';
import { computed, ref, type Ref } from 'vue';

import BaseButton from '../base/baseButton.vue';
import VueCard from '../card.vue';
import VueForm from '../form.vue';
import RaceFile from './files/raceFile.vue';

type Props = {
  initfiles: IFile[];
  medialibarytranslations: any;
  name: string;
  race: IRace;
};
const props = defineProps<Props>();

const files: Ref<IFile[]> = ref(props.initfiles);

const oldFileIdsOrder = ref<number[]>([]);

updateOldFileIdsOrder();

const initUploadFiles: Ref<any[]> = ref([]);
const isSaving = ref(false);
const isMoving = ref(false);
const isReadyToSubmit = ref(false);
const pickedMedia = ref({});

const filesRef = ref<HTMLElement>();

useSortable(filesRef, files, {
  animation: 150,
  bubbleScroll: true,
  dragClass: 'drag',
  forceFallback: true,
  ghostClass: 'ghost',
  handle: '.handle',
  scrollSensitivity: 50,
  scrollSpeed: 10,
  sort: true,
});

watch(files, async () => {
  await updateSort();
});

function updateOldFileIdsOrder() {
  oldFileIdsOrder.value = files.value.map(file => file.id);
}

const disableSave = computed(() => {
  if (Object.keys(pickedMedia.value).length === 0) {
    return true;
  }
  return !isReadyToSubmit.value;
});

async function getAllMedia() {
  try {
    const { data } = await useAxios<File[]>(route('webapi.races.allMedia', { race: props.race }));
    files.value = data.value;
  }
  catch (error) {
    console.log(error);
  }
}

async function updateSort() {
  isMoving.value = true;
  for (const [index, file] of files.value.entries()) {
    if (file.id !== oldFileIdsOrder.value[index]) {
      await updateMedia(file, index + 1);
    }
  }
  updateOldFileIdsOrder();

  // await getAllMedia();
  isMoving.value = false;
}

function deletedFile(id: number) {
  const index = files.value.findIndex(file => file.id === id);
  if (index > -1) {
    files.value.splice(index, 1);
  }
}

async function updateMedia(file: IFile, orderColumn: number) {
  try {
    await useAxios(route('webapi.media.patch', { media: file }), {
      data: {
        collection_name: file.collection_name,
        name: file.name,
        order_column: orderColumn,
      },
      method: 'PATCH',
    });
  }
  catch (error) {
    errorHandler(error);
  }
}

async function saveMedia(form: Element | Element[] | Vue | Vue[]) {
  if (!(form instanceof HTMLFormElement)) {
    console.log('not element');
    return;
  }
  const formdata = new FormData(form);
  formdata.set('model_id', props.race.id.toString());
  formdata.set('model_type', 'App\\Race');
  formdata.set('media_collection', 'default');
  isSaving.value = true;
  try {
    await useAxios(route('webapi.media.post'), { data: formdata, method: 'POST' });
    await getAllMedia();
    initUploadFiles.value = [];
    isSaving.value = false;
    pickedMedia.value = {};
  }
  catch (error) {
    console.log(error);
  }
}
</script>
