<template>
  <div>
    <RacePricesAgeItem
      v-for="(price, index) in _prices"
      :key="price.id"
      :disabled="disabled || isLoading"
      :first-item="index === 0"
      :first-item-deletable="firstItemDeletable && _prices.length === 1"
      :last-item="index === _prices.length - 1"
      :price="price"
      :race-id="raceId"
      @removed="removePrice(price)"
      @updated="updatedAgePrice" />
  </div>
</template>

<script setup lang="ts">
import type { IRacePrice } from '@/interfaces';

import { errorHandler } from '@/helpers';
import { pull } from 'lodash';

import RacePricesAgeItem from './racePricesAgeItem.vue';

type Props = {
  categoryRaceId?: number;
  disabled?: boolean;
  firstItemDeletable?: boolean;
  prices: IRacePrice[];
  raceId: number;
};

const props = defineProps<Props>();

const _prices: Ref<IRacePrice[]> = ref(props.prices);

const isLoading = ref(false);

async function createRacePriceAge(maxYear?: number): Promise<void> {
  try {
    const { data } = await useAxios<IRacePrice>(route('webapi.price.store', { race: { id: props.raceId } }), {
      data: { amount: 0, category_race_id: props.categoryRaceId, max_year: maxYear },
      method: 'POST',
    });
    if (data.value) _prices.value.push(data.value);
  }
  catch (error) {
    errorHandler(error);
  }
}

onMounted(async () => {
  if (_prices.value.length === 0) return await createRacePriceAge();
});

async function updatedAgePrice(value: IRacePrice) {
  const index = _prices.value.findIndex(price => price.id === value.id);
  _prices.value[index] = value;
  if (index === _prices.value.length - 1 && value.min_year) await createRacePriceAge(value.min_year - 1);
}

async function removePrice(price: IRacePrice) {
  isLoading.value = true;
  pull(_prices.value, price);
  const lastPrice = _prices.value.at(-1);

  if (lastPrice) await updatePrice(lastPrice, { min_year: null });
  isLoading.value = false;
}

async function updatePrice(price: IRacePrice, { max_year, min_year }: { max_year?: null | number; min_year?: null | number }): Promise<void> {
  try {
    const { data } = await useAxios<IRacePrice>(route('webapi.price.update', { price, race: { id: props.raceId } }), {
      data: { max_year, min_year },
      method: 'PATCH',
    });
    if (data.value) {
      const index = _prices.value.findIndex(price => price.id === data.value?.id);
      console.log(index);
      _prices.value[index] = data.value;
      console.log(_prices.value);
    }
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
