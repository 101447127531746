<template>
  <div class="flex gap-2">
    <div class="flex gap-2">
      <InformationButton
        class="my-auto"
        :text="$lang('l.expandCategorySelectionTooltip')" />
      <div>
        {{ $lang('l.expandCategorySelection') }}
      </div>
    </div>
    <div>
      <Checkbox
        v-model="toggle"
        class="mt-0"
        @update:model-value="patchRunner(toggle)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRunner } from '@/interfaces';
import axios from 'axios';
import { type PropType, ref } from 'vue';
import { route } from '../../plugins/route';
import Checkbox from '../checkbox.vue';
import InformationButton from '../informationButton.vue';

const props = defineProps({
  runner: { required: true, type: Object as PropType<IRunner> },
});

async function patchRunner(value) {
  await axios.patch(route('webapi.runner.patch', { id: props.runner.id }), { has_extended_category_list: value });
}
const toggle = ref(props.runner.has_extended_category_list);
</script>
