<template>
  <div
    :id="id"
    class="relative mt-2 text-left text-gray-700"
    :class="{ 'cursor-not-allowed opacity-75': disabled }">
    <div class="flex flex-row">
      <div
        class="w-8 text-center"
        :class="{ 'cursor-pointer': !disabled }"
        @click="toggle">
        <div
          class="border shadow-md"
          :class="[small ? 'h-5 w-5 rounded-md' : 'h-7 w-7 rounded-lg',
                   { 'border-2 border-red-500': showError() }]">
          <div
            v-if="internalValue"
            class="flex h-full items-center justify-center">
            <i
              aria-hidden="true"
              class="far fa-check my-auto text-green-600"
              :class="small ? '' : 'text-xl'" />
          </div>
        </div>
      </div>
      <div
        ref="labelRef"
        class="align-center text-black"
        :class="small ? 'ml-2 text-sm' : 'ml-4'"
        v-html="requiredLabel()" />
    </div>
    <div
      v-if="showError()"
      class="input__error text-xs"
      role="alert">
      {{ initialError || fieldControl.errors.value[0] }}
    </div>

    <input
      hidden
      :name="name"
      type="text"
      :value="internalValue ? valueId : uncheckedValue ? (uncheckedValue === 'null' ? null : uncheckedValue) : 0">
  </div>
</template>

<script setup lang="ts">
import tippy from 'tippy.js';
import { onBeforeUpdate, onMounted, ref } from 'vue';
import { useFieldControl } from '../composables/form/fieldControl';

const props = defineProps({
  id: Number,
  autofocus: Boolean,
  blade: Boolean,
  disabled: Boolean,
  initError: String,
  initialError: String,
  initValue: Boolean,
  label: { default: '', type: String },
  modelValue: [Boolean, Number, String],
  name: String,
  placeholder: String,
  required: Boolean,
  small: Boolean,
  tooltipp: String,
  type: { default: 'text', type: String },
  uncheckedValue: [String, Number, Boolean, null],
  validationRules: String,
  valueId: [String, Boolean, Number],
});

const emit = defineEmits(['update:modelValue', 'blur']);

const isDirty = ref(false);
const isTouched = ref(false);
const internalValue = ref(false);
const labelRef = ref(null);

function setInternalValue(value: any) {
  if (typeof value === 'undefined') {
    internalValue.value = false;
    return;
  }

  if (typeof value === 'string') {
    if (value === '0' || value.toLowerCase() === 'false') {
      internalValue.value = false;
      return;
    }

    internalValue.value = Boolean(value);
    return;
  }

  if (typeof value === 'boolean') {
    internalValue.value = value;
    return;
  }

  internalValue.value = !!value;
}

if (props.blade) {
  setInternalValue(props.initValue);
}
else {
  setInternalValue(props.modelValue);
}

const initialError = ref(props.initialError);

const fieldControl = useFieldControl(props.name, isTouched, props.required, props.validationRules);
fieldControl.validate(internalValue.value);

function showError() {
  return !fieldControl.isValid.value && isTouched.value;
}

function toggle() {
  if (props.disabled) {
    return;
  }
  isTouched.value = true;
  internalValue.value = !internalValue.value;
  initialError.value = null;
  validate();
  updateModelValue(internalValue.value);
  blur(internalValue.value);
}

function blur(ev: any) {
  emit('blur', ev);
}

function updateModelValue(ev: any) {
  emit('update:modelValue', ev);
}

function validate() {
  fieldControl.validate(internalValue.value);
  isDirty.value = true;
}

function requiredLabel() {
  return props.label + (props.required ? ' *' : '');
}

onMounted(() => {
  if (props.tooltipp && labelRef.value instanceof Element) {
    tippy(labelRef.value, { theme: 'light' }).setContent(props.tooltipp);
  }
});

onBeforeUpdate(() => {
  if (props.blade) {
    return;
  }
  internalValue.value = props.modelValue;
  validate();
});
</script>
