<!-- eslint-disable vue/no-extra-parens -->
<template>
  <div v-tooltip.auto="tooltip" class="relative">
    <InputLabel v-if="label" :for="id ?? name" :label="label" :required="required" />
    <Multiselect
      :id="id ?? name"
      v-model="value"
      :can-clear="canClear"
      :classes="classes"
      :clear-on-search="clearOnSearch"
      :create-option="createOption"
      :delay="delay"
      :disabled="disabled || parentDisabled"
      :infinite="infinite"
      :label="(optionKey as string)"
      :limit="limit"
      :min-chars="minChars"
      :mode="mode"
      :no-options-text="$lang('l.nothingFound')"
      :no-results-text="$lang('l.nothingFound')"
      :object="valueAsObject"
      :options="options"
      :placeholder="placeholder"
      :resolve-on-load="resolveOnLoad"
      :searchable="!noSearch"
      :track-by="trackBy"
      :value-prop="valueProp ?? 'id'"
      @created="$emit('created', $event)" />
    <InputFooter
      :has-error="hasError"
      :message="hasError && errorMessage ? errorMessage : hint ? hint : undefined"
      :meta="meta" />
  </div>
</template>

<script setup lang="ts" generic="T, K extends keyof T & string">
import type { IExtendedProps } from './types';

import Multiselect from '@vueform/multiselect';

import { useFieldCustom } from './composables';
import InputFooter from './inputFooter.vue';
import InputLabel from './inputLabel.vue';

type Props = {
  createOption?: boolean;
  searchKeys?: string;
  clearOnSearch?: boolean;
  delay?: number;
  infinite?: boolean;
  limit?: number;
  minChars?: number;
  mode?: Multiselect['mode'];
  modelValue?: number;
  /**
   * Disables the clear function when true. Automatically set to false if 'required' is true.
   */
  noClear?: boolean;
  noSearch?: boolean;
  optionKey?: K;
  options: ((query: string) => Promise<T[]>) | T[];
  resolveOnLoad?: boolean;
  /**
   *  Specifies keys for searching within options. Multiple keys can be separated by '|', similar to Laravel syntax.
   */
  searchBy?: string;
  valueAsObject?: boolean;
  valueProp?: string;
};

type Emits = {
  'created': [value: T];
  'update:modelValue': [value: number];
};

const props = defineProps<Props & IExtendedProps>();
defineEmits<Emits>();

const { errorMessage, hasError, meta, value } = useFieldCustom<number>(props);

const parentDisabled = inject<boolean>('disabled', false);

const canClear = computed(() => !props.noClear && !props.required);
const trackBy = computed(() => props.searchBy?.includes('|') ? props.searchBy.split('|') : props.searchBy);

const classesInit = {
  assist: 'absolute -m-px w-px h-px overflow-hidden',
  container: 'relative mx-auto w-full flex items-center justify-end box-border border rounded-md leading-snug active:border-blue-700 focus:border-blue-700',
  containerActive: 'border-blue-700',
  containerDisabled: 'opacity-60 bg-[#efefef4d]',
  containerOpen: 'rounded-b-none',
  containerOpenTop: 'rounded-t-none',
  dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border focus:ring-1 border-blue-700 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b-md',
  dropdownHidden: 'hidden',
  fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
  noOptions: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
  noResults: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
  option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
  optionDisabled: 'text-gray-300 cursor-not-allowed',
  optionPointed: 'bg-orange-400 text-white',
  options: 'flex flex-col p-0 m-0 list-none',
  optionSelected: 'text-white bg-orange',
  optionSelectedDisabled: 'bg-orange bg-opacity-50 cursor-not-allowed',
  optionSelectedPointed: 'text-white bg-orange-400',
  singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug px-4 box-border',
  singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
  spacer: 'h-9 py-px box-content',
  wrapper: 'relative mx-auto w-full flex items-center justify-end box-border outline-none',
};

const classes = computed(() => {
  const classes = { ...classesInit };
  classes.container = hasError.value ? `${classes.container} border-red-600` : `${classes.container}`;
  classes.container = props.disabled || parentDisabled ? `${classes.container} cursor-default` : `${classes.container} cursor-pointer`;
  return classes;
});
</script>

<style>
  @import '../../../../node_modules/@vueform/multiselect/themes/tailwind.css';
</style>
