<template>
  <VueButton
    :disabled="disabled"
    type="button"
    @click="deleteTransactionAlert">
    <slot />
  </VueButton>
</template>

<script setup lang="ts">
import { lang } from '@/plugins';
import Swal from 'sweetalert2';
import { Toast } from '../helpers/toast';
import VueButton from './button.vue';

type Props = {
  disabled: boolean;
  transactionId: number;
};
const props = defineProps<Props>();

async function deleteTransactionAlert() {
  try {
    const swal = await Swal.fire({
      cancelButtonText: lang('l.cancel'),
      confirmButtonText: lang('l.yes'),
      icon: 'warning',
      showCancelButton: true,
      text: lang('l.transactionDeleteQuestion'),
      title: lang('l.transactionDeleteConfirm'),
    });
    if (swal.isDenied) {
      return;
    }
    await deleteTransaction();
  }
  catch (error: any) {

  }
}

async function deleteTransaction() {
  try {
    await useAxios(route('webapi.transaction.delete', props.transactionId), {
      method: 'delete',
    });
    await Toast.fire({
      icon: 'success',
      title: lang('l.delete.success'),
    });
    window.location.replace('/home');
  }
  catch (error: any) {
    Swal.fire({
      icon: 'error',
      text: error.response.data.message,
    });
  }
}
</script>
