<template>
  <div class="flex gap-3 rounded-md bg-lightgray-200 px-2 py-1">
    <div>{{ categoryRace.category.short_name }}</div>
    <button
      class="cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
      :disabled="loading || !categoryRace.startlist_is_detachable"
      @click="detacheCategoryRace(categoryRace)">
      <i
        aria-hidden="true"
        class="far fa-x text-red-500 hover:text-red-600" />
    </button>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace } from '@/interfaces';
import axios from 'axios';
import Swal from 'sweetalert2';
import { type PropType, ref } from 'vue';
import { route } from '../../plugins/route';

defineProps({ categoryRace: { required: true, type: Object as PropType<ICategoryRace> } });
const emits = defineEmits(['detached', 'loading']);
const loading = ref(false);

async function detacheCategoryRace(categoryRace: ICategoryRace) {
  try {
    loading.value = true;
    if (!categoryRace.startlist_is_detachable) return;
    const res = await axios.patch<ICategoryRace>(route('webapi.categoryRace.patch', categoryRace), {
      startlist_id: null,
    });
    emits('detached', res.data);
    loading.value = false;
  }
  catch (error) {
    Swal.fire({
      html: error.response.data.message,
      icon: 'error',
    });
    loading.value = false;
  }
}
</script>
