<template>
  <div class="relative">
    <BaseLabel :label="label" />
    <Editor
      v-model="value"
      v-bind="$attrs"
      :api-key="env.VITE_TINY_API_KEY"
      class="w-full"
      :init="editorSettings"
      :required="required" />
    <ErrorMessage
      :message="error"
      :meta="meta" />
  </div>
</template>

<script setup lang="ts">
import Editor from '@tinymce/tinymce-vue';
import { useField } from 'vee-validate';

import { editorSettings } from '../../configs/baseEditor';
import BaseLabel from './baseLabel.vue';
import ErrorMessage from './errorMessage.vue';

const props = defineProps<Props>();

const env = import.meta.env;

type Props = {
  label?: string;
  name: string;
  required?: boolean;
};
const { errorMessage: error, meta, value } = useField<number | string>(() => props.name, props.required ? 'required' : '', {
  syncVModel: true,
  validateOnValueUpdate: false,
});
</script>
