<template>
  <div>
    <SupCard title="l.category">
      <div class="p-3">
        <RacePricesCategoryItem
          v-for="categoryRace in categoryRacesWithPrices"
          :key="categoryRace.id"
          :category-race="categoryRace"
          :disabled="disabled"
          :race-id="raceId" />
        <CreateRacePriceCategory
          v-if="categoryRacesWithPrices.length < categoryRaces.length"
          :category-races="selectableCategoryRaces"
          :disabled="disabled"
          :race-id="raceId"
          @created="addPrice" />
      </div>
    </SupCard>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, IRacePrice } from '@/interfaces';

import SupCard from '@/components/ui/supCard.vue';

import CreateRacePriceCategory from './createRacePriceCategory.vue';
import RacePricesCategoryItem from './racePricesCategoryItem.vue';

type Props = {
  categoryRaces: (ICategoryRace & { prices: IRacePrice[] })[];
  disabled?: boolean;
  raceId: number;

};
const props = defineProps<Props>();

const _categoryRaces = ref(props.categoryRaces);

const categoryRacesWithPrices = computed(() => {
  return _categoryRaces.value.filter(cr => cr.prices.length > 0);
});

const selectableCategoryRaces = computed(() => {
  return _categoryRaces.value.filter(cr => !categoryRacesWithPrices.value.includes(cr)).map((cr) => {
    return {
      ...cr,
      label: cr.category?.short_name,
    };
  });
});

function addPrice(price: IRacePrice) {
  const index = _categoryRaces.value.findIndex(cr => cr.id === price.category_race_id);
  if (index > -1) _categoryRaces.value[index].prices.push(price);
}
</script>
