<template>
  <div class="relative mt-2">
    <BaseLabel :label="label" />
    <Multiselect
      v-model="value"
      :can-clear="clearable"
      :can-deselect="true"
      class="multiselect-custom"
      :disabled="disabled"
      :label="optionKey"
      :mode="mode"
      :no-options-text="$lang('l.nothingFound')"
      :no-results-text="$lang('l.nothingFound')"
      :options="options"
      :placeholder="placeholder"
      :searchable="searchable"
      :track-by="searchKey || optionKey"
      :value-prop="valueProp ?? 'id'" />
    <ErrorMessage
      :disable-message="disableMessage"
      :disabled="disabled"
      :message="errorMessage"
      :meta="meta" />
  </div>
</template>

<script setup lang="ts" generic="T">
import Multiselect from '@vueform/multiselect';
import { useField } from 'vee-validate';

import BaseLabel from './baseLabel.vue';
import ErrorMessage from './errorMessage.vue';

type Props = {
  clearable?: boolean;
  clearOnSelect?: boolean;
  customOption?: (value: T) => void;
  disabled?: boolean;
  disableMessage?: string;
  label?: string;
  mode?: Multiselect['mode'];
  modelValue?: number;
  name?: string;
  optionKey?: string;
  options: T[];
  placeholder?: string;
  required?: boolean;
  searchable?: boolean;
  searchKey?: keyof T;
  valueProp?: keyof T;
};

type Emits = {
  'blur': [value: T];
  'input': [value: T];
  'update:modelValue': [value: number];
};

const props = withDefaults(defineProps<Props>(), {
  customOption: undefined,
  disableMessage: undefined,
  label: '',
  mode: 'single',
  modelValue: undefined,
  name: 'multiselect',
  optionKey: 'label',
  placeholder: '',
  rules: '',
  searchKey: undefined,
});
defineEmits<Emits>();

const { errorMessage, meta, value } = useField<number>(props.name, props.required ? 'required' : '', {
  syncVModel: true,
  validateOnValueUpdate: false,
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.multiselect-custom {
    --ms-border-color: #af9fab;
    --ms-radius: 0.5rem;
    --ms-ring-width: 0px;
    --ms-option-py: 0.25rem;
    --ms-border-color-active: #af9fab;
    --ms-dropdown-border-color: #af9fab;
    --ms-option-color-pointed: #1f2937;
    --ms-option-bg-selected: #ef8c29;
    --ms-option-bg-selected-pointed: #f3a658;
    @apply shadow-md;
}
</style>
