<template>
  <div>
    <Card class="mb-4 p-3">
      {{ $lang('l.organiser.intro') }}
    </Card>

    <div class="mb-4 flex flex-col gap-4 md:flex-row">
      <InformationPriceBox class="md:w-1/2" />
      <InformationContactBox class="md:w-1/2" />
    </div>
    <div>
      <InformationDetailsBox />
      <template v-if="isMediumScreen">
        <InformationDetailsTable
          v-for="organiserInformationGroup, index in organiserInformationGroups"

          :key="index"
          :organiser-information-group="organiserInformationGroup"
          :organiser-informations="getInformationfilteredByGroup(organiserInformationGroup.id)" />
      </template>
      <Card
        v-else
        class="flex gap-5 p-3">
        <iconify-icon
          class="my-auto text-2xl text-orange-500"
          icon="fas:triangle-exclamation" />
        {{ $lang('l.viewOnDesktop') }}
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import Card from '../card.vue';
import InformationContactBox from './informationContactBox.vue';
import InformationDetailsBox from './informationDetailsBox.vue';
import InformationDetailsTable from './informationDetailsTable.vue';
import InformationPriceBox from './informationPriceBox.vue';

type Props = {
  organiserInformation: Record<string, any>[];
  organiserInformationGroups: Record<string, any>[];
};

const props = defineProps<Props>();

const isMediumScreen = useMediaQuery('(min-width: 768px)');

function getInformationfilteredByGroup(groupId: number) {
  return props.organiserInformation.filter((information: any) => {
    return information.organiser_information_group_id === groupId;
  });
}

// console.log(getInformationfilteredByGroup(1));
console.log(props.organiserInformation);
</script>
