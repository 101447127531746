<template>
  <!-- ToDo: Übersetzung -->
  <VueCheckbox
    :label="$lang('l.raceEnded')"
    :model-value="modelValue"
    @update:model-value="updateRaceFinish" />
</template>

<script setup lang="ts">
import type { IRace } from '@/interfaces';
import axios from 'axios';
import { type PropType, ref } from 'vue';
import { route } from '../../plugins/route';
import VueCheckbox from '../checkbox.vue';

const props = defineProps({
  race: Object as PropType<IRace>,
});

const modelValue = ref(props.race.is_finished);

async function updateRaceFinish(toggle: boolean) {
  try {
    const response = await axios.patch(route('webapi.races.patch', props.race), { is_finished: toggle });
    modelValue.value = response.data.is_finished;
  }
  catch (error) {}
}
</script>
