<template>
  <div>
    <div
      v-if="isWaiting"
      class="flex items-center justify-center">
      <div
        class="flex h-16 w-16 items-center justify-center rounded-full bg-blue-600 text-sm font-bold text-gray-900">
        {{ timeRemaining }}
      </div>
    </div>
    <slot v-if="!isWaiting" />
  </div>
</template>

<script setup lang="ts">
type Props = {
  timeToWait: number;
};

const props = defineProps<Props>();

const isWaiting = ref(true);
const timeRemaining = ref(0);

function waiting() {
  setTimeout(() => {
    isWaiting.value = false;
  }, props.timeToWait);
  setInterval(() => timeRemaining.value--, 1000);
}

onBeforeMount(() => {
  timeRemaining.value = props.timeToWait / 1000;
});

onMounted(() => {
  waiting();
});
</script>
