import dayjs from 'dayjs';

export default function parseInput(value: string | number, type: string, event?: string, max?: number): any {
  if (type === 'year') return handleYear(value, event, max);
  return value;
}
/**
 * On Input Event the Function blocks Not Numeric Inputs and proof that just a Number with 4 Digits can be in the Input field
 * On Blur Event the Function checkfs if the Date is between 1900 and now. If its out that range, it will jump to the actual Year
 * @param value
 * @param event
 * @returns Number with 4 Digits on Input / Valid Year on Blur
 */
function handleYear(value: string | number, event?: string, max?: number) {
  const year = typeof value === 'string' ? Number.parseInt(value) : value;
  if (!value) {
    return value;
  }
  if (event === 'input') {
    const validYear = checkNaN(year);
    return checkMaxNumbers(validYear, 4);
  }
  else if (event === 'blur') {
    if (max && year >= max) {
      return max;
    }
    else if (year > dayjs().get('y')) {
      return dayjs().get('y');
    }
    else if (year < dayjs().get('y') - 120) {
      return dayjs().get('y') - 120;
    }
    return year;
  }
}

/**
 * Check the Number if its a valid Number.
 * If the Input should be a String with "NaN", the function returns an Empty String
 * If the Input is NaN it removes the last Char which should return a Number.

 * @param number
 * @returns
 */
function checkNaN(number: number) {
  if (isNaN(number)) {
    const string = number.toString();
    if (string === 'NaN') {
      return;
    }
    return Number.parseInt(string.slice(0, -1));
  }
  return number;
}

/**
 * The Function removes the last Digit if the Number is higher than max Digits.
 *
 * @param number
 * @param maxDigits Number of the Digits the Number should max be.
 * @returns a Number between 0 and a Number of Nines with max Digits
 */
function checkMaxNumbers(number: number, maxDigits: number) {
  maxDigits = Number.parseInt('9'.repeat(maxDigits));
  if (number > maxDigits) {
    return Math.floor(number / 10);
  }
  return number;
}
