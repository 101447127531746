<template>
  <div class="hidden">
    <slot :marker="marker" />
  </div>
</template>

<script setup lang="ts">
type Props = {
  icon?: string;
  map: google.maps.Map;
  options?: google.maps.marker.AdvancedMarkerElementOptions;
};

type Emits = {
  click: [value: unknown];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const marker = ref<google.maps.marker.AdvancedMarkerElement>();

onMounted(async () => {
  await initMarker();
});

async function initMarker() {
  const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;

  const img = props.icon ? createIcon(props.icon) : undefined;

  marker.value = new AdvancedMarkerElement({
    content: img,
    map: toRaw(props.map),
    position: props.options ? props.options.position : props.map.getCenter(),
    ...props.options,
  });

  marker.value.addListener('click', (event: unknown) => {
    emit('click', event);
  });
}

function createIcon(icon: string) {
  const img = document.createElement('img');
  img.src = icon;
  img.height = 45;
  img.width = 30;
  return img;
}
</script>
