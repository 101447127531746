<template>
  <select
    v-model="selectedLanguage"
    class="rounded-l-xl rounded-t-xl bg-white pl-4 pr-8 text-black"
    @change="changeLang">
    <option
      v-for="(props, local) in supportedLocals"
      :key="local"
      :value="props.url">
      {{ props.native }}
    </option>
  </select>
</template>

<script setup lang="ts">
import { type PropType, ref, type Ref } from 'vue';

const props = defineProps({
  activeUrl: { required: false, type: String },
  localizedUrls: { type: Array as PropType<{ url: string; slag: string }[]> },
  supportedLocals: { required: true, type: Object as PropType<ILocale> },
});

interface ILocale {
  [key: string];
  name: string;
  native: string;
  regional: string;
  url: string;
}

const selectedLanguage: Ref<string> = ref(props.activeUrl);

function changeLang() {
  const url = props.localizedUrls.find(obj => obj.slag === selectedLanguage.value)?.url;
  window.open(url, '_self');
}
</script>
