<template>
  <button
    class="relative inline-grid h-10 items-center justify-center rounded-md px-4 text-white disabled:opacity-60"
    :class="_color"
    :disabled="loading || disabled"
    :type="type">
    <div v-if="loading" class="absolute left-0 top-0 flex h-full w-full">
      <iconify-icon class="fa-spin m-auto" icon="fas:spinner-third" />
    </div>
    <div :class="[{ 'opacity-0': loading }, { 'gap-2': icon && label && !noMobileLabel }, { 'md:gap-2': icon && label && noMobileLabel }, { flex: icon }]">
      <iconify-icon v-if="icon" class="my-auto" :icon="icon" />
      <div :class="{ 'hidden md:block': noMobileLabel }">
        {{ translatedLabel }}
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { lang } from '@/plugins';
import chalk from 'chalk';

type Props = {
  color?: 'info' | 'negative' | 'positive' | 'primary' | 'secondary';
  disabled?: boolean;
  icon?: string;
  label?: string;
  loading?: boolean;
  noMobileLabel?: boolean;
  type?: HTMLButtonElement['type'];
};
const {
  color = 'primary',
  disabled,
  icon,
  label,
  type = 'button',
} = defineProps<Props>();

const translatedLabel = computed(() => {
  if (!label) return;
  if (!label.startsWith('l.')) {
    console.warn(`Label ${chalk.redBright(label)} is plain or already translated. Change to key with l-prefix, cause the label will automatically translated.`);
    return label;
  }
  const translated = lang(label);
  if (translated.startsWith('l.')) {
    console.warn(`Translation not found: ${chalk.redBright(label)}`);
  }
  return translated;
});

const colorMap = new Map([
  ['info', 'bg-sky-500 enabled:hover:bg-sky-500/90'],
  ['negative', 'bg-red-500 enabled:hover:bg-red-500/90'],
  ['positive', 'bg-green-500 enabled:hover:bg-green-500/90'],
  ['primary', 'bg-blue-700 enabled:hover:bg-blue-700/90'],
  ['secondary', 'bg-gray-200 enabled:hover:bg-gray-200/90'],
]);

const _color = computed(() => colorMap.get(color));

// Console Warnings //
if (!label && !icon) console.warn('SupButton needs at least a label or an icon.');
</script>
