<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-full">
    <div class="rounded-lg bg-white shadow-xl">
      <form
        v-if="edit"
        class="flex flex-col gap-5 px-3 pb-3 pt-6"
        novalidate
        @submit="submit">
        <BaseInput
          :label="$lang('l.name')"
          name="name"
          required />
        <BaseEditor
          :label="$lang('l.description')"
          name="description"
          required />
        <EtaPicker
          :label="$lang('l.eta')"
          name="eta" />
        <ConnectFeatureRequest
          :feature-requests="featureRequests"
          :label="$lang('l.featureProposal')"
          name="feature_requests" />
        <SupSelect
          v-if="user.is_admin && feature"
          label="l.status.default"
          name="status"
          option-key="name"
          :options="selectableStatus" />

        <div class="grid grid-cols-2 gap-3">
          <BaseButton
            color="negative"
            :disabled="isLoading"
            :label="$lang('l.cancel')"
            type="button"
            @click="edit = false" />
          <BaseButton
            :label="feature ? $lang('l.save') : $lang('l.create.default')"
            :loading="isLoading"
            type="submit" />
        </div>
      </form>
      <div v-else-if="feature">
        <div class="pb-3">
          <div class="flex justify-between px-3 pt-3">
            <div class="font-bold">
              {{ feature.name }}
            </div>
            <ButtonGroup
              v-if="user.is_admin"
              :tooltip="$lang('l.actionsDisabledWhileEdit')">
              <BaseButton
                class="my-auto"
                :disabled="isLoading"
                icon="fas fa-pencil"
                variant="plain"
                @click="edit = true" />
              <BaseButton
                class="my-auto"
                color="negative"
                :confirmation="{
                  title: `${$lang('l.feature')} ${$lang('l.delete.confirmation')}`,
                  template: 'delete',
                }"
                icon="fas fa-trash"
                :loading="isLoading"
                variant="plain"
                @click="deleteFeatureRequest(feature)" />
            </ButtonGroup>
          </div>
          <div
            class="my-2 px-3"
            v-html="feature.description" />
          <div
            v-if="feature.feature_requests.length > 0"
            class="border-t border-lightgray-200">
            <div class="mt-2 px-3">
              <div class="mb-1 text-sm font-bold">
                {{ $lang('l.linkedFeatureProposal') }}
              </div>
              <div class="flex flex-col gap-1">
                <a
                  v-for="(item, index) in feature.feature_requests"
                  :key="index"
                  class="my-1 rounded-md bg-lightgray-300 p-1 text-sm hover:scale-102"
                  :href="route('feature.ideas')">
                  {{ item.name }}
                </a>
              </div>
            </div>
          </div>
          <div
            v-if="feature.eta && status !== featureRequestStatuses.Done"
            class="mt-3 px-3 text-right text-xs">
            {{ $lang('l.eta') }}: {{ dayjs(feature.eta).quarter() }}. {{ $lang('l.quarter') }}
            {{ dayjs(feature.eta).format('YY') }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex h-40 w-full cursor-pointer"
        @click="edit = true">
        <i class="fas fa-plus m-auto text-5xl text-green-600" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IFeature, IFeatureApiRequest, IFeatureRequest, IUser } from '@/interfaces';

import { useAxios } from '@vueuse/integrations/useAxios';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useForm } from 'vee-validate';
import { computed, inject, ref, watch } from 'vue';

import { errorHandler } from '../../helpers/api';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import BaseButton from '../base/baseButton.vue';
import BaseEditor from '../base/baseEditor.vue';
import BaseInput from '../base/baseInput.vue';
import ButtonGroup from '../base/buttonGroup.vue';
import SupSelect from '../ui/supSelect.vue';
import ConnectFeatureRequest from './connectFeatureRequest.vue';
import EtaPicker from './etaPicker.vue';

type Props = {
  feature?: IFeature;
  featureRequests?: IFeatureRequest[];
  featureRequestStatuses?: { [key: string]: number };
  status: number;
};

const props = defineProps<Props>();

const emits = defineEmits(['created', 'updated', 'edit', 'deleted', 'onCreate', 'moveLeft', 'moveRight']);

dayjs.extend(quarterOfYear);

const user = inject('user') as IUser;

const edit = ref(false);

const selectableStatus = computed(() => {
  const array = [];
  let name = '';
  for (const [key, value] of Object.entries(props.featureRequestStatuses)) {
    name = key[0].toLowerCase() + key.slice(1);
    array.push({ id: value, name: lang(`l.featureStatus.${name}`) });
  }
  return array;
});

const { handleSubmit, resetForm } = useForm<IFeature>(props.feature ? { initialValues: props.feature } : undefined);

watch(
  () => [edit.value, props.feature],
  () => {
    if (props.feature) {
      const value = {
        ...props.feature,
      };
      props.feature ? resetForm({ values: value }) : resetForm();
    }
    else {
      edit.value ? emits('onCreate', true) : emits('onCreate', false);
    }
  },
);

const { execute, isLoading } = useAxios<IFeature>();
const submit = handleSubmit(async (values) => {
  try {
    if (props.feature) {
      const { id, ...patchData } = formToRequest(values);
      const { data } = await execute(route('webapi.feature.patch', id), {
        data: patchData,
        method: 'PATCH',
      });
      if (data.value) {
        edit.value = false;
        return emits('updated', data.value);
      }
    }
    else {
      const { data } = await execute(route('webapi.feature.post'), {
        data: formToRequest({ ...values, status: props.status }),
        method: 'POST',
      });
      if (data.value) {
        edit.value = false;
        return emits('created', data.value);
      }
    }
  }
  catch (error) {
    errorHandler(error);
  }
});

async function deleteFeatureRequest(feature: IFeature) {
  try {
    await execute(route('webapi.feature.delete', feature.id), {
      method: 'DELETE',
    });
    return emits('deleted', feature);
  }
  catch (error) {
    errorHandler(error);
  }
}

function formToRequest(feature: IFeature): IFeatureApiRequest {
  const { feature_requests, ...data } = feature;
  return {
    ...data,
    eta: data.eta ? data.eta : undefined,
    feature_request_ids: feature_requests ? feature_requests.map(element => element.id) : undefined,
  };
}
</script>
