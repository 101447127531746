/* eslint-disable no-prototype-builtins */
import type { IRule, IRunner, ISlot } from '@/interfaces';

// False if Slot isnt Valid
export function validateSlot(rules: IRule[], teamRules: IRule, member: IRunner, slots: ISlot[]) {
  member = JSON.parse(JSON.stringify(member));
  if (!member) {
    return;
  }
  if (!checkTeamRules(teamRules, member, slots)) {
    return false;
  }
  if (rules.length === 0) {
    return true;
  }

  if (typeof rules === 'object') {
    rules = Object.values(rules);
  }
  if (Array.isArray(rules)) {
    const checks: Array<boolean> = [];
    for (const ruleGroup of rules) {
      checks.push(checkRuleGroup(ruleGroup, member));
    }
    return checks.includes(true);
    return false;
  }
}

function checkTeamRules(teamRules: IRule, member: IRunner, slots: ISlot[]) {
  const checks: Array<boolean> = [];
  if (teamRules.hasOwnProperty('min_year_of_birth')) {
    checks.push(isOverMinAge(teamRules.min_year_of_birth, member));
  }
  if (teamRules.hasOwnProperty('max_year_of_birth')) {
    checks.push(isUnderMaxAge(teamRules.max_year_of_birth, member));
  }
  if (teamRules.hasOwnProperty('min_gender_count')) {
    checks.push(teamHasMinGenderOrSpace(teamRules.min_gender_count, member, slots));
  }
  if (teamRules.hasOwnProperty('max_gender_count')) {
    checks.push(teamHasMaxGender(teamRules.max_gender_count, member, slots));
  }
  if (teamRules.hasOwnProperty('combined_min_age')) {
    checks.push(teamHasMinCombinedAge(teamRules.combined_min_age, member, slots));
  }
  if (teamRules.hasOwnProperty('combined_max_age')) {
    checks.push(teamHasMaxCombinedAge(teamRules.combined_max_age, member, slots));
  }
  if (teamRules.hasOwnProperty('gender')) {
    checks.push(isCorrectGender(teamRules.gender, member));
  }

  return checks.every((state) => {
    return state === true;
  });
  return false;
}

function checkRuleGroup(group: any, member: IRunner) {
  const checks: Array<boolean> = [];
  if (group.hasOwnProperty('gender')) {
    checks.push(checkGender(group.gender, member));
  }
  if (group.hasOwnProperty('min_year_of_birth')) {
    checks.push(isOverMinAge(group.min_year_of_birth, member));
  }
  if (group.hasOwnProperty('max_year_of_birth')) {
    checks.push(isUnderMaxAge(group.max_year_of_birth, member));
  }
  return checks.every((state) => {
    return state === true;
  });
  return false;
}

function checkGender(gender: number, member: IRunner) {
  if (gender === member.gender_id) {
    return true;
  }
  return false;
}

function teamHasMinGenderOrSpace(minGenderCount: Record<string, string>, member: IRunner, slots: ISlot[]) {
  const gender_id = Number.parseInt(Object.keys(minGenderCount)[0]);
  const count = Number.parseInt(Object.values(minGenderCount)[0]);
  if (member.gender_id === gender_id) {
    return true;
  }
  const filterEmptySlot = slots.filter((slot) => {
    return Array.isArray(slot.teamMember);
  });
  if (filterEmptySlot.length > count) {
    return true;
  }
  const filterGenderSlots = slots.filter((slot) => {
    if (!Array.isArray(slot.teamMember)) {
      return slot.teamMember.gender_id === gender_id;
    }
  });
  if (filterGenderSlots.length >= count) {
    return true;
  }
  const filterOtherGenderSlots = slots.filter((slot) => {
    if (!Array.isArray(slot.teamMember)) {
      return slot.teamMember.gender_id !== gender_id;
    }
  });
  if (filterOtherGenderSlots.length < count) {
    return true;
  }
  return false;
}

function teamHasMaxGender(maxGenderCount: Record<string, string>, member: IRunner, slots: ISlot[]) {
  const gender_id = Number.parseInt(Object.keys(maxGenderCount)[0]);
  const count = Number.parseInt(Object.values(maxGenderCount)[0]);
  if (member.gender_id !== gender_id) {
    return true;
  }
  const filterGenderSlots = slots.filter((slot) => {
    return slot.teamMember.gender_id === gender_id;
  });

  if (filterGenderSlots.length < count) {
    return true;
  }
  return false;
}

function teamHasMinCombinedAge(minCombinedAge: number, member: IRunner, slots: ISlot[]) {
  let ageCounter = 0;
  const currentYear = new Date().getFullYear();
  const memberAge = currentYear - member.year_of_birth;
  let emptySlots = 0;
  for (const slot of slots) {
    if (!Array.isArray(slot.teamMember)) {
      const slotMemberAge = currentYear - slot.teamMember.year_of_birth;
      ageCounter += slotMemberAge;
    }
    else {
      emptySlots += 1;
    }
  }
  if (emptySlots === 1 && memberAge + ageCounter < minCombinedAge) {
    return false;
  }
  return true;
}

function teamHasMaxCombinedAge(maxCombinedAge: number, member: IRunner, slots: ISlot[]) {
  let ageCounter = 0;
  const currentYear = new Date().getFullYear();
  const memberAge = currentYear - member.year_of_birth;
  for (const slot of slots) {
    if (!Array.isArray(slot.teamMember)) {
      const slotMemberAge = currentYear - slot.teamMember.year_of_birth;
      ageCounter += slotMemberAge;
    }
  }
  if (memberAge + ageCounter <= maxCombinedAge) {
    return true;
  }
  return false;
}

function isCorrectGender(gender: number, member: IRunner) {
  if (member.gender_id !== gender) {
    return false;
  }
  return true;
}

function isOverMinAge(minYear: number, member: IRunner) {
  if (minYear <= member.year_of_birth) {
    return true;
  }
  return false;
}

function isUnderMaxAge(maxYear: number, member: IRunner) {
  if (maxYear >= member.year_of_birth) {
    return true;
  }
  return false;
}
