<template>
  <div>
    <Card>
      <template #title>
        {{ $lang('l.informationsOlevents') }}
      </template>
      <form
        class="flex flex-col gap-2 p-3"
        novalidate>
        <BaseCheckbox
          :disabled="loading"
          :label="$lang('l.oleventsCanSendMeInformations')"
          name="has_info_mail" />
        <div class="mb-1 mt-3 flex gap-2">
          <span>{{ $lang('l.reminderDeadline') }}</span>
          <InformationButton :text="$lang('l.reminderDeadlineInformation')" />
        </div>
        <BaseCheckbox
          :disabled="loading"
          :label="$lang('l.nationalRacesSwiss')"
          name="has_national_race_reminder" />
        <div class="mb-1 mt-3">
          {{ $lang('l.races.regional') }}
        </div>
        <div class="grid w-60 grid-flow-col grid-rows-4 gap-2">
          <div
            v-for="(region, index) in regions"
            :key="index"
            class="flex gap-2">
            <BaseCheckbox
              :checked-value="region.id"
              :disabled="loading"
              :label="region.name"
              :name="`race_reminder_regions[${index}]`"
              small
              :unchecked-value="null" />
          </div>
        </div>
      </form>
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { IRegion, IUser } from '@/interfaces';

import axios from 'axios';
import { useForm } from 'vee-validate';
import { type PropType, ref, watch } from 'vue';

import { Toast } from '../../helpers/toast';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import BaseCheckbox from '../base/baseCheckbox.vue';
import Card from '../card.vue';
import InformationButton from '../informationButton.vue';

const props = defineProps({
  regions: { default: () => [], type: Array as PropType<IRegion[]> },
  user: { required: true, type: Object as PropType<IUser> },
});

const { controlledValues, handleSubmit, meta, resetForm } = useForm({ initialValues: userToForm(props.user) });

watch(controlledValues, () => {
  if (meta.value.dirty) updatePreferences();
});
const loading = ref(false);
const updatePreferences = handleSubmit.withControlled(async (values) => {
  try {
    loading.value = true;
    const res = await axios.patch<IUser>(route('webapi.user.patch', props.user), formToRequest(values));
    resetForm({ values: userToForm(res.data) });
    loading.value = false;
    Toast.fire({
      icon: 'success',
      title: lang('l.saved'),
    });
  }
  catch (error) {
    console.log(error);
    loading.value = false;
  }
});

function userToForm(user: IUser): IReminderPreferencesForm {
  const { has_info_mail, has_national_race_reminder, race_reminder_regions } = user;
  return {
    has_info_mail,
    has_national_race_reminder,
    race_reminder_regions: parseRegions(race_reminder_regions),
  };
}
function formToRequest(values: IReminderPreferencesForm): Partial<IReminderPreferencesRequest> {
  return {
    ...values,
    race_reminder_regions: values.race_reminder_regions.filter(el => el),
  };
}

function parseRegions(race_reminder_regions: IRegion[]) {
  return props.regions.map((region) => {
    if (race_reminder_regions.find(el => el.id === region.id)) {
      return region.id;
    }
    return null;
  });
}

interface IReminderPreferencesForm {
  has_info_mail: boolean;
  has_national_race_reminder: boolean;
  race_reminder_regions: number[];
}
interface IReminderPreferencesRequest {
  has_info_mail: boolean;
  has_national_race_reminder: boolean;
  race_reminder_regions: number[];
}
</script>
