import { lang } from '@/plugins';
import chalk from 'chalk';

export function getTranslatedLabel(label?: string) {
  if (!label) return;
  if (!label.startsWith('l.')) {
    console.warn(`Label ${chalk.redBright(label)} is plain or already translated. Change to key with l-prefix, cause the label will automatically translated.`);
    return label;
  }
  const translated = lang(label);
  if (translated.startsWith('l.')) {
    console.warn(`Translation not found: ${chalk.redBright(label)}`);
  }
  return translated;
}
