<template>
  <div
    v-if="newSponsor"
    class="grid grid-cols-1 gap-5 p-3 xl:grid-cols-3">
    <Input
      v-model="sponsorRef.name"
      class="my-auto"
      label="Name" />
    <UrlInput
      v-model="sponsorRef.url"
      class="my-auto"
      label="Link" />
    <div class="flex justify-between gap-5">
      <div class="mt-2 grow">
        <Button
          class="button-primary"
          :disabled="isDisabled"
          @click="save">
          {{ $lang('l.save') }}
        </Button>
      </div>
      <div class="mt-2">
        <Button
          class="button-delete"
          :disabled="Boolean(sponsor && sponsor.championships && sponsor.championships.length)"
          :title="tooltipDelete"
          @click="deleteSponsor">
          <i class="fa-solid fa-trash" />
        </Button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full cursor-pointer p-3 text-center"
    @click="newSponsor = true">
    <i
      class="fa-solid fa-plus w-full rounded-md border-2 border-dashed border-green-600 py-2 text-3xl text-green-600" />
  </div>
</template>

<script setup lang="ts">
import type { ISponsor } from '@/interfaces';
import axios from 'axios';
import { computed, type PropType, type Ref, ref } from 'vue';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import Button from '../button.vue';
import Input from '../input.vue';
import UrlInput from './urlInput.vue';

const props = defineProps({
  index: Number,
  sponsor: Object as PropType<ISponsor>,
});
const emits = defineEmits(['afterCreate', 'afterUpdate', 'afterDelete']);

const sponsorRef: Ref<ISponsor> = ref({
  id: props.sponsor ? props.sponsor.id : null,
  championships: [],
  name: props.sponsor ? props.sponsor.name : '',
  url: props.sponsor ? props.sponsor.url : '',
});

const newSponsor = ref(!!props.sponsor);

const isDisabled = computed(() => {
  if (!props.sponsor) {
    if (sponsorRef.value.name && sponsorRef.value.url) {
      return false;
    }
    return true;
  }
  if (sponsorRef.value.name !== props.sponsor.name) {
    return false;
  }
  if (sponsorRef.value.url !== props.sponsor.url) {
    return false;
  }
  return true;
});
async function save() {
  if (sponsorRef.value.id) {
    await updateSponsor();
  }
  else {
    await createSponsor();
  }
}
async function createSponsor() {
  try {
    const res = await axios.post(route('webapi.sponsor.post'), sponsorRef.value);
    emits('afterCreate', res.data);
    newSponsor.value = false;
    sponsorRef.value.id = null;
    sponsorRef.value.name = '';
    sponsorRef.value.url = '';
  }
  catch (error) {}
}
async function updateSponsor() {
  try {
    const res = await axios.patch(route('webapi.sponsor.patch', { id: sponsorRef.value.id }), sponsorRef.value);
    emits('afterUpdate', res.data, props.index);
  }
  catch (error) {}
}

async function deleteSponsor() {
  if (!sponsorRef.value.id) {
    return (newSponsor.value = false);
  }
  try {
    await axios.delete(route('webapi.sponsor.delete', { id: sponsorRef.value.id }));
    emits('afterDelete', props.index);
  }
  catch (error) {}
}

const tooltipDelete = computed(() => {
  if (!props.sponsor) {
    return;
  }
  if (!props.sponsor.championships) {
    return;
  }
  if (!props.sponsor.championships.length) {
    return;
  }
  let string = `${lang('l.connected_with')}: `;

  props.sponsor.championships.forEach((championship, index) => {
    string += championship.name;
    if (index + 1 < props.sponsor.championships.length) {
      string += ' | ';
    }
  });

  return string;
});
</script>
