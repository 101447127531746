<template>
  <div>
    <SupCard class="w-full lg:w-[25rem]" title="l.discount.assign">
      <div class="p-3">
        <form v-if="discounts.length > 0">
          <SupSelect
            :disabled="isLoading || (discounts.length < 2 && isDiscountSelected)"
            label="l.discount"
            name="discount_id"
            no-clear
            :options="discountOptions" />
          <template v-if="isDiscountSelected">
            <SupSelect
              :clear-on-search="true"
              :delay="0"
              :disabled="isLoading"
              :infinite="true"
              label="l.runner.*"
              :limit="30"
              :min-chars="3"
              name="runner_id"
              no-clear
              :options="async (query: string) => {
                return await fetchRunners(query)
              }"
              :placeholder="$lang('l.lastnameMinChars', { min: '3' })"
              :resolve-on-load="false"
              @update:model-value="submit()" />

            <SupSelect
              :disabled="isLoading"
              label="l.clubs"
              name="club_id"
              no-clear
              option-key="name"
              :options="clubs"
              @update:model-value="submit()" />
          </template>
        </form>
        <div v-else>
          {{ $lang('l.discounts.noneCreated') }}
        </div>
      </div>
    </SupCard>
  </div>
</template>

<script setup lang="ts">
import type { IClub, IRaceDiscount, IRaceDiscountAssignment, IRunner } from '@/interfaces';

import SupCard from '@/components/ui/supCard.vue';
import SupSelect from '@/components/ui/supSelect.vue';
import { errorHandler } from '@/helpers';

type Props = {
  clubs: IClub[];
  discountAssignments: IRaceDiscountAssignment[];
  discounts: IRaceDiscount[];
  raceId: number;
};

type Emits = {
  created: [discountAssignment: IRaceDiscountAssignment];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const abortController = ref<AbortController>();

const discountOptions = computed(() => {
  return props.discounts.map((discount) => {
    return {
      ...discount,
      label: discount.amount ? Intl.NumberFormat('de-CH', { currency: 'CHF', style: 'currency' }).format(discount.amount) : `${discount.percentage}%`,
    };
  });
});

async function fetchRunners(query?: string) {
  if (!query) return [];
  try {
    if (abortController.value) {
      abortController.value.abort();
    }
    abortController.value = new AbortController();
    const signal = abortController.value.signal;

    const { data } = await useAxios<IRunner[]>(route('webapi.runners.search', { _query: { lastname: query } }), { signal });
    if (data.value) return filterMapRunners(data.value);
    return [];
  }
  catch {
    return [];
  }
}

function filterMapRunners(runners: IRunner[]) {
  const filteredRunners = runners.filter(runner => !props.discountAssignments.some(discountAssignment => discountAssignment.runner_id === runner.id));
  console.log(filteredRunners);
  return filteredRunners.map((runner) => {
    return {
      ...runner,
      label: `${runner.displayname} - ${runner.year_of_birth}`,
    };
  });
}

type Form = {
  club_id?: number;
  discount_id?: number;
  runner_id?: number;
};

const { handleSubmit, resetField, setFieldValue, values } = useForm<Form>({
  initialValues: { discount_id: props.discounts.length === 1 ? props.discounts[0].id : undefined },
});

const isDiscountSelected = computed(() => {
  if (!values.discount_id) return false;

  if (values.discount_id) return true;
});

const isLoading = ref(false);

const submit = handleSubmit.withControlled(async (values) => {
  try {
    isLoading.value = true;
    const { data } = await useAxios<IRaceDiscountAssignment>(route('webapi.discountAssignment.store', { race: { id: props.raceId } }), {
      data: values,
      method: 'POST',
    });
    if (data.value) {
      emit('created', data.value);
    }
    isLoading.value = false;
  }
  catch (error) {
    isLoading.value = false;
    errorHandler(error);
  }
  finally {
    isLoading.value = false;
    resetField('runner_id');
    resetField('club_id');
    if (props.discounts.length === 1) {
      setFieldValue('discount_id', props.discounts[0].id);
    }
  }
});

watch(() => props.discounts, (values) => {
  if (values.length === 0) {
    setFieldValue('discount_id', undefined);
  }
  else if (values.length === 1) {
    setFieldValue('discount_id', values[0].id);
  }
  else {
    const value = values.at(-1);
    if (value) setFieldValue('discount_id', value.id);
  }
}, { deep: true });
</script>
