<template>
  <div id="map" v-bind="$attrs" />
  <slot :map="map" />
</template>

<script setup lang="ts">
type Props = {
  options?: {
    center?: google.maps.MapOptions['center'];
    mapTypeControl?: google.maps.MapOptions['mapTypeControl'];
    rotateControl?: google.maps.MapOptions['rotateControl'];
    scaleControl?: google.maps.MapOptions['scaleControl'];
    streetViewControl?: google.maps.MapOptions['streetViewControl'];
    zoom?: google.maps.MapOptions['zoom'];
    zoomControl?: google.maps.MapOptions['zoomControl'];
  };
};

type Emits = {
  click: [value: unknown];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

onMounted(async () => {
  await initMap();
});

const map = ref<google.maps.Map>();

async function initMap(): Promise<void> {
  const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;

  map.value = new Map(
    document.querySelector('#map') as HTMLElement,
    { ...props.options, mapId: '65903117d0dc0097' },
  );

  map.value.addListener('click', (event: unknown) => {
    emit('click', event);
  });
}
</script>
