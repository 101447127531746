<template>
  <Card>
    <template #title>
      {{ $lang('l.pageCounts') }}
    </template>
    <div class="p-3">
      <VChart
        autoresize
        class="h-[25rem]"
        :option="option" />
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { IPageCountsStatistic } from '@/interfaces';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { computed, type ComputedRef, type PropType, ref } from 'vue';
import VChart from 'vue-echarts';
import Card from '../../card.vue';

const props = defineProps({
  pageCounts: { required: true, type: Object as PropType<IPageCountsStatistic> },
});
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrBefore);
use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

const fullCounts: ComputedRef<{ dates: string[]; counts: number[] }> = computed(() => {
  return addMissingDates(props.pageCounts);
});

const option = ref({
  series: [
    {
      data: fullCounts.value.counts,
      type: 'bar',
    },
  ],
  tooltip: {
    trigger: 'item',
  },
  xAxis: {
    data: fullCounts.value.dates,
    type: 'category',
  },
  yAxis: {
    minInterval: 1,
    type: 'value',
  },
});

function addMissingDates(pageCounts: IPageCountsStatistic) {
  console.log(pageCounts);
  const diff = dayjs().diff(dayjs(Object.keys(pageCounts)[0]), 'day');
  const dateWithcounts: { dates: string[]; counts: number[] } = { counts: [], dates: [] };
  for (let i = 0; i <= diff; i++) {
    const date = dayjs(Object.keys(pageCounts)[0]).add(i, 'day').format('YYYY-MM-DD');
    const dateIndex = Object.keys(pageCounts).findIndex(el => el === date);
    if (dateIndex === -1) {
      dateWithcounts.dates.push(date);
      dateWithcounts.counts.push(0);
    }
    else {
      dateWithcounts.dates.push(date);
      dateWithcounts.counts.push(Object.values(pageCounts)[dateIndex]);
    }
  }
  console.log(dateWithcounts);
  return dateWithcounts;
}
</script>
