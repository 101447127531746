import { ValidationRule } from './rules/validationRule';
import { ref, type Ref } from 'vue';

export class FieldControl {
  private rules: ValidationRule[] = [];
  private _isValid: Ref<boolean> = ref(true);
  private _errors: Ref<Array<string>> = ref([]);
  private _fieldName: string;
  private _value: any;
  private _isTouched: Ref<boolean>;

  constructor(fieldName: string, isTouchedRef: Ref<boolean>, rules: ValidationRule[] = []) {
    this._isValid.value = true;
    this._fieldName = fieldName;
    this.rules = rules;
    this._isTouched = isTouchedRef;
  }

  public addValidationRule(...validationRules: ValidationRule[]) {
    this.rules.push(...validationRules);
  }

  public setTouched() {
    this._isTouched.value = true;
  }

  public validate(value: any) {
    this._value = value;
    this._isValid.value = true;
    this._errors.value.length = 0;
    for (const rule of this.rules) {
      if (!this.validateRule(this._value, rule)) {
        this._isValid.value = false;
      }
    }
    return this._isValid.value;
  }

  private validateRule(value: any, rule: any) {
    const validation = rule.validate(value);
    if (!validation.isValid) {
      this._errors.value.push(validation.error);
      return false;
    }

    return true;
  }

  public get isValid() {
    return this._isValid;
  }

  public get fieldName() {
    return this._fieldName;
  }

  public get errors() {
    return this._errors;
  }
}
