<template>
  <button
    v-tippy="{
      allowHTML: true,
      arrow: true,
      content: text,
      moveTransition: 'transform 0.2s ease-out',
      placement: 'auto',
      theme: 'light',
      zIndex: 9999,
      hideOnClick: false,
    }"
    v-bind="$attrs"
    class="text-blue-600 hover:text-blue-700"
    @click="show = true">
    <i class="fa-regular fa-square-info" />
  </button>
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  text: { required: true, type: String },
});
const show = ref(false);
</script>
