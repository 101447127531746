<template>
  <a :href="$route('cart.summary', props.transaction)" target="_blank">
    <div class="border-b bg-white px-4 py-2 hover:bg-lightgray-100">
      <BaseListField :label="headers[0]" :value="transaction.receipt_number" />
      <BaseListField :label="headers[1]" :value="transaction.created_at" />
      <BaseListField :label="headers[2]" :value="races" />
      <BaseListField>
        <span class="whitespace-nowrap">
          <b> {{ $lang(headers[3]) }}</b>:
          <span class="rounded-md px-2 py-1 text-sm" :class="stateColor">{{ getTranslatedLabel(`l.transaction_state.${transaction.transaction_state.name}`) }}</span>
        </span>
      </BaseListField>
      <BaseListField :label="headers[4]" :value="amount" />
    </div>

  </a>
</template>

<script setup lang="ts">
import type { IRace, ITransaction } from '@/interfaces';

import { getTranslatedLabel } from '@/helpers';

import BaseListField from '../base/list/baseListField.vue';

type Props = {
  headers: string[];
  transaction: ITransaction & { races: IRace[] };
};
const props = defineProps<Props>();

const races = computed(() => {
  if (!props.transaction.races || props.transaction.races.length === 0) return;
  const races = props.transaction.races.map(race => race.name);
  return races.join(' | ');
});

const amount = computed(() => props.transaction.amount === null ? '-' : `CHF ${props.transaction.amount.toFixed(2)}`);

const stateColor = computed(() => {
  if (!props.transaction.transaction_state) return;
  const state = props.transaction.transaction_state.name;
  if (state === 'confirmed' || state === 'external-payment') return 'bg-lightgray-300';
  if (state === 'created' || state === 'waiting' || state === 'waiting-invoice') return 'bg-red-500 text-white';
});
</script>
