<template>
  <div class="mb-3">
    <div class="border-b px-3">
      <b>{{ title }}</b>
      <button v-if="discountAssignments.length === 0" class="float-right disabled:opacity-50" type="button" @click="$emit('removeDiscount', discount)">
        <iconify-icon class="text-red-500" icon="fas:trash" />
      </button>
    </div>
    <ul v-if="discountAssignments.length > 0">
      <li v-for="discountAssignment, index in discountAssignments" :key="index" class="px-3 py-px odd:bg-lightgray-100">
        {{ discountAssignment.runner ? discountAssignment.runner.displayname : discountAssignment.club.name }}
        <button class="float-right disabled:opacity-50" type="button" @click="$emit('removeDiscountAssignment', discountAssignment)">
          <iconify-icon class="text-red-500" icon="fas:trash" />
        </button>
      </li>
    </ul>
    <div v-else class="px-3">
      {{ $lang('l.discounts.notSet') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRaceDiscount, IRaceDiscountAssignment } from '@/interfaces';

type Props = {
  discount: IRaceDiscount;
  discountAssignments: IRaceDiscountAssignment[];
};
type Emit = {
  removeDiscount: [discount: IRaceDiscount];
  removeDiscountAssignment: [discountAssignment: IRaceDiscountAssignment];
};
const props = defineProps<Props>();
defineEmits<Emit>();

const title = props.discount.amount ? Intl.NumberFormat('de-CH', { currency: 'CHF', style: 'currency' }).format(props.discount.amount) : `${props.discount.percentage}%`;
</script>
