<template>
  <div v-if="showIntro">
    <div class="scrollbar fixed inset-0 z-40">
      <div class="flex min-h-screen justify-center p-2 text-center sm:block sm:p-0">
        <div
          aria-hidden="true"
          class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span
          aria-hidden="true"
          class="hidden sm:inline-block sm:h-screen sm:align-middle">&#8203;</span>

        <div
          aria-labelledby="modal-headline"
          aria-modal="true"
          class="xl:w-8/10 isolate inline-block overflow-hidden rounded-lg bg-white text-center text-sm shadow-xl transition-all sm:my-8 sm:w-10/12 sm:align-middle md:text-base lg:w-9/12 2xl:w-2/3"
          role="dialog">
          <div class="bg-white p-3 sm:p-6 sm:pb-4 xl:p-10">
            <div class="lg:space-y-7">
              <div class="hidden justify-around lg:block">
                <img
                  alt=""
                  class="inline-block w-24 xl:w-48 xl:shadow-xl"
                  src="">
              </div>

              <div class="lg:flex lg:space-x-8">
                <div
                  class="mb-3 flex flex-col space-y-3 border-b border-gray-200 pb-3 lg:mb-0 lg:w-1/2 lg:border-0">
                  <div class="text-base md:text-lg xl:text-3xl">
                    {{ $lang('l.solv.title') }}
                  </div>
                  <div
                    class="mb-3 text-sm xl:text-base"
                    v-html="$lang('l.solv.description')" />

                  <form novalidate @submit="submit">
                    <div class="flex w-full justify-center gap-5">
                      <SupInput class="w-3/4 lg:w-1/2" label="l.solv_number" name="solv_number" required rules="length:6" />
                      <SupButton
                        class="my-auto"
                        :disabled="requestActive"
                        label="l.search"
                        type="submit" />
                    </div>
                  </form>
                </div>
                <div class="space-y-3 lg:w-1/2">
                  <div class="text-base md:text-lg xl:text-3xl">
                    {{ $lang('l.intRunner.title') }}
                  </div>
                  <div
                    class="text-sm xl:text-base"
                    v-html="$lang('l.intRunner.description')" />

                  <VueButton
                    class="button-primary"
                    href="/runners/create">
                    {{ $lang('l.intRunner.create') }}
                  </VueButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swal from 'sweetalert2';
import { errorMessageFromValidationError } from '../helpers/errorMessageFromValidationError';
import { Toast } from '../helpers/toast';
import VueButton from './button.vue';
import SupButton from './ui/supButton.vue';
import SupInput from './ui/supInput.vue';

const showIntro = ref(false);
const requestActive = ref(false);

onMounted(async () => {
  await findAndAddWithEmail();
});

async function findAndAddWithEmail() {
  try {
    const { data } = useAxios(route('webapi.user.addMyRunnerAuto'), { method: 'POST' });
    await Toast.fire({
      icon: 'success',
      title: data.value.data.message,
    });
  }
  catch (error) {
    showIntro.value = true;
  }
}

const { handleSubmit, resetForm } = useForm<{ solv_number: string }>();

const submit = handleSubmit((values) => {
  findAndAddWithSolv(values.solv_number);
});

async function findAndAddWithSolv(solvNumber: string) {
  if (requestActive.value) {
    return;
  }
  requestActive.value = true;
  try {
    const { data } = await useAxios(route('webapi.user.addMyRunnerBySolv'), {
      data: {
        solv_number: solvNumber,
      },
      method: 'POST',
    });
    showIntro.value = false;
    await Toast.fire({
      icon: 'success',
      title: data.value.data.message,
    });
    requestActive.value = false;
  }
  catch (error: any) {
    requestActive.value = false;
    if (error.response.status === 404) {
      resetForm();
      Swal.fire({
        html: error.response.data.message,
        icon: 'error',
      });
      return;
    }

    if (error.response.status === 422) {
      resetForm();
      Swal.fire({
        icon: 'error',
        text: errorMessageFromValidationError(error),
      });
      return;
    }

    if (error.response.status === 409) {
      resetForm();
      Swal.fire({
        html: error.response.data.message,
        icon: 'error',
      }).then(() => {
        showIntro.value = false;
      });
      return;
    }

    resetForm();
    Swal.fire({
      html: error.response.data.message,
      icon: 'error',
    });
  }
}
</script>
