<template>
  <div class="">
    <VueInput
      v-model="request.lastname"
      class="w-full"
      :label="$lang('l.lastname')"
      name="lastname"
      type="text"
      @update:model-value="search" />
    <VueInput
      v-model="request.firstname"
      class="w-full"
      :label="$lang('l.firstname')"
      name="firstname"
      type="text"
      @update:model-value="search" />
  </div>
</template>

<script setup lang="ts">
import type { ISolvRunnerRequest } from '@/interfaces';
import axios from 'axios';
import { type Ref, ref } from 'vue';
import { route } from '../../plugins/route';
import VueInput from '../input.vue';

const emits = defineEmits(['search', 'loading']);
const request: Ref<ISolvRunnerRequest> = ref({
  firstname: null,
  lastname: null,
});

const abortController: Ref<AbortController> = ref();

function search(): void {
  if (!request.value.lastname || request.value.lastname.length < 3) {
    emits('search', []);
    return;
  }

  if (abortController.value) {
    abortController.value.abort();
  }
  querySolv();
}

async function querySolv(): Promise<void> {
  abortController.value = new AbortController();
  const signal = abortController.value.signal;
  emits('loading', true);
  try {
    const result = await axios.post<ISolvRunnerResult[]>(route('user.queryRunner'), request.value, {
      signal,
    });
    emits('search', result.data);
    emits('loading', false);
  }
  catch (error) {
    if (error.message !== 'canceled') {
      console.log(error);
      emits('loading', false);
    }
  }
}
</script>
