<template>
  <div class="my-3">
    <div class="grid">
      <div class="mb-3">
        <b class="my-auto">{{ $lang('l.rules') }}: </b>
        <fieldset
          class="w-full"
          :disabled="!isServerAdmin && !props.category.event_id">
          <table
            v-if="_rules.length"
            class="w-full table-auto border-collapse">
            <thead>
              <tr>
                <th
                  v-for="attr, index in translatedAttrs"
                  :key="index"
                  class="px-2 py-1 font-normal">
                  {{ attr }}
                </th>
              </tr>
            </thead>
            <tbody>
              <TeamRuleTableRow
                v-for="rule, index in _rules"
                :key="index"
                :allowed-attrs="allowedAttrs"
                :index="index"
                :rule="rule"
                @deleted="deletedTeamRule(rule)"
                @updated="updatedTeamRule" />
            </tbody>
          </table>
        </fieldset>

        <div class="mt-5">
          <BaseButton
            :disabled="!isServerAdmin"
            :label="$lang('l.create_rule')"
            @click="createRule" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategory, IRule } from '@/interfaces';
import BaseButton from '@/components/base/baseButton.vue';
import { errorHandler, GendersKey, IsServerAdminKey } from '@/helpers';
import { lang, route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { computed, inject, ref } from 'vue';
import TeamRuleTableRow from './teamRuleTableRow.vue';

type Props = {
  category: ICategory;
  rules: IRule[];
};
type Emit = {
  deleted: [];
  update: [rule: IRule];
};
const props = defineProps<Props>();
defineEmits<Emit>();

const _rules = ref(props.rules ?? []);
const genders = inject(GendersKey);
const isServerAdmin = inject(IsServerAdminKey);
const selectableGenders = genders.map((g) => {
  return {
    id: g.id,
    name: g.name_translated,
  };
});

const allowedAttrs = [
  { name: 'combined_max_age', type: 'number' },
  { name: 'combined_min_age', type: 'number' },
  { name: 'gender_id', options: selectableGenders, type: 'select' },
  { name: 'max_age', type: 'number' },
  { name: 'max_age_amount', type: 'number' },
  { name: 'min_age', type: 'number' },
  { name: 'min_age_amount', type: 'number' },
  { name: 'max_gender_amount', type: 'number' },
  { name: 'min_gender_amount', type: 'number' },
  { name: 'position', type: 'number' },
  { name: 'stand_alone', type: 'boolean' },
];
const translatedAttrs = computed(() => {
  return allowedAttrs.map(attr => lang(`l.team_rules.fields.${attr.name}`));
});

async function createRule() {
  try {
    const { data } = await useAxios(route('webapi.teamRule.post', props.category), {
      method: 'POST',
    });
    _rules.value.push(data.value);
  }
  catch (error) {
    console.log(error);
    errorHandler(error);
  }
}

function updatedTeamRule(rule: IRule) {
  console.log('updated rule', rule);
  const index = _rules.value.findIndex(r => r.id === rule.id);
  _rules.value[index] = rule;
}

function deletedTeamRule(rule: IRule) {
  const newRules = _rules.value.filter(r => r.id !== rule.id);
  _rules.value = newRules;
}
</script>
