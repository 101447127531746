<template>
  <VueSelect
    v-model="modelValue"
    class="mt-2 min-h-16 w-full"
    init-error=""
    :init-value="initValue"
    :label="label"
    :name="name"
    :option-key="optionKey"
    :options="optionsArray"
    :required="required"
    :searchable="searchable"
    :taggable="taggable"
    @option:created="createClub" />
</template>

<script lang="ts" setup>
import type { IClub } from '@/interfaces';
import axios from 'axios';
import _ from 'lodash';
import { type PropType, ref } from 'vue';
import { route } from '../../plugins/route';
import VueSelect from '../select.vue';

const props = defineProps({
  initValue: [Object, String, Number],
  label: String,
  name: String,
  optionKey: String,
  options: Array as PropType<Array<IClub>>,
  required: Boolean,
  searchable: Boolean,
  taggable: Boolean,
});

const optionsArray = ref(props.options);
const modelValue = ref(props.initValue);

async function createClub(club: IClub) {
  try {
    const response = await axios.post<IClub>(route('webapi.clubs.post', club));
    modelValue.value = response.data;
    optionsArray.value.push(response.data);
    optionsArray.value = _.sortBy(optionsArray.value, ['name']);
  }
  catch (error) {
    console.log(error);
  }
}
</script>
