<template>
  <div v-if="classifications.length">
    <div class="font-semibold">
      {{ $lang('l.reminderDeadline') }}
    </div>
    <div
      v-for="(value, index) in classifications"
      :key="index">
      <div
        v-if="isActive[value]"
        class="flex gap-2 pl-5">
        <button
          class="my-auto text-2xl disabled:cursor-not-allowed disabled:opacity-50"
          :disabled="loading"
          type="button"
          @click="updateRaceReminder('remove', value)">
          <i class="fa-solid fa-toggle-on text-green-600" />
        </button>
        <div class="my-auto">
          {{ getLabel(value) }}
        </div>
      </div>
      <div
        v-else
        class="flex gap-2 pl-5">
        <button
          class="my-auto text-2xl disabled:cursor-not-allowed disabled:opacity-50"
          :disabled="loading"
          type="button"
          @click="updateRaceReminder('add', value)">
          <i class="fa-solid fa-toggle-off text-lightgray-500" />
        </button>
        <div class="my-auto">
          {{ getLabel(value) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRace, IUser } from '@/interfaces';
import axios from 'axios';
import { type PropType, ref } from 'vue';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';

const props = defineProps({
  race: { required: true, type: Object as PropType<IRace> },
  user: { required: true, type: Object as PropType<IUser> },
});

const isActive = ref({
  national: props.user.has_national_race_reminder,
  regional: !!props.user.race_reminder_regions.find(el => el.id === props.race.region_id),
});

const loading = ref(false);

const classifications: Classification[] = [];
if (props.race.classification.iof_classification.toLocaleLowerCase() === 'national') classifications.push('national');
if (props.race.region) classifications.push('regional');

function getLabel(classification: Classification) {
  if (classification === 'national') {
    return lang('l.nationalAndChampionship');
  }
  return props.race.region.name;
}

async function updateRaceReminder(action: Action, classification: Classification) {
  try {
    loading.value = true;
    const res = await axios.patch<IUser>(
      route('webapi.user.patch', props.user),
      handleReminderUpdate(action, classification),
    );
    isActive.value.national = res.data.has_national_race_reminder;
    isActive.value.regional = !!res.data.race_reminder_regions.find(el => el.id === props.race.region_id);
    loading.value = false;
  }
  catch (error) {
    loading.value = false;
    console.log(error);
  }
}

function handleReminderUpdate(action: Action, classification: Classification) {
  const raceReminderRegionsIds = props.user.race_reminder_regions.map(el => el.id);
  console.log(raceReminderRegionsIds);
  if (action === 'add') {
    if (classification === 'national') {
      return { has_national_race_reminder: true };
    }
    if (!props.user.race_reminder_regions.find(el => el.id === props.race.region.id)) {
      raceReminderRegionsIds.push(props.race.region.id);
    }
    return { race_reminder_regions: raceReminderRegionsIds };
  }
  if (classification === 'national') {
    return { has_national_race_reminder: false };
  }
  return { race_reminder_regions: raceReminderRegionsIds.filter(el => el !== props.race.region.id) };
}

type Action = 'add' | 'remove';
type Classification = 'national' | 'regional';
</script>
