<template>
  <form class="my-3">
    <div class="grid gap-5 sm:grid-cols-2">
      <div class="mb-3">
        <b class="my-auto">{{ $lang('l.settings') }}: </b>
        <div
          v-if="!disabled"
          class="mt-4 grid gap-5">
          <BaseInput
            :custom-format="formatShortName"
            name="short_name"
            required
            rules="max:12"
            @blur="submit()" />
          <BaseInput
            name="name"
            required
            @blur="submit()" />
          <div class="grid grid-cols-2 gap-5">
            <BaseInput
              :min="0"
              name="export_id"
              type="number"
              @blur="submit()" />
            <BaseInput
              :min="0"
              name="sort_order"
              type="number"
              @blur="submit()" />
          </div>
        </div>
        <div
          v-else>
          <div>{{ $lang('l.name') }}: {{ category.name }}</div>
          <div>{{ $lang('l.export_id') }}: {{ category.export_id }}</div>
          <div>{{ $lang('l.sort_order') }}: {{ category.sort_order }}</div>
        </div>
      </div>
      <div class="mb-3">
        <b class="my-auto">{{ $lang('l.rules') }}: </b>
        <div
          v-if="!disabled"
          class="mt-4 grid gap-5">
          <div class="-mt-2 w-full">
            <BaseMultiselect
              class="w-full"
              clearable
              label="l.gender"
              name="gender_id"
              option-key="name"
              :options="genders"
              @update:model-value="submit()" />
          </div>
          <div class="grid grid-cols-2 gap-5">
            <BaseInput
              :custom-format="formatAge"
              :max="120"
              :min="0"
              name="min_age"
              type="number"
              @blur="submit()" />
            <BaseInput
              :custom-format="formatAge"
              :max="120"
              :min="0"
              name="max_age"
              type="number"
              @blur="submit()" />
          </div>
        </div>
        <div v-else>
          <div>{{ $lang('l.gender') }}: {{ getGenderName() }}</div>
          <div>{{ $lang('l.min_age') }}: {{ category.min_age || '-' }}</div>
          <div>{{ $lang('l.max_age') }}: {{ category.max_age || '-' }}</div>
        </div>
      </div>
    </div>
    <!-- Settings -->
    <div class="mb-3">
      <b class="my-auto">{{ $lang('l.settings') }}: </b>
      <fieldset
        class="flex flex-wrap gap-3"
        :disabled="disabled">
        <ButtonCheckbox
          v-for="setting, index in settings"
          :key="index"
          :label="setting.label"
          :name="setting.name"
          @change="submit()" />
      </fieldset>
    </div>
  </form>
</template>

<script setup lang="ts">
import type { ICategory } from '@/interfaces';
import BaseInput from '@/components/base/baseInput.vue';
import BaseMultiselect from '@/components/base/baseMultiselect.vue';
import { errorHandler, GendersKey } from '@/helpers';
import { lang, route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { inject } from 'vue';
import ButtonCheckbox from './buttonCheckbox.vue';

type Props = {
  category: ICategory;
  disabled?: boolean;
};

type Emits = {
  updated: [category: ICategory];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const genders = inject(GendersKey);

const { handleSubmit } = useForm<ICategory>({ initialValues: props.category });
const { abort, execute, isLoading } = useAxios<ICategory>();

const settings = [
  { label: 'l.category.settings.doping_required', name: 'doping_required' },
  { label: 'l.category.settings.iof_required', name: 'iof_required' },
  { label: 'l.category.settings.solv_required', name: 'solv_required' },
];

const submit = handleSubmit.withControlled(async (values) => {
  try {
    if (isLoading.value) {
      abort();
    }
    const { data } = await execute(route('webapi.category.patch', props.category), {
      data: values,
      method: 'PATCH',
    });
    emit('updated', data.value);
  }
  catch (error) {
    errorHandler(error);
  }
});

function getGenderName() {
  const gender = genders.find(g => g.id === props.category.gender_id);
  if (gender) return lang(`l.${gender.name}`);
  return '-';
}

function formatAge(value: number) {
  if (value > 120) return 120;
  if (value < 0) return 0;
  return value;
}

function formatShortName(value: string) {
  if (value.length > 12) return value.slice(0, 12);
  return value;
}
</script>
