<template>
  <div class="flex flex-col">
    <div
      v-if="!extraDefPermission.extra_def.required"
      class="flex justify-start gap-5">
      <button
        v-if="!extra"
        class="cursor-pointer disabled:cursor-wait disabled:opacity-50"
        :disabled="isLoading"
        type="button"
        @click="addExtra(extraDefPermission.extra_def)">
        <i
          aria-hidden="true"
          class="far fa-plus-circle" />
      </button>
      <button
        v-else
        class="cursor-pointer disabled:cursor-wait disabled:opacity-50"
        :disabled="isLoading"
        type="button"
        @click="deleteExtra">
        <i
          aria-hidden="true"
          class="far fa-minus-circle text-red-500" />
      </button>
      <div class="w-full">
        {{ $lang(`l.extraDef.${extraDefPermission.extra_def.name}.name`) }}
      </div>
    </div>
    <EditExtra
      v-if="extra"
      :extra="extra"
      :extra-def="extraDefPermission.extra_def"
      :race-runner="raceRunner" />
  </div>
</template>

<script setup lang="ts">
import type { IExtra, IExtraDef, IExtraDefPermission, IRaceRunner } from '@/interfaces';
import axios from 'axios';
import { computed, inject, type PropType, ref } from 'vue';
import { route } from '../../plugins/route';
import { useCartStore } from '../../store/cart';
import EditExtra from './editExtra.vue';

const props = defineProps({
  extraDefPermission: { required: true, type: Object as PropType<IExtraDefPermission> },
  raceRunner: { required: true, type: Object as PropType<IRaceRunner> },
});

const raceRunnerIndex = inject<number>('raceRunnerIndex');

const isLoading = ref(false);
const cartStore = useCartStore();
const extra = computed(() => {
  return props.raceRunner.extras.find(extra => extra.extra_def_id === props.extraDefPermission.extra_def.id);
});

async function addExtra(extraDef: IExtraDef) {
  try {
    isLoading.value = true;
    const res = await axios.post<IExtra>(route('webapi.extra.post'), {
      extra_def_id: extraDef.id,
      race_runner_id: props.raceRunner.id,
    });
    const index = props.raceRunner.extras.findIndex(extra => extra.id === res.data.id);
    if (index > -1) {
      cartStore.raceRunners[raceRunnerIndex].extras[index] = res.data;
    }
    else {
      cartStore.raceRunners[raceRunnerIndex].extras.push(res.data);
    }
    await cartStore.fetchRaceRunnerById(props.raceRunner.id);
    isLoading.value = false;
  }
  catch (error) {
    isLoading.value = false;
    throw new Error(error);
  }
}

async function deleteExtra() {
  try {
    isLoading.value = true;
    await axios.delete(route('webapi.extra.delete', extra.value));
    const index = props.raceRunner.extras.findIndex(el => el.id === extra.value.id);
    cartStore.raceRunners[raceRunnerIndex].extras.splice(index, 1);
    await cartStore.fetchRaceRunnerById(props.raceRunner.id);
    isLoading.value = false;
  }
  catch (error) {
    isLoading.value = false;
    throw new Error(error);
  }
}
</script>
