<template>
  <div class="grid grid-cols-1 gap-3 p-3 2xl:grid-cols-2">
    <AccountItem
      v-for="(account, index) in accountList"
      :key="index"
      :account="account"
      :clubs="clubs"
      :countries="countries"
      :genders="genders"
      @removed="removed(index)"
      @updated="updated" />
    <AccountItem
      :clubs="clubs"
      :countries="countries"
      :genders="genders"
      @created="created" />
  </div>
</template>

<script setup lang="ts">
import type { IAccount, IClub, ICountry, IGender } from '@/interfaces';
import { type Ref, ref } from 'vue';
import AccountItem from './accountItem.vue';

type Props = {
  accounts: IAccount[];
  clubs: IClub[];
  countries: ICountry[];
  genders: IGender[];
};

const props = defineProps<Props>();

const accountList: Ref<IAccount[]> = ref(props.accounts);

function created(account: IAccount) {
  accountList.value.push(account);
}
function updated(account: IAccount) {
  const idx = accountList.value.findIndex(el => el.id === account.id);
  accountList.value[idx] = account;
}
function removed(idx: number) {
  accountList.value.splice(idx, 1);
}
</script>
