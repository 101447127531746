<template>
  <Card>
    <template #title>
      {{ $lang('l.categories.default') }}
    </template>
    <div class="p-3">
      <VChart
        autoresize
        class="h-[25rem]"
        :option="option" />
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { ICategoryStatistic } from '@/interfaces';
import { PieChart } from 'echarts/charts';
import { LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { type PropType, ref } from 'vue';
import VChart from 'vue-echarts';
import { lang } from '../../../plugins/lang';
import Card from '../../card.vue';

const props = defineProps({
  categories: { default: () => [], required: true, type: Array as PropType<ICategoryStatistic[]> },
});

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);

const option = ref({
  legend: {
    data: props.categories.map(el => el.label),
    left: 'left',
    orient: 'vertical',
  },
  series: [
    {
      center: ['65%', '45%'],
      data: props.categories.map((el) => {
        return {
          name: el.label,
          value: el.attendees.count,
        };
      }),
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowOffsetX: 0,
        },
      },
      name: lang('l.attendees'),
      radius: '55%',
      type: 'pie',
    },
  ],
  tooltip: {
    formatter: '{a} <br/>{b} : {c} ({d}%)',
    trigger: 'item',
  },
});
</script>
