<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="flex w-full flex-col rounded-md bg-white p-3 shadow-md">
      <div class="flex justify-between">
        <div class="font-bold">
          {{ featureRequest.name }}
        </div>
        <div class="flex flex-col gap-2">
          <ButtonGroup
            v-if="showActionButtons"
            :disabled="disabledWhileEdit"
            :tooltip="$lang('l.actionsDisabledWhileEdit')">
            <BaseButton
              :disabled="isLoading || loadingUpdateStar"
              icon="fas fa-pencil"
              variant="plain"
              @click="$emit('edit', featureRequest)" />
            <BaseButton
              class="my-auto"
              color="negative"
              :confirmation="{
                title: `${$lang('l.proposal')} ${$lang('l.delete.confirmation')}`,
                template: 'delete',
              }"
              :disabled="loadingUpdateStar"
              icon="fas fa-trash"
              :loading="isLoading"
              variant="plain"
              @click="deleteFeatureRequest(featureRequest)" />
          </ButtonGroup>
          <div class="flex items-center gap-2">
            <BaseButton
              class="text-yellow-500"
              :disabled="loadingUpdateStar || user.id === featureRequest.user_id"
              :icon="['fas fa-star', 'far fa-star']"
              :toggle="featureRequest.is_starred"
              variant="plain"
              @update:toggle="updateStar" />
            <div>({{ featureRequest.star_count }})</div>
          </div>
        </div>
      </div>
      <div
        class="mr-16"
        v-html="featureRequest.description" />
      <div
        v-if="featureRequest.features.length > 0"
        class="border-t border-lightgray-200">
        <div class="mt-2 px-3">
          <div class="mb-1 text-sm font-bold">
            {{ $lang('l.linkedFeatureProposal') }}
          </div>
          <div class="flex flex-col gap-1">
            <a
              v-for="(item, index) in featureRequest.features"
              :key="index"
              class="my-1 rounded-md bg-lightgray-300 p-1 text-sm hover:scale-102"
              :href="route('feature.roadmap')">
              {{ item.name }}
            </a>
          </div>
        </div>
      </div>
      <div class="mt-4 flex justify-between text-xs">
        <div
          v-if="user.id === featureRequest.user_id"
          class="font-bold">
          {{ $lang('l.myProposal') }}
        </div>
        <div v-else />
        <div v-html="getStatus(featureRequest.status)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IFeatureRequest, IUser } from '@/interfaces';

import { useAxios } from '@vueuse/integrations/useAxios';
import Swal from 'sweetalert2';
import { computed, inject, type PropType } from 'vue';

import { errorHandler } from '../../helpers/api';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import BaseButton from '../base/baseButton.vue';
import ButtonGroup from '../base/buttonGroup.vue';

const props = defineProps({
  featureRequest: { required: true, type: Object as PropType<IFeatureRequest> },
  featureRequestEdit: { default: undefined, type: Object as PropType<IFeatureRequest> },
  statuses: { default: undefined, type: Object as PropType<{ [key: string]: number }[]> },
  user: { default: undefined, type: Object as PropType<IUser> },
});

const emits = defineEmits(['edit', 'updated', 'deleted']);

const user = inject('user') as IUser;

const { execute, isLoading } = useAxios<IFeatureRequest>();

const { execute: executeUpdateStar, isLoading: loadingUpdateStar } = useAxios<IFeatureRequest>(
  route('webapi.featureRequest.patch', props.featureRequest),
  {
    method: 'PATCH',
  },
  { immediate: false },
);

function getStatus(status: number) {
  let name = '';
  let color = '';
  for (const [key, value] of Object.entries(props.statuses)) {
    // @ts-expect-error: i dont know why
    if (status === value) {
      name = key;
      if (key === 'UnderReview') color = 'text-orange-500';
      if (key === 'Approved') color = 'text-green-600';
      if (key === 'Rejected') color = 'text-red-500';
    }
  }
  name = name[0].toLowerCase() + name.slice(1);
  return (
    `<span>${
      lang('l.status.default')
    }: </span>`
    + `<span class='${color}'>${
      lang(`l.featureStatus.${name}`)
    }</span>`
  );
}

async function deleteFeatureRequest(featureRequest: IFeatureRequest) {
  try {
    await execute(route('webapi.featureRequest.delete', featureRequest.id), {
      method: 'DELETE',
    });
    return emits('deleted', featureRequest);
  }
  catch (error) {
    errorHandler(error);
    Swal.fire({
      html: error.response.data.message,
      icon: 'error',
    });
  }
}

async function updateStar(value: boolean) {
  try {
    const { data } = await executeUpdateStar({ data: { is_starred: value } });
    emits('updated', data.value);
  }
  catch (error) {
    errorHandler(error);
  }
}

const disabledWhileEdit = computed(() => {
  if (!props.featureRequestEdit) return false;
  if (props.featureRequestEdit.id !== props.featureRequest.id) return false;
  return true;
});

const showActionButtons = computed(() => {
  if (user.is_admin) return true;
  if (props.featureRequest.status !== props.statuses.Submitted) return false;
  if (user.id !== props.featureRequest.user_id) return false;
  return true;
});
</script>
