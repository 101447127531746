<template>
  <div class="flex flex-col">
    <div
      v-if="!extraDef.required"
      class="flex justify-start gap-5">
      <button
        v-if="!extra"
        class="cursor-pointer disabled:cursor-wait disabled:opacity-50"
        :disabled="isLoading"
        type="button"
        @click="$emit('createExtra')">
        <i
          aria-hidden="true"
          class="far fa-plus-circle" />
      </button>
      <button
        v-else
        class="cursor-pointer disabled:cursor-wait disabled:opacity-50"
        :disabled="isLoading"
        type="button"
        @click="deleteExtra">
        <i
          aria-hidden="true"
          class="far fa-minus-circle text-red-500" />
      </button>
      <div class="w-full">
        {{ $lang(`l.extraDef.${extraDef.name}.name`) }}
      </div>
    </div>
    <div v-if="extra">
      <TeamExtraField
        v-for="(extraFieldDef, index) in extraDef.extra_field_defs"
        :key="index"
        :disabled="extraDef.name === 'foodChoiceBaselTeamOl' && extraFieldDef.name === 'nothing'"
        :extra-def-name="extraDef.name"
        :extra-field="getExtraField(extraFieldDef.id)"
        :extra-field-def="extraFieldDef"
        :max="max"
        :min="extraDef.name === 'foodChoiceBaselTeamOl' ? 0 : undefined"
        :readonly="readonly"
        @update="updateExtraField" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IExtra, IExtraDef, IExtraField } from '@/interfaces';
import { errorHandler } from '@/helpers';
import { route } from '@/plugins';
import { watchOnce } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';
import { ref, watch } from 'vue';
import TeamExtraField from './teamExtraField.vue';

type Props = {
  extraDef: IExtraDef;
  extra?: IExtra;
  max?: number;
  readonly?: boolean;
};
type Emits = {
  createExtra: [];
  extraDeleted: [];
  updateExtra: [extra: IExtra];

};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const isLoading = ref(false);

function getExtraField(extraFieldDefId: number) {
  if (!props.extra) return;
  return props.extra.extra_fields.find(extraField => extraField.extra_field_def_id === extraFieldDefId);
}

const extraFieldDefNothing = props.extraDef.extra_field_defs.find(el => el.name === 'nothing');

watchOnce(
  () => props.extra,
  () => {
    if (props.extraDef.name === 'foodChoiceBaselTeamOl' && props.extra && props.max > 0) {
      props.extraDef.extra_field_defs.forEach((extraFieldDef) => {
        const extraField = props.extra.extra_fields.find(el => el.extra_field_def_id === extraFieldDef.id);
        if (extraFieldDef.name === 'nothing') {
          updateExtraField(extraFieldDef.id, props.max, true);
        }
        else if (extraField && extraField.value > 0) {
          updateExtraField(extraFieldDef.id, 0, true);
        }
        else if (!extraField) {
          updateExtraField(extraFieldDef.id, 0, true);
        }
      });
    }
  },
);
// Watcher for FoodChoiceBaselTeamOl
watch(
  () => props.max,
  (val) => {
    if (val === 0) {
      props.extraDef.extra_field_defs.forEach((extraFieldDef) => {
        const extraField = props.extra.extra_fields.find(el => el.extra_field_def_id === extraFieldDef.id);
        if (extraField && extraField.value > 0) {
          updateExtraField(extraFieldDef.id, 0, true);
        }
        else if (!extraField) {
          updateExtraField(extraFieldDef.id, 0, true);
        }
      });
    }
    else if (props.extraDef.name === 'foodChoiceBaselTeamOl' && props.extra) {
      let count = 0;
      const extraFieldDefs = props.extraDef.extra_field_defs.filter(
        extraFieldDef => extraFieldDef.name !== 'nothing',
      );
      extraFieldDefs.forEach((extraFieldDef) => {
        const extraField = props.extra.extra_fields.find(el => el.extra_field_def_id === extraFieldDef.id);
        if (extraField) {
          count += extraField.value;
        }
        else {
          updateExtraField(extraFieldDef.id, 0, true);
        }
      });
      updateExtraField(extraFieldDefNothing.id, val > count ? val - count : 0, true);
    }
  },
);

async function deleteExtra() {
  try {
    if (!props.extra) return;
    isLoading.value = true;
    await useAxios<IExtra>(route('webapi.extra.delete', props.extra), { method: 'DELETE' });
    emit('extraDeleted');
    isLoading.value = false;
  }
  catch (error) {
    isLoading.value = false;
    errorHandler(error);
  }
}

async function updateExtraField(extraFieldDefId: number, value: number, withoutSpecial?: boolean) {
  try {
    if (!props.extra) return;
    isLoading.value = true;
    const { data } = await useAxios<IExtraField>(route('webapi.extra.storeField', props.extra.id), {
      data: {
        extra_field_def_id: extraFieldDefId,
        extra_id: props.extra.id,
        value,
      },
      method: 'POST',
    });
    const newExtra = props.extra;
    const extraFieldIndex = newExtra.extra_fields.findIndex(
      extraField => extraField.extra_field_def_id === data.value.extra_field_def_id,
    );
    if (extraFieldIndex < 0) {
      newExtra.extra_fields.push(data.value);
    }
    else {
      newExtra.extra_fields[extraFieldIndex] = data.value;
    }
    // This is only for foodChoiceBaselTeamOl
    if (props.extraDef.name === 'foodChoiceBaselTeamOl' && !withoutSpecial) {
      let currentValue = data.value.value;
      const extraFieldDefs = props.extraDef.extra_field_defs.filter(
        extraFieldDef => extraFieldDef.name !== 'nothing' && extraFieldDef.id !== extraFieldDefId,
      );
      extraFieldDefs.forEach((extraFieldDef) => {
        const extraField = newExtra.extra_fields.find(el => el.extra_field_def_id === extraFieldDef.id);
        if (!extraField) {
          updateExtraField(extraFieldDef.id, 0, true);
        }
        else if (extraField.value <= props.max - currentValue) {
          updateExtraField(extraFieldDef.id, extraField.value, true);
          currentValue += extraField.value;
        }
        else {
          updateExtraField(extraFieldDef.id, props.max - currentValue, true);
          currentValue += props.max - currentValue;
        }
      });
      updateExtraField(extraFieldDefNothing.id, props.max - currentValue, true);
    }
    isLoading.value = false;
  }
  catch (error) {
    isLoading.value = false;
    errorHandler(error);
  }
}
</script>
