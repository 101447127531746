<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-[30rem_1fr]">
    <div>
      <FeatureRequestForm
        v-if="open"
        :feature-request-edit="featureRequestEdit"
        :statuses="statuses"
        :user="user"
        @created="featureRequestList.push($event), (open = false)"
        @reset="(featureRequestEdit = undefined), (open = false)"
        @updated="updated" />
      <div
        v-else
        class="w-full md:w-[30rem]"
        @click="open = true">
        <Card>
          <template #title>
            {{ $lang('l.featureRequest') }}
          </template>
          <div class="flex flex-col gap-3 p-3">
            <div v-html="$lang('l.featureRequestInfo')" />
            <div
              class="flex h-40 w-full cursor-pointer"
              @click="open = true">
              <i class="fas fa-plus m-auto text-5xl text-green-600" />
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="grid-col grid gap-3">
      <FeatureRequestCard
        v-for="(featureRequest, index) in featureRequestList"
        :key="index"
        :feature-request="featureRequest"
        :feature-request-edit="featureRequestEdit"
        :statuses="statuses"
        :user="user"
        @deleted="deleted"
        @edit="edit"
        @updated="updated" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IFeatureRequest, IUser } from '@/interfaces';
import { inject, nextTick, type PropType, ref, type Ref } from 'vue';
import Card from '../card.vue';
import FeatureRequestCard from './featureRequestCard.vue';
import FeatureRequestForm from './featureRequestForm.vue';

const props = defineProps({
  featureRequests: { default: () => [], type: Array as PropType<IFeatureRequest[]> },
  statuses: { default: undefined, type: Object as PropType<{ [key: string]: number }[]> },
});

const open = ref(false);
const user = inject('user') as IUser;

const featureRequestList: Ref<IFeatureRequest[]> = ref(props.featureRequests);

const featureRequestEdit: Ref<IFeatureRequest> = ref();

function edit(feartureRequest: IFeatureRequest) {
  open.value = true;
  nextTick(() => {
    featureRequestEdit.value = feartureRequest;
  });
}
function updated(featureRequest: IFeatureRequest) {
  const index = featureRequestList.value.findIndex(el => el.id === featureRequest.id);
  featureRequestList.value[index] = featureRequest;
  featureRequestEdit.value = undefined;
  open.value = false;
}
function deleted(featureRequest: IFeatureRequest) {
  const index = featureRequestList.value.findIndex(el => el.id === featureRequest.id);
  console.log(featureRequest);
  featureRequestList.value.splice(index, 1);
}
</script>
