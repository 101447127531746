<template>
  <Card>
    <template #title>
      {{ $lang('l.AttendeesList') }}
      <AttendeeCount
        v-if="teams.length"
        :all="teams.length"
        :current="selectedCategory ? selectedCategory.teams.length : 0"
        team />
    </template>
    <div class="flex grow flex-col gap-5 p-3 xl:flex-row xl:gap-10">
      <RaceAttendeeTeamCategoryPicker
        :categories-with-teams="categoryRacesWithTeams"
        @change-category="selectedCategory = $event" />
      <RaceAttendeeTeamCategoryList
        :categories-with-teams="categoryRacesWithTeams"
        class="font-mono"
        :selected-category="selectedCategory" />
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { ICategoryRace, ITeam } from '@/interfaces';
import { computed, ref } from 'vue';
import Card from '../../components/card.vue';
import AttendeeCount from './attendeeCount.vue';
import RaceAttendeeTeamCategoryList from './raceAttendeeTeamCategoryList.vue';
import RaceAttendeeTeamCategoryPicker from './raceAttendeeTeamCategoryPicker.vue';

type Props = {
  categoryRaces: ICategoryRace[];
  teams: ITeam[];
};

const props = defineProps<Props>();

const selectedCategory = ref<ICategoryRace>();

const categoryRacesWithTeams = computed<ICategoryRace[]>(() => {
  return props.categoryRaces.map((catRace) => {
    const teams = props.teams.filter((team) => {
      return team.category_race_id === catRace.id;
    });
    return {
      ...catRace,
      teams,
    };
  });
});
</script>
