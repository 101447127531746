<template>
  <span class="float-right flex h-7 w-7 items-center justify-center rounded-full bg-blue-800 text-sm text-white">{{
    cartStore.fullCart.length
  }}</span>
</template>

<script setup lang="ts">
import { useCartStore } from '../../store/cart';

const cartStore = useCartStore();
</script>
