<template>
  <VueSelect
    class="mt-2 w-60 xl:hidden"
    clearable
    :label="$lang('l.category.default')"
    :model-value="selectedCategoryRace"
    :options="sortedCategoryRacesWithTeams"
    :search-keys="['category.short_name']"
    searchable
    :show-option="
      //@ts-ignore
      (categoriesWithTeams) => categoriesWithTeams.category.short_name
    "
    @update:model-value="changeCategory" />
  <div>
    <div class="hidden flex-col border text-center xl:flex">
      <div
        class="w-16 cursor-pointer p-2 hover:font-bold"
        :class="{ 'font-bold': selectedCategoryRace === null }"
        @click="changeCategory(null)">
        {{ $lang('l.all') }}
      </div>
      <div
        v-for="(category, index) in sortedCategoryRacesWithTeams"
        :key="index"
        class="w-16 cursor-pointer p-2 even:bg-lightgray-200 hover:font-bold"
        :class="{ 'font-bold': setActiveCategory(category) }"
        @click="changeCategory(category)">
        {{ category.category.short_name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace } from '@/interfaces';
import { sortBy } from 'lodash';
import { computed, ref } from 'vue';
import VueSelect from '../select.vue';

type Props = {
  categoriesWithTeams: ICategoryRace[];
};
type Emits = {
  (e: 'changeCategory', category: ICategoryRace): void;
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const selectedCategoryRace = ref<ICategoryRace>(null);

function changeCategory(category: ICategoryRace) {
  selectedCategoryRace.value = category;
  emit('changeCategory', category);
}

function setActiveCategory(categoryRace: ICategoryRace) {
  if (selectedCategoryRace.value !== null) {
    if (selectedCategoryRace.value.id === categoryRace.id) return true;
  }
}

const sortedCategoryRacesWithTeams = computed(() => {
  return sortBy(props.categoriesWithTeams, (category) => {
    return category.category.sort_order;
  });
});
</script>
