import type { TippyOptions } from 'vue-tippy';

export function createTippy(message: string): TippyOptions {
  return {
    allowHTML: true,
    arrow: true,
    content: message,
    moveTransition: 'transform 0.2s ease-out',
    placement: 'auto',
    theme: 'light',
    zIndex: 9999,
  };
}
