<template>
  <Card class="grid rounded-none lg:rounded-lg">
    <form
      class="flex flex-col justify-between gap-5 p-5 lg:flex-row"
      novalidate
      @submit.prevent="submit">
      <b class="my-auto text-xl">{{ $lang('l.discount') }}:</b>
      <div class="flex flex-col gap-5 lg:flex-row">
        <BaseInput
          v-if="!validMessage"
          class="my-auto"
          :disabled="validMessage || isLoading"
          :label="$lang('l.discountCode')"
          name="code"
          required />
        <div class="flex w-full lg:w-auto">
          <BaseButton
            v-if="!validMessage && !errorMessage"
            class="my-auto"
            :label="$lang('l.discountCodeCheck')"
            :loading="isLoading"
            type="submit" />
          <div
            v-if="!validMessage && errorMessage"
            class="my-auto flex gap-2 text-red-500">
            <i class="fas fa-circle-x my-auto" />
            <div class="my-auto">
              {{ errorMessage }}
            </div>
          </div>
          <div
            v-else-if="validMessage && !errorMessage"
            class="my-auto flex gap-2 text-green-500">
            <i class="fas fa-circle-check my-auto" />
            <div class="my-auto">
              {{ validMessage }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </Card>
</template>

<script setup lang="ts">
import type { ITeam } from '@/interfaces';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { lang } from '../../../plugins/lang';
import { route } from '../../../plugins/route';
import BaseButton from '../../base/baseButton.vue';
import BaseInput from '../../base/baseInput.vue';
import Card from '../../card.vue';

type Props = {
  team: ITeam;
};

type Emits = {
  updateHasDiscount: [hasDiscount: boolean];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleSubmit, values } = useForm({
  keepValuesOnUnmount: true,
});

const { execute, isLoading } = useAxios<ITeam>();

const validMessage = ref('');
const errorMessage = ref('');

if (props.team.has_discount) {
  validMessage.value = lang('l.discountCodeActivated');
}

watch(
  () => values.code,
  () => {
    if (!validMessage.value && errorMessage.value) {
      errorMessage.value = '';
    }
  },
);

const submit = handleSubmit(async (values) => {
  errorMessage.value = '';
  try {
    const { data } = await execute(route('discountCode.redeem.team', props.team), {
      data: { ...values, user_id: props.team.user_id },
      method: 'POST',
    });
    validMessage.value = lang('l.discountCodeActivated');
    emit('updateHasDiscount', data.value.has_discount);
  }
  catch (err) {
    errorMessage.value = err.response.data;
  }
});
</script>
