import { FieldControl } from './fieldControl';

export class FormControl {
  private fieldControls: FieldControl[] = [];

  public addFieldControl(fieldControl: FieldControl) {
    this.fieldControls.push(fieldControl);
  }

  public removeFieldControl(fieldControl: FieldControl) {
    const index = this.fieldControls.findIndex((fieldControlInArray) => fieldControlInArray === fieldControl);
    if (!index) {
      return;
    }
    this.fieldControls.splice(index, 1);
  }

  public setFieldsTouched() {
    for (const fieldControl of this.fieldControls) {
      fieldControl.setTouched();
    }
  }

  public get isValid() {
    let isValid = true;
    for (const fieldControl of this.fieldControls) {
      if (!fieldControl.isValid.value) {
        isValid = false;
      }
    }

    return isValid;
  }
}
