<template>
  <GoogleMap
    v-slot="{ map }"
    class="relative h-[600px] w-full overflow-hidden rounded-md"
    :options="mapOptions">
    <GoogleMapCluster
      v-if="map"
      :locations="markers"
      :map="map"
      :min-points="1" />
  </GoogleMap>
</template>

<script setup lang="ts">
import type { IRaceRunner } from '@/interfaces';

import { GoogleMap, GoogleMapCluster } from '@/components/google/maps';

type Props = {
  raceRunners: IRaceRunner[];
};

const props = defineProps<Props>();

const mapOptions = {
  center: { lat: 46.998_544_2, lng: 8.189_931_9 },
  maxZoom: 18,
  rotateControl: false,
  streetViewControl: false,
  zoom: 3,
};

const markers = props.raceRunners.filter(raceRunner => raceRunner.coordinates.latitude && raceRunner.coordinates.longitude)
  .map((raceRunner) => {
    return {
      lat: Number(raceRunner.coordinates.latitude),
      lng: Number(raceRunner.coordinates.longitude),
    };
  });
</script>
