export function handleTouchStart(ev: TouchEvent) {
  ev.target.style.width = `${ev.target.offsetWidth}px`;
  ev.target.style.maxHeight = `${ev.target.offsetHeight}px`;
}

export function handleTouchMove(ev: TouchEvent) {
  ev.preventDefault();
  const location = ev.targetTouches[0]; // Finger Position
  const pageX = `${location.pageX - ev.target.offsetWidth / 2}px`;
  const pageY = `${location.pageY - ev.target.offsetHeight / 2}px`;
  ev.target.style.position = 'absolute';
  ev.target.style.left = pageX;
  ev.target.style.top = pageY;
}

export function handleTouchEnd(ev: TouchEvent, dropzones) {
  const pageX = Number.parseInt(ev.target.style.left) - 50;
  const pageY = Number.parseInt(ev.target.style.top) - 50;
  for (const i in dropzones) {
    if (checkDropzone(ev, dropzones[i])) {
      return i;
    }
  }
  ev.target.style = null;
  return null;
}

function checkDropzone(ev, dropzone: any) {
  if (!dropzone) {
    return false;
  }
  const zoneLeft = dropzone.offsetLeft - 15;
  const zoneTop = dropzone.offsetTop - 15;
  const zoneRight = dropzone.offsetLeft + dropzone.offsetWidth + 15;
  const zoneBot = dropzone.offsetTop + dropzone.offsetHeight + 15;
  if (zoneLeft > ev.target.offsetLeft - ev.target.offsetWith / 2) {
    return false;
  }
  if (zoneTop - ev.target.offsetHeight / 2 > ev.target.offsetTop) {
    return false;
  }
  if (zoneRight < ev.target.offsetLeft + ev.target.offsetWidth - ev.target.offsetWith / 2) {
    return false;
  }
  if (zoneBot < ev.target.offsetTop - ev.target.offsetHeight / 2 + ev.target.offsetHeight) {
    return false;
  }
  return true;
}
