<template>
  <button
    class="flex justify-between border-b px-2 py-0.5 md:p-2 last:border-none"
    :class="[isRunning ? 'bg-lightgray-300' : 'bg-white',
             result.combined_name && !isIndividual ? 'h-24' : 'h-10 md:h-16']"
    type="button">
    <div class="my-auto flex grow justify-start gap-3">
      <div
        class="my-auto w-[22px] text-center text-xl"
        :class="isRunning ? 'text-gray-300' : 'text-orange-500'">
        {{ result.rank > 0 ? result.rank : '-' }}
      </div>
      <div class="flex w-full flex-col">
        <div class="flex justify-between  text-sm md:text-base">
          <div class="flex justify-between gap-3">
            <div v-if="team">
              {{ team.name }}
            </div>
            <div
              v-else
              class="text-start">
              <div>
                {{ result.bib_number }}
                <span v-if="!isIndividual">{{ result.team_name }}</span>
                <span v-if="!result.combined_name && !isIndividual">{{ result.lastname }}, {{ result.firstname }}</span>
                <span v-else-if="isIndividual">{{ result.combined_name ? result.combined_name : `${result.lastname}, ${result.firstname}` }}</span>
              </div>
              <div
                v-if="result.combined_name && !isIndividual"
                class="text-xs">
                {{ result.combined_name }}
              </div>
            </div>
            <div
              v-if="isApprovedMeRunner"
              class="flex">
              <i class="fas fa-person-running my-auto text-orange-500" />
            </div>
          </div>

          <div class="flex justify-end gap-2">
            <div
              v-if="showUpdatedMarker"
              class="my-auto flex">
              <i class="fas fa-circle-small fa-fade my-auto text-sky-500" />
            </div>
            <span>
              {{ finishTimeOrRating }}
            </span>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex gap-3 text-xs">
            <div v-if="team">
              {{ result.bib_number }} {{ result.lastname }}, {{ result.firstname }}
            </div>
            <div v-else>
              S: {{ startTime }}
            </div>

            <template v-if="!hasBadRating">
              <div
                v-for="(controlStationResult, csIndex) in result.radio_control_station_results"
                :key="csIndex">
                {{ `R${csIndex + 1}:` }}
                {{
                  controlStationResult
                    ? formatSecondsAsTime(controlStationResult.split_time)
                    : '--:--'
                }}
              </div>
            </template>
          </div>
          <div
            v-if="shouldDisplayTimeBehind"
            class="text-end text-xs">
            <span v-if="result.time_behind > 0"> + {{ formatSecondsAsTime(result.time_behind) }} </span>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import type { IResult, ITeamResult } from '@/interfaces';
import dayjs from 'dayjs';
import { computed } from 'vue';
import { isFailed } from '../../helpers/rating';
import { formatSecondsAsTime } from '../../helpers/time';

type Props = {
  isIndividual?: boolean;
  team?: ITeamResult;
  raceIsCurrent: boolean;
  result: IResult;
};
const props = defineProps<Props>();

const isApprovedMeRunner = computed(() => {
  if (!window.User || !window.User.approved_runner_ids) return false;
  if (!props.result || !props.result.runner_id) return false;
  return window.User.approved_runner_ids.includes(props.result.runner_id);
});

/**
 * Returns a computed VueJS object that indicates whether the `last_change_for_live_result`
 * property of the `props.result` object was changed in the last 60 seconds.
 *
 * @returns {boolean} `true` if the `last_change_for_live_result` was changed in the last 60 seconds, `false` otherwise.
 */
const showUpdatedMarker = computed(() => {
  return dayjs(props.result.last_change_for_live_result).isAfter(dayjs().subtract(2, 'minute'));
});

const startTime = computed(() => {
  if (props.result.start_date_time) {
    const startDateTime = dayjs(props.result.start_date_time);
    if (startDateTime.hour() !== 0 || startDateTime.minute() !== 0 || startDateTime.second() !== 0) {
      return startDateTime.format('HH:mm:ss');
    }
  }
  return '--:--';
});

const hasBadRating = computed(() => {
  if (!props.result.rating) return false;
  return isFailed(props.result.rating.id);
});

const finishTimeOrRating = computed(() => {
  if (props.result.overall_time && (!props.result.rating || props.result.rating.id === 1 || props.result.rating.id === 15)) {
    return formatSecondsAsTime(props.result.overall_time);
  }
  else if (props.result.finish_time && (!props.result.rating || props.result.rating.id === 1 || props.result.rating.id === 15)) {
    return formatSecondsAsTime(props.result.finish_time);
  }
  else if (hasBadRating.value) {
    return props.result.rating.name_short;
  }
  return null;
});

/**
 * Returns true if the time behind for a result should be displayed.
 * @param result The result to check.
 * @returns Whether or not to display the time behind.
 */
const shouldDisplayTimeBehind = computed(() => {
  // Do not display if the rank is 0.
  if (props.result.rank === 0 || props.result.rank === 1) return false;
  // Do not display for certain rating IDs.
  if (hasBadRating.value) return false;
  if (!props.result.time_behind) return false;
  // Display for all other cases.
  return true;
});

/**
 * Returns false if:
 * - The Race is not Current
 * - The result has a rating with ID 2 (DNS)
 * - The result has a Finish Time
 * - The result has the `is_read_out` property set to true
 */
const isRunning = computed(() => {
  if (!props.raceIsCurrent) {
    return false;
  }
  if (props.result.rating && props.result.rating.id === 2) {
    return false;
  }
  /* if (props.result.finish_time) {
    return false;
  } */
  if (props.result.is_read_out) {
    return false;
  }
  return true;
});
</script>
