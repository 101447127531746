<template>
  <VueCard>
    <template
      v-if="cost.team"
      #title
      class="flex justify-between">
      <div>{{ cost.team.club_name }} {{ cost.team.name }}</div>
      <div>{{ cost.team.race.name }}</div>
    </template>
    <template
      v-else-if="cost.race_runner"
      #title
      class="flex justify-between">
      <div>{{ cost.race_runner.firstname }} {{ cost.race_runner.lastname }}</div>
      <div>{{ cost.race_runner.race.name }}</div>
    </template>
    <template
      v-else-if="cost.extra_field"
      #title
      class="flex justify-between">
      <div>
        {{ $lang(`l.extraDef.${cost.extra_field.extra_field_def.name}.name`) }} -
        {{ $lang(`l.extraDef.${cost.extra_field.extra.extra_def.name}.name`) }}
      </div>
    </template>
    <div class="p-4">
      <div class="flex flex-col gap-4">
        <div v-if="cost.extra_field">
          {{ $lang('l.race.*') }}: {{ cost.extra_field.extra.race_runner.race.name }}
        </div>
        <div v-if="cost.extra_field">
          {{ $lang('l.raceRunner') }}:
          {{ cost.extra_field.extra.race_runner.firstname }}
          {{ cost.extra_field.extra.race_runner.lastname }}
        </div>
        <div>{{ $lang('l.price') }}: {{ cost.price - cost.discount }}</div>
        <div class="flex flex-row gap-4">
          <VueCheckbox
            :label="$lang('l.toRefund')"
            :model-value="refund"
            @update:model-value="toggleRefund" />
        </div>
        <VueInput
          v-model.number="amount"
          :disabled="!refund"
          :label="$lang('l.amount')"
          type="number"
          @update:model-value="patchAmount" />
        <VueSelect
          v-model="newState"
          :disabled="!refund"
          :label="$lang('l.status.new')"
          option-key="name"
          :options="states"
          @update:model-value="patchState" />
      </div>
    </div>
  </VueCard>
</template>

<script setup lang="ts">
import type { ICostToRefund, IRaceRunnerState } from '@/interfaces';

import { computed, type PropType, ref, type Ref } from 'vue';

import VueCard from '../card.vue';
import VueCheckbox from '../checkbox.vue';
import VueInput from '../input.vue';
import VueSelect from '../select.vue';

const props = defineProps({
  cost: Object as PropType<ICost>,
  costsToRefund: Array as PropType<ICostToRefund[]>,
  states: Array as PropType<IRaceRunnerState[]>,
});

const costToRefund: Ref<ICostToRefund> = ref({
  amount: 0,
  cost_id: props.cost.id,
  state_id: null,
});
const amount = ref(0);
const newState: Ref<IRaceRunnerState> = ref(null);
const refund = ref(false);

const costAmount = computed(() => {
  return props.cost.price - props.cost.discount;
});

function toggleRefund() {
  if (refund.value) {
    const index = props.costsToRefund.findIndex(element => element.cost_id === props.cost.id);
    props.costsToRefund.splice(index, 1);
    amount.value = 0;
    costToRefund.value.amount = amount.value;
    newState.value = null;
    costToRefund.value.state_id = null;
    refund.value = false;
    return;
  }
  amount.value = costAmount.value;
  costToRefund.value.amount = amount.value;
  newState.value = props.states.find(state => state.id === 4);
  costToRefund.value.state_id = newState.value.id;
  props.costsToRefund.push(costToRefund.value);
  refund.value = true;
}

function patchAmount() {
  if (typeof amount.value === 'string') {
    costToRefund.value.amount = 0;
    return;
  }
  costToRefund.value.amount = amount.value;
}

function patchState() {
  costToRefund.value.state_id = newState.value.id;
}
</script>
