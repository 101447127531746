<template>
  <BaseInput
    :custom-format="formatValue"
    :label="$lang('l.siCard')"
    :model-value="raceRunner.si_card"
    name="si_card"
    @blur="updateSiNumber" />
</template>

<script setup lang="ts">
import type { IRaceRunner } from '@/interfaces';
import type { PropType } from 'vue';
import { useCartStore } from '../../store/cart';
import BaseInput from '../base/baseInput.vue';

const props = defineProps({
  raceRunner: { required: true, type: Object as PropType<IRaceRunner> },
});

const cartStore = useCartStore();

async function updateSiNumber(si_card: number) {
  await cartStore.raceRunnerUpdate(props.raceRunner.id, { si_card });
}

function formatValue(value: string | number) {
  let number = typeof value === 'string' ? Number.parseInt(value) : value;
  if (!value) {
    return value;
  }
  number = checkNaN(number);
  return checkMaxNumbers(number, 9);
}
/**
 * Check the Number if its a valid Number.
 * If the Input should be a String with "NaN", the function returns an Empty String
 * If the Input is NaN it removes the last Char which should return a Number.

 * @param number
 * @returns
 */
function checkNaN(number: number) {
  if (isNaN(number)) {
    const string = number.toString();
    if (string === 'NaN') {
      return;
    }
    return Number.parseInt(string.slice(0, -1));
  }
  return number;
}

/**
 * The Function removes the last Digit if the Number is higher than max Digits.
 *
 * @param number
 * @param maxDigits Number of the Digits the Number should max be.
 * @returns a Number between 0 and a Number of Nines with max Digits
 */
function checkMaxNumbers(number: number, maxDigits: number) {
  maxDigits = Number.parseInt('9'.repeat(maxDigits));
  if (number > maxDigits) {
    return Math.floor(number / 10);
  }
  return number;
}
</script>
