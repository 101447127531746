import type { ICreateValidationRuleOption } from '@/interfaces';
import { TransLation } from '../../translation';
import * as EmailValidator from 'email-validator';

export class ValidationRule {
  protected constructor(rule: (value: string) => boolean, errorString: string) {
    this.rule = rule;
    this.errorString = errorString;
  }

  private rule: (value: string) => boolean = (value: any): boolean => {
    throw new Error('must be implemented on derived class');
  };

  private errorString = 'l.error';

  private evaluate(value: any): boolean {
    return this.rule(value);
  }

  public validate(value: any): { isValid: boolean; error?: string } {
    const isValid = this.evaluate(value);
    const res = {
      isValid,
      error: !isValid ? this.errorString : undefined
    };
    return res;
  }

  static email(): ValidationRule {
    const rule = (value: any): boolean => {
      return EmailValidator.validate(value);
    };

    const errorString = TransLation.get('validation.below.email');

    return new ValidationRule(rule, errorString);
  }

  static minLength(minLength: number): ValidationRule {
    const rule = (value: any): boolean => {
      return String(value).length >= minLength || String(value).length === 0;
    };

    const errorString = TransLation.get('validation.below.min.string', {
      min: minLength.toString()
    });

    return new ValidationRule(rule, errorString);
  }

  static maxLength(maxLength: number): ValidationRule {
    const rule = (value: any): boolean => {
      return String(value).length <= maxLength;
    };

    const errorString = TransLation.get('validation.below.max.string', {
      max: maxLength.toString()
    });

    return new ValidationRule(rule, errorString);
  }

  static get required() {
    const rule = (value: any): boolean => {
      if (Array.isArray(value)) {
        return !!value.length;
      }

      if (value === undefined || value === null) {
        return false;
      }

      if (value === false) {
        return false;
      }

      if (value instanceof Date) {
        // invalid date won't pass
        return !isNaN(value.getTime());
      }

      if (typeof value === 'object') {
        for (const _ in value) return true;
        return false;
      }

      return !!String(value).length;
    };

    const errorString = TransLation.get('validation.below.required');

    return new ValidationRule(rule, errorString);
  }

  static create(createValidationRuleOption: ICreateValidationRuleOption) {
    switch (createValidationRuleOption.name) {
    case 'min':
      let minLength = createValidationRuleOption.paramters?.[0];

      if (typeof minLength === 'string') {
        minLength = parseInt(minLength, 10);
      }

      if (!minLength || typeof minLength !== 'number') {
        minLength = 1;
      }
      return ValidationRule.minLength(minLength);

    case 'max':
      let maxLength = createValidationRuleOption.paramters?.[0];

      if (typeof maxLength === 'string') {
        maxLength = parseInt(maxLength, 10);
      }

      if (!maxLength || typeof maxLength !== 'number') {
        maxLength = 1;
      }
      return ValidationRule.maxLength(maxLength);

    case 'email':
      return ValidationRule.email();

    default:
      break;
    }
  }
}
