<template>
  <Card>
    <template #title>
      {{ $lang('l.featureRequest') }}
    </template>
    <div class="flex">
      <form
        class="flex w-80 flex-col gap-5 rounded-md bg-white p-5 md:w-[30rem]"
        novalidate
        @reset="reset"
        @submit="submit">
        <SupInput
          label="l.name"
          name="name"
          required />
        <BaseEditor
          :label="$lang('l.description')"
          name="description"
          required />
        <SupSelect
          v-if="featureRequestEdit && user?.is_admin"
          label="l.status.default"
          name="status"
          no-clear
          option-key="name"
          :options="selectableStatus" />
        <div class="grid grid-cols-2 gap-5">
          <BaseButton
            color="negative"
            :disabled="isLoading"
            :label="$lang('l.reset')"
            type="reset" />
          <BaseButton
            :label="props.featureRequestEdit ? $lang('l.update') : $lang('l.submit')"
            :loading="isLoading"
            type="submit" />
        </div>
      </form>
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { IFeatureRequest, IUser } from '@/interfaces';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';

import { computed, type PropType, watch } from 'vue';
import { errorHandler } from '../../helpers/api';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import BaseButton from '../base/baseButton.vue';
import BaseEditor from '../base/baseEditor.vue';
import Card from '../card.vue';
import SupInput from '../ui/supInput.vue';
import SupSelect from '../ui/supSelect.vue';

const props = defineProps({
  featureRequestEdit: { default: undefined, type: Object as PropType<IFeatureRequest> },
  statuses: { default: undefined, type: Object as PropType<{ [key: string]: number }[]> },
  user: { default: undefined, type: Object as PropType<IUser> },
});

const emits = defineEmits(['created', 'updated', 'reset']);

const selectableStatus = computed(() => {
  const array = [];
  let name = '';
  for (const [key, value] of Object.entries(props.statuses ?? [])) {
    name = key[0].toLowerCase() + key.slice(1);
    array.push({ id: value, name: lang(`l.featureStatus.${name}`) });
  }
  return array;
});

const { handleSubmit, resetForm } = useForm<IFeatureRequest>();
const { execute, isLoading } = useAxios<IFeatureRequest>();

const submit = handleSubmit(async (values) => {
  try {
    if (!props.featureRequestEdit) {
      const { data } = await execute(route('webapi.featureRequest.post'), {
        data: { ...values, is_starred: true },
        method: 'POST',
      });
      resetForm();
      return emits('created', data.value);
    }
    const { id, ...patchData } = values;
    const { data } = await execute(route('webapi.featureRequest.patch', id), {
      data: patchData,
      method: 'PATCH',
    });
    resetForm();
    return emits('updated', data.value);
  }
  catch (error) {
    errorHandler(error);
  }
});

watch(
  () => props.featureRequestEdit,
  (values) => {
    values ? resetForm({ values }) : resetForm();
  },
);

function reset() {
  resetForm();
  emits('reset');
}
</script>
