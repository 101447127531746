<template>
  <div class="block rounded-lg bg-white shadow-xl">
    <div class="flex content-center justify-between rounded-t-lg border-b bg-lightgray-300 p-3">
      <div>
        {{ course.name }}
      </div>
      <div class="flex space-x-2">
        <button
          class="text-blue-600 hover:font-bold disabled:cursor-not-allowed disabled:text-gray-100"
          :disabled="!editMode"
          @click="save">
          <i
            aria-hidden="true"
            class="far fa-save" />
        </button>
        <button
          class="text-blue-600 hover:font-bold disabled:cursor-not-allowed disabled:text-gray-100"
          :disabled="editMode"
          @click="editMode = true">
          <i
            aria-hidden="true"
            class="far fa-edit" />
        </button>
        <button
          v-if="course.isDeleteable"
          class="text-red-500 hover:font-bold"
          @click="remove">
          <i
            aria-hidden="true"
            class="far fa-trash" />
        </button>
      </div>
    </div>
    <div class="p-3">
      <div class="border-b pb-2">
        {{ race.name }}
      </div>
      <div class="pt-2">
        <div v-if="editMode">
          <form
            class="mt-5 flex flex-col gap-5"
            novalidate
            @submit="save">
            <BaseInput
              :label="$lang('l.courseName')"
              name="name"
              required />
            <BaseInput
              v-if="race.cloud_o"
              :label="$lang('l.courseCloudOCode')"
              name="cloud_o_code" />
            <BaseInput
              :label="$lang('l.externalResultlist')"
              name="result_list_url" />
            <BaseInput
              :label="$lang('l.maprunUrl')"
              name="map_run_url" />
          </form>
        </div>
        <div v-if="!editMode">
          <div class="flex space-x-2">
            <div class="w-36">
              {{ $lang('l.courseName') }}:
            </div>
            <div>{{ course.name }}</div>
          </div>
          <div
            v-if="race.cloud_o"
            class="flex space-x-2">
            <div class="w-36">
              {{ $lang('l.courseCloudOCode') }}:
            </div>
            <div>{{ course.cloud_o_code }}</div>
          </div>
          <div class="flex space-x-2">
            <div v-if="course.result_list_url">
              <a
                class="w-36 text-blue-600 hover:text-blue-800"
                :href="course.result_list_url"
                rel="noopener noreferrer"
                target="_blank">
                <i
                  aria-hidden="true"
                  class="far fa-external-link-alt mr-1" />
                {{ $lang('l.externalResultlist') }}
              </a>
            </div>
            <div
              v-else
              class="w-36">
              {{ $lang('l.externalResultlist') }}
            </div>
          </div>
          <div
            v-if="race.cloud_o"
            class="flex space-x-2">
            <div>
              <div v-if="course.map_run_url">
                <a
                  class="text-blue-600 hover:text-blue-800"
                  :href="course.map_run_url"
                  rel="noopener noreferrer"
                  target="_blank">
                  <i
                    aria-hidden="true"
                    class="far fa-external-link-alt mr-1" />
                  {{ $lang('l.maprunUrl') }}
                </a>
              </div>
              <div v-else>
                {{ $lang('l.maprunUrl') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICourse, IRace } from '@/interfaces';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useForm } from 'vee-validate';
import { type PropType, ref, watch } from 'vue';
import { Toast } from '../../helpers/toast';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import BaseInput from '../base/baseInput.vue';

const props = defineProps({
  course: { required: true, type: Object as PropType<ICourse> },
  race: { required: true, type: Object as PropType<IRace> },
});

const emits = defineEmits(['remove', 'updated']);
const editMode = ref(false);

const { handleSubmit, resetForm } = useForm<ICourseForm>({
  initialValues: {
    cloud_o_code: props.course.cloud_o_code,
    map_run_url: props.course.map_run_url,
    name: props.course.name,
    result_list_url: props.course.result_list_url,
  },
});
watch(editMode, () => {
  if (editMode.value) {
    resetForm({
      values: {
        cloud_o_code: props.course.cloud_o_code,
        map_run_url: props.course.map_run_url,
        name: props.course.name,
        result_list_url: props.course.result_list_url,
      },
    });
  }
});

const save = handleSubmit(async (values) => {
  editMode.value = false;
  try {
    const res = await axios.patch(
      route('webapi.course.update', { course: props.course.id, race: props.race.id }),
      values,
    );
    Toast.fire({ icon: 'success', title: lang('l.saved') });
    emits('updated', res.data);
  }
  catch (error) {
    Toast.fire({ icon: 'error', text: error.response.data.message, title: lang('l.errorTitle') });
  }
});

async function remove() {
  try {
    const swal = await Swal.fire({
      cancelButtonText: lang('l.cancel'),
      confirmButtonText: lang('l.yes'),
      icon: 'warning',
      showCancelButton: true,
      title: lang('l.courseDeleteQuestion'),
    });
    if (swal.isConfirmed) {
      await axios.delete(route('webapi.course.destroy', { course: props.course.id, race: props.race.id }));
      Toast.fire({ icon: 'success', title: lang('l.courseDeleteSuccess') });
      emits('remove', props.course);
    }
    else {
      Toast.fire({ icon: 'info', title: lang('l.courseDeleteCancel') });
    }
  }
  catch (error) {
    Toast.fire({ icon: 'error', text: error.response.data.message, title: lang('l.errorTitle') });
  }
}

interface ICourseForm {
  cloud_o_code?: string;
  map_run_url?: string;
  name: string;
  result_list_url?: string;
}
</script>
