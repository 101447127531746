import type { RawEditorOptions } from 'tinymce';

export const editorSettings: RawEditorOptions = {
  branding: false,
  content_style:
        'h6 {font-size:18px; margin-block-start:0em;margin-block-end:0em} '
        + 'p {margin-block-start:0em}'
        + 'ul {margin-block-start: 0em}',
  default_link_target: '_blank',
  menubar: false,
  min_height: 300,
  plugins: ['autoresize', 'lists', 'advlist', 'link'],
  toolbar:
        'undo redo | h6 | bold italic | link unlink | alignleft aligncenter alignright alignjustify |bullist numlist | cut copy | removeformat',
};
