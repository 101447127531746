<template>
  <div class="border-t px-2">
    <div class="flex h-9 w-full grow gap-5">
      <button
        class="flex grow gap-3"
        type="button"
        @click="open = !open">
        <div
          v-if="!open"
          class="my-auto flex">
          <i class="fas fa-plus my-auto" />
        </div>
        <div
          v-else
          class="my-auto flex">
          <i class="fas fa-minus my-auto" />
        </div>
        <div class="my-auto grow text-start">
          {{ category.short_name }}
        </div>
      </button>
      <button
        class="flex h-9 w-9 text-red-500 hover:enabled:text-red-400 disabled:cursor-not-allowed disabled:opacity-50"
        :disabled="!categoryRace.is_detachable || isLoading"
        type="button"
        @click="deleteCategoryRace">
        <i class="fas fa-trash m-auto" />
      </button>
    </div>
    <div
      v-if="open"
      class="pb-3">
      <TeamCategoryUpdateForm
        :category="category"
        :disabled="category.event_id || isServerAdmin ? false : true"
        @updated="$emit('categoryUpdated', $event)" />
      <TeamRules
        :category="category"
        :rules="props.category.team_rules" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategory, ICategoryRace } from '@/interfaces';
import { errorHandler, IsServerAdminKey, SwalConfirmation } from '@/helpers';
import { route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { inject, ref } from 'vue';
import TeamCategoryUpdateForm from './teamCategoryUpdateForm.vue';
import TeamRules from './teamRules.vue';

type Props = {
  category: ICategory;
  categoryRace: ICategoryRace;
};

type Emits = {
  categoryUpdated: [category: ICategory];
  categoryRaceCreated: [categoryRace: ICategoryRace];
  categoryRaceUpdated: [categoryRace: ICategoryRace];
  categoryRaceDeleted: [categoryRace: ICategoryRace];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const isServerAdmin = inject(IsServerAdminKey);
const open = ref(false);

const { execute, isLoading } = useAxios();

async function deleteCategoryRace() {
  const swal = await SwalConfirmation({ template: 'delete', title: 'l.category.delete_assignment' });
  if (swal.isConfirmed) {
    try {
      await execute(route('webapi.categoryRace.delete', props.categoryRace), {
        method: 'DELETE',
      });
      emit('categoryRaceDeleted', props.categoryRace);
    }
    catch (error) {
      errorHandler(error);
    }
  }
}
</script>
