<template>
  <BaseMultiselect
    class="my-4"
    :disable-message="race.is_end_of_mutation ? $lang('l.mutation.endIsReached') : ''"
    :disabled="isLoading || disableSelect || race.is_end_of_mutation"
    label="l.starttime.*"
    :model-value="selectedStartId"
    option-key="time_formated"
    :options="startsOrdered"
    required
    @update:model-value="selectStart" />
</template>

<script setup lang="ts">
import type { IRace, IRaceRunner, IStart } from '@/interfaces';

import { useAxios } from '@vueuse/integrations/useAxios';
import dayjs from 'dayjs';

import { computed, ref } from 'vue';
import { errorHandler } from '../../helpers/api';
import { Toast } from '../../helpers/toast';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import BaseMultiselect from '../base/baseMultiselect.vue';

type Props = {
  asEventAdmin?: boolean;
  race: IRace;
  raceRunner: IRaceRunner;
};
const props = defineProps<Props>();

const disableSelect = ref(false);

const {
  data: starts,
  execute: loadStarts,
  isLoading,
} = useAxios<IStart[]>(route('webapi.starts.getForRaceRunner'), {
  params: { race_runner_id: props.raceRunner.id },
});

const startsOrdered = computed(() => {
  if (!starts.value) return [];
  return starts.value
    .filter(start => start.race_runner_id === props.raceRunner.id || (!start.race_runner_id && !start.reserved))
    .sort((a, b) => dayjs(a.time).unix() - dayjs(b.time).unix());
});
const selectedStartId = computed(() => {
  const start = startsOrdered.value.find(start => start.race_runner_id === props.raceRunner.id);
  if (start) return start.id;
  return null;
});

async function takeStart(startId: number) {
  try {
    await useAxios<IStart>(
      route('webapi.starts.selectStart', {
        raceRunner: props.raceRunner.id,
        start: startId,
      }),
      { method: 'POST' },
    );
    Toast.fire({
      icon: 'success',
      title: lang('l.startTimeSelected'),
    });
  }
  catch (error) {
    errorHandler(error);
    await loadStarts();
  }
}

async function selectStart(startId: number) {
  disableSelect.value = true;
  await takeStart(startId);
  disableSelect.value = false;
}

defineExpose({
  loadStarts,
  selectedStartId,
});
</script>
