<template>
  <div
    class="flex flex-row"
    :class="small ? 'gap-x-2' : 'gap-x-4'">
    <button
      class="my-auto cursor-pointer text-center disabled:cursor-not-allowed disabled:opacity-50"
      :disabled="disabled"
      type="button"
      @click="checkedValue ? handleChange(checkedValue) : (value = !value)">
      <div
        class="border shadow-md"
        :class="small ? 'h-5 w-5 rounded-md' : 'h-7 w-7 rounded-lg'">
        <div
          v-if="checked"
          class="flex h-full items-center justify-center">
          <i
            aria-hidden="true"
            class="far fa-check my-auto text-green-600"
            :class="small ? '' : 'text-xl'" />
        </div>
      </div>
    </button>
    <div
      class="my-auto"
      :class="{ 'text-sm': small }">
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef } from 'vue';

const props = defineProps({
  checkedValue: { default: undefined, type: [String, Number, Object] },
  disabled: { default: false, type: Boolean },
  initValue: { default: undefined, type: String },
  label: { default: '', type: String },
  name: { required: true, type: String },
  required: { default: false, type: Boolean },
  small: { default: false, type: Boolean },
  type: { default: 'text', type: String },
  uncheckedValue: { default: undefined, type: [String, Number, Object] },
});

const { checked, handleChange, value } = useField(toRef(props, 'name'), undefined, {
  checkedValue: props.checkedValue ? props.checkedValue : true,
  type: 'checkbox',
  uncheckedValue: props.uncheckedValue ? props.checkedValue : false,
});
</script>
