<template>
  <GoogleMap
    v-if="isCoordinateValid()"
    v-slot="{ map }"
    class="h-80 w-full rounded-lg bg-white shadow-xl"
    :options="mapOptions">
    <GoogleMapMarker v-if="map" :map="map" />
  </GoogleMap>
</template>

<script setup lang="ts">
import { GoogleMap, GoogleMapMarker } from '../google/maps';

type Props = {
  lat: number;
  lng: number;
};
const props = defineProps<Props>();

const mapOptions = {
  center: { lat: props.lat, lng: props.lng },
  mapTypeControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  zoom: 15,
  zoomControl: false,
};

const isCoordinateValid = () => !(props.lat < -85 || props.lat > 85 || props.lng < -180 || props.lng > 180);
</script>
