<template>
  <div
    v-if="team"
    class="flex flex-col items-start gap-2">
    <div v-if="!onlyCartButton" class="min-h-8 align-middle">
      {{ lang('l.status.default') }}:
      <VueSpinner
        v-if="isLoading"
        class="inline" />
      <span v-else>{{ lang(`l.team_state.${team.state.name}`) }}</span>
    </div>
    <div class="flex w-full flex-col gap-4">
      <div v-if="!onlyCartButton">
        <a
          class="button-primary block px-4 py-2 text-center"
          :href="route('team.edit', team)">
          <i
            aria-hidden="true"
            class="far fa-edit mr-2" />
          <span>{{ lang('l.editTeam') }}</span>
        </a>
      </div>

      <div v-if="team.state_id === 8">
        <button
          class="button-primary px-4 py-2"
          @click="clickAddRemoveButton">
          <i
            aria-hidden="true"
            class="fa-solid fa-cart-shopping mr-2" />
          <span>{{ lang('l.putInCart') }}</span>
        </button>
      </div>
      <div v-else-if="team.state_id === 1">
        <a
          class="button-secondary block px-4 py-2 text-center"
          :href="route('cart.index')">
          <i
            aria-hidden="true"
            class="fa-solid fa-cart-shopping mr-2" />
          <span>{{ lang('l.openCart') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ITeam } from '@/interfaces';
import { lang } from '@/plugins';
import { Toast } from '../../helpers/toast';
import VueSpinner from '../spinner.vue';

type Props = {
  initTeam: ITeam;
  onlyCartButton?: boolean;
};
const props = defineProps<Props>();

const isLoading = ref(false);
const team = ref<ITeam>(null);

async function addToCart() {
  try {
    await useAxios<ITeam>(route('webapi.team.addToCart', { team: team.value }), { method: 'POST' });
    await Toast.fire(lang('l.signedUp'));
    team.value.state_id = 1;
  }
  catch (error) {

  }
}

async function clickAddRemoveButton() {
  if (isLoading.value) return;
  isLoading.value = true;
  try {
    if (team.value.state_id === 8) {
      await addToCart();
      return;
    }

    await removeFromCart();
  }
  finally {
    isLoading.value = false;
  }
}

onBeforeMount(() => {
  team.value = props.initTeam;
});

async function removeFromCart() {
  try {
    useAxios<ITeam>(route('webapi.team.removeFromCart', { team: team.value }), { method: 'DELETE' });
    await Toast.fire(lang('l.signedOff'));
    team.value.state_id = 8;
  }
  catch (error) {

  }
}
</script>
