<template>
  <div class="space-y-7 p-3 pb-6">
    <SignupRunner
      v-for="runner, index in runners"
      :key="index"
      :is-event-admin="isEventAdmin"
      :race-runners="getRaceRunners(runner.id)"
      :races="races"
      :runner="runner"
      :user="user" />
  </div>
</template>

<script setup lang="ts">
import type { IRace, IRaceRunner, IRunner, IUser } from '@/interfaces';

import SignupRunner from './signupRunner.vue';

type Props = {
  isEventAdmin?: boolean;
  raceRunners: IRaceRunner[];
  races: IRace[];
  runners: IRunner[];
  user: IUser;
};
const props = defineProps<Props>();

const getRaceRunners = (runnerId: number) => props.raceRunners.filter(raceRunner => raceRunner.runner_id === runnerId);
</script>
