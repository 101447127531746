<template>
  <div class="relative">
    <BaseLabel :label="getLabel()" />
    <input
      v-model="value"
      v-bind="$attrs"
      class="box-border block w-full rounded-lg border  border-gray-200 px-4 py-2 text-gray-900 shadow-md  disabled:cursor-not-allowed disabled:opacity-50"
      :class="required ? 'border-solid' : 'border-dashed'"
      :disabled="disabled"
      :max="max"
      :min="min"
      :name="name"
      onkeydown="return type === 'number' ? event.keyCode !== 69 : true"
      :placeholder="placeholder"
      :type="type"
      @blur="onBlur"
      @input="onInput">
    <ErrorMessage
      :disabled="disabled"
      :message="errorMessage"
      :meta="meta" />
  </div>
</template>

<script setup lang="ts">
import chalk from 'chalk';
import { useField } from 'vee-validate';
import { computed } from 'vue';

import parseInput from '../../helpers/form/inputParser';
import BaseLabel from './baseLabel.vue';
import ErrorMessage from './errorMessage.vue';

type Properties = {
  customFormat?: (value: number | string) => number | string;
  disabled?: boolean;
  label?: string;
  max?: number;
  min?: number;
  modelValue?: number | string;
  name: string;
  noLabel?: boolean;
  placeholder?: string;
  required?: boolean;
  rules?: string;
  type?: string;
};

const properties = withDefaults(defineProps<Properties>(), {
  customFormat: undefined,
  label: undefined,
  max: undefined,
  min: undefined,
  modelValue: undefined,
  placeholder: '',
  rules: '',
  type: 'text',
});

const emits = defineEmits<Emits>();

type Emits = {
  'blur': [value: number | string];
  'update:modelValue': [value: number | string];
};

function getLabel() {
  if (properties.label && properties.noLabel) {
    console.warn(`You use ${chalk.redBright('label')} and ${chalk.redBright('no-label')} prop together, which is not intended. The Prop ${chalk.redBright('no-label')} get Priority, so no label will show. Please remove one of them.`);
    return '';
  }
  if (properties.noLabel) return '';
  if (properties.label) return properties.label;
  return `l.${properties.name}`;
}

const rules = computed(() => {
  let string_ = '';
  if (properties.required) string_ += 'required';
  if (properties.rules) {
    string_ += '|';
    string_ += properties.rules;
  }
  return string_;
});

const { errorMessage, meta, value } = useField<number | string>(() => properties.name, rules, {
  syncVModel: true,
});

function onInput() {
  value.value = parseInput(value.value, properties.type, 'input', properties.max);
  if (properties.customFormat) {
    value.value = properties.customFormat(value.value);
  }
}
function onBlur() {
  value.value = parseInput(value.value, properties.type, 'blur', properties.max);
  emits('blur', value.value);
}
</script>
