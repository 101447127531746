<template>
  <form
    class="p-3"
    novalidate>
    <fieldset :disabled="disabled || isLoading">
      <slot />
      <div v-if="resetLabel || submitLabel" class="mt-5 flex justify-end gap-3">
        <SupButton v-if="resetLabel" color="negative" :label="resetLabel" type="reset" />
        <SupButton v-if="submitLabel" :label="submitLabel" :loading="isLoading" type="submit" />
      </div>
    </fieldset>
  </form>
</template>

<script setup lang="ts" generic="T extends Record<string, any>">
import SupButton from './supButton.vue';

type Props = {
  disabled?: boolean;
  errorFunction?: (error: unknown) => void;
  keepValuesOnUnmount?: boolean;
  noSubmitReset?: boolean;
  resetLabel?: string;
  submitLabel?: string;
};
const props = defineProps<Props>();

const isLoading = ref(false);

provide('disabled', props.disabled || isLoading.value);
</script>
