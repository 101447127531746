<template>
  <div>
    <!-- Button -->
    <button
      class="flex items-center px-3 text-white"
      type="button"
      @click="toggleMenu">
      <iconify-icon class="text-xl" icon="fas:bars" />
    </button>

    <!-- Menü -->
    <div v-if="showMenu">
      <div class="scrollbar fixed inset-0 z-50">
        <div class="min-h-screen flex">
          <!-- Needed to make the background dark (Flex above is needed) -->
          <div
            aria-hidden="true"
            class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75" />
          </div>

          <span
            aria-hidden="true"
            class="hidden sm:inline-block sm:h-screen sm:align-middle">&#8203;</span>
          <!-- End of the Background-Magic -->

          <div
            class="absolute left-0 h-screen w-3/4 flex flex-col bg-white md:w-1/3 sm:w-1/2">
            <div class="mt-2">
              <button
                class="ml-auto flex items-center p-3 px-5 text-gray-700"
                type="button"
                @click="toggleMenu">
                <i class="fal fa-times text-3xl" />
              </button>
            </div>
            <div>
              <ul>
                <MobileMenuItem icon="fas:compass" :label="$lang('l.races.default')" :route="route('start')" />
                <MobileMenuItem icon="fas:cloud" :route="route('cloud-o-days.index')">
                  <span class="my-auto">{{ $lang('l.cloudO') }} {{ $lang('l.events') }}</span>
                </MobileMenuItem>
                <MobileMenuItem icon="fas:brackets-square" :label="$lang('l.fixFinder')" :route="route('fix-finder.index')" />
                <MobileMenuItem icon="fas:square-poll-horizontal" :label="$lang('l.results')" :route="route('results.index', dayjs().year())" />
                <MobileMenuItem icon="fas:trophy" :label="$lang('l.championships')" :route="route('championships.index')" />
                <MobileMenuItem icon="fas:square-question" :label="$lang('l.help')" route="https://support.ol-events.ch/hc/" />
                <MobileMenuItem icon="fas:circle-info" :label="$lang('l.informations.default')" :route="route('informations.index')" />
                <MobileMenuItem icon="fas:right-to-bracket" :label="$lang('l.login')" :route="route('login')" />
                <MobileMenuItem icon="fas:id-card" :label="$lang('l.registerNow')" :route="route('register')" />
              </ul>
            </div>
            <div class="grow" />
            <div />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { ref } from 'vue';

import MobileMenuItem from './guest/mobileMenuItem.vue';

const showMenu = ref(false);

function toggleMenu() {
  showMenu.value = !showMenu.value;

  const zendeskIcon = document.querySelector('#launcher');

  if (zendeskIcon !== undefined && zendeskIcon !== undefined) {
    zendeskIcon.style.zIndex = '20';
  }
}
</script>
