import type { IRunner, ITeamMember, IValidationMember } from '@/interfaces';

export function parseRunnerToMember(runner: IRunner): ITeamMember {
  return {
    firstname: runner.firstname,
    gender_id: runner.gender_id,
    lastname: runner.lastname,
    nation_id: runner.nation_id,
    runner_id: runner.id,
    year_of_birth: runner.year_of_birth,
  };
}

export function parseToValidationMember(member: IRunner | ITeamMember): IValidationMember {
  return {
    id: member.id,
    gender_id: member.gender_id,
    year_of_birth: member.year_of_birth,
  };
}
