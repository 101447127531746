<template>
  <div />
</template>

<script setup lang="ts">
import Swal, { type SweetAlertOptions } from 'sweetalert2';

type Props = {
  swalOption: SweetAlertOptions;
};

const props = defineProps<Props>();

onMounted(() => {
  Swal.fire(props.swalOption);
});
</script>
