<template>
  <div class="xxl:w-1/2 mb-4 w-full 3xl:max-w-xl">
    <Select
      v-model="startTime"
      :edit-mode="true"
      :label="$lang('l.timing')"
      name="starttime_request_id"
      :options="cartStore.startTimeRequests"
      required
      :show-option="(startTimeRequest:IStarttimeRequest) => $lang(startTimeRequest.name)"
      @update:model-value="updateRaceRunner" />
  </div>
</template>

<script setup lang="ts">
import type { IRaceRunner, IStarttimeRequest } from '@/interfaces';
import { onMounted, type PropType, type Ref, ref, watch } from 'vue';
import { useCartStore } from '../../store/cart';
import Select from '../select.vue';

const props = defineProps({
  raceRunner: { required: true, type: Object as PropType<IRaceRunner> },
});

const cartStore = useCartStore();
const startTime: Ref<IStarttimeRequest | undefined> = ref();

onMounted(() => {
  startTime.value = cartStore.startTimeRequests.find(value => value.id === props.raceRunner.starttime_request_id);
});

watch(
  () => props.raceRunner.starttime_request_id,
  () => {
    startTime.value = cartStore.startTimeRequests.find(
      value => value.id === props.raceRunner.starttime_request_id,
    );
  },
);
function updateRaceRunner() {
  cartStore.raceRunnerUpdate(props.raceRunner.id, {
    parental_starttime_race_runner_id: props.raceRunner.parental_starttime_race_runner_id,
    starttime_request_id: startTime.value ? startTime.value.id : null,
  });
}
</script>
