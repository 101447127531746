<template>
  <div class="w-full">
    <div class="mb-4 ml-2">
      {{ racesTitle }}
    </div>
    <div class="space-y-6">
      <BaseMultiselect
        clearable
        :disabled="raceRunner ? true : false"
        label="l.race.*"
        :model-value="selectedRace ? selectedRace.id : undefined"
        option-key="name"
        :options="races"
        @update:model-value="updateSelectedRace" />
      <SignupForRace
        v-if="selectedRace"
        :is-event-admin="isEventAdmin"
        :race="selectedRace"
        :race-runner="raceRunner"
        :runner="runner"
        same-date-race
        :user="user" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRace, IRaceRunner, IRunner, IUser } from '@/interfaces';

import { ref } from 'vue';

import BaseMultiselect from '../base/baseMultiselect.vue';
import SignupForRace from '../race/signupForRace.vue';

type Props = {
  isEventAdmin?: boolean;
  raceRunner?: IRaceRunner;
  races: IRace[];
  runner: IRunner;
  user: IUser;
};
const props = defineProps<Props>();

const racesTitle = props.races.map(race => race.name).join(' / ');

const selectedRace = ref<IRace>();

const updateSelectedRace = (id: number) => selectedRace.value = props.races.find(r => r.id === id);

if (props.raceRunner) {
  updateSelectedRace(props.raceRunner.race_id);
}
</script>
