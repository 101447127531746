<template>
  <div class="p-3">
    <div class="2xl:flex">
      <div class="xlx:w-1/3 2xl:mr-8">
        <VueSolvSearchForm
          @loading="onLoading"
          @search="onSearch" />
      </div>

      <div
        v-if="loading"
        class="mt-8 flex h-full flex-col justify-center text-4xl">
        <i class="fas fa-circle-notch fa-spin" />
      </div>
      <VueForm
        v-if="!loading"
        id="approveRunnerMail"
        :action="$route('user.verifyRunners')"
        class="block w-full"
        method="POST">
        <div class="grid w-full grid-cols-1 gap-4 break-words sm:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4">
          <VueSolvSearchResult
            v-for="(result, index) in results"
            :key="index"
            :result="result" />
        </div>
        <div
          v-if="results.length"
          class="mt-5">
          <BaseButton
            :label="$lang('l.combine')"
            type="submit" />
        </div>
      </VueForm>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISolvRunnerResult } from '@/interfaces';
import { type Ref, ref } from 'vue';
import BaseButton from '../base/baseButton.vue';
import VueForm from '../form.vue';
import VueSolvSearchForm from './solvSearchForm.vue';
import VueSolvSearchResult from './solvSearchResult.vue';

const results: Ref<ISolvRunnerResult[]> = ref([]);
const loading: Ref<boolean> = ref(false);

function onLoading(state: boolean) {
  loading.value = state;
}

function onSearch(res: ISolvRunnerResult[]) {
  results.value = res;
}
</script>
