<template>
  <component
    :is="link && !isDisabled ? 'a' : 'button'"
    v-tippy="tippy"
    v-bind="$attrs"
    :aria-disabled="isDisabled"
    class="btn flex"
    :class="btnClass"
    :disabled="isDisabled"
    :href="link"
    :type="link && !isDisabled ? undefined : type"
    @click.capture="confirmation">
    <span
      class="btn__content"
      :class="{ 'opacity-0': loading }">
      <span
        v-if="Array.isArray(iconClass) && toggleState !== undefined"
        :class="{ 'mr-2': label }">
        <span v-if="toggleState"> <i :class="iconClass[0]" /></span>
        <span v-else><i :class="iconClass[1]" /></span>
      </span>
      <span
        v-else
        :class="{ 'mr-2': label && icon }">
        <i :class="iconClass" />
      </span>
      <span
        v-if="label"
        class="w-full"
        :class="hideLabelOnMobile && icon ? 'hidden md:block' : ''">
        {{ label }}
      </span>
    </span>
    <span
      v-if="loading"
      class="btn__loader">
      <i
        aria-hidden
        class="fass fa-spinner-third fa-spin m-auto" />
    </span>
  </component>
</template>

<script setup lang="ts">
import type { SwalConfirmationProps } from '@/interfaces';

import { computed, type PropType, ref } from 'vue';
import { type TippyOptions, Tippy as vTippy } from 'vue-tippy';

import { SwalConfirmation } from '../../helpers/swal';

const props = defineProps({
  color: { default: 'primary', type: String as PropType<Color> },
  confirmation: { default: undefined, type: Object as PropType<SwalConfirmationProps> },
  disabled: { default: false, type: Boolean },
  hideLabelOnMobile: { default: false, type: Boolean },
  icon: { default: undefined, type: [String, Array] as PropType<[string, string] | string> },
  label: { default: undefined, type: String },
  link: { default: undefined, type: String },
  loading: { default: false, type: Boolean },
  toggle: { default: undefined, type: Boolean },
  tooltip: { default: undefined, type: String },
  type: { default: 'button', type: String as PropType<Type> },
  variant: { default: 'default', type: String as PropType<Variant> },
});

const emits = defineEmits(['click', 'update:toggle']);

const isDisabled = computed(() => {
  if (props.loading || props.disabled) return true;
  return undefined;
});

const tippy: TippyOptions = {
  allowHTML: true,
  arrow: true,
  content: props.tooltip,
  moveTransition: 'transform 0.2s ease-out',
  placement: 'top',
  theme: 'light',
  zIndex: 9999,
};

const toggleState = ref(props.toggle);

async function confirmation(event: PointerEvent) {
  if (props.confirmation) {
    const swal = await SwalConfirmation(props.confirmation);
    if (swal.isConfirmed) {
      return emits('click', event);
    }
    return;
  }
  if (toggleState.value !== undefined) {
    toggleState.value = !toggleState.value;
    emits('update:toggle', toggleState.value);
  }
  return emits('click', event);
}

const iconClass = computed(() => {
  if (typeof props.icon === 'string') {
    if (toggleState.value !== undefined)
      console.warn('It\'s recommended to give two different Icons for Toggle usage.');
    return props.icon;
  }
  else if (Array.isArray(props.icon)) {
    if (toggleState.value === undefined) {
      console.error('You can\'t use multiple Icons without Toggle. Please deliver two Icon Strings as Array');
      return undefined;
    }
    return props.icon;
  }
  return undefined;
});

const btnClass = computed(() => {
  const classes = [];
  classes.push(`btn--color--${props.color}`, `btn--variant--${props.variant}`);
  if (props.loading) classes.push('btn--loading');
  return classes;
});

type Color = 'negative' | 'positive' | 'primary' | 'secondary';
type Type = 'button' | 'reset' | 'submit';
type Variant = 'block' | 'default' | 'plain';
</script>
