<template>
  <div class="overflow-hidden rounded-lg bg-white shadow-xl sm:block sm:flex-auto">
    <div class="flex justify-between rounded-t-lg border-b bg-lightgray-300 p-3">
      <div class="flex items-center">
        {{ $lang('l.durationWindows') }}
      </div>
      <div class="flex gap-5">
        <InformationButton
          class="text-2xl"
          :text="hasSolv ? $lang('l.editOnOL') : $lang('l.informations.raceDateManagement')" />
        <BaseButton
          class="text-lg"
          :disabled="hasSolv"
          icon="far fa-plus"
          @click="createRaceDateWindow" />
      </div>
    </div>
    <div class="space-y-6 divide-y-2 p-4">
      <VueRaceDate
        v-for="(raceDate, index) in raceDateWindows"
        :key="index"
        :deletable="raceDateWindows.length > 1 ? true : false"
        :disabled="hasSolv"
        :race-date="raceDate"
        @remove="removeRaceDate" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRaceDate } from '@/interfaces';

import axios from 'axios';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { onMounted, ref, type Ref } from 'vue';

import { route } from '../plugins/route';
import BaseButton from './base/baseButton.vue';
import InformationButton from './informationButton.vue';
import VueRaceDate from './raceDate.vue';

const props = defineProps({
  hasSolv: { type: Boolean },
  raceId: { required: true, type: Number },
});

const raceDateWindows: Ref<IRaceDate[]> = ref([]);

onMounted(async () => {
  await getRaceDates(props.raceId);
});

async function getRaceDates(raceId: number) {
  try {
    const result = await axios.get<IRaceDate[]>(route('race.racedate.store', { id: raceId }));
    raceDateWindows.value = result.data;
  }
  catch (error) {
    console.log(error);
  }
}

async function createRaceDateWindow() {
  try {
    const result = await axios.put<IRaceDate>(route('race.racedate.store', { id: props.raceId }), {
      start_date: dayjs().format('YYYY-MM-DD'),
    });
    raceDateWindows.value.push(result.data);
  }
  catch (error) {
    await Swal.fire({
      icon: 'error',
      text: error.response.data.message,
    });
  }
}

function removeRaceDate(deletedRaceDate: IRaceDate) {
  raceDateWindows.value.splice(raceDateWindows.value.indexOf(deletedRaceDate), 1);
}
</script>
