<template>
  <form
    ref="formRef"
    :action="action"
    novalidate
    @submit="submit">
    <div>
      <slot />
    </div>
  </form>
</template>

<script lang="ts">
export default {
  name: 'VueForm',
};
</script>

<script setup lang="ts">
// eslint-disable-next-line import/first
import { ref } from 'vue';
// eslint-disable-next-line import/first
import { useFormControl } from '../composables/form/formControl';

const props = defineProps({
  action: { type: [Function, String] },
});

const formRef = ref(null);

const formId = Math.random()
  .toString(36)
  .replace(/[^a-z]+/g, '')
  .substr(0, 5);

const formControl = useFormControl(formId);

function submit(event: Event) {
  formControl.setFieldsTouched();

  if (!formControl.isValid.value) {
    event.preventDefault();
    return;
  }

  if (props.action && typeof props.action === 'function') {
    props.action(formRef.value);
    event.preventDefault();
  }
}

defineExpose({
  formId,
});
</script>
