<template>
  <div>
    <div class="mb-5 flex justify-between border-b pb-1">
      <b>{{ categoryRace.category?.short_name }}</b>
      <button type="button" @click="sortTeams()">
        <i class="fas fa-rotate" />
      </button>
    </div>
    <div class="flex flex-col gap-8">
      <Team v-for="team in teams" :key="team.id" :team="team" @updated="updateTeam" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, ITeam } from '@/interfaces';

import { sortBy } from 'lodash';

import Team from './team.vue';

type Props = {
  categoryRace: ICategoryRace;
};
const props = defineProps<Props>();

const teams = ref<ITeam[]>([]);

function sortTeams(initalTeam?: ITeam[]) {
  teams.value = sortBy(initalTeam ?? teams.value, ['club_name', 'name']);
}

function updateTeam(team: ITeam) {
  const index = teams.value.findIndex(t => t.id === team.id);
  teams.value[index].name = team.name;
  teams.value[index].club_name = team.club_name;
}

// Init
sortTeams(props.categoryRace.teams);
</script>
