<template>
  <form
    class="p-3"
    novalidate
    @submit="submit">
    <div class="flex flex-col gap-x-5 gap-y-1 divide-y">
      <div class="grid grid-cols-2 gap-x-5 gap-y-1">
        <div class="col-span-2 mb-1 ml-4">
          <i class="fal fa-user" />
        </div>
        <SupInput label="l.firstname" name="firstname" />
        <SupInput label="l.lastname" name="lastname" />
        <SupInput label="l.street" name="street" />
        <SupInput label="l.street2" name="street2" />
        <SupInput label="l.plz" name="plz" />
        <SupInput label="l.city" name="city" />
        <SupInput
          label="l.email_short"
          name="email" />
        <SupSelect
          label="l.gender"
          name="gender_id"
          option-key="name_translated"
          :options="genders" />
        <SupSelect
          label="l.club"
          name="club_id"
          option-key="name"
          :options="clubs"
          required />
        <SupSelect
          label="l.country"
          name="country_id"
          option-key="name"
          :options="countries" />
      </div>
      <div class="grid grid-cols-2 gap-x-5 gap-y-1 pt-5">
        <div class="col-span-2 mb-1 ml-4">
          <i class="fal fa-credit-card" />
        </div>
        <SupInput label="l.account_owner" name="account_owner" />
        <SupInput label="l.account" name="account" />
        <SupInput
          label="l.supsign_account"
          name="supsign_account"
          required />
      </div>
    </div>
    <div class="mt-5 flex justify-end gap-5">
      <BaseButton
        color="negative"
        :label="lang('l.cancel')"
        @click="$emit('close')" />
      <BaseButton
        :disabled="!meta.dirty"
        :label="lang('l.save')"
        type="submit" />
    </div>
  </form>
</template>

<script setup lang="ts">
import type { IAccount, IClub, ICountry, IGender } from '@/interfaces';
import SupInput from '@/components/ui/supInput.vue';
import SupSelect from '@/components/ui/supSelect.vue';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { errorHandler, filterEmpty } from '../../../helpers/api';
import { Toast } from '../../../helpers/toast';
import { lang } from '../../../plugins/lang';
import { route } from '../../../plugins/route';
import BaseButton from '../../base/baseButton.vue';

type Props = {
  account?: IAccount;
  clubs: IClub[];
  countries: ICountry[];
  genders: IGender[];
};
type Emits = {
  (e: 'updated', account: IAccount): void;
  (e: 'created', account: IAccount): void;
  (e: 'close'): void;
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleSubmit, meta, resetForm } = useForm<IAccount>({ initialValues: props.account });

const submit = handleSubmit.withControlled(async (values) => {
  try {
    if (props.account) {
      const { data } = await useAxios<IAccount>(route('webapi.account.patch', { id: props.account.id }), {
        data: values,
        method: 'PATCH',
      });
      emit('updated', data.value);
    }
    else {
      const { data } = await useAxios<IAccount>(route('webapi.account.post'), {
        data: filterEmpty(values),
        method: 'POST',
      });
      emit('created', data.value);
    }
    finishForm();
  }
  catch (error) {
    errorHandler(error);
  }
});

async function finishForm() {
  await Toast.fire({
    icon: 'success',
    title: props.account ? lang('l.updated') : lang('l.saved'),
  });
  resetForm();
}
</script>
