import type { IReason } from '@/interfaces';

export function errorMessageFromValidationError(reason: IReason): string {
  const response = reason.response;
  const messages: string[] = [];
  const errors: { [index: string]: string[] } = response.data.errors;
  for (const [key, message] of Object.entries(errors)) {
    messages.push(...message);
  }

  return messages.join('<br>');
}
