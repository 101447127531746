<template>
  <div
    class="relative mb-16 w-full"
    :class="selectedCategoryRace || selectedRaceResult ? 'flex flex-col' : 'flex flex-col-reverse'">
    <div
      v-if="!selectedCategoryRace"
      class="z-20 grid w-full xl:overflow-hidden xl:rounded-lg xl:shadow-lg">
      <RaceResultsCategory
        v-for="(categoryRace, index) in categoryRaces"
        :key="index"
        :base-url="baseUrl"
        :category-race="categoryRace"
        @select-category-race="loadCategoryRace(categoryRace)" />
    </div>
    <RaceResultsList
      v-else-if="!selectedRaceResult"
      :base-url="baseUrl"
      :category-race="selectedCategoryRace"
      :is-individual="race.form_id === 3"
      :race-is-current="race.is_current && !race.is_finished"
      :selected-leg="selectedLeg"
      @reset-category-race="loadCategoryRaceList"
      @select-leg="selectedLeg = $event"
      @select-race-result="loadResult" />
    <RaceResultDetail
      v-else-if="selectedRaceResult"
      :base-url="baseUrl"
      :radio-control-stations="selectedCategoryRace.control_stations"
      :result="selectedRaceResult"
      :show-split-times-date="race.split_times_public_at"
      :split-times-visible="splitTimesPublic"
      @reset-result-detail="loadCategoryRace" />
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, IRace, IResult } from '@/interfaces';

import { useTimeoutFn } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';
import { ref } from 'vue';

import { errorHandler } from '../../helpers/api';
import { route } from '../../plugins/route';
import RaceResultDetail from './raceResultDetail.vue';
import RaceResultsCategory from './raceResultsCategory.vue';
import RaceResultsList from './raceResultsList.vue';

type Props = {
  race: IRace;
};

const props = defineProps<Props>();

const categoryRaces = ref<ICategoryRace[]>(props.race.category_races);

const splitTimesPublic = ref(props.race.are_split_times_public);

const getBaseUrl = () => `${location.protocol}//${location.host}${location.pathname}`;
const baseUrl = getBaseUrl();

function init() {
  const parameters = new URLSearchParams(document.location.search);
  const cr = Number.parseInt(parameters.get('cr'));
  const r = Number.parseInt(parameters.get('r'));
  selectView({ cr, r });
  if (cr && r) {
    oldEventState.value = { cr, r };
  }
  else if (cr) {
    oldEventState.value = { cr };
  }
  else {
    oldEventState.value = {};
  }
}

const oldEventState = ref<{ cr?: number; r?: number }>();

window.addEventListener('popstate', (event: PopStateEvent) => {
  if (event.state) {
    if (event.state.r && event.state.cr) {
      selectView(event.state);
    }
    else if (event.state.cr) {
      selectView({ cr: event.state.cr });
    }
    else {
      selectView({});
    }
    oldEventState.value = event.state;
  }
  else {
    selectView({});
  }
});

const selectedLeg = ref(1);

const { execute } = useAxios<{ race: IRace }>(route('webapi.results.getByRace', props.race), {}, { immediate: false });

const { start } = useTimeoutFn(
  async () => {
    await updateCategoryRaces();
  },
  10_000,
  { immediate: false },
);

if (props.race.is_current && !props.race.is_finished) {
  start();
}

async function updateCategoryRaces() {
  try {
    const { data } = await execute();
    categoryRaces.value = data.value.race.category_races;
    if (selectedCategoryRace.value) {
      selectedCategoryRace.value = categoryRaces.value.find(
        categoryRace => categoryRace.id === selectedCategoryRace.value?.id,
      );
      splitTimesPublic.value = data.value.race.are_split_times_public;
    }
    start();
  }
  catch (error) {
    errorHandler(error);
  }
  start();
}

const selectedCategoryRace = ref<ICategoryRace | null>();
const selectedRaceResult = ref<IResult | null>();

function selectView({ cr, r }: { cr?: number; r?: number }) {
  if (cr && !r) {
    loadCategoryRace(cr, false);
  }
  else if (cr && r) {
    loadResult(cr, r, false);
  }
  else {
    loadCategoryRaceList(false);
  }
}

function loadCategoryRaceList(setHistory = true) {
  selectedCategoryRace.value = null;
  selectedRaceResult.value = null;
  selectedLeg.value = 1;
  if (setHistory) {
    history.pushState({}, '', baseUrl);
  }
  if (props.race.is_current && !props.race.is_finished) {
    start();
  }
}
/**
 * Setzt das gewählte CategoryRace und passt die URL mit dem Parameter CategoryRaceId an.
 *
 * @param categoryRaceOrId Ein CategoryRace Objekt oder die ID eines CategoryRace
 */

function loadCategoryRace(categoryRaceOrId: ICategoryRace | number, setHistory = true) {
  let categoryRace: ICategoryRace | null = null;
  categoryRace = typeof categoryRaceOrId === 'number' ? categoryRaces.value.find(cr => cr.id === categoryRaceOrId) ?? null : categoryRaceOrId;
  selectedCategoryRace.value = categoryRace;
  selectedRaceResult.value = null;
  if (categoryRace === null) loadCategoryRaceList();
  if (setHistory) {
    history.pushState({ cr: categoryRace.id }, '', `${baseUrl}?cr=${categoryRace.id}`);
  }
}

/**
 *  Setzt das gewählte Result sowie das CategoryRace und passt die URL mit dem Parameter ResultId und CategoryRaceId an.
 *
 * @param categoryRaceOrId Ein CategoryRace Objekt oder die ID eines CategoryRace
 * @param resultOrId Ein Result Objekt oder die ID eines Result
 */
function loadResult(categoryRaceOrId: ICategoryRace | number, resultOrId: IResult | number, setHistory = true): void {
  let categoryRace: ICategoryRace | null = null;
  let result: IResult | null = null;
  categoryRace = typeof categoryRaceOrId === 'number' ? categoryRaces.value.find(cr => cr.id === categoryRaceOrId) ?? null : categoryRaceOrId;
  if (categoryRace === null) {
    return loadCategoryRaceList();
  }
  if (typeof resultOrId === 'number') {
    if (categoryRace.team_results) {
      const allResults = categoryRace.team_results.flatMap(tr => tr.results);
      result = allResults.find(r => r.id === resultOrId) ?? null;
    }
    else {
      result = categoryRace.results.find(r => r.id === resultOrId) ?? null;
    }
  }
  else {
    result = resultOrId;
  }
  if (result === null) {
    return loadCategoryRace(categoryRace);
  }
  selectedCategoryRace.value = categoryRace;
  selectedRaceResult.value = result;

  if (setHistory) {
    history.pushState(
      { cr: categoryRace.id, r: result.id },
      '',
      `${baseUrl}?cr=${categoryRace.id}&r=${result.id}`,
    );
  }
}

/**
 * Wird beim Pageload aufgerufen
 */
init();
</script>

<style>
.left-right-enter-active,
.left-right-leave-active {
    transition: 0.5s;
}

.left-right-enter-from {
    transform: translate(100%, 0);
}
.left-right-leave-to {
    transform: translate(-100%, 0);
}
.right-left-enter-active,
.right-left-leave-active {
    transition: 0.5s;
}

.right-left-enter-from {
    transform: translate(-100%, 0);
}
.right-left-leave-to {
    transform: translate(100%, 0);
}
</style>
