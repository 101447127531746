<template>
  <div class="p-3 pt-6">
    <VueSearchUser
      :event="event"
      @user-res="users = $event" />
    <div class="mt-6 flex flex-col gap-2">
      <div
        v-for="user in users"
        :key="user.id"
        class="flex">
        <a
          class="rounded-md bg-lightgray-200 px-2 py-1"
          :href="$route('events.signupAsAdmin', {
            user: user.id,
            event: event.id,
          })">{{ user.name }} {{ user.firstname }} {{ user.club }}</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IEvent, IUser } from '@/interfaces';
import { type PropType, type Ref, ref } from 'vue';
import VueSearchUser from '../user/searchUser.vue';

defineProps({
  event: { required: true, type: Object as PropType<IEvent> },
});
const users: Ref<IUser[]> = ref([]);
</script>
