import { computed, ref, type Ref } from 'vue';
import axios from 'axios';
import { defineStore } from 'pinia';
import { route } from '../plugins/route';
import type { IClub, IRace, IRaceRunner, IStarttimeRequest, ITeam } from '@/interfaces';

export const useCartStore = defineStore('cart', () => {
  // States
  const clubs: Ref<IClub[]> = ref([]);
  const count = ref(0);
  const raceRunners: Ref<IRaceRunner[]> = ref([]);
  const races: Ref<IRace[]> = ref([]);
  const startTimeRequests: Ref<IStarttimeRequest[]> = ref([]);
  const teams: Ref<ITeam[]> = ref([]);

  const extraFieldInformationAccepted = ref(false);

  const fullCart = computed(() => {
    return [...raceRunners.value, ...teams.value];
  });

  const cartItemCount = computed(() => {
    if (fullCart.value.length) return fullCart.value.length;
    return count;
  });

  async function raceRunnerUpdate(id: number, raceRunner: Partial<IRaceRunner>) {
    try {
      const res = await axios.patch<IRaceRunner>(route('webapi.racerunner.update.cart', id), raceRunner);
      const index = raceRunners.value.findIndex((raceRunner) => raceRunner.id === id);
      raceRunners.value[index] = mapCartItem(res.data) as IRaceRunner;
      const parentalId = raceRunners.value[index].parental_starttime_race_runner_id;
      if (parentalId) {
        console.log('hi', parentalId);
        await fetchRaceRunnerById(parentalId);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchRaceRunnerById(id: number) {
    try {
      const res = await axios.get<IRaceRunner>(route('webapi.raceRunner.get', id));
      const index = raceRunners.value.findIndex((raceRunner) => raceRunner.id === res.data.id);
      raceRunners.value[index] = mapCartItem(res.data) as IRaceRunner;
    } catch (error) {
      throw new Error(error);
    }
  }
  /**
     * Maps Relations to clean RaceRunner Object

     * @param raceRunner without Relations
     * @returns raceRunner with Relations
     */
  function mapCartItem(raceRunner: IRaceRunner | ITeam): IRaceRunner | ITeam {
    const race = races.value.find((race) => race.id === raceRunner.race_id);
    return {
      ...raceRunner,
      category_race: race.category_races.find((categoryRace) => categoryRace.id === raceRunner.category_race_id),
      club: clubs.value.find((club) => club.id === raceRunner.club_id),
      race: race
    };
  }

  function getRaceRunnerById(id: number) {
    const index = raceRunners.value.findIndex((raceRunner) => raceRunner.id === id);
    return raceRunners.value[index];
  }

  return {
    raceRunners,
    cartItemCount,
    count,
    clubs,
    teams,
    races,
    fullCart,
    startTimeRequests,
    extraFieldInformationAccepted,
    raceRunnerUpdate,
    mapCartItem,
    fetchRaceRunnerById,
    getRaceRunnerById
  };
});