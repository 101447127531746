<template>
  <div>
    <SupCard
      class="w-full md:w-60"
      title="l.discount.create">
      <SupForm class="w-full gap-3" @submit.prevent="submit">
        <SupInput label="l.amount" :min="0" name="value" required type="number" />
        <div class="mb-5 flex gap-5">
          <SupRadio label="CHF" name="type" value="chf" />
          <SupRadio label="%" name="type" value="%" />
        </div>
        <SupButton class="w-full" label="l.discount.create" type="submit" />
      </SupForm>
    </SupCard>
  </div>
</template>

<script setup lang="ts">
import type { IRaceDiscount } from '@/interfaces';

import SupButton from '@/components/ui/supButton.vue';
import SupCard from '@/components/ui/supCard.vue';
import SupForm from '@/components/ui/supForm.vue';
import SupInput from '@/components/ui/supInput.vue';
import SupRadio from '@/components/ui/supRadio.vue';
import { errorHandler } from '@/helpers';

type Props = {
  raceId: number;
};

type Emit = {
  created: [discount: IRaceDiscount];
};

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const { execute } = useAxios<IRaceDiscount>();

const { handleSubmit } = useForm<IRaceDiscount>({
  initialValues: {
    type: 'chf',
  },
});

const submit = handleSubmit.withControlled(async (values) => {
  try {
    const { data } = await execute(route('webapi.discount.store', { race: { id: props.raceId } }), {
      data: {
        amount: values.type === 'chf' ? value.value : undefined,
        percentage: values.type === '%' ? value.value : undefined,
      },
      method: 'POST',
    });
    if (data.value) {
      emit('created', data.value);
    }
  }
  catch (error) {
    errorHandler(error);
  }
});
</script>
