<template>
  <div class="input relative mt-2 min-h-[3.75rem] text-left text-gray-700">
    <label>
      <span
        ref="labelRef"
        class="input__label">
        {{ label }}
      </span>
    </label>
    <input
      class="input__field w-full"
      :disabled="disabled"
      :value="modelValue"
      @blur="fixInput(($event.target as HTMLTextAreaElement).value)">
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  disabled: Boolean,
  label: String,
  modelValue: String,
});

const emits = defineEmits(['update:modelValue']);

function fixInput(input: string) {
  input = input.toLowerCase();
  if (input.startsWith('http://') || input.startsWith('https://') || input.startsWith('www.')) {
    emits('update:modelValue', input);
    return;
  }
  input = `http://${input}`;
  emits('update:modelValue', input);
}
</script>
