<template>
  <div v-if="runner">
    <div class="mb-5 mt-8 font-bold">
      {{ runner.firstname }} {{ runner.lastname }}
    </div>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
      <div
        v-for="(raceOrRaces, index) in getSortedRaces()"
        :key="index"
        class="w-full">
        <SignupForRace
          v-if="!Array.isArray(raceOrRaces)"
          :is-event-admin="isEventAdmin"
          :race="raceOrRaces"
          :race-runner="getRaceRunner(raceOrRaces.id)"
          :runner="runner"
          :user="user" />
        <SignupForSameDateRace
          v-else
          :is-event-admin="isEventAdmin"
          :race-runner="getRaceRunnerSameDate(raceOrRaces)"
          :races="raceOrRaces"
          :runner="runner"
          :user="user" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRace, IRaceRunner, IRunner, IUser } from '@/interfaces';

import { groupBy, sortBy } from 'lodash';

import SignupForRace from '../race/signupForRace.vue';
import SignupForSameDateRace from '../race/signupForSameDateRace.vue';

type Props = {
  isEventAdmin?: boolean;
  raceRunners: IRaceRunner[];
  races: IRace[];
  runner: IRunner;
  user: IUser;
};

const props = defineProps<Props>();

function getSortedRaces() {
  const groupedRaces = groupBy(props.races, 'race_dates[0].start_date');
  const races: (IRace | IRace[])[] = [];
  for (const key of sortBy(Object.keys(groupedRaces))) {
    const group = groupedRaces[key];
    races.push(group.length === 1 ? group[0] : group);
  }
  return races;
}

const getRaceRunner = (raceId: number) => props.raceRunners.find(element => element.race_id === raceId && element.runner_id === props.runner.id);

function getRaceRunnerSameDate(races: IRace[]) {
  for (const race of races) {
    const raceRunner = getRaceRunner(race.id);
    if (raceRunner && raceRunner.state_id !== 4) {
      return raceRunner;
    }
  }
}
</script>
