<template>
  <div class="flex flex-col gap-4 md:flex-row">
    <div class="flex grow flex-col items-start gap-4 3xl:flex-row">
      <TeamDetail
        :rules="team.category.rules"
        :team="teamRef"
        :user="user"
        @update-team="updateTeam" />
      <div class="flex w-full flex-col gap-4">
        <TeamSlots
          ref="slots"
          :moving-runner="movingRunner"
          :team="teamRef"
          @add-slot="addSlot"
          @dropzones="dropzones = $event"
          @move-member="moveMember"
          @remove-slot="removeSlot"
          @update-member="updateMember" />
      </div>
    </div>
    <div
      ref="searchTeamMembers"
      class="w-full md:w-1/3 xl:w-1/2 2xl:w-1/3">
      <SearchTeamMembers
        :dropzones="dropzones"
        :search-trigger="searchTrigger"
        :team="teamRef"
        @drop-touch="dropTouch"
        @end-search-trigger="searchTrigger = false"
        @moving-runner="movingRunner = $event"
        @scroll-to-slots="scrollToSlots"
        @update-team="teamRef = $event" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IGender, IRunner, ISlot, ITeam, ITeamMember, IUser } from '@/interfaces';
import { route } from '@/plugins';
import axios from 'axios';
import { provide, type Ref, ref } from 'vue';
import SearchTeamMembers from './searchTeamMembers.vue';
import TeamDetail from './teamDetail.vue';
import TeamSlots from './teamSlots.vue';

type Props = {
  genders: IGender;
  team: ITeam;
  user: IUser;
};
const props = defineProps<Props>();

provide('genders', props.genders);

const teamRef: Ref<ITeam> = ref(props.team);
const searchTrigger = ref(false);
const movingRunner: Ref<IRunner> = ref(null);
const searchTeamMembers = ref(null);

function addSlot(slot: ISlot, index: number) {
  teamRef.value.slots[index] = slot;
  postAxiosCall(teamRef.value);
  searchTrigger.value = true;
}

function removeSlot(index: number) {
  teamRef.value.slots[index] = { errorMessages: [], position: null, rules: [], teamMember: [] };
  postAxiosCall(teamRef.value);
  searchTrigger.value = true;
}

function moveMember(targetIndex: number, currentIndex: number) {
  if (targetIndex === currentIndex) {
    return;
  }
  delete teamRef.value.slots[currentIndex].teamMember.id;
  teamRef.value.slots[targetIndex].teamMember = teamRef.value.slots[currentIndex].teamMember;
  // TODO
  // Backend anpassen damit Benutzer mit ID geupdatet werden.
  teamRef.value.slots[currentIndex].teamMember = [];
  postAxiosCall(teamRef.value);
}

const slots = ref(null);

function scrollToSlots() {
  slots.value.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

const dropzones = ref([]);

function dropTouch(member: ITeamMember, index: number) {
  teamRef.value.slots[index].teamMember = member;
  postAxiosCall(teamRef.value);
  searchTrigger.value = true;
}

function onSlotUpdate() {
  searchTrigger.value = true;
}

function updateMember(member: ITeamMember, index: number) {
  teamRef.value.slots[index].teamMember = member;
  delete teamRef.value.slots[index].teamMember.created_at;
  postAxiosCall(teamRef.value);
}

function updateTeam(club_name: string, team_name: string, public_transportation_count: number, si_card?: number) {
  teamRef.value.club_name = club_name;
  teamRef.value.name = team_name;
  teamRef.value.si_card = si_card;
  teamRef.value.public_transportation_count = public_transportation_count;
  postAxiosCall(teamRef.value);
}

function postAxiosCall(team: ITeam) {
  axios
    .post(route('webapi.team.post', team.id), {
      club_name: team.club_name,
      name: team.name,
      public_transportation_count: team.public_transportation_count,
      si_card: team.si_card,
      slots: team.slots,
    })
    .then((res) => {
      teamRef.value = res.data;
    })
    .catch((reason) => {
      console.log(reason);
    });
}
</script>
