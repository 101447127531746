<template>
  <form
    @submit="submit">
    <b>{{ $lang('l.category.create_new') }}</b>
    <div class="mt-4 grid gap-5">
      <BaseInput
        label="l.name"
        name="name"
        required />
      <BaseInput
        label="l.short_name"
        name="short_name"
        required />
      <BaseMultiselect
        label="l.category.type"
        name="category_type_id"
        option-key="name"
        :options="categoryTypes"
        required />
      <BaseButton
        :label="$lang('l.category.create')"
        type="submit" />
    </div>
  </form>
</template>

<script setup lang="ts">
import type { ICategory, ICategoryRace } from '@/interfaces';
import BaseButton from '@/components/base/baseButton.vue';
import BaseInput from '@/components/base/baseInput.vue';
import BaseMultiselect from '@/components/base/baseMultiselect.vue';
import { errorHandler } from '@/helpers';
import { CategoryTypesKey, RaceKey } from '@/helpers/injection';
import { route } from '@/plugins';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { inject } from 'vue';

type Emits = {
  created: [category: ICategory & { category_races: ICategoryRace[] }];
};
const emit = defineEmits<Emits>();

const categoryTypes = inject(CategoryTypesKey);

const { handleSubmit, resetForm } = useForm();

const race = inject(RaceKey);

const submit = handleSubmit(async (values) => {
  try {
    const { data } = await useAxios<ICategory & { category_races: ICategoryRace[] }>(route('webapi.category.post', race), {
      data: { ...values },
      method: 'POST',
    });
    emit('created', data.value);
    resetForm();
  }
  catch (error) {
    errorHandler(error);
  }
});
</script>
