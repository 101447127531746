<template>
  <VueCard>
    <template #title>
      {{ $lang('l.fileManagement') }}: {{ name }}
    </template>
    <div class="space-y-3 p-3">
      <div>
        <div v-for="media in files">
          <div class="flex space-x-4">
            <div
              class="my-auto lg:mt-4"
              @click="deleteMedia(media)">
              <i
                aria-hidden="true"
                class="far fa-trash-alt mr-3 cursor-pointer text-red-500 lg:mr-0" />
            </div>
            <div class="flex w-full flex-col lg:flex-row">
              <VueInput
                v-model="media.name"
                class="w-full lg:w-96"
                :label="$lang('name')"
                @blur="patchMedia(media)" />
            </div>
          </div>
        </div>
      </div>
      <VueSpinner v-if="isSaving" />
      <VueForm
        v-if="!isSaving"
        :action="saveMedia">
        <MediaLibraryAttachment
          :initial-value="initUploadFiles"
          :multiple="false"
          name="files"
          :translations="medialibarytranslations"
          @change="pickedMedia = $event"
          @is-ready-to-submit-change="isReadyToSubmit = $event" />
        <VueButton
          class="button-primary mt-2"
          :disabled="disableSave"
          type="submit">
          {{ $lang('l.save') }}
        </VueButton>
      </VueForm>
    </div>
  </VueCard>
</template>

<script setup lang="ts">
import type { IAssociation, IFile } from '@/interfaces';

import axios from 'axios';
import { MediaLibraryAttachment } from 'spatie-media-lib-pro/media-library-pro-vue3-attachment/dist';
import { computed, type PropType, ref, type Ref } from 'vue';

import { Toast } from '../../../helpers/toast';
import { lang } from '../../../plugins/lang';
import { route } from '../../../plugins/route';
import VueButton from '../../button.vue';
import VueCard from '../../card.vue';
import VueForm from '../../form.vue';
import VueInput from '../../input.vue';
import VueSpinner from '../../spinner.vue';

const props = defineProps({
  association: Object as PropType<IAssociation>,
  initfiles: Array as PropType<Array<IFile>>,
  medialibarytranslations: Object as PropType<any>,
  name: String,
});
const inProgress = ref(false);
const files: Ref<IFile[]> = ref(props.initfiles);
const initUploadFiles: Ref<any[]> = ref([]);
const isSaving = ref(false);
const isReadyToSubmit = ref(false);
const pickedMedia = ref({});

const disableSave = computed(() => {
  if (Object.keys(pickedMedia.value).length === 0) {
    return true;
  }
  if (!isReadyToSubmit.value) {
    return true;
  }
  return false;
});

async function getAllMedia() {
  try {
    const media = await axios.get(route('webapi.associations.allMedia', props.association));
    files.value = media.data;
  }
  catch (error) {
    console.log(error);
  }
}

async function saveMedia(form: Element | Element[] | Vue | Vue[]) {
  if (!(form instanceof HTMLFormElement)) {
    console.log('not element');
    return;
  }
  const formdata = new FormData(form);
  formdata.set('model_id', props.association.id.toString());
  formdata.set('model_type', 'App\\Association');
  formdata.set('media_collection', 'association_logo');
  isSaving.value = true;
  try {
    await axios.post(route('webapi.media.post'), formdata);
    await getAllMedia();
    initUploadFiles.value = [];
    isSaving.value = false;
    pickedMedia.value = {};
  }
  catch (error) {
    console.log(error);
  }
}

async function deleteMedia(media: IFile) {
  const index = files.value.findIndex(file => file.id === media.id);
  if (index === -1) {
    await Toast.fire({ icon: 'error', text: lang('l.errorText') });
    return;
  }
  files.value.splice(index, 1);
  try {
    await axios.delete(route('webapi.media.delete', media));
  }
  catch {
    await Toast.fire({ icon: 'error', text: lang('l.errorText') });
  }
}

async function patchMedia(media: IFile) {
  try {
    await axios.patch(route('webapi.media.patch', media), {
      collection_name: media.collection_name,
      name: media.name,
    });
  }
  catch {
    await Toast.fire({ icon: 'error', text: lang('l.errorText') });
  }
}
</script>
