import { computed } from 'vue';
import { FormControl } from '../../helpers/validation/formControl';

export const formControls: { [key: string]: FormControl } = {};

export function useFormControl(formId: string) {
  const control = new FormControl();

  if (formControls.hasOwnProperty(formId)) {
    throw new Error('formId not unique');
  }

  formControls[formId] = control;

  const isValid = computed(() => control.isValid);

  return {
    control,
    isValid,
    setFieldsTouched: control.setFieldsTouched.bind(control),
  };
}
