<template>
  <div class="flex w-full flex-col gap-5">
    <div class="flex justify-end">
      <BackButton />
    </div>
    <Statistics :race="race" />
    <div>
      <div class="mb-5 border-b px-3 text-xl">
        {{ $lang('l.raceprice') }}
      </div>
      <div class="grid w-full grid-cols-2 gap-5">
        <div>
          <SupCard title="l.raceYear">
            <div class="p-3">
              <RacePricesAge class="grow" :disabled="race.registration_is_open && !isAdmin" :prices="agePrices" :race-id="race.id" />
            </div>
          </SupCard>
        </div>
        <RacePricesCategory :category-races="categoryRaces" class="grow" :disabled="race.registration_is_open && !isAdmin" :race-id="race.id" />
      </div>
    </div>
    <div>
      <div class="mb-3 border-b px-3 text-xl">
        {{ $lang('l.discounts') }}
      </div>
      <div class="mb-3 px-3">
        {{ $lang('l.discount.informationText') }}
      </div>
      <div class="flex flex-col gap-5 pb-14 lg:flex-row">
        <div class="flex flex-col gap-5 md:flex-row">
          <CreateDiscount :race-id="race.id" @created="_discounts.push($event)" />
          <AssignDiscount
            class="grow"
            :clubs="_clubs"
            :discount-assignments="_discountAssignments"
            :discounts="_discounts"
            :race-id="race.id"
            @created="_discountAssignments.push($event)" />
        </div>
        <Discounts
          :discount-assignments="_discountAssignments"
          :discounts="_discounts"
          :race-id="race.id"
          @remove-discount="pull(_discounts, $event)"
          @remove-discount-assignment="pull(_discountAssignments, $event)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComponentProps } from '@/helpers';
import type { ICategoryRace, IClub, IEvent, IRaceDiscount, IRaceDiscountAssignment, IRacePrice } from '@/interfaces';

import BackButton from '@/components/backButton.vue';
import SupCard from '@/components/ui/supCard.vue';
import { pull } from 'lodash';

import AssignDiscount from './assignDiscount.vue';
import CreateDiscount from './createDiscount.vue';
import Discounts from './discounts.vue';
import RacePricesAge from './racePricesAge.vue';
import RacePricesCategory from './racePricesCategory.vue';
import Statistics from './statistics.vue';

type Props = {
  agePrices: IRacePrice[];
  categoryRaces: (ICategoryRace & { prices: IRacePrice[] })[];
  clubs: IClub[];
  discountAssignments: IRaceDiscountAssignment[];
  discounts: IRaceDiscount[];
  event?: IEvent;
  race: ComponentProps<typeof Statistics>['race'];

};

const props = defineProps<Props>();

const _discounts = ref<IRaceDiscount[]>(props.discounts);
const _discountAssignments = ref<IRaceDiscountAssignment[]>(props.discountAssignments);

const _clubs = computed(() => props.clubs.filter(club => !props.discountAssignments.some(discountAssignment => discountAssignment.club_id === club.id)));

const isAdmin = computed(() => window.User.is_admin);
</script>
