<template>
  <div v-if="race">
    <Card>
      <template #title>
        <div>
          {{ race.name }}
        </div>
        <BackButton />
      </template>

      <div class="xl:p-3">
        <div class="grid grid-cols-1 gap-5 3xl:grid-cols-2">
          <CategoriesSection
            v-for="section, index in isTeam ? teamSections : singleSections"
            :key="index"
            :categories="getCategoriesBySection(section)"
            :category-races="getFilteredCategoryRaces(section)"
            :is-team="isTeam"
            :label="$lang(`l.categories.section.${section}`)"
            :show-create="section === 'other' ? true : false" />
        </div>
      </div>
      <div v-if="race" class="flex justify-end p-3">
        <BaseButton class="button-primary w-auto" :label="$lang('l.forward')" :link="$route('admin.races.show', race)" />
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import type { ICategory, ICategoryRace, ICategoryType, IEvent, IGender, IRace } from '@/interfaces';

import BackButton from '@/components/backButton.vue';
import BaseButton from '@/components/base/baseButton.vue';
import Card from '@/components/card.vue';
import { CategoryTypesKey, GendersKey, IsServerAdminKey, RaceKey } from '@/helpers/injection';
import { sortBy } from 'lodash';
import { provide } from 'vue';

import CategoriesSection from './categoriesSection.vue';

type Props = {
  categories: ICategory[];
  categoryRaces: ICategoryRace[];
  categoryTypes: ICategoryType[];
  event?: IEvent;
  genders: IGender[];
  isServerAdmin?: boolean;
  isTeam?: boolean;
  race?: IRace;
};

const props = defineProps<Props>();

const singleSections = ['female-single', 'male-single', 'other'] as const;
const teamSections = ['wo-team', 'other'] as const;

type SectionTuple = typeof singleSections | typeof teamSections;
type Section = SectionTuple[number];

provide(CategoryTypesKey, props.categoryTypes);
provide(GendersKey, props.genders);
provide(RaceKey, props.race);
provide(IsServerAdminKey, props.isServerAdmin);

function getCategoriesBySection(section: Section) {
  const categories = sortBy(props.categories, 'category.sort_order');
  switch (section) {
    case 'female-single': {
      return categories.filter(c => c.wo && c.gender_id === 2);
    }
    case 'male-single': {
      return categories.filter(c => c.wo && c.gender_id === 1);
    }
    case 'wo-team': {
      return categories.filter(c => c.wo);
    }
    case 'other': {
      return categories.filter(c => !c.wo);
    }
  // No default
  }
  return categories;
}

function getWoCategoriesSingleFemale(all?: boolean) {
  const singleFemaleCategories = sortBy(props.categories.filter(c => c.wo && c.gender_id === 2), 'category.sort_order');
  if (all) return singleFemaleCategories;
  return singleFemaleCategories.filter(c => !props.categoryRaces.some(cr => cr.category_id === c.id));
}

function getWoCategoriesSingleMale(all?: boolean) {
  const singleMaleCategories = sortBy(props.categories.filter(c => c.wo && c.gender_id === 1), 'category.sort_order');
  if (all) return singleMaleCategories;
  return singleMaleCategories.filter(c => !props.categoryRaces.some(cr => cr.category_id === c.id));
}

function getOtherCategories(all?: boolean) {
  const otherCategories = sortBy(props.categories.filter(c => !c.wo), 'category.sort_order');
  if (all) return otherCategories;
  return otherCategories.filter(c => !props.categoryRaces.some(cr => cr.category_id === c.id));
}

function getWoTeam(all?: boolean) {
  const woTeamCategories = sortBy(props.categories.filter(c => c.wo), 'category.sort_order');
  if (all) return woTeamCategories;
  return woTeamCategories.filter(c => !props.categoryRaces.some(cr => cr.category_id === c.id));
}

function getFilteredCategoryRaces(filter: Section) {
  let categories: ICategory[] = [];
  switch (filter) {
    case 'female-single': {
      categories = getWoCategoriesSingleFemale(true);

      break;
    }
    case 'male-single': {
      categories = getWoCategoriesSingleMale(true);

      break;
    }
    case 'wo-team': {
      categories = getWoTeam(true);

      break;
    }
    case 'other': {
      categories = getOtherCategories(true);

      break;
    }
  // No default
  }
  return props.categoryRaces.filter(cr => categories.some(c => c.id === cr.category_id));
}
</script>
