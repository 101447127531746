<template>
  <Card>
    <template #title>
      {{ $lang('l.race.details') }}
      <BackButton />
    </template>
    <SupForm class="flex flex-col gap-5 p-5" submit-label="l.race.create" @submit.prevent="submit">
      <div class="grid grid-cols-1 gap-5 lg:grid-cols-2 2xl:grid-cols-3">
        <SupInput
          label="l.name"
          name="name"
          :required="values.solv_id ? false : true" />
        <SupSelect
          label="l.solv_number"
          name="solv_id"
          :options="solvIds" />
        <SupSelect
          label="l.organizer"
          name="club_id"
          option-key="name"
          :options="clubs"
          searchable />
        <SupSelect
          label="l.typeOfRace"
          name="raceType"
          no-clear
          :options="raceTypes"
          :required="values.solv_id ? false : true"
          searchable />
        <SupSelect
          label="l.classification"
          name="classification_id"
          no-clear
          option-key="name"
          :options="classifications"
          required
          searchable />
        <SupSelect
          label="l.region"
          name="region_id"
          no-clear
          option-key="name"
          :options="regions"
          required
          searchable />
        <SupInput
          label="l.date"
          name="raceDate"
          :required="values.solv_id ? false : true"
          type="date" />
      </div>
      <div>
        <div class="mb-5 border-b">
          {{ $lang('l.race.createFromTemplate') }}
        </div>
        <SupSelect
          clearable
          label="l.race.template"
          name="template"
          option-key="name"
          :options="templates"
          searchable
          value-as-object
          @update:model-value="updateTemplate" />
      </div>
    </SupForm>
  </Card>
</template>

<script setup lang="ts">
import type { IClassification, IClub, IEvent, IRace, IRegion } from '@/interfaces';

import { errorHandler } from '@/helpers';

import BackButton from '../backButton.vue';
import Card from '../card.vue';
import SupForm from '../ui/supForm.vue';
import SupInput from '../ui/supInput.vue';
import SupSelect from '../ui/supSelect.vue';

type Props = {
  classifications: IClassification[];
  clubs: IClub[];
  event?: IEvent;
  raceTypes: string[];
  regions: IRegion[];
  solvIds: string[];
  templates: IRace[];
};

const props = defineProps<Props>();

const { handleSubmit, resetForm, setValues, values } = useForm();

function updateTemplate(template?: IRace) {
  if (template) {
    setValues({
      classification_id: template.classification_id,
      raceType: getRaceType(template),
      region_id: template.region_id,
    });
  }
  else {
    resetForm();
  }
}

function getRaceType(template: IRace) {
  if (template.cloud_o) {
    return props.raceTypes.find(type => type === 'CloudO');
  }
  return props.raceTypes.find(type => template.form.name === type);
}

const submit = handleSubmit.withControlled(async (values) => {
  try {
    const template_id = values.template ? values.template.id : undefined;
    values = Object.fromEntries(Object.keys(values)
      .filter(k => values[k] !== null)
      .map(k => [k, values[k]]));
    const { data } = await useAxios<IRace>(route('webapi.races.post'), {
      data: {
        ...values,
        event_id: props.event ? props.event.id : undefined,
        template_id,
      },
      method: 'POST',
    });
    if (data.value) {
      window.location.href = `/admin/races/${data.value.id}/categoryrace`;
    }
  }
  catch (error) {
    console.log(error);
    errorHandler(error);
  }
});
</script>
