<template>
  <label class="block text-sm font-medium text-start" :for="$props.for">
    <span>{{ translatedLabel }}</span>
    <span
      v-if="required"
      class="ml-1 text-red-500">*</span>
  </label>
</template>

<script setup lang="ts">
import { lang } from '@/plugins';
import chalk from 'chalk';
import { computed } from 'vue';

type Props = {
  for: string;
  label: string;
  required?: boolean;
};
const { label } = defineProps<Props>();

const translatedLabel = computed(() => {
  if (!label.startsWith('l.')) {
    console.warn(`Label ${chalk.redBright(label)} is plain or already translated. Change to key with l-prefix, cause the label will automatically translated.`);
    return label;
  }
  const translated = lang(label);
  if (translated.startsWith('l.')) {
    console.warn(`Translation not found: ${chalk.redBright(label)}`);
  }
  return translated;
});
</script>
