<template>
  <li class="px-3 py-px odd:bg-lightgray-100">
    <span>{{ getTranslatedLabel(label) }}</span>
    <span class="float-right">{{ formattedValue }}</span>
  </li>
</template>

<script setup lang="ts">
import { getTranslatedLabel } from '@/helpers';

type Props = {
  customFormat?: (value: boolean | number) => string;
  label: string;
  value: boolean | number;
};
const props = defineProps<Props>();

const formattedValue = computed(() => {
  if (typeof props.value === 'boolean') {
    return props.value ? 'Ja' : 'Nein';
  }
  if (props.customFormat) {
    return props.customFormat(props.value);
  }

  return Intl.NumberFormat('de-CH', { currency: 'CHF', style: 'currency' }).format(props.value);
});
</script>
