<template>
  <div
    v-if="!readonly"
    class="grid gap-5">
    <Card class="grid w-full gap-5 rounded-none p-3 lg:rounded-lg">
      <form
        class="flex flex-col gap-5 p-3"
        novalidate
        @submit="submit">
        <b class="mb-2 text-center text-2xl lg:text-3xl">{{ lang('l.assemble_team') }}</b>
        <TransitionGroup>
          <TeamMember
            v-for="(teamMember, index) in values.team_members"
            :key="index"
            :genders="genders"
            :index="index"
            :position="teamMember.team_position"
            :show-remove-member="fields.length > team.rules.min_team_member_count"
            @blur="onBlur(index)"
            @remove="removeUser(index)" />
        </TransitionGroup>
        <Transition>
          <AddMember
            v-if="fields.length < team.rules.max_team_member_count"
            :position="fields[fields.length - 1].value.team_position + 1"
            @add="addMember" />
        </Transition>
        <div class="text-red-600">
          <div
            v-for="(error, index) in errors"
            :key="index">
            {{ error }}
          </div>
        </div>
      </form>
    </Card>
  </div>

  <Card
    v-else
    class="grid w-full rounded-none lg:rounded-lg">
    <div class="hidden grow gap-5 px-3 py-2 lg:flex">
      <b class="w-10 text-center">{{ lang('l.position_short') }}</b>
      <b class="grow">{{ lang('l.firstname') }} / {{ lang('l.lastname') }}</b>
      <b class="w-28 text-center">{{ lang('l.year_of_birth') }}</b>
      <b class="w-28 text-center">{{ lang('l.gender') }}</b>
      <b class="w-14 text-center">{{ lang('l.plz') }}</b>
      <b class="w-28 text-center"> {{ lang('l.city') }}</b>
    </div>
    <div
      v-for="(slot, index) in filterSlots(team.slots)"
      :key="index"
      class="grid grid-cols-[40px_1fr] px-3 py-2 last:rounded-b-lg odd:bg-lightgray-100 lg:gap-5">
      <div class="m-auto text-xl">
        {{ slot.teamMember.team_position }}
      </div>
      <div class="flex flex-col lg:flex-row lg:gap-5">
        <div class="grow">
          {{ slot.teamMember.firstname }} {{ slot.teamMember.lastname }}
        </div>
        <div class="w-28 lg:text-center">
          {{ slot.teamMember.year_of_birth }}
        </div>
        <div class="w-28 lg:text-center">
          {{ getGender(slot.teamMember.gender_id) }}
        </div>
        <div class="hidden w-14 lg:block lg:text-center">
          {{ slot.teamMember.zip }}
        </div>
        <div class="hidden w-28 lg:block lg:text-center">
          {{ slot.teamMember.city }}
        </div>
        <div class="w-28 lg:hidden lg:text-center">
          {{ slot.teamMember.zip }} {{ slot.teamMember.city }}
        </div>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import type { IGender, ISlot, ITeam, ITeamMember } from '@/interfaces';

import { useAxios } from '@vueuse/integrations/useAxios';
import { useFieldArray, useForm } from 'vee-validate';

import Card from '../../../components/card.vue';
import { errorHandler } from '../../../helpers/api';
import { lang } from '../../../plugins/lang';
import { route } from '../../../plugins/route';
import AddMember from './addMember.vue';
import TeamMember from './teamMember.vue';

type Props = {
  errors: string[];
  genders: IGender[];
  readonly: boolean;
  team: ITeam;
};

type Emits = {
  (e: 'backToTeam'): void;
  (e: 'openPayment'): void;
  (e: 'updateTeam', team: ITeam): void;
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleSubmit, values } = useForm<{ team_members: ITeamMember[] }>({
  initialValues: {
    team_members: initMembers(),
  },
  keepValuesOnUnmount: true,
});

const { fields, push, remove, update } = useFieldArray<ITeamMember>('team_members');

function addMember() {
  // @ts-expect-error: xxx
  push({ city: '', firstname: '', lastname: '', team_position: fields.value.length + 1, year_of_birth: null, zip: '' });
}

function initMembers() {
  const members = [];
  const minSize = props.team.rules.min_team_member_count;

  for (const slot of props.team.slots) {
    if (!Array.isArray(slot.teamMember)) {
      members.push(slot.teamMember);
    }
  }
  if (members.length < minSize) {
    for (let index = members.length; index < minSize; index++) {
      members.push({ city: '', firstname: '', lastname: '', team_position: index + 1, year_of_birth: null, zip: '' });
    }
  }
  return members;
}

function removeUser(index: number) {
  remove(index);
  submit();
}

function onBlur(index: number) {
  const teamMemberId = values.team_members[index].id;
  const teamMembers = removeIncompleteUsers(values);
  if (teamMembers.team_members.find(element => element.id === teamMemberId)) {
    submit();
  }
}
const { abort, execute, isLoading } = useAxios<ITeam>();

const submit = handleSubmit(async (values) => {
  try {
    const requestValue = removeIncompleteUsers(values);

    if (requestValue.team_members.length === 0) return;

    if (isLoading.value) {
      abort();
    }
    const { data } = await execute(route('guest.create.team', props.team), {
      data: requestValue,
      method: 'POST',
    });
    console.log('thats data', data.value);
    emit('updateTeam', data.value);
    for (const [index, slot] of data.value.slots.entries()) {
      if (!Array.isArray(slot.teamMember)) {
        update(index, slot.teamMember);
      }
    }
  }
  catch (error) {
    errorHandler(error);
  }
});

function removeIncompleteUsers(teamMembers: { team_members: ITeamMember[] }) {
  const team_members = teamMembers.team_members.filter((member) => {
    if (!member.firstname) return false;
    if (!member.lastname) return false;
    if (!member.year_of_birth) return false;
    if (!member.gender_id) return false;
    if (!member.zip) return false;
    if (!member.city) return false;
    return true;
  });
  return { team_members };
}

function getGender(id: number): string {
  const gender = props.genders.find(element => element.id === id);
  if (gender) return gender.name_translated;
  return '';
}

const filterSlots = (slots: ISlot[]) => slots.filter(slot => !Array.isArray(slot.teamMember));
</script>

<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
