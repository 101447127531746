<template>
  <form
    class="flex flex-col gap-5"
    novalidate
    @submit="submit">
    <div class="grid gap-5 lg:grid-cols-2 lg:gap-10">
      <div>
        <Card class="grid w-full gap-5 rounded-none p-3 lg:rounded-lg">
          <b class="mb-2 text-center text-2xl lg:text-3xl">{{ $lang('l.team') }}</b>
          <div
            v-if="!readonly"
            class="mt-5 grid gap-1 pb-5">
            <SupInput
              label="l.teamName"
              name="club_name"
              required />
            <SupSelect
              label="l.category.default"
              name="category_race_id"
              :options="_categoryRaces"
              required />
            <div
              v-if="getRules() && !readonly"
              class="flex gap-3 px-3 transition-all">
              <i class="fas fa-info-circle my-auto text-xl text-blue-600" />
              <div class="my-auto">
                {{ getRules() }}
              </div>
            </div>
            <SupInput
              class="mt-2"
              label="l.siCard"
              name="si_card"
              type="si_card" />
          </div>
          <div
            v-else-if="teamInputs"
            class="bg-lightgray-100 p-3 text-lg">
            <div>{{ $lang('l.teamName') }}: {{ teamInputs.club_name }}</div>
            <div>{{ $lang('l.category.default') }}: {{ getCategoryName() }}</div>
            <div v-if="teamInputs.si_card">
              {{ $lang('l.si_card') }}: {{ teamInputs.si_card }}
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card class="grid w-full gap-5 rounded-none p-3 lg:rounded-lg">
          <b class="mb-2 text-center text-2xl lg:text-3xl"> {{ $lang('l.personalData') }} </b>
          <div
            v-if="!readonly"
            class="grid gap-1 pb-5">
            <SupInput
              label="l.firstname"
              name="firstname"
              required />
            <SupInput
              label="l.lastname"
              name="lastname"
              required />
            <SupInput
              label="l.email"
              name="email"
              required
              rules="email" />
          </div>
          <div
            v-else-if="teamInputs"
            class="bg-lightgray-100 p-3 text-lg">
            <div>{{ teamInputs.firstname }} {{ teamInputs.lastname }}</div>
            <div>{{ teamInputs.email }}</div>
          </div>
        </Card>
      </div>
    </div>
    <div
      v-if="!readonly"
      class="flex justify-end gap-5 px-3">
      <BaseButton
        color="negative"
        :label="$lang('l.cancel')"
        :link="$route('races.show', raceId)" />
      <BaseButton
        :label="$lang('l.forward')"
        type="submit" />
    </div>
  </form>
</template>

<script setup lang="ts">
import type { ICategoryRace, ITeam } from '@/interfaces';
import SupInput from '@/components/ui/supInput.vue';
import SupSelect from '@/components/ui/supSelect.vue';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { errorHandler } from '../../../helpers/api';
import BaseButton from '../../base/baseButton.vue';
import Card from '../../card.vue';

type Props = {
  categoryRaces: ICategoryRace[];
  raceId: number;
  readonly: boolean;
};
type Emits = {
  created: [team?: ITeam];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleSubmit, values } = useForm({ keepValuesOnUnmount: true });

const teamInputs = ref<Record<string, string | number> | null>();

const _categoryRaces = computed(() => {
  return props.categoryRaces.map((cr) => {
    return {
      ...cr,
      label: cr.category?.name,
    };
  });
});

const submit = handleSubmit.withControlled(async (values) => {
  teamInputs.value = values;
  try {
    const { data } = await useAxios<ITeam>(route('guest.create'), {
      data: { ...values, si_card: values.si_card || null },
      method: 'POST',
    });
    emit('created', data.value);
  }
  catch (err) {
    errorHandler(err);
    teamInputs.value = null;
  }
});
function getCategoryName() {
  const categoryRace = props.categoryRaces.find(el => el.id === teamInputs.value?.category_race_id);
  if (categoryRace) return categoryRace?.category?.name;
  return '';
}

function getRules() {
  if (values.category_race_id) {
    return props.categoryRaces.find(el => el.id === values.category_race_id)?.category?.rules;
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
