<template>
  <div>
    <div class="space-y-3 md:space-y-6 xl:grid xl:grid-cols-3 xl:gap-6 xl:space-y-0 2xl:grid-cols-4">
      <StartlistElement
        v-for="(startlist, index) in startlists"
        :key="index"
        :category-races="categoryRaces"
        :race="race"
        :startlist="startlist"
        @deleted="deleted"
        @updated="updated" />
      <div>
        <BaseButton
          class="w-full text-lg"
          :label="$lang('l.add.default')"
          :loading="loading"
          @click="createStartlist" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, IRace, IStartlist } from '@/interfaces';
import axios from 'axios';
import Swal from 'sweetalert2';
import { type PropType, type Ref, ref } from 'vue';
import { route } from '../../plugins/route';
import BaseButton from '../base/baseButton.vue';
import StartlistElement from './startlistElement.vue';

const props = defineProps({
  categoryRaces: { default: () => [], type: Array as PropType<ICategoryRace[]> },
  race: { required: true, type: Object as PropType<IRace> },
});

const startlists: Ref<IStartlist[]> = ref(props.race.startlists);
const loading = ref(false);
async function createStartlist() {
  try {
    loading.value = true;
    const res = await axios.post<IStartlist>(route('webapi.startlist.post'), { race_id: props.race.id });
    startlists.value.push(res.data);
    loading.value = false;
  }
  catch (error) {
    loading.value = false;
    console.log(error);
    Swal.fire({
      html: error.response.data.message,
      icon: 'error',
    });
  }
}

function updated(startlist: IStartlist) {
  const index = startlists.value.findIndex(el => el.id === startlist.id);
  startlists.value[index] = startlist;
}

function deleted(startlist: IStartlist) {
  const index = startlists.value.findIndex(el => el.id === startlist.id);
  startlists.value.splice(index, 1);
}
</script>
