import { TransLation } from '../helpers/translation';

export default {
  install: (app: any) => {
    app.config.globalProperties.$lang = (name: string, attributes?: { [key: string]: string }) => {
      return TransLation.get(name, attributes);
    };
  },
};

export function lang(name: string, attributes?: { [key: string]: string }) {
  return TransLation.get(name, attributes);
}
