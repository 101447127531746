<template>
  <div class="relative mt-2 block">
    <div
      v-if="!dropdownIsTop"
      class="absolute -top-3.5 left-2.5 z-10 whitespace-nowrap bg-white px-2 text-gray-400"
      style="transform: scale(0.9)">
      {{ label }}{{ required && label ? ' *' : '' }}
    </div>

    <VSelect
      append-to-body
      :calculate-position="calcWithPopper"
      class="base_select__field"
      :class="{
        'is-invalid': showError(),
      }"
      :clearable="clearable"
      :disabled="disabled"
      :filter="getFilter()"
      :filterable="filterable"
      :label="getOptionKey()"
      :model-value="internalValue"
      :options="options"
      :placeholder="placeholder"
      :searchable="searchable"
      :selectable="selectable"
      :taggable="taggable"
      @option:created="created"
      @search="search"
      @search:blur="blur"
      @update:model-value="input">
      <template #no-options>
        {{ $lang('l.nothingFound') }}
      </template>
      <template
        v-if="showOption"
        #option="option">
        {{ showOption(option) }}
      </template>
      <template
        v-if="showOption"
        #selected-option="option">
        {{ showOption(option) }}
      </template>
    </VSelect>
    <div
      v-if="showError()"
      class="vs__error text-xs"
      role="alert">
      {{ initialError || fieldControl.errors.value[0] }}
    </div>
    <div
      v-else-if="disableMessage"
      class="vs__error text-xs"
      role="alert"
      v-html="disableMessage" />
    <select
      hidden
      :name="name">
      <option
        v-if="internalValue"
        selected
        :value="internalValue.id || internalValue" />
    </select>
    <div
      v-if="modelValue"
      hidden>
      {{ modelValue.id || modelValue }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'VueSelect',
};
</script>

<script lang="ts" setup>
// eslint-disable-next-line import/first
import { createPopper } from '@popperjs/core';
// eslint-disable-next-line import/first
import Fuse from 'fuse.js';
// eslint-disable-next-line import/first
import { onBeforeUpdate, type PropType, ref, type Ref } from 'vue';
// eslint-disable-next-line import/first
import VSelect from 'vue-select';
// eslint-disable-next-line import/first
import { useFieldControl } from '../composables/form/fieldControl';
// eslint-disable-next-line import/first
import 'vue-select/dist/vue-select.css';

const props = defineProps({
  backendSearch: Function,
  clearable: { default: false, type: Boolean },
  disabled: { default: false, type: Boolean },
  disableMessage: { default: '', type: String },
  filterable: { default: true, type: Boolean },
  initError: String,
  initValue: [Object, String, Number],
  isBlade: Boolean,
  label: String,
  modelValue: [Object, String, Number],
  name: { default: '', type: String },
  optionKey: String,
  options: { default: () => [], type: Array },
  placeholder: { default: '', type: String },
  required: { default: false, type: Boolean },
  searchable: { default: false, type: Boolean },
  searchKeys: Array as PropType<Array<Fuse.FuseOptionKey>>,
  selectable: { default: () => true, type: Function },
  showOption: Function,
  taggable: { default: false, type: Boolean },
});

const emit = defineEmits(['search', 'update:modelValue', 'blur', 'option:created']);

const internalValue: Ref<object | string | number> = ref(null);
const isDirty = ref(false);
const initialError = ref(props.initError);
const isTouched = ref(false);

if (props.initValue) {
  if (!isNaN(props.initValue)) {
    internalValue.value = props.options.find(opt => opt.id == props.initValue);
  }
  else {
    internalValue.value = props.initValue;
  }
}
else {
  internalValue.value = props.modelValue;
}

const fieldControl = useFieldControl(props.name, isTouched, props.required);
fieldControl.validate(internalValue.value);

function input(ev: any) {
  internalValue.value = ev;
  initialError.value = null;
  validate();
  updateModelValue(ev);
  blur(ev);
}

function validate() {
  fieldControl.validate(internalValue.value);
  isDirty.value = true;
}

function showError() {
  if (initialError.value) {
    return true;
  }
  return !fieldControl.isValid.value && isTouched.value;
}

function getFilter() {
  if (!props.searchKeys || props.searchKeys.length === 0) {
    return;
  }
  return (options: any[], search: string) => {
    const fuse = new Fuse(options, {
      findAllMatches: true,
      keys: props.searchKeys,
      shouldSort: true,
    });

    return fuse.search(search).map(({ item }): { item: any } => item);
  };
}
const dropdownIsTop = ref(false);
function getOptionKey() {
  if (props.options && props.options[0]) {
    if (typeof props.options[0] === 'object') {
      return props.optionKey;
    }

    return 'label';
  }

  return props.optionKey;
}

function search(search: any, loading: any) {
  emit('search', { loading, search });
}

function updateModelValue(ev: any) {
  emit('update:modelValue', ev);
}

function blur(ev: any) {
  isTouched.value = true;
  validate();
  emit('blur', ev);
}

function created(ev: any) {
  emit('option:created', ev);
}

onBeforeUpdate(() => {
  if (props.isBlade) {
    return;
  }

  internalValue.value = props.modelValue;
  validate();
});

function calcWithPopper(dropdownList: HTMLUListElement, component, { width }) {
  dropdownList.style.width = width;
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        enabled: true,
        fn({ state }) {
          component.$el.classList.toggle('drop-up', state.placement === 'top');
          if (state.placement === 'top') dropdownIsTop.value = true;
        },
        name: 'toggleClass',
        phase: 'write',
      },
    ],
  });
  return () => {
    popper.destroy();
    dropdownIsTop.value = false;
  };
}
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.vs__dropdown-toggle {
    padding: 0.4rem;
    border: 1px solid #af9fab;
    border-radius: 0.5rem;
}
</style>
