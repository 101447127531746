<template>
  <div
    v-element-hover="onHover"
    class="flex w-full flex-col rounded-md bg-lightgray-300">
    <div class="pt-3 text-center text-lg">
      {{ getLanguageStatusLabel(status) }}
    </div>
    <div class="flex flex-col gap-3 p-3">
      <TransitionGroup name="fade">
        <RoadmapFeature
          v-for="(feature, featureIndex) in featureList"
          :key="featureIndex"
          :feature="feature"
          :feature-edit="featureEdit"
          :feature-request-statuses="featureRequestStatuses"
          :feature-requests="featureRequests"
          :status="status"
          @deleted="deleted"
          @updated="$emit('updated', $event)" />
      </TransitionGroup>
      <Transition name="fade">
        <RoadmapFeature
          v-if="(isHovered || createOpen) && user.is_admin"
          :feature-request-statuses="featureRequestStatuses"
          :feature-requests="featureRequests"
          :status="status"
          @created="created"
          @on-create="createOpen = $event" />
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IFeature, IFeatureRequest, IUser } from '@/interfaces';
import { vElementHover } from '@vueuse/components';
import { inject, type PropType, ref, type Ref, watch } from 'vue';
import { lang } from '../../plugins/lang';
import RoadmapFeature from './roadmapFeature.vue';

const props = defineProps({
  featureEdit: { default: undefined, type: Object as PropType<IFeature> },
  featureRequests: { default: () => [], type: Array as PropType<IFeatureRequest[]> },
  featureRequestStatuses: { default: undefined, type: Object as PropType<{ [key: string]: number }> },
  features: { default: () => [], type: Array as PropType<IFeature[]> },
  status: { required: true, type: Number },
});

defineEmits(['updated']);

watch(
  () => props.features,
  (val) => {
    featureList.value = val;
  },
  { deep: true },
);

const user = inject('user') as IUser;

const featureList: Ref<IFeature[]> = ref(props.features.filter(el => el.status === props.status));
const isHovered = ref(false);

const createOpen = ref(false);

function getLanguageStatusLabel(status: number) {
  let name = '';
  Object.entries(props.featureRequestStatuses).forEach(([key, value]) => {
    if (status === value) {
      name = key;
    }
  });
  name = name[0].toLowerCase() + name.slice(1);
  return lang(`l.featureStatus.${name}`);
}

function onHover(state: boolean) {
  isHovered.value = state;
}

function created(feature: IFeature) {
  featureList.value.push(feature);
  createOpen.value = false;
}

function deleted(feature: IFeature) {
  const index = featureList.value.findIndex(el => el.id === feature.id);
  featureList.value.splice(index, 1);
}
</script>
