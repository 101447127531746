<template>
  <div
    v-if="extraFieldDef.input_type.name === 'text' || extraFieldDef.input_type.name === 'integer'"
    class="mt-4">
    <VueInput
      v-model="field"
      :label="$lang(`l.extraFieldDef.${extraFieldDef.name}.name`)"
      :min="extraFieldDef.input_type.name === 'integer' ? 1 : undefined"
      :name="`extraFieldInput: ${extraFieldDef.name}`"
      :required="extraFieldDef.required"
      :type="extraFieldDef.input_type.name === 'integer' ? 'number' : 'text'"
      @blur="update(extraFieldDef.input_type.name === 'integer' ? true : false)" />
  </div>
  <div
    v-else-if="extraFieldDef.input_type.name === 'checkbox'"
    class="mb-6 mt-2">
    <VueCheckbox
      v-model="field"
      :label="$lang(`l.extraFieldDef.${extraFieldDef.name}.name`)"
      :name="`extraFieldInput: ${extraFieldDef.name}`"
      :required="extraFieldDef.required"
      @blur="update" />
  </div>
  <div
    v-else-if="extraFieldDef.input_type.name === 'select'"
    class="mb-6 mt-4">
    <VueSelect
      v-model="field"
      :clearable="true"
      :label="$lang(`l.extraFieldDef.${extraFieldDef.name}.name`)"
      :name="`extraFieldInput: ${extraFieldDef.name}`"
      :options="extraFieldDef.extra_field_def_choices"
      :placeholder="$lang('l.select')"
      :required="extraFieldDef.required"
      :show-option="(choice) => $lang(`l.extraFieldDefChoice.${choice.value}`)"
      @update:model-value="update(true)" />
  </div>
</template>

<script setup lang="ts">
import type { IExtra, IExtraField, IExtraFieldDef, IExtraFieldDefChoices } from '@/interfaces';
import axios from 'axios';
import Swal from 'sweetalert2';
import { inject, onMounted, type PropType, type Ref, ref } from 'vue';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import { useCartStore } from '../../store/cart';
import VueCheckbox from '../checkbox.vue';
import VueInput from '../input.vue';
import VueSelect from '../select.vue';

const props = defineProps({
  extra: { required: true, type: Object as PropType<IExtra> },
  extraFieldDef: { required: true, type: Object as PropType<IExtraFieldDef> },
});

const cartStore = useCartStore();
const raceRunnerIndex = inject<number>('raceRunnerIndex');

const field: Ref<string | IExtraFieldDefChoices> = ref(null);

onMounted(() => {
  if (props.extraFieldDef.input_type.name === 'select') {
    const extraField = props.extra.extra_fields.find(el => el.extra_field_def_id === props.extraFieldDef.id);
    if (extraField) {
      field.value = props.extraFieldDef.extra_field_def_choices.find(el => el.id == extraField.value);
    }
  }
  else {
    setExtraFieldValue();
  }
});

function setExtraFieldValue() {
  const extraField = props.extra.extra_fields.find(extra => extra.extra_field_def_id === props.extraFieldDef.id);
  if (extraField) {
    field.value = extraField.value;
  }
  else {
    field.value = null;
  }
}

async function update(min?: boolean) {
  if (min) {
    if (Number.parseInt(field.value as string) < 1) field.value = null;
  }
  if (!field.value) {
    field.value = null;
  }
  if (!props.extraFieldDef.popup_text || cartStore.extraFieldInformationAccepted) return postExtraField();
  const swal = await Swal.fire({
    cancelButtonText: lang('l.cancel'),
    confirmButtonText: lang('l.okAccept'),
    icon: 'info',
    reverseButtons: true,
    showCancelButton: true,
    text: props.extraFieldDef.popup_text,
  });
  if (swal.isConfirmed) {
    postExtraField();
    cartStore.extraFieldInformationAccepted = true;
  }
  if (swal.isDismissed) {
    setExtraFieldValue();
  }
}

async function postExtraField() {
  let ext: string | boolean | number;
  if (typeof field.value === 'string') {
    ext = field.value;
  }
  else if (typeof field.value === 'boolean') {
    if (field.value === true) {
      ext = field.value;
    }
    else {
      ext = undefined;
    }
  }
  else if (field.value) {
    ext = field.value.id;
  }
  const extraField = await axios.post<IExtraField>(route('webapi.extra.storeField', props.extra.id), {
    extra_field_def_id: props.extraFieldDef.id,
    extra_id: props.extra.id,
    value: ext,
  });
  cartStore.fetchRaceRunnerById(cartStore.raceRunners[raceRunnerIndex].id);
  const extraIndex = cartStore.raceRunners[raceRunnerIndex].extras.findIndex(el => el.id === props.extra.id);
  const extraFieldIndex = cartStore.raceRunners[raceRunnerIndex].extras[extraIndex].extra_fields.findIndex(
    el => el.id === extraField.data.id,
  );
  if (extraFieldIndex > -1) {
    cartStore.raceRunners[raceRunnerIndex].extras[extraIndex].extra_fields[extraFieldIndex] = extraField.data;
  }
  else {
    cartStore.raceRunners[raceRunnerIndex].extras[extraIndex].extra_fields.push(extraField.data);
  }
}
</script>
