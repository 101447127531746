import dayjs from 'dayjs';

/**
 * Parse a timestamp to a readable Date with Time
 *
 * @param datetime timestamp as string
 * @returns Date with Format: DD.MM.YYYY HH:mm
 */
export function getDateWithTime(datetime: string) {
  return dayjs(datetime).format('DD.MM.YYYY HH:mm');
}

/**
 *  Parse a Dayjs Object to accepted Date String from Laravel Validator
 *
 * @param value dayjs Object
 * @returns Date with Format YYYY-MM-DD HH:mm:ss
 */
export function dayjsToLaravel(value: dayjs.Dayjs) {
  return value.format('YYYY-MM-DD HH:mm:ss');
}
