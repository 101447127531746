import type { IBaseProps } from './types';

import { lang } from '@/plugins';
import chalk from 'chalk';

import { type FieldOptions, useField } from 'vee-validate';

export function useFieldCustom<T = unknown>(props: IBaseProps & { [key: string]: unknown }, options: Partial<FieldOptions<T>> = {}) {
  const translatedLabel = computed(() => {
    if (!props.label) return;
    const label = props.label;
    if (!label.startsWith('l.')) {
      console.warn(`Label ${chalk.redBright(label)} is plain or already translated. Change to key with l-prefix, cause the label will automatically translated.`);
      return;
    }
    const translated = lang(label);
    if (translated.startsWith('l.')) {
      console.warn(`Translation not found: ${chalk.redBright(label)}`);
    }
    return translated;
  });

  // Default options
  options = {
    label: translatedLabel.value,
    syncVModel: true,
    ...options,
  };

  const rules = computed(() => {
    if (props.required) {
      return props.rules ? `required|${props.rules}` : 'required';
    }
    return props.rules;
  });

  const field = useField<T>(() => props.name, rules, options);

  const hasError = computed(() => !!(field.meta.touched && field.errorMessage.value));

  return { ...field, hasError };
}
