<template>
  <label
    v-if="label"
    class="absolute -top-3.5 left-2.5 z-10 whitespace-nowrap bg-white px-2 text-gray-400"
    style="transform: scale(0.9)">
    {{ translatedLabel }}
  </label>
</template>

<script setup lang="ts">
import chalk from 'chalk';
import { computed } from 'vue';
import { lang } from '../../plugins/lang';

type Props = {
  label?: string;
};
const props = defineProps<Props>();

const translatedLabel = computed(() => {
  if (!props.label) return;
  if (!props.label.startsWith('l.')) {
    console.warn(`Label ${chalk.redBright(props.label)} is plain or already translated. Change to key with l-prefix, cause the label will automatically translated.`);
    return props.label;
  }
  const translated = lang(props.label);
  if (translated.startsWith('l.')) {
    console.warn(`Translation not found: ${chalk.redBright(props.label)}`);
  }
  return translated;
});
</script>
