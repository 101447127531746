<template>
  <div class="mt-4 border-t-2 pt-4">
    <div class="text-xl font-bold">
      {{ $lang('l.extra') }}:
    </div>
    <ExtraListItem
      v-for="(extraDefPermission, index) in extraDefPermissons"
      :key="index"
      class="mb-2"
      :extra-def-permission="extraDefPermission"
      :race-runner="raceRunner" />
  </div>
</template>

<script setup lang="ts">
import type { IExtraDefPermission, IRaceRunner } from '@/interfaces';
import { type PropType, type Ref, ref } from 'vue';
import ExtraListItem from './extraListItem.vue';

const props = defineProps({
  raceRunner: { required: true, type: Object as PropType<IRaceRunner> },
});

const extraDefPermissons: Ref<IExtraDefPermission[]> = ref(
  addExtraDefPermissions([
    props.raceRunner.race.event.extra_def_permissions,
    props.raceRunner.race.extra_def_permissions,
    props.raceRunner.category_race.extra_def_permissions,
    props.raceRunner.category_race.category.extra_def_permissions,
  ]),
);

function addExtraDefPermissions(extraDefPermissionsArray: IExtraDefPermission[][]) {
  const result: IExtraDefPermission[] = [];
  extraDefPermissionsArray.forEach((permissions) => {
    permissions.forEach((perm) => {
      const i = result.findIndex(el => el.extra_def.id === perm.extra_def.id);
      if (i > -1) {
        result[i] = perm;
      }
      else {
        result.push(perm);
      }
    });
  });
  return result;
}
</script>
