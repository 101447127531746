import { lang } from '@/plugins/lang';
import axios from 'axios';
import Swal from 'sweetalert2';

export function requestParser(obj: Record<string, unknown>) {
  return Object.keys(obj)
    .filter(k => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
}

export function errorHandler(error: unknown): void {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.name === 'CanceledError') return;
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.data.message) {
        Swal.fire({ icon: 'error', text: String(error.response.data.message), title: lang('l.error.default') });
      }
      else {
        Swal.fire({ icon: 'error', text: String(error.response.data), title: lang('l.error.default') });
      }
    }
    else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      Swal.fire({ icon: 'error', text: String(error.request), title: lang('l.error.default') });

      // Something happened in setting up the request that triggered an Error
    }
    else if (error.name !== 'CanceledError') {
      Swal.fire({ icon: 'error', text: String(error.message), title: lang('l.error.default') });
    }
  }
}

export function filterEmpty<T>(obj: T): T {
  Object.keys(obj).forEach((key) => {
    const val = obj[key];

    if (val === '' || val === null || val === undefined) {
      delete obj[key];
    }
    else if (typeof val === 'object') {
      filterEmpty(val);
    }
  });

  return obj;
}
