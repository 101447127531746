import type { FormControl } from '../../helpers/validation/formControl';
import { getCurrentInstance, onBeforeUnmount, type Ref } from 'vue';
import { FieldControl } from '../../helpers/validation/fieldControl';
import { parseValidationRules } from '../../helpers/validation/rules/parseValidationRules';
import { ValidationRule } from '../../helpers/validation/rules/validationRule';
import { formControls } from './formControl';
import { getFormId } from './getFormId';

export function useFieldControl(
  fieldName: string,
  isTouchedRef: Ref<boolean>,
  isRequired = false,
  validationRules = '',
) {
  const control = new FieldControl(fieldName, isTouchedRef);

  const formId = getFormId(getCurrentInstance());

  let formControl: FormControl;
  if (formId && formControls.hasOwnProperty(formId)) {
    formControl = formControls[formId];
    formControl.addFieldControl(control);
  }

  const loadValidationRules = (validationRules, isRequired = false) => {
    const createValidationRuleOptions = parseValidationRules(validationRules);

    const validations: ValidationRule[] = [];

    for (const createValidationRuleOption of createValidationRuleOptions) {
      const validation = ValidationRule.create(createValidationRuleOption);
      if (!validation) {
        continue;
      }
      validations.push(validation);
    }

    if (isRequired) {
      validations.push(ValidationRule.required);
    }

    control.addValidationRule(...validations);
  };

  loadValidationRules(validationRules, isRequired);

  onBeforeUnmount(() => {
    if (!formControl) {
      return;
    }
    formControl.removeFieldControl(control);
  });

  return {
    errors: control.errors,
    isValid: control.isValid,
    validate: control.validate.bind(control),
  };
}
