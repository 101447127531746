<template>
  <div>
    <BaseTable v-if="isMediumScreen" :headers="headers">
      <template v-if="transactions.length">
        <InvoicesTableRow v-for="transaction, index in transactions" :key="index" :transaction="transaction" />
      </template>
      <tr v-else class="bg-white">
        <td class="px-4 py-2 text-center" colspan="5">
          {{ $lang('l.invoices.noOpenAndPaid') }}
        </td>
      </tr>
    </BaseTable>
    <template v-else>
      <InvoicesListElement v-for="transaction, index in transactions" :key="index" :headers="headers" :transaction="transaction" />
    </template>
  </div>
</template>

x
<script setup lang="ts">
import type { ComponentProps } from '@/helpers';

import BaseTable from '@/components/base/table/baseTable.vue';

import InvoicesListElement from './invoicesListElement.vue';
import InvoicesTableRow from './invoicesTableRow.vue';

type Props = {
  transactions: ComponentProps<typeof InvoicesTableRow>['transaction'][];
};
defineProps<Props>();

const headers = ['l.invoice.default', 'l.date', 'l.races.default', 'l.status.default', 'l.amount'];

const isMediumScreen = useMediaQuery('(min-width: 768px)');
</script>
