<template>
  <button
    class="flex h-10 md:h-12 cursor-pointer justify-between border-b bg-white px-4 py-2 hover:bg-lightgray-100"
    type="button"
    @click="$emit('selectCategoryRace')">
    <div class="my-auto flex justify-between gap-5">
      <div class="my-auto">
        {{ categoryRace.category.short_name }} ({{ `${finishedRaceRunnersCount}/${getMaxCount()}` }})
      </div>
      <div
        v-if="isApprovedMeRunner"
        class="flex">
        <i class="fas fa-person-running my-auto text-orange-500" />
      </div>
    </div>

    <i class="fas fa-chevron-right my-auto text-2xl text-orange-500" />
  </button>
</template>

<script setup lang="ts">
import type { ICategoryRace } from '@/interfaces';

import { computed } from 'vue';

import { isFinished } from '../../helpers/rating';

type Props = {
  baseUrl: string;
  categoryRace: ICategoryRace;
};
type Emits = {
  (e: 'selectCategoryRace'): void;
};
const props = defineProps<Props>();
defineEmits<Emits>();

const isApprovedMeRunner = computed(() => {
  if (!window.User || !window.User.approved_runner_ids) return false;
  let hasAprovedMeRunners = false;
  if (props.categoryRace.team_results) {
    for (const teamResult of props.categoryRace.team_results) {
      for (const result of teamResult.results) {
        if (result && result.runner_id && window.User.approved_runner_ids.includes(result.runner_id)) {
          hasAprovedMeRunners = true;
        }
      }
    }
  }
  else {
    for (const result of props.categoryRace.results) {
      if (result && result.runner_id && window.User.approved_runner_ids.includes(result.runner_id)) {
        hasAprovedMeRunners = true;
      }
    }
  }

  return hasAprovedMeRunners;
});

const finishedRaceRunnersCount = computed(() => {
  const cr = props.categoryRace;
  if (cr.team_results) {
    return cr.team_results.filter((teamResult) => {
      return (
        teamResult.results?.filter((result) => {
          if (result.rank) return true;
          if (!result.rating) return false;
          if (!result.is_read_out) return false;
          return isFinished(result.rating.id);
        }).length === cr.legs
      );
    }).length;
  }
  return props.categoryRace.results.filter((result) => {
    if (!result.rating) return false;
    return isFinished(result.rating.id);
  }).length;
});

function getMaxCount(): number {
  const cr = props.categoryRace;
  if (cr.team_results && cr.team_results[0].results) {
    return cr.team_results.filter((teamResult) => {
      const firstLegResult = teamResult.results.find(result => result.leg === 1);
      if (!firstLegResult) return false;
      if (!firstLegResult.rating) return true;
      return firstLegResult.rating.id !== 2;
    }).length;
  }
  return props.categoryRace.results.filter((result) => {
    if (!result.rating) return true;
    return result.rating.id !== 2;
  }).length;
}
</script>
