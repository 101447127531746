<template>
  <VueCard>
    <template #title>
      {{ $lang('l.summaryRefund') }}
    </template>
    <div class="p-4">
      <div class="flex flex-col gap-4">
        <div>{{ $lang('l.total') }}: {{ totRefundAmount }}</div>
        <div
          v-if="!!costsToRefund.length"
          class="justify-content-end flex flex-row">
          <div>
            <div
              class="button-primary cursor-pointer"
              @click="refund">
              {{ $lang('l.toRefund') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueCard>
</template>

<script setup lang="ts">
import type { ICostToRefund, ITransaction } from '@/interfaces';
import axios from 'axios';
import { computed, type PropType } from 'vue';
import { route } from '../../plugins/route';
import VueCard from '../card.vue';

const props = defineProps({
  costsToRefund: Array as PropType<ICostToRefund[]>,
  transaction: Object as PropType<ITransaction>,
});

const totRefundAmount = computed(() => {
  return props.costsToRefund.reduce((prev, current) => {
    return prev + current.amount;
  }, 0);
});

async function refund() {
  try {
    const result = await axios.post<ITransaction>(
      route('cart.makeRefund', { transaction: props.transaction.id }),
      props.costsToRefund,
    );
    window.location.href = route('cart.summary', { transaction: result.data.id });
  }
  catch (error) {
    console.log(error);
  }
}
</script>
