<template>
  <div class="mb-2 text-lg">
    {{ organiserInformationGroup.name }}
  </div>

  <BaseTable
    class="mb-8 shadow-xl"
    :headers="tableHeaders">
    <informationDetailsTableRow
      v-for="information, index in organiserInformations"
      :key="index"
      class="mb-8 last:!border-b-0 xl:last:rounded-md"
      :information="information" />
  </BaseTable>
</template>

<script setup lang="ts">
import type { ComponentProps } from '@/helpers';

import BaseTable from '@/components/base/table/baseTable.vue';

import informationDetailsTableRow from './informationDetailsTableRow.vue';

type Props = {
  organiserInformationGroup: Record<string, any>;
  organiserInformations: Record<string, any>[];
};

const props = defineProps<Props>();

type Headers = ComponentProps<typeof BaseTable>['headers'];

const tableHeaders: Headers = [
  { class: 'w-72 bg-lightgray-200 p-2 first:rounded-tl-md text-left', label: 'l.organiser.details.table.name' },
  { class: 'w-12 bg-lightgray-200 py-2', icon: 'far:pen-to-square', tooltip: 'l.organiser.details.table.responsible' },
  { class: 'w-12 bg-lightgray-200 py-2', icon: 'far:square-check', tooltip: 'l.organiser.details.table.control' },
  { class: 'w-12 bg-lightgray-200 py-2', icon: 'far:asterisk', tooltip: 'l.organiser.details.table.required' },
  { class: 'w-12 bg-lightgray-200 py-2', icon: 'far:language', tooltip: 'l.organiser.details.table.translatable' },
  { class: 'last:rounded-tr-md bg-lightgray-200 p-2 text-left', label: 'l.organiser.details.table.comment' },
];
</script>
