import { flare } from '@flareapp/js';

import { flareVue } from '@flareapp/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue/dist/vue.esm-bundler.js';
import VueTippy from 'vue-tippy';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';

import AccountManagement from './components/admin/accounts/accountManagement.vue';
import VueAssociationFilemanagement from './components/admin/association/associationFilemanagement.vue';

import AdminCategories from './components/admin/race/adminCategories.vue';

import AdminRaceFinances from './components/admin/race/finances/index.vue';
import PageCounts from './components/admin/race/pageCounts.vue';
import RaceRunnerMap from './components/admin/race/raceRunnerMap.vue';
import AdminRaceAdmins from './components/admin/race/settings/raceAdmins.vue';
import TeamsQuickEdit from './components/admin/race/teams/quickEdit.vue';
import VueAppMenu from './components/appMenu.vue';
import VueBackButton from './components/backButton.vue';
import BaseButton from './components/base/baseButton.vue';
import YearSwitch from './components/base/yearSwitch.vue';
import VueButton from './components/button.vue';
import VueCartBadge from './components/cart/cartBadge.vue';
import VueCartBadgeHeader from './components/cart/cartBadgeHeader.vue';
import CartOverview from './components/cart/cartOverview.vue';
import VueCheckbox from './components/checkbox.vue';
import VueCountdownWaiting from './components/countdownWaiting.vue';
import DateTimePicker from './components/dateTimePicker.vue';
import VueEditor from './components/editor.vue';
import DeleteEvent from './components/event/deleteEvent.vue';
import VueSignupRunnerAsAdmin from './components/event/signupRunnerAsAdmin.vue';
import SignupRunners from './components/event/signupRunners.vue';
import IdeasOverview from './components/features/ideasOverview.vue';
import RoadmapOverview from './components/features/roadmapOverview.vue';
import VueForm from './components/form.vue';
import VueGuestMenu from './components/guestMenu.vue';
import InformationButton from './components/informationButton.vue';
import VueInput from './components/input.vue';
import VueIntro from './components/intro.vue';
import InvoicesList from './components/invoices/invoicesList.vue';
import VueLangSwitcher from './components/langSwitcher.vue';
import MenuItem from './components/layout/menuItem.vue';
import MenuWrapper from './components/layout/menuWrapper.vue';
import InformationPage from './components/organiser/informationPage.vue';
import RaceCategories from './components/race/categories/raceCategories.vue';
import CoursesManagement from './components/race/coursesManagement.vue';
import CreateRace from './components/race/createRace.vue';
import ExportSettings from './components/race/exportSettings.vue';
import RaceGuestTeamSignup from './components/race/guest/teamSignup.vue';
import OrwareExport from './components/race/orwareExport.vue';
import VueRaceAttendeeList from './components/race/RaceAttendeeList.vue';
import VueRaceAttendeeTeamList from './components/race/raceAttendeeTeamList.vue';
import VueRaceFilemanagement from './components/race/raceFilemanagement.vue';
import VueRaceFinishCheckbox from './components/race/raceFinishCheckbox.vue';
import RaceLocationsMap from './components/race/raceLocationsMap.vue';
import RaceMap from './components/race/raceMap.vue';
import RaceReminderSwitch from './components/race/raceReminderSwitch.vue';
import RaceResultsCategoryList from './components/race/raceResultsCategoryList.vue';
import FavoriteButton from './components/race/races/favoriteButton.vue';
import RunnersInvalidDoping from './components/race/runnersInvalidDoping.vue';
import RunnersInvalidIof from './components/race/runnersInvalidIof.vue';
import StartlistOverview from './components/race/startlistOverview.vue';
import VueRaceDatesManagement from './components/raceDatesManagement.vue';
import RegisteredRaceRunner from './components/raceRunner/registeredRaceRunner.vue';
import SelectStart from './components/raceRunner/selectStart.vue';
import VueCreateRefund from './components/refund/createRefund.vue';
import VueClubSelect from './components/runner/clubSelect.vue';
import VueResendVerification from './components/runner/resendVerification.vue';
import VueSolvAddByNumber from './components/runner/solvAddByNumber.vue';
import VueSolvSearch from './components/runner/solvSearch.vue';
import VueScrollToTop from './components/scrollToTop.vue';
import VueSelect from './components/select.vue';
import VueSessionSweetalert from './components/sessionSweetalert.vue';
import SetFlareUser from './components/setFlareUser.vue';
import SponsorList from './components/sponsor/sponsorList.vue';
import VueDeleteTeam from './components/team/deleteTeam.vue';
import RegisteredTeam from './components/team/registeredTeam.vue';
import VueSearchTeamMembers from './components/team/searchTeamMembers.vue';
import VueTeamCartStatusAction from './components/team/teamCartStatusAction.vue';
import VueTeamEditOverview from './components/team/teamEditOverview.vue';
import VueTeamMembers from './components/team/teamSlots.vue';
import VueTransactionDelete from './components/transactionDelete.vue';
import TestUi from './components/ui/index.vue';
import CategoryFilterSwitch from './components/user/categoryFilterSwitch.vue';
import EditUserForm from './components/user/editUserForm.vue';
import RaceReminderPreferences from './components/user/raceReminderPreferences.vue';
import UserManagers from './components/user/userManagers.vue';
import { importIcons } from './helpers/import';
import lang from './plugins/lang';
import route from './plugins/route';
import './configs/rules';
import '../sass/tailwind-app.scss';
import './vue-bootstrap';
import 'floating-vue/dist/style.css';

const app = createApp({
  components: {
    AccountManagement,
    AdminCategories,
    AdminRaceAdmins,
    AdminRaceFinances,
    BaseButton,
    CartOverview,
    CategoryFilterSwitch,
    CoursesManagement,
    CreateRace,
    DateTimePicker,
    DeleteEvent,
    EditUserForm,
    ExportSettings,
    FavoriteButton,
    IdeasOverview,
    InformationButton,
    InformationPage,
    InvoicesList,
    MenuItem,
    MenuWrapper,
    OrwareExport,
    PageCounts,
    RaceCategories,
    RaceGuestTeamSignup,
    RaceLocationsMap,
    RaceMap,
    RaceReminderPreferences,
    RaceReminderSwitch,
    RaceResultsCategoryList,
    RaceRunnerMap,
    RegisteredRaceRunner,
    RegisteredTeam,
    RoadmapOverview,
    RunnersInvalidDoping,
    RunnersInvalidIof,
    SelectStart,
    SetFlareUser,
    SignupRunners,
    SponsorList,
    StartlistOverview,
    TeamsQuickEdit,
    TestUi,
    UserManagers,
    VueAppMenu,
    VueAssociationFilemanagement,
    VueBackButton,
    VueButton,
    VueCartBadge,
    VueCartBadgeHeader,
    VueCheckbox,
    VueClubSelect,
    VueCountdownWaiting,
    VueCreateRefund,
    VueDeleteTeam,
    VueEditor,
    VueForm,
    VueGuestMenu,
    VueInput,
    VueIntro,
    VueLangSwitcher,
    VueRaceAttendeeList,
    VueRaceAttendeeTeamList,
    VueRaceDatesManagement,
    VueRaceFilemanagement,
    VueRaceFinishCheckbox,
    VueResendVerification,
    VueScrollToTop,
    VueSearchTeamMembers,
    VueSelect,
    VueSessionSweetalert,
    VueSignupRunnerAsAdmin,
    VueSolvAddByNumber,
    VueSolvSearch,
    VueTeamCartStatusAction,
    VueTeamEditOverview,
    VueTeamMembers,
    VueTransactionDelete,
    YearSwitch,
  },
});

// TODO Upload vom Sourcemap, muss von Hand gebaut werden: https://flareapp.io/docs/javascript-error-tracking/resolving-bundled-code
if (process.env.NODE_ENV === 'production') {
  flare.light();
  app.use(flareVue);
}

importIcons();

app.provide('user', window.User);

app.use(ZiggyVue);
app.use(route);
app.use(lang);

app.use(createPinia());
app.use(VueTippy);

declare module '@vue/runtime-core' {
  type ComponentCustomProperties = {
    $lang: (name: string, attributes?: { [key: string]: string }) => string;
    $route: (name: string, parameters?: Array<any> | number | object | string, absolute?: boolean) => string;
  };
}
app.mount('#app');
