<template>
  <div>
    <!-- Button -->
    <div
      class="flex h-9 items-center p-1 text-white md:h-12"
      @click="toggleMenu">
      <i class="fas fa-ellipsis-v text-xl" />
    </div>

    <!-- Menü -->
    <transition name="fade">
      <div v-if="showMenu">
        <div class="fixed inset-0 z-40 h-screen w-full overflow-hidden">
          <div
            class="scrollbar absolute z-50 flex h-full w-3/4 flex-col bg-white shadow-mmenu sm:w-1/2 md:w-1/3">
            <div class="mt-2 flex items-center justify-between">
              <button
                class="flex items-center p-5 text-gray-700"
                @click="toggleMenu">
                <i class="fal fa-times text-4xl" />
              </button>
              <a
                class="block p-5"
                href="/home"><img
                  class="h-12"
                  src="../../../resources/images/logo/RGB_logo_coloredsmall.png"></a>
            </div>

            <slot />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const showMenu = ref(false);
const windowTop = ref(0);

function toggleMenu() {
  showMenu.value = !showMenu.value;

  const zendeskIcon = document.querySelector('#launcher');

  if (zendeskIcon !== undefined && zendeskIcon != undefined) {
    zendeskIcon.style.zIndex = '20';
  }

  // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  if (showMenu.value) {
    windowTop.value = document.documentElement.scrollTop;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${windowTop.value}px`;
  }
  else {
    document.body.style.position = '';
    document.documentElement.scrollTo(0, windowTop.value || 0);
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
