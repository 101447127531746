<template>
  <th
    :class="typeof header === 'object' && header.class ? header.class : 'bg-lightgray-200 px-4 py-2 text-start xl:first:rounded-tl-md xl:last:rounded-tr-md'">
    <component
      :is="header.sort_key ? 'button' : 'div'"
      v-if="typeof header === 'object'"
      v-tippy="tippy"
      :class="header.subclass"
      :type="header.sort_key ? 'button' : undefined"
      @click="toggle">
      <span v-if="header.label">{{ getTranslatedLabel(header.label) }}</span>
      <span class="flex">
        <iconify-icon
          v-if="header.icon"
          class="m-auto"
          :icon="header.icon" />
      </span>

      <SortIcon
        v-if="header.sort_key"
        class="my-auto"
        :state="state" />
    </component>
    <template v-else>
      {{ getTranslatedLabel(header) }}
    </template>
  </th>
</template>

<script setup lang="ts">
import type { TippyOptions } from 'vue-tippy';

import { getTranslatedLabel } from '@/helpers';
import { lang } from '@/plugins';
import { computed } from 'vue';

import SortIcon from './sortIcon.vue';

type Header = {
  class?: string;
  icon?: string;
  label?: string;
  sort_key?: string;
  subclass?: string;
  tooltip?: string;
} | string;

type Sort = {
  sort_asc?: boolean;
  sort_column?: string;
};

type Props = {
  header: Header;
  sort?: Sort;
};

type Emits = {
  sort: [value?: Sort];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const state = computed(() => {
  if (!props.sort || typeof props.header === 'string') return;
  if (props.sort.sort_column === props.header.sort_key) {
    return props.sort.sort_asc ? 'down' : 'up';
  }
  return undefined;
});

function toggle() {
  if (typeof props.header === 'string') return;
  if (props.sort && props.sort.sort_column && props.sort.sort_column === props.header.sort_key && props.sort.sort_asc) {
    emit('sort', { sort_asc: false, sort_column: props.header.sort_key });
  }
  else if (props.sort && props.sort.sort_column && !props.sort.sort_asc) {
    emit('sort');
  }
  else {
    emit('sort', { sort_asc: true, sort_column: props.header.sort_key });
  }
}

const tippy: TippyOptions = {
  allowHTML: true,
  arrow: true,
  content: typeof props.header === 'object' && props.header && props.header.tooltip ? lang(props.header.tooltip) : undefined,
  moveTransition: 'transform 0.2s ease-out',
  placement: 'top',
  theme: 'light',
  zIndex: 9999,
};
</script>
