<template>
  <div class="hidden">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { MarkerClusterer, type MarkerClustererOptions } from '@googlemaps/markerclusterer';

type Props = {
  locations: google.maps.marker.AdvancedMarkerElementOptions['position'][];
  map: google.maps.Map;
  minPoints?: number;
  options?: MarkerClustererOptions;
};
const props = defineProps<Props>();

onMounted(async () => {
  await initCluster();
});

async function initCluster() {
  const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
  const markers = props.locations.map(position => new AdvancedMarkerElement({ position }));

  new MarkerClusterer({ map: toRaw(props.map), markers });
}
</script>
