<template>
  <div class="flex gap-5">
    <SupSelect
      v-model="selectedCategoryRaceId"
      class="w-full"
      :disabled="disabled || isLoading"
      label="l.category"
      name="category_race_id"
      no-clear
      :options="categoryRaces"
      required
      @update:model-value="create" />
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, IRacePrice } from '@/interfaces';

import SupSelect from '@/components/ui/supSelect.vue';
import { errorHandler } from '@/helpers';

type Props = {
  categoryRaces: ICategoryRace[];
  disabled?: boolean;
  raceId: number;
};
type Emits = {
  created: [racePrice: IRacePrice];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { execute, isLoading } = useAxios<IRacePrice>();

const selectedCategoryRaceId = ref<number>();

async function create(categoryRaceId: number): Promise<void> {
  try {
    const { data } = await execute(route('webapi.price.store', { race: { id: props.raceId } }), {
      data: { amount: 0, category_race_id: categoryRaceId },
      method: 'POST',
    });
    if (data.value) {
      selectedCategoryRaceId.value = undefined;
      emit('created', data.value);
    }
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
