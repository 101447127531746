<template>
  <div
    class="info-window-wrapper">
    <div
      ref="infoWindowRef"
      v-bind="$attrs">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  map: google.maps.Map;
  marker: google.maps.marker.AdvancedMarkerElement;
  opened: boolean;
  options?: google.maps.InfoWindowOptions;
};
type Emits = {
  closeclick: [];
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const infoWindow = ref<google.maps.InfoWindow>();
const infoWindowRef = ref<HTMLElement>();

const close = () => infoWindow.value?.close();

onMounted(async () => {
  console.log(props);
  await initInfoWindow();
});

watch(() => props.opened, (newValue, oldValue) => {
  if (newValue === false && oldValue === true) {
    infoWindow.value?.close();
  }
});

async function initInfoWindow() {
  const { InfoWindow } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;

  infoWindow.value = new InfoWindow({
    ...props.options,
    content: infoWindowRef.value,

  });

  const _marker = toRaw(props.marker);

  _marker.addListener('click', () => {
    infoWindow.value?.open({
      anchor: _marker,
      map: toRaw(props.map),
    });
  });

  infoWindow.value.addListener('closeclick', () => {
    emit('closeclick');
  });
}

defineExpose({
  close,
  open,
});
</script>

<style scoped>
.info-window-wrapper {
  display: none;
}

.mapdiv .info-window-wrapper {
  display: inline-block;
}
</style>
