<template>
  <div class="grid w-full font-mono xl:overflow-hidden xl:rounded-lg xl:shadow-lg">
    <button
      class="flex cursor-pointer gap-5 border-b bg-white p-2 hover:bg-lightgray-100"
      type="button"
      @click="$emit('resetCategoryRace')">
      <i class="fas fa-chevron-left my-auto w-[22px] text-center text-xl md:text-2xl text-orange-500" />
      <div>
        <div class="my-auto text-left">
          {{ categoryRace.category.short_name }}
          ({{ `${finishedRaceRunnersCount}/${getMaxCount()}` }})
        </div>
      </div>
    </button>
    <div
      v-if="categoryRace.team_results"
      class="flex border-b bg-white px-5 last:border-none">
      <button
        v-for="(leg, index) in categoryRace.legs"
        :key="index"
        class="my-2 flex justify-between px-4 py-1"
        :class="
          selectedLeg === leg ? 'border-b-2 border-orange-500' : 'border-b-2 border-white hover:border-b-2'
        "
        type="button"
        @click="$emit('selectLeg', leg)">
        <span>{{ `${lang('l.leg')} ${leg}` }}</span>
        <div
          v-if="hasLegApprovedMeRunner(leg)"
          class="my-auto ml-2 flex">
          <i class="fas fa-person-running m-auto text-orange-500" />
        </div>
      </button>
    </div>
    <RaceResult
      v-for="(result, index) in sortResults(results)"
      :key="index"
      :class="{ 'hover:bg-lightgray-100': isResultClickable(result) }"
      :disabled="!isResultClickable(result)"
      :is-individual="isIndividual"
      :race-is-current="raceIsCurrent"

      :result="result"
      :team="
        categoryRace.team_results
          ? categoryRace.team_results.find((el) => el.id === result.team_result_id)
          : undefined
      "
      @click="$emit('selectRaceResult', categoryRace, result)" />
  </div>
</template>

<script setup lang="ts">
import type { ICategoryRace, IResult, ITeamResult } from '@/interfaces';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { computed, onMounted } from 'vue';
import { isFailed, isFinished, isRunning } from '../../helpers/rating';
import { lang } from '../../plugins/lang';
import RaceResult from './raceResult.vue';

type Props = {
  baseUrl: string;
  categoryRace: ICategoryRace;
  isIndividual?: boolean;
  raceIsCurrent: boolean;
  selectedLeg: number;
};
type Emits = {
  (e: 'resetCategoryRace'): void;
  (e: 'selectRaceResult', categoryRace: ICategoryRace, result: IResult): void;
  (e: 'selectLeg', leg: number): void;
};
const props = defineProps<Props>();
defineEmits<Emits>();

function hasLegApprovedMeRunner(leg: number) {
  if (!window.User || !window.User.approved_runner_ids) return false;
  let hasAprovedMeRunners = false;
  props.categoryRace.team_results.forEach((teamResult: ITeamResult) => {
    if (
      teamResult.results
      && teamResult.results[leg - 1]
      && teamResult.results[leg - 1].runner_id
      && window.User.approved_runner_ids.includes(teamResult.results[leg - 1].runner_id)
    ) {
      hasAprovedMeRunners = true;
    }
  });
  return hasAprovedMeRunners;
}

const finishedRaceRunnersCount = computed(() => {
  const cr = props.categoryRace;
  if (cr.team_results) {
    return cr.team_results.filter((teamResult) => {
      return (
        teamResult.results?.filter((result) => {
          if (result.rank) return true;
          if (!result.rating) return false;
          if (!result.is_read_out) return false;
          return isFinished(result.rating.id);
        }).length === cr.legs
      );
    }).length;
  }
  return props.categoryRace.results.filter((result) => {
    if (!result.rating) return false;
    return isFinished(result.rating.id);
  }).length;
});

function getMaxCount(): number {
  const cr = props.categoryRace;
  if (cr.team_results) {
    if (cr.team_results[0].results) {
      return cr.team_results.filter((teamResult) => {
        const firstLegResult = teamResult.results.find(result => result.leg === 1);
        if (!firstLegResult) return false;
        if (!firstLegResult.rating) return true;
        return firstLegResult.rating.id !== 2;
      }).length;
    }
  }
  return props.categoryRace.results.filter((result) => {
    if (!result.rating) return true;
    return result.rating.id !== 2;
  }).length;
}

const results = computed(() => {
  if (props.categoryRace.team_results) {
    const results: IResult[] = [];
    props.categoryRace.team_results.forEach((teamResult: ITeamResult) => {
      const result = teamResult.results.find(el => el.leg === props.selectedLeg);
      if (result) {
        results.push(result);
      }
      else {
        results.push(null);
      }
    });
    return results;
  }
  return props.categoryRace.results;
});

function sortResults(results: IResult[]): IResult[] {
  const sortedResults: IResult[][] = [];
  let index = 0;
  let finished = results.filter((result) => {
    if (!result) return false;
    if (!result.rating) return false;
    if (result.rating.id !== 1) return false;
    if (!result.rank) return false;
    if (!result.start_date_time) return false;
    return true;
  });
  if (props.categoryRace.team_results) {
    finished = sortBy(finished, (res) => {
      return res.rank;
    });
  }
  sortedResults.push(finished.length ? finished : []);
  removeUsedResults();

  // Control Stations
  if (props.categoryRace.control_stations.length) {
    for (let i = props.categoryRace.control_stations.length; i > 0; i--) {
      const cs = results.filter((result) => {
        if (!result) return false;
        if (result.rating && !isRunning(result.rating.id)) return false;
        if (result.rank) return false;
        if (!result.radio_control_station_results || result.radio_control_station_results.length !== i) return false;
        return true;
      });

      sortedResults.push(cs.length ? cs : []);
    }
    removeUsedResults();
  }
  // Not Started
  const notStarted = results
    .filter((result) => {
      if (!result) return false;
      if (result.rating && !isRunning(result.rating.id)) return false;
      if (result.rank) return false;
      return true;
    })
    .sort((a, b) => {
      return dayjs(a.start_date_time).unix() - dayjs(b.start_date_time).unix();
    });

  sortedResults.push(notStarted.length ? notStarted : []);
  removeUsedResults();

  // Failed
  const failed = results
    .filter((result) => {
      if (!result) return false;
      if (!result.rating) return false;
      if (!isFailed(result.rating.id)) return false;
      if (result.rank) return false;
      return true;
    })
    .sort((a, b) => a.rating.id - b.rating.id);

  sortedResults.push(failed.length ? failed : []);
  removeUsedResults();
  sortedResults.push(results);
  removeUsedResults();
  return sortedResults.flat();

  function removeUsedResults() {
    if (!results.length || !sortedResults.length) return;
    results = results.filter(result => result && !sortedResults[index].find(el => el.id === result.id));
    index++;
  }
}

function isResultClickable(result: IResult) {
  if (result.rating && result.rating.id === 2) return false;
  if (props.categoryRace.team_results) return false;
  return true;
}

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
