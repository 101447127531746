<template>
  <div class="mb-4 w-full 2xl:w-1/2 3xl:max-w-xl">
    <VueCard class="mx-2 flex h-full flex-col justify-between">
      <template #title>
        <div class="text-xl">
          {{ team.club_name }} {{ team.name }}
        </div>
        <VueButton @click="signoff">
          <i
            aria-hidden="true"
            class="far fa-trash" />
        </VueButton>
      </template>
      <div class="grow p-3">
        <div>
          {{ team.race.name }}
        </div>
        <div>
          {{ $lang('l.category.default') }}:
          <span>{{ team.category_race.category.short_name }}</span>
        </div>
        <div>
          <ul class="ml-8 list-outside list-disc">
            <li
              v-for="(slot, index) in team.slots"
              :key="index">
              <span v-if="!Array.isArray(slot.teamMember)">
                {{ slot.teamMember.firstname }} {{ slot.teamMember.lastname }}
              </span>
              <span v-else> - </span>
            </li>
          </ul>
        </div>
        <form
          v-if="team.race.has_discount_codes"
          class="mt-5 flex flex-col justify-between gap-5 border-y py-3"
          novalidate
          @submit="submit">
          <b class="my-auto text-xl">{{ $lang('l.discount') }}:</b>
          <BaseInput
            v-if="!validMessage"
            class="my-auto"
            :disabled="validMessage.length > 0 || isLoading"
            :label="$lang('l.discountCode')"
            name="code" />
          <div
            v-else-if="validMessage && !errorMessage"
            class="my-auto flex gap-2 text-green-500">
            <i class="fas fa-circle-check my-auto" />
            <div class="my-auto">
              {{ validMessage }}
            </div>
          </div>
          <div class="flex w-full justify-end">
            <BaseButton
              v-if="!validMessage && !errorMessage"
              class="my-auto"
              :disabled="!values.code || !values.code.length || isLoading"
              :label="$lang('l.discountCodeCheck')"
              :loading="isLoading"
              type="submit" />
            <div
              v-if="!validMessage && errorMessage"
              class="my-auto flex gap-2 text-red-500">
              <i class="fas fa-circle-x my-auto" />
              <div class="my-auto">
                {{ errorMessage }}
              </div>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <div class="h-7 w-full text-right text-lg font-bold">
          <p>{{ $lang('l.total') }}: CHF {{ team.price_info.tot }}</p>
        </div>
      </template>
    </VueCard>
  </div>
</template>

<script setup lang="ts">
import type { ITeam } from '@/interfaces';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { errorHandler } from '../../helpers/api';
import { lang } from '../../plugins/lang';
import { route } from '../../plugins/route';
import { useCartStore } from '../../store/cart';
import BaseButton from '../base/baseButton.vue';
import BaseInput from '../base/baseInput.vue';
import VueButton from '../button.vue';
import VueCard from '../card.vue';

type Props = {
  team: ITeam;
};
const props = defineProps<Props>();

const cartStore = useCartStore();
const { handleSubmit, values } = useForm({
  keepValuesOnUnmount: true,
});

const { execute, isLoading } = useAxios<ITeam | string>();
const validMessage = ref('');
const errorMessage = ref('');

if (props.team.has_discount) {
  validMessage.value = `${lang('l.discountCodeActivated')} | CHF ${props.team.price_info.discount}.-`;
}

const submit = handleSubmit(async (values) => {
  errorMessage.value = '';
  try {
    const { data } = await execute(route('discountCode.redeem.team', props.team), {
      data: { ...values, user_id: props.team.user_id },
      method: 'POST',
    });
    validMessage.value = lang('l.discountCodeActivated');
    const team = data.value as ITeam;
    const teams = cartStore.teams;
    const idx = teams.findIndex(el => el.id === team.id);
    teams[idx] = team;
    cartStore.$patch({
      teams: teams.map((team) => {
        return cartStore.mapCartItem(team);
      }),
    });
  }
  catch (err) {
    errorMessage.value = err.response.data as string;
  }
});

watch(
  () => values.code,
  () => {
    if (!validMessage.value && errorMessage.value) {
      errorMessage.value = '';
    }
  },
);
async function signoff() {
  try {
    await useAxios(route('webapi.team.removeFromCart', props.team.id), { method: 'DELETE' });
    const index = cartStore.teams.findIndex(item => item.id === props.team.id);
    cartStore.teams.splice(index, 1);
  }
  catch (error) {
    errorHandler(error);
  }
}
</script>
